import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaAngleRight } from 'react-icons/fa';

import TappableCard from '../common/TappableCard';
import { Text } from '../common/Text';
import { EarnBadge } from '../common/Fitcoin';

const OuterTappable = styled(TappableCard)`
  margin: 0.4rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(165, 165, 165, 0.1);
  border-radius: 8px;
`;

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 24px;
  height: 52px;
`;

const Icon = styled.img`
  max-width: 3.625rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: stretch;
`;

const TitleText = styled(Text)`
  font-size: 13px;
  float: left;
  margin-right: 0.5rem;
  color: #413958;
  margin-left: 7px;
`;

const SubtitleText = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #a5aab0;
  width: 200px;
`;

const ArrowIcon = styled(FaAngleRight)`
  color: #413958;
  font-size: 12px;
`;

const OpdBadge = styled.div`
  align-self: flex-start;
  margin: 0.625rem;
  margin-top: 0;
  padding: 0.25rem 0.75rem;
  background-image: linear-gradient(to right, #db4c6d, #ffa14f);
  border-radius: 2rem;
`;

const OpdBadgeText = styled(Text)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: white;
`;

const HomeActionCard = ({
  onTap,
  title,
  subtitle,
  icon,
  offer,
  opdBadge,
  className,
}) => (
  <OuterTappable onTap={onTap} className={className}>
    <OuterContainer>
      <TitleContainer>
        <div className="flex-center mb-4">
          <img src={icon} alt="" width={'20px'} height={'20px'} />
          <TitleText>{title}</TitleText>
          {offer && <span className="offer-box">{offer}</span>}
        </div>
      </TitleContainer>
      <div>
        <ArrowIcon />
      </div>
    </OuterContainer>
  </OuterTappable>
);

HomeActionCard.propTypes = {
  onTap: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.string.isRequired,
  earnBadge: PropTypes.bool,
  opdBadge: PropTypes.bool,
};

HomeActionCard.defaultProps = {
  subtitle: null,
  earnBadge: false,
  opdBadge: false,
};

export default HomeActionCard;
