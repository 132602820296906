import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

export function getRewards(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/console/rewards`, {
      headers,
      params: {
        v: config.version,
        web: true,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getRewardsHistory(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/fit-rewards/purchases`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function redeemProduct(
  productId,
  productType,
  phone,
  countryCode,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  const body = {
    productId,
    type: productType,
    phone,
    countryCode,
  };

  return axios
    .post(`${config.apiBaseUrl}/fit-rewards/redeem-product`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
