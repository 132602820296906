import React, { Component } from 'react';
import Modal from '../../containers/common/Modal';
import { FadeInTransition } from '../transitions/transitions';
import styled from 'styled-components';
import uploadIcon from '../../images/common/upload-success.svg';
const ModalContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

export class UploadSuccessModal extends Component {
  state = {
    showUploadOption: true,
  };
  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.setState({
      showUploadOption: false,
    });
    setTimeout(this.props.hideModal, 200);
  };
  hideModal = () => {
    this.setState({
      showUploadOption: false,
    });
    setTimeout(this.props.hideModal, 200);
  };

  render() {
    const { showUploadOption } = this.state;
    return (
      <Modal>
        <ModalContainer onClick={this.handleOutsideClick}>
          <FadeInTransition
            in={showUploadOption}
            mountOnEnter
            unmountOnExit
            onExited={this.props.hideModal}
            appear
          >
            <div
              className="bg-textbox rounded-12 pt-40px pl-24px pr-24px mr-10 ml-10"
              ref={(node) => (this.node = node)}
            >
              <div className="flex flex-col items-center text-center">
                <img src={uploadIcon} alt={'upload'} />
                <p className="mt-36px mb-8px text-title-m text-txtlight font-semibold">
                  {this.props.header}
                </p>
                <p className="text-body-s text-txtsecondary font-medium">
                  {this.props.message}
                </p>
                <div
                  className="mt-48px mb-16px bg-primary w-full p-12px rounded-12"
                  onClick={this.hideModal}
                >
                  <span className="text-button-s text-textbox font-semibold">
                    Got it
                  </span>
                </div>
              </div>
            </div>
          </FadeInTransition>
        </ModalContainer>
      </Modal>
    );
  }
}

export default UploadSuccessModal;
