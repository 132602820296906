import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';

import { initializeChatClient } from '../../services/chatClient';
import { setBotChatInfo, resetBotChat } from '../../actions/index';
import StyledTappable from '../../components/common/StyledTappable';
import { Text } from '../../components/common/Text';
import LoadingComponent from '../../components/common/LoadingComponent';
import UploadingComponent from '../../components/common/UploadingComponent';
import { FlyInTransition } from '../../components/transitions/transitions';
import UserMessage from '../../components/consultations/chat-bubbles/UserMessage';
import UserImageMessage from '../../components/consultations/chat-bubbles/UserImageMessage';
import UserFileMessage from '../../components/consultations/chat-bubbles/UserFileMessage';
import StringInput from '../../components/bot/StringInput';
import SingleListInput from '../../components/bot/SingleListInput';
import MultiSelectInput from '../../components/bot/MultiSelectInput';
import BotMessage from '../../components/bot/BotMessage';
import BotHtmlMessage from '../../components/bot/BotHtmlMessage';
import ConditionCardMessage from '../../components/bot/ConditionCardMessage';
import TypingIndicator from '../../components/consultations/TypingIndicator';
import { endChat, uploadFileToBotConversation } from '../../services';
import LookupInput from '../../components/bot/LookupInput';
import { logEvent } from '../../utils/logEvent';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  z-index: 10;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
`;

const HeaderTopContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0.875rem;
`;

const BackContainer = styled(StyledTappable)`
  padding-right: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledArrow = styled(FaArrowLeft)`
  width: 0.875rem;
`;

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: hidden;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderText = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const StyledUploadingComponent = styled(UploadingComponent)`
  margin: 0;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: white;
  padding: 0.375rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 999;
  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.1);
  background: white;
`;

class BotChatContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chatMessage: '',
      loading: true,
      uploading: false,
      uploadProgress: 0,
    };
    const { state } = props.location;
    const { dispatch, history } = props;
    this.conversationBodyRef = React.createRef();
    if (state) {
      const { chatName, channel, action } = state;
      dispatch(
        setBotChatInfo({
          chatChannel: channel,
          chatName: chatName,
          chatSubtitle: '',
          chatIcon: `https://api.getvisitapp.com/apiv2/user-images/doctor-${42}.png`,
          receiverId: -1,
        })
      );
      this.chatClient = initializeChatClient();
      this.chatClient.subscribe(channel);
      // Publish the first message
      this.chatClient.botPublish({
        text: action.text,
        flowType: action.flowType,
        flowDirective: {
          entryPoint: action.entryPoint,
        },
      });
    } else {
      history.replace('/home');
    }
  }

  // getSnapshotBeforeUpdate = prevProps => {
  //   const { chatMessages } = this.props.chatInfo;
  //   const previousChatMessages = prevProps.chatInfo.chatMessages;
  //   if (chatMessages.length > previousChatMessages.length) {
  //     const list = this.conversationBodyRef.current;
  //     return list
  //   }
  // }

  componentDidMount() {
    logEvent('Chat Bot Screen');
  }

  componentDidUpdate(prevProps, prevState) {
    const { chatMessages } = this.props.chatInfo;
    const previousChatMessages = prevProps.chatInfo.chatMessages;
    if (chatMessages.length > previousChatMessages.length) {
      this.conversationBodyRef.current.scrollTop =
        this.conversationBodyRef.current.scrollHeight;
    }
  }

  componentWillUnmount = () => {
    const { authToken, chatInfo, dispatch } = this.props;
    if (this.chatClient) {
      this.chatClient.unsubscribe(chatInfo.chatChannel);
    }
    dispatch(resetBotChat());
    endChat(authToken).catch((err) => {
      console.log(err);
    });
  };

  sendMessage = (messagePayload) => {
    console.log('sending mesage', messagePayload);
    this.chatClient.botPublish(messagePayload);
  };

  uploadAttachment = (ele) => {
    let inputElem = document.getElementById(ele.callToAction.action);
    const { authToken, userId } = this.props;
    const { uploadUrl } = ele.callToAction;

    const file = inputElem.files[0];

    if (!file) {
      return;
    }

    const data = new FormData();

    data.append('file', file);
    data.append('preview', file);
    data.append('userId', userId);
    data.append('doctorId', -1);
    this.setState({
      uploading: true,
    });
    const onUploadProgress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const { loaded, total } = progressEvent;
        this.setState({
          uploadProgress: (loaded / total) * 100,
        });
      } else {
        this.setState({
          uploadProgress: 100,
        });
      }
    };
    uploadFileToBotConversation(uploadUrl, data, onUploadProgress, authToken)
      .then((res) => {
        const message = {
          text: 'image',
          url: res.fileUrl,
          cardType: 'chatImage',
          filePreviewUrl: res.filePreview,
        };
        this.sendMessage(message);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          uploading: false,
        });
      });
  };

  showDoctors = () => {
    const { history } = this.props;
    history.replace('/consultation/doctor-select', {
      specialistSearch: false,
      searchType: 'treat',
    });
  };

  render() {
    const { chatInfo, history } = this.props;
    const { uploading, uploadProgress } = this.state;
    const renderUserMessage = (ele) => {
      switch (ele.cardType) {
        case 'text':
          return (
            <UserMessage
              key={ele.messageId}
              message={ele.text}
              timestamp={ele.timetoken}
            />
          );
        case 'chatImage':
          return (
            <UserImageMessage
              key={ele.messageId}
              filePreviewUrl={ele.filePreviewUrl}
              url={ele.url}
              timestamp={ele.timetoken}
            />
          );
        case 'documentCard':
          return (
            <UserFileMessage
              fileName={ele.documentFileName}
              url={ele.documentUrl}
              messageId={ele.messageId}
              key={ele.messageId}
              timestamp={ele.timetoken}
            />
          );
        default:
          return false;
      }
    };
    const renderBotMessage = (ele) => {
      switch (ele.cardType) {
        case 'text':
          return (
            <BotMessage
              key={ele.messageId}
              message={ele.text}
              timestamp={ele.timetoken}
            />
          );
        case 'html':
          return <BotHtmlMessage key={ele.messageId} link={ele.link} />;
        case 'conditionCard':
          return (
            <ConditionCardMessage
              key={ele.messageId}
              cardDetails={ele.cardDetails}
            />
          );
        default:
          return (
            <BotMessage
              key={ele.messageId}
              message={ele.text}
              timestamp={ele.timetoken}
            />
          );
      }
    };
    const renderChatMessage = (ele) => {
      console.log(ele);
      switch (ele.userType) {
        case 'user':
          return renderUserMessage(ele);
        case 'bot':
          return renderBotMessage(ele);
        default:
          return renderUserMessage(ele);
      }
    };

    const ConversationInput = (chatMessage) => {
      switch (chatMessage.flowDirective.inputType) {
        case 'singleSelect':
          return (
            <SingleListInput
              options={chatMessage.flowDirective.elements}
              showDoctors={this.showDoctors}
              sendMessage={this.sendMessage}
              uploadAttachment={this.uploadAttachment}
            />
          );
        // case 'multiList':
        //   return <MultiListInput options={flowDirective.elements} sendMessage={this.sendMessage} />;
        // case 'lookup':
        //   return (
        //     <LookupInput
        //       searchApi={flowDirective.searchApi}
        //       sendMessage={this.sendMessage}
        //       itemIcon={flowDirective.icon} />
        //   );
        case 'autoComplete':
          return (
            <LookupInput
              searchApi={chatMessage.flowDirective.searchApi}
              sendMessage={this.sendMessage}
            />
          );
        case 'multipleSelect':
          return (
            <MultiSelectInput
              options={chatMessage.flowDirective.elements}
              sendMessage={this.sendMessage}
            />
          );
        case 'keyboardText':
          return <StringInput sendMessage={this.sendMessage} />;
        case 'keyboardNumber':
          return <StringInput numeric sendMessage={this.sendMessage} />;
        // case 'noInput':
        //   return <NoInput />;
        // case 'otp':
        //   return <OtpInput sendMessage={this.sendMessage} userPhone={this.props.responseOptions.userPhone} />;
        default:
          return null;
      }
    };

    const ConversationBody = () =>
      chatInfo.chatMessages.length === 0 ? (
        <LoadingComponent />
      ) : (
        <>
          <BodyContainer
            ref={this.conversationBodyRef}
            onScroll={this.onListScroll}
          >
            {chatInfo.chatMessages.map(renderChatMessage)}
            <FlyInTransition
              in={
                chatInfo.chatMessages[chatInfo.chatMessages.length - 1]
                  .senderId !== '-1'
              }
              mountOnEnter
              unmountOnExit
              appear
            >
              <TypingIndicator />
            </FlyInTransition>
          </BodyContainer>
          <InputContainer>
            {ConversationInput(
              chatInfo.chatMessages[chatInfo.chatMessages.length - 1]
            )}
            {/* <input
              type="file"
              id="imageAttachment"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={this.handleImageAttachment}
            />
            <input
              type="file"
              id="fileAttachment"
              accept=".pdf"
              style={{ display: 'none' }}
              onChange={this.handleFileAttachment}
            />
            <InputTappable onTap={this.attachFile}>
              <AttachIcon />
            </InputTappable>
            <InputBox
              valueLink={chatMessageLink}
              className="visit-input"
              placeholder="Type your message here"
            />
            <InputTappable
              onTap={this.sendMessage}
              disabled={chatMessage.trim().length === 0}>
              <SendIcon active={chatMessage.trim().length !== 0} />
            </InputTappable> */}
          </InputContainer>
        </>
      );
    return (
      <OuterContainer>
        <HeaderContainer>
          <HeaderTopContainer>
            <BackContainer
              onTap={() => {
                logEvent('Chat Bot Close with Cross Button');
                history.goBack();
              }}
            >
              <StyledArrow />
            </BackContainer>
            <HeaderTextContainer>
              <HeaderTitleContainer>
                <HeaderText>{chatInfo.chatName}</HeaderText>
              </HeaderTitleContainer>
            </HeaderTextContainer>
          </HeaderTopContainer>
        </HeaderContainer>
        {uploading && (
          <StyledUploadingComponent
            uploadProgress={`${uploadProgress}%`}
            borderRadius={0}
          />
        )}
        {ConversationBody()}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  chatInfo: state.botChat,
  userId: state.user.userId,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(BotChatContainer);
