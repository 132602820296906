export const defaultState = {
  userId: null,
  authToken: null,
  userName: null,
  userEmail: null,
  countryCode: null,
  userPhone: null,
  dateOfBirth: null,
  userAge: 0,
  userGender: null,
  otpVerified: false,
  emailVerified: false,
  sponsorId: 0,
  verifiedSponsor: false,
  userUUID: null,
  platform: null,
  oldPhone: null,
  versionCode: null,
  referralCode: null,
  referralLink: null,
  createdAt: null,
  ftAvailable: false,
  sponsorCommonName: null,
  feedAvailable: false,
  qChat: false,
  inviteShareText: null,
  profileImage: null,
  inviteFitReward: null,
  supportPhone: null,
  streamId: 0,
  userChannelGroup: null,
  validateSponsor: false,
  walletBalance: 0,
  searchText: '',
  manageDependentLink: '',
  ticketingUrl: '',
  selectedVerticalByUser: '',
  selectedScreen: '/home',
  dependentsList: '',
  patientsList: '',
  showMainUser: true,
  memberId: '',
};

export default function user(state = defaultState, action) {
  console.log(action.payload);
  switch (action.type) {
    case 'SET_USER_INFO':
      return Object.assign({}, state, action.payload.userInfo);
    case 'UNSET_USER_INFO':
      return Object.assign({}, defaultState);
    case 'AUTH_TOKEN':
      return Object.assign({}, state, {
        authToken: action.payload,
      });
    case 'SET_FITCOIN_BALANCE':
      return Object.assign({}, state, {
        walletBalance: action.payload.balance,
      });
    case 'SET_SEARCH_TEXT':
      return Object.assign({}, state, {
        searchText: action.payload,
      });
    case 'SET_MANAGE_DEPENDENT_LINK':
      return Object.assign({}, state, {
        manageDependentLink: action.payload,
      });
    case 'SET_USER_NAME':
      return Object.assign({}, state, {
        userName: action.payload,
      });
    case 'SET_USER_EMAIL':
      return Object.assign({}, state, {
        userEmail: action.payload,
      });
    case 'SET_USER_GENDER':
      return Object.assign({}, state, {
        userGender: action.payload,
      });
    case 'SET_TICKETING_URL':
      return Object.assign({}, state, {
        ticketingUrl: action.payload,
      });
    case 'SET_CURRENT_VERTICAL':
      return Object.assign({}, state, {
        selectedVerticalByUser: action.payload,
      });
    case 'SET_POLICY_DETAILS':
      return Object.assign({}, state, {
        dependentsList: action.payload.dependentsList,
        patientsList: action.payload.patientsList,
        showMainUser: action.payload.showMainUser,
        memberId: action.payload.memberId,
      });
    case 'SET_CURRENT_STATE': 
      return Object.assign({}, state, {
          selectedScreen: action.payload
        })
    default:
      return state;
  }
}
