import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CardContainer from '../../components/common/CardContainer';
import { BoldText, LinkText, ButtonText } from '../../components/common/Text';
import { PrimaryButton } from '../../components/common/Buttons';

import rewardsBg from '../../images/home/rewards-bg.jpg';
import rewardsBrand from '../../images/home/rewards-brand.png';
import StyledTappable from '../common/StyledTappable';

const OuterContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-shrink: 0;
  justify-content: center;
  align-items: stretch;
  padding: 1rem 1.25rem 3rem 1.25rem;
  margin-bottom: 1.5rem;
  width: ${() =>
    `${document.body.clientWidth >= 800 ? 800 : document.body.clientWidth}px`};
  height: ${() =>
    `${
      0.82 *
      (document.body.clientWidth >= 800 ? 375 : document.body.clientWidth)
    }px`};
  background-image: ${() => `url(${rewardsBg})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
`;

const StyledCardContainer = styled(CardContainer)`
  padding: 0.625rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  text-align: center;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
`;

const BrandImages = styled.img`
  width: 100%;
  max-width: 14rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-evenly;
  align-items: center;
`;

const StyledButtonText = styled(ButtonText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const StyledLinkText = styled(LinkText)`
  text-decoration: underline;
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-top: 1rem;
`;

const RewardsCard = ({ onSeeAllTapped, onKnowMoreTapped }) => (
  <OuterContainer>
    <StyledCardContainer>
      <TitleContainer>
        <TitleText>Claim FITCoins for rewards from brands you love</TitleText>
      </TitleContainer>
      <BodyContainer>
        <BrandImages src={rewardsBrand} />
      </BodyContainer>
      <ButtonContainer>
        <PrimaryButton onTap={onSeeAllTapped}>
          <StyledButtonText>See All Offers</StyledButtonText>
        </PrimaryButton>
      </ButtonContainer>
      <StyledTappable onTap={onKnowMoreTapped}>
        <StyledLinkText>Know more</StyledLinkText>
      </StyledTappable>
    </StyledCardContainer>
  </OuterContainer>
);

RewardsCard.propTypes = {
  onSeeAllTapped: PropTypes.func,
  onKnowMoreTapped: PropTypes.func,
};

export default RewardsCard;
