import React, { Component } from 'react';
import styled from 'styled-components';

import { BoldText, Text } from '../../components/common/Text';
import Header from '../../components/common/Header';
import { FadeInTransition } from '../../components/transitions/transitions';
import gavel from '../../images/policy/gavel.svg';
import medicine from '../../images/policy/medicine.svg';
import hospital from '../../images/policy/hospital.svg';
import herbal from '../../images/policy/herbal.svg';
import substance from '../../images/policy/substance.svg';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  // border: 4mm solid rgb(20, 50, 220, 0.6);
  align-items: stretch;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  align-items: center;
  // max-width: 50rem;
  // border: 4mm solid rgb(170, 50, 220, 0.6);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Body = styled.div`
  display: flex;
  flex-shrink: 0;
  /* flex: 1; */
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 2rem;
  max-width: 30rem;
  // align-self: center;
  // border: 4mm solid rgb(170, 50, 20, 0.6);
  // justify-content: center;
  // overflow-y: scroll;
  overflow-x: hidden;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex: 1 0; */
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  /* align-items: stretch; */
  margin-top: 2rem;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: center;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 0.625rem;
  margin-left: 0.625rem;
`;

const SubTitleText = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  // align-self: flex-start;
  text-align: center;
  margin: 0.625rem 1.25rem;
  margin-top: 0rem;
`;

const IncompleteLine = styled.div`
  display: flex;
  height: 0.1rem;
  align-self: stretch;
  background-color: #cbcbcb;
  flex: 1;
  margin: 0.625rem;
`;

const Icon = styled.img`
  max-width: 3.5rem;
  /* margin-top: 2rem; */
  align-self: center;
`;

class WhatsNotCovered extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: [
        {
          title: 'Hospitalization Costs & Daycare Procedures',
          desc: 'Inpatient treatment and daycare procedures will not be covered in the insurance policy.',
          image: 'hospital',
        },
        {
          title: 'Naturopathy treatment(s)',
          desc: 'Naturopathy treatment(s) will not be covered in the insurance policy.',
          image: 'herbal',
        },
        {
          title: 'Substance abuse and de-addiction programs for insured person',
          desc: 'Abuse or the consequences of the abuse of intoxicants or hallucinogenic substances such as intoxicating drugs and alcohol, including smoking cessation programs and the treatment of nicotine addiction or any other substance abuse treatment or services, or supplies.',
          image: 'substance',
        },
        {
          title: 'Breach of law',
          desc: 'Any Insured Person committing or attempting to commit a breach of law with criminal intent, or intentional self injury or attempted suicide while sane or insane.',
          image: 'gavel',
        },
      ],
      loading: false,
    };
  }

  renderInfo = () => {
    const allComponents = [];

    this.state.info.forEach((i, index) => {
      let icon;
      switch (i.image) {
        case 'gavel':
          icon = gavel;
          break;
        case 'hospital':
          icon = hospital;
          break;
        case 'substance':
          icon = substance;
          break;
        case 'medicine':
          icon = medicine;
          break;
        case 'herbal':
          icon = herbal;
          break;
        default:
          break;
      }
      allComponents.push(
        <InfoContainer key={`${i.image}_${index}`}>
          <Icon src={icon} alt="Success" />
          <TitleText>{i.title}</TitleText>
          <SubTitleText>{i.desc}</SubTitleText>
          <IncompleteLine />
        </InfoContainer>
      );
    });

    return allComponents;
  };

  render() {
    console.log({ props: this.props });
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <Header title="What’s not covered?" />
          <BodyContainer>
            <Body>
              {/* <HeaderText>What’s not covered?</HeaderText> */}

              {this.renderInfo()}
            </Body>
          </BodyContainer>
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

export default WhatsNotCovered;
