import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  OTSession,
  OTPublisher,
  OTStreams,
  OTSubscriber,
  preloadScript,
} from 'opentok-react';
import { FaTimes } from 'react-icons/fa';
import config from '../../config';
// import { stopVideoCall } from '../services';
import StyledTappable from '../../components/common/StyledTappable';
import { stopVideoCall } from '../../actions';

const OuterContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const Body = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /*centers items on the line (the x-axis by default)*/
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
  margin-bottom: 20rem;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  z-index: 999;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #dadada;
`;

const CloseIcon = styled(FaTimes)`
  align-self: center;
  width: 2rem;
  height: 2rem;
`;

class VideoCallContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (!props.video.startCall || !props.video.videoCallPayload) {
      props.history.goBack();
    }
  }

  stopVideoCall = () => {
    console.log('stopVideoCall');
    const { dispatch } = this.props;
    dispatch(stopVideoCall());
    // stopVideoCall(
    //   this.props.location.state.authToken,
    //   this.props.location.state.doctorId,
    //   this.props.location.state.sessionId
    // )
    //   .then(result => {
    //     console.log({ result }, 'result from stopVideoCall');
    //     this.props.history.goBack();
    //   })
    //   .catch(err => {
    //     console.log({ err });
    //   });
  };

  componentWillUnmount = () => {
    console.log('componentWillUnmount called in video call');
    const { dispatch } = this.props;
    dispatch(stopVideoCall());
  };

  render() {
    if (!this.props.video.startCall || !this.props.video.videoCallPayload) {
      this.props.history.goBack();
      return null;
    }

    const { userToken, sessionId } = this.props.video.videoCallPayload;

    console.log(
      { userToken, sessionId, otKey: config.opentokApiKey },
      'required props in video call'
    );

    return (
      <OuterContainer>
        <OTSession
          apiKey={config.opentokApiKey.toString()}
          sessionId={sessionId}
          token={userToken}
        >
          <OTPublisher />
          <OTStreams>
            <OTSubscriber />
          </OTStreams>
        </OTSession>
        <Body>
          <CloseContainer onTap={() => this.stopVideoCall()}>
            <CloseIcon />
          </CloseContainer>
        </Body>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  authToken: state.user.authToken,
  video: state.video,
});

export default connect(mapStateToProps)(preloadScript(VideoCallContainer));
