import React, { Component } from 'react';
import styled from 'styled-components';
import HomeIcon from '../../images/header/Home.svg';
import InsurerIcon from '../../images/header/Insurer.svg';
import RewardIcon from '../../images/header/Gift.svg';
import DisbaledInsureIcon from '../../images/header/DInsurer.png';
import DisabledRewardsIcon from '../../images/header/DRewards.png';
import DisabledHomeIcon from '../../images/header/DHome.svg';
import { FaChevronRight } from 'react-icons/fa';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCurrentActiveTab } from '../../actions';
import ClaimsIcon from '../../images/accounts/Claims.svg';
import ClinicIcon from '../../images/accounts/ClinicAppointment.svg';
import DependentsIcon from '../../images/accounts/DependantIcon.svg';
import FaqIcon from '../../images/accounts/faq.svg';
import InfoIcon from '../../images/accounts/Info.svg';
import LabsIcon from '../../images/accounts/Labs.svg';
import LogoutIcon from '../../images/accounts/Logout.svg';
import MedicineIcon from '../../images/accounts/MedicineIcon.svg';
import OnlineConsultsIcon from '../../images/accounts/OnlineConsults.svg';
import PurchaseIcon from '../../images/accounts/PurchaseIcon.svg';
import TermIcon from '../../images/accounts/TC.svg';
import BackButton from '../../images/accounts/BackButton.svg';
import initializeFreshDesk from '../../utils/freshchat';
import AccountSection from '../../components/home/AccountSection';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 375px;
  max-width: 375px;
  overflow: scroll;
  margin-bottom: 224px;
  user-select: none;
`;

const TabCardsContainer = styled.div`
  width: 100%;
  background: #ffffff;
`;

const TabCard = styled.div`
  width: 100%;
  padding: 24px 20px;
  background: #ffffff;
  border-radius: 16px;
  border: 2px solid #01bfbd;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const ActiveImage = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 19px;
`;

const ActiveTabText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.3px;
  color: #01bfbd;
`;

const InActiveTabCard = styled.div`
  opacity: 0.4;
  width: 100%;
  padding: 24px 20px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
`;

const InActiveTabTex = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.3px;
  color: #0f0b28;
`;

const BlockContainer = styled.div`
  display: flex;
  padding: 20px 0px 0px 0px;
  align-items: center;
  flex-direction: column;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  background: #ffffff;
  padding: 16px 30px 16px 30px;
  border-radius: 16px;
  margin-bottom: 8px;
`;

const ItemText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #0f0b28;
`;

const RightArrowIcon = styled(FaChevronRight)`
  width: 6px;
  color: #a5a6bb;
`;

const RightArrowBlue = styled(FaChevronRight)`
  width: 6px;
  color: #01bfbd;
`;

const QuickActionsContainer = styled.div`
  margin-top: 20px;
  bacground: #f8f8f9;
`;

const SubHeaders = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #0f0b28;
`;

const tabs = [
  {
    title: 'Home',
    icon: HomeIcon,
    link: '/home',
    disabledIcon: DisabledHomeIcon,
  },
  // {
  //   title: 'Insure',
  //   icon: InsurerIcon,
  //   link: '/home/insure',
  //   disabledIcon: DisbaledInsureIcon,
  // },
  {
    title: 'Rewards',
    icon: RewardIcon,
    link: '/home/rewards',
    disabledIcon: DisabledRewardsIcon,
  },
];

class SideNavigator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAccount: false,
    };

    console.log(props);
  }

  handleCardClicks = (link, title) => {
    const { history } = this.props;
    const currentState = String(title).toLowerCase();
    history.push(link);
    this.props.dispatch(setCurrentActiveTab(currentState));
  };

  render() {
    const { config, dontShowNavigator } = this.props;
    const renderTab = (tab) => {
      switch (tab.title) {
        case 'Home':
          return (
            <>
              {this.props.currentActiveTab === 'home' ? (
                <TabCard
                  onClick={() => this.handleCardClicks(tab.link, tab.title)}
                >
                  <ActiveImage src={tab.icon} />
                  <ActiveTabText>{tab.title}</ActiveTabText>
                </TabCard>
              ) : (
                <InActiveTabCard
                  onClick={() => this.handleCardClicks(tab.link, tab.title)}
                  style={{ borderBottom: '1px solid #EEEEEE' }}
                >
                  <ActiveImage src={tab.disabledIcon} />
                  <InActiveTabTex>{tab.title}</InActiveTabTex>
                </InActiveTabCard>
              )}
            </>
          );
        case 'Insure': {
          if (config.insured || config.subscribed) {
            return (
              <>
                {this.props.currentActiveTab === 'insure' ? (
                  <TabCard
                    onClick={() => this.handleCardClicks(tab.link, tab.title)}
                  >
                    <ActiveImage src={tab.icon} />
                    <ActiveTabText>{tab.title}</ActiveTabText>
                  </TabCard>
                ) : (
                  <InActiveTabCard
                    onClick={() => this.handleCardClicks(tab.link, tab.title)}
                    style={{ borderBottom: '1px solid #EEEEEE' }}
                  >
                    <ActiveImage src={tab.disabledIcon} />
                    <InActiveTabTex>{tab.title}</InActiveTabTex>
                  </InActiveTabCard>
                )}
              </>
            );
          }
          return;
        }
        case 'Rewards': {
          if (config.showFitCoins) {
            return (
              <>
                {this.props.currentActiveTab === 'rewards' ? (
                  <TabCard
                    onClick={() => this.handleCardClicks(tab.link, tab.title)}
                  >
                    <ActiveImage src={tab.icon} />
                    <ActiveTabText>{tab.title}</ActiveTabText>
                  </TabCard>
                ) : (
                  <InActiveTabCard
                    onClick={() => this.handleCardClicks(tab.link, tab.title)}
                  >
                    <ActiveImage src={tab.disabledIcon} />
                    <InActiveTabTex>{tab.title}</InActiveTabTex>
                  </InActiveTabCard>
                )}
              </>
            );
          }
          return;
        }
        default:
          return;
      }
    };
    const renderFirstBlock = () => {
      const { showManageDependent, showFitCoins } = this.props.config;
      if (showManageDependent || showFitCoins) {
        return (
          <BlockContainer>
            {showManageDependent && this.props.manageDependentLink && (
              <ItemContainer
                onClick={() => window.open(this.props.manageDependentLink)}
              >
                <div className="flex flex-row items-center">
                  <img
                    src={DependentsIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText>Manage Dependents</ItemText>
                </div>
                <RightArrowIcon />
              </ItemContainer>
            )}
            {showFitCoins && (
              <ItemContainer
                onClick={() =>
                  this.handleCardClicks('/rewards/history', 'purchase-history')
                }
                style={
                  this.props.currentActiveTab === 'purchase-history'
                    ? {
                        border: '1px solid #01BFBD',
                        background: '#FBFAFF',
                      }
                    : {}
                }
              >
                <div className="flex flex-row items-center">
                  <img
                    src={PurchaseIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText
                    style={
                      this.props.currentActiveTab === 'purchase-history'
                        ? { color: '#01BFBD', fontWeight: '700' }
                        : {}
                    }
                  >
                    Purchase History
                  </ItemText>
                </div>
                <RightArrowIcon />
              </ItemContainer>
            )}
          </BlockContainer>
        );
      }
      return;
    };

    const renderSecondBlock = () => {
      const { showOffline, showMeds, showLabs, showReimbursment, showQChat } =
        this.props.config;
      if (showOffline || showMeds || showLabs || showReimbursment) {
        return (
          <BlockContainer>
            {showOffline && (
              <ItemContainer
                onClick={() =>
                  this.handleCardClicks('/offline/orders', 'clinic-orders')
                }
                style={
                  this.props.currentActiveTab === 'clinic-orders'
                    ? {
                        border: '1px solid #01BFBD',
                        background: '#FBFAFF',
                      }
                    : {}
                }
              >
                <div className="flex flex-row items-center">
                  <img
                    src={ClinicIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText
                    style={
                      this.props.currentActiveTab === 'clinic-orders'
                        ? { color: '#01BFBD', fontWeight: '700' }
                        : {}
                    }
                  >
                    My Appointments
                  </ItemText>
                </div>
                <RightArrowBlue />
              </ItemContainer>
            )}
            {showMeds && (
              <ItemContainer
                onClick={() =>
                  this.handleCardClicks('/pharmacy/orders', 'medicine-orders')
                }
                style={
                  this.props.currentActiveTab === 'medicine-orders'
                    ? {
                        border: '1px solid #01BFBD',
                        background: '#FBFAFF',
                      }
                    : {}
                }
              >
                <div className="flex flex-row items-center">
                  <img
                    src={MedicineIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText
                    style={
                      this.props.currentActiveTab === 'medicine-orders'
                        ? { color: '#01BFBD', fontWeight: '700' }
                        : {}
                    }
                  >
                    My Medicine Orders
                  </ItemText>
                </div>
                <RightArrowBlue />
              </ItemContainer>
            )}
            {showQChat && (
              <ItemContainer
                onClick={() =>
                  this.handleCardClicks('/online/orders', 'online-orders')
                }
                style={
                  this.props.currentActiveTab === 'online-orders'
                    ? {
                        border: '1px solid #01BFBD',
                        background: '#FBFAFF',
                      }
                    : {}
                }
              >
                <div className="flex flex-row items-center">
                  <img
                    src={OnlineConsultsIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText
                    style={
                      this.props.currentActiveTab === 'online-orders'
                        ? { color: '#01BFBD', fontWeight: '700' }
                        : {}
                    }
                  >
                    My Online Consults
                  </ItemText>
                </div>
                <RightArrowBlue />
              </ItemContainer>
            )}
            {showLabs && (
              <ItemContainer
                onClick={() =>
                  this.handleCardClicks('/lab-test', 'labs-orders')
                }
                style={
                  this.props.currentActiveTab === 'labs-orders'
                    ? {
                        border: '1px solid #01BFBD',
                        background: '#FBFAFF',
                      }
                    : {}
                }
              >
                <div className="flex flex-row items-center">
                  <img
                    src={LabsIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText
                    style={
                      this.props.currentActiveTab === 'labs-orders'
                        ? { color: '#01BFBD', fontWeight: '700' }
                        : {}
                    }
                  >
                    My Labs Tests
                  </ItemText>
                </div>
                <RightArrowBlue />
              </ItemContainer>
            )}
            {showReimbursment && (
              <ItemContainer
                onClick={() => this.handleCardClicks('/claims', 'my-claims')}
                style={
                  this.props.currentActiveTab === 'my-claims'
                    ? {
                        border: '1px solid #01BFBD',
                        background: '#FBFAFF',
                      }
                    : {}
                }
              >
                <div className="flex flex-row items-center">
                  <img
                    src={ClaimsIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText
                    style={
                      this.props.currentActiveTab === 'my-claims'
                        ? { color: '#01BFBD', fontWeight: '700' }
                        : {}
                    }
                  >
                    My Claims
                  </ItemText>
                </div>
                <RightArrowBlue />
              </ItemContainer>
            )}
          </BlockContainer>
        );
      }
    };
    return (
      <OuterContainer>
        {!dontShowNavigator ? (
          <>
            {' '}
            <TabCardsContainer style={{ borderRadius: '16px' }}>
              {tabs.map((tab) => renderTab(tab))}
            </TabCardsContainer>
            {((this.props.config.showManageDependent &&
              this.props.manageDependentLink) ||
              this.props.config.showFitCoins) && (
              <QuickActionsContainer>
                <SubHeaders>Quick Actions</SubHeaders>
                <div>{renderFirstBlock()}</div>
              </QuickActionsContainer>
            )}
            <QuickActionsContainer>
              <SubHeaders>My Transactions</SubHeaders>
              <div>{renderSecondBlock()}</div>
            </QuickActionsContainer>
            <div
              style={{ marginTop: '12px' }}
              id={'side-navigator-account-section'}
            >
              <SubHeaders>Profile</SubHeaders>
              <div style={{ marginTop: '12px' }}>
                <AccountSection
                  openEditAccount={() =>
                    this.props.history.push('/edit-account')
                  }
                />
              </div>
            </div>{' '}
          </>
        ) : (
          ''
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.app.config,
  currentActiveTab: state.app.currentActiveTab,
  manageDependentLink: state.user.manageDependentLink,
  dontShowNavigator: state.app.dontShowNavigator,
});

export default withRouter(connect(mapStateToProps)(SideNavigator));
