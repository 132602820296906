import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';
import headphones from '../../images/common/headphones.svg';

const FABContainer = styled(Tappable)`
  width: 56px;
  height: 56px;
  border-radius: 44px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
`;

const FAB = () => {
  const user = useSelector((state) => state.user);

  return (
    <FABContainer
      onClick={() =>
        window.open(
          `${user.ticketingUrl}?token=${user.authToken.split(' ')[1]}`,
          '_blank'
        )
      }
    >
      <img src={headphones} alt="support" />
    </FABContainer>
  );
};

export default FAB;
