import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { connect } from 'react-redux';
import { FadeInTransition } from '../../components/transitions/transitions';
import VisitLogo from '../../images/visit-logo-white.svg';
import { loginMagicLink, updateLoginState } from '../../actions';
import { logEvent } from '../../utils/logEvent';

const LogoAnimation = keyframes`
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0.25;
    }
    50% {
        opacity: 0.5;
    }
    75% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
`;

const ContentAnimation = keyframes`
    0% {
        transform: translateY(100%);
    }
    25% {
        transform: translateY(75%);
    }
    50% {
        transform: translateY(50%);
    }
    75% {
        transform: translateY(25%);
    }
    100% {
        transform: translateY(0%);
    }
`;

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #01bfbd;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  animation-name: ${LogoAnimation};
  animation-duration: 0.5s;
`;

const ContentText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  animation-name: ${ContentAnimation};
  animation-duration: 0.5s;
  margin-left: 80px;
  margin-right: 80px;
`;

class NewOnboardingHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogo: false,
    };
  }

  startTheAnimationTimer = () => {
    setTimeout(() => {
      this.setState({ showLogo: true });
    }, 1000);
  };

  componentDidMount() {
    this.startTheAnimationTimer();
    const { dispatch, history, location, loginState } = this.props;
    window.baseLandingUrl = window.location.href;
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    const magicLink = searchParams.get('mluib7c');
    if (magicLink) {
      logEvent('Onboarding Magic Code Applied');
      dispatch(updateLoginState('LOGGED_OUT'));
      dispatch({
        type: 'UNSET_USER_INFO',
      });
      dispatch(loginMagicLink(magicLink, history, searchParams.get('tab')));
    } else {
      setTimeout(() => {
        history.push('/carousel');
      }, 3000);
    }
  }

  //   componentDidMount = () => {
  //     const { dispatch, history, location, loginState } = this.props;
  //     window.baseLandingUrl = window.location.href;
  //     const { search } = location;
  //     const searchParams = new URLSearchParams(search);
  //     const magicLink = searchParams.get('mluib7c');
  //     if (magicLink) {
  //       logEvent('Onboarding Magic Code Applied');
  //       this.setState({ showLoadingModal: true });
  //       dispatch(updateLoginState('LOGGED_OUT'));
  //       dispatch({
  //         type: 'UNSET_USER_INFO',
  //       });
  //       dispatch(loginMagicLink(magicLink, history, searchParams.get('tab')));
  //     } else if (loginState === 'LOGGED_IN') {
  //       logEvent('Onboarding User Logged In');
  //       history.replace('/home');
  //     } else if (loginState === 'ONE_LAST_STEP') {
  //       logEvent('Onboarding One Last Step Screen');
  //       history.push('/one-last-step');
  //     } else if (loginState === 'SELECT_USER') {
  //       history.push('/select-user');
  //     } else {
  //       // GA Tracking
  //       window.gtag('event', 'screen_view', {
  //         screen_name: 'Intro Screen',
  //       });
  //       logEvent('Onboarding Intro Screen');
  //       window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
  //         .then((sdk) => {
  //           if (sdk.isMethodSupported('fetchGrantToken')) {
  //             return sdk.fetchGrantToken().then((res) => {
  //               this.setState({
  //                 showLoadingModal: true,
  //               });
  //               dispatch(loginPhonepe(res.grantToken, history, location.search));
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           // either phonepe SDK does not exist, or something went wrong fetching grant token
  //           console.log(err);
  //         });
  //     }
  //   };

  render() {
    return (
      <OuterContainer>
        {this.state.showLogo && (
          <div className="flex flex-col items-center justify-center">
            <div>
              <Logo src={VisitLogo} />
            </div>
            <ContentText>
              One stop solution for all your health concerns
            </ContentText>
          </div>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(NewOnboardingHome);
