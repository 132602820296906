import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BoldText } from '../common/Text';

import fitcoinIconWhite from '../../images/common/rewardiconwhite.png';
import fitcoinIcon from '../../images/common/rewardicon.png';
import { fontSize } from '../../utils';
import WalletIcon from '../../images/header/Wallet.svg';
import FitCoinIcon from '../../images/header/FitCoin.svg';

const FitcoinBadgeContainer = styled.div`
  padding: 0.325rem 0.7625rem;
  display: flex;
  align-items: center;
`;

const EarnText = styled(BoldText)`
  color: white;
  font-size: ${() => `${0.6875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const EarnIcon = styled.img`
  width: 0.6875rem;
  margin: 0 0.25rem;
`;

const Icon = styled.img`
  width: 1rem;
  margin: 0 0.5rem;
`;

const BalanceText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
`;

const EarnBadge = ({ className }) => (
  <FitcoinBadgeContainer className={className}>
    <EarnText>Earn</EarnText>
    <EarnIcon src={fitcoinIconWhite} alt="Fitcoin" />
  </FitcoinBadgeContainer>
);

const FitcoinIcon = () => <Icon src={FitCoinIcon} />;

const BalanceBadge = ({ balance }) => (
  <FitcoinBadgeContainer>
    <img
      src={WalletIcon}
      style={{ paddingRight: '10px', borderRight: '1px solid #F3F3F3' }}
    />
    <img
      src={FitCoinIcon}
      alt={balance}
      style={{ width: '24px', marginRight: '4px', marginLeft: '6px' }}
    />
    <BalanceText>{balance}</BalanceText>
  </FitcoinBadgeContainer>
);

BalanceBadge.propTypes = {
  balance: PropTypes.string.isRequired,
};

export {
  FitcoinBadgeContainer as FitcoinContainer,
  EarnBadge,
  FitcoinIcon,
  BalanceBadge,
};
