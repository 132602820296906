import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TappableCard from '../common/TappableCard';
import { Text } from '../common/Text';
import { FaChevronRight, FaAngleDown, FaAngleUp } from 'react-icons/fa';

import StatusIconPng from '../../images/lab-test/status.png';
import StatusProcessing from '../../images/consultations/processing.png';
import StatusCross from '../../images/consultations/new-flow/cancelled.svg';
import StatusTickGreen from '../../images/consultations/processed.png';
import StatusTick from '../../images/consultations/confirmed.png';
import UserIcon from '../../images/consultations/new-flow/user.svg';
import Time from '../../images/consultations/new-flow/clock.svg';
import DoctorIcon from '../../images/consultations/new-flow/doctor-preview.svg';

const OrderContainer = styled(TappableCard)`
  display: flex;
  flex-direction: row;
  padding: 1.25rem;
  padding-left: 0;
  margin: 0.75rem 0;
  border-radius: 12px;
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : 'none')};
`;

const LabelBar = styled.div`
  background: ${(props) => props.color};
  width: ${(props) => props.barWidth}px;
  height: ${(props) => props.barHeight}px;
  margin-right: 0.875rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TitleTextContainer = styled.div`
  display: flex;
  flex: 1;
  overflow-x: hidden;
  padding-bottom: 12px;
  border-bottom: 1px dashed #d3d6db;
`;

const TitleText = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusItem = styled.div`
  float: right;
  display: flex;
  align-items: center;
`;

const StatusText = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => (props.color ? props.color : '#0091E5;')};
  margin-left: 5px;
`;

const AngleRight = styled(FaChevronRight)`
  color: #01bfbd;
  font-size: 14px;
  width: 8px;
`;

const ConsultationStatusCard = ({ orderRequest, onTap }) => {
  return (
    <OrderContainer onTap={onTap} borderColor={orderRequest.status.hex}>
      <LabelBar
        color={orderRequest.status.hex}
        barWidth={6}
        barHeight={94}
      ></LabelBar>
      <div className="flex flex-col w-full">
        <TitleContainer>
          <TitleTextContainer>
            <TitleText className="txt-black-600-12 flex-center">
              <img src={DoctorIcon} alt="dr." height={32} width={32} />
              <span className="pl-5">
                <span className="text-body-l text-txtlight font-bold">
                  {orderRequest.doctorName}
                </span>
                <br />
                <span className="text-chip text-sub font-medium">
                  {orderRequest.vertical}
                </span>
              </span>
            </TitleText>
            <AngleRight />
          </TitleTextContainer>
        </TitleContainer>
        <div>
          <div className="text-body-s font-semibold text-txtlight mt-10 mb-4 flex flex-row items-center">
            <img
              src={UserIcon}
              style={{ width: '16px', height: '16px' }}
              className="mr-8px"
              alt="user"
            />
            {orderRequest.patientName}
          </div>
          <div>
            <StatusItem>
              {orderRequest.status.icon === 'processing' ? (
                <img
                  src={StatusProcessing}
                  alt="status"
                  width={'16px'}
                  height={'16px'}
                />
              ) : (
                ''
              )}
              {orderRequest.status.icon === 'cross' ? (
                <img
                  src={StatusCross}
                  alt="status"
                  width={'16px'}
                  height={'16px'}
                />
              ) : (
                ''
              )}
              {orderRequest.status.icon === 'tick' &&
              orderRequest.status.hex === '#01BFBD' ? (
                <img
                  src={StatusTickGreen}
                  alt="status"
                  width={'16px'}
                  height={'16px'}
                />
              ) : (
                ''
              )}
              {orderRequest.status.icon === 'tick' &&
              orderRequest.status.hex !== '#01BFBD' ? (
                <img
                  src={StatusTick}
                  alt="status"
                  width={'16px'}
                  height={'16px'}
                />
              ) : (
                ''
              )}
              <StatusText active={true} color={orderRequest.status.hex}>
                {orderRequest.status.label}
              </StatusText>
            </StatusItem>
            <div className="flex flex-row items-center">
              <img
                src={Time}
                style={{ width: '16px', height: '16px' }}
                className="mr-8px"
                alt="Time"
              />
              <div className="txt-mgrey-600-12">
                {orderRequest.appointmentOn}
              </div>
            </div>
          </div>
        </div>
      </div>
    </OrderContainer>
  );
};

ConsultationStatusCard.propTypes = {
  onTap: PropTypes.func,
  orderRequest: PropTypes.object.isRequired,
};

export default ConsultationStatusCard;
