import React, { Component } from 'react';
import { connect } from 'react-redux';
import closeIcon from '../../images/lab-test/close.svg';
import covidHeader from '../../images/covid/header.svg';
import teleIcon from '../../images/covid/tele.svg';
import diagnosticIcon from '../../images/covid/diagnostic.svg';
import hotlineIcon from '../../images/covid/helpline.svg';
import prescriptionIcon from '../../images/covid/prescription.svg';
import { initializeAmplitudeClient } from '../../services/analytics';
import Modal from '../../containers/common/Modal';
import { FlyInTransition } from '../../components/transitions/transitions';
import crossIcon from '../../images/common/cross.svg';
import membersIcon from '../../images/buying-journey/members.svg';
import config from '../../config';
import specialistIcon from '../../images/covid/specialist.svg';
import { logEvent } from '../../utils/logEvent';
import { getFreeAssessmentStatus } from '../../services';

class SelectPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: true,
      selectedPlan: 2999,
    };
  }

  hideModal = () => {
    this.setState({ showModal: false });
    setTimeout(this.props.hideModal, 200);
  };

  handleOutsideClick = (e) => {
    if (this.modalNode && this.modalNode.contains(e.target)) {
      return;
    }
    this.hideModal();
  };

  addRelative = () => {
    const { name, editing, age, gender, phone } = this.state;
    const { editingMember } = this.props;
    this.props.addRelative(name, phone, age, gender, editingMember);
  };

  changeSelectPlanHandler = (e) => {
    this.setState({ selectedPlan: e.target.value });
  };

  render() {
    const { editing, noOfDependents, perMemberPrice, total } = this.state;
    return (
      <Modal>
        <div className="modalContainer" onClick={this.handleOutsideClick}>
          <FlyInTransition
            in={this.state.showModal}
            mountOnEnter
            unmountOnExit
            appear
          >
            <div
              className="bg-textbox absolute bottom-0px w-full rounded-t-3xl max-h-full overflow-auto"
              ref={(node) => (this.modalNode = node)}
            >
              <div className="p-20 border-b border-txtsecondary-shades7 border-solid flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <img src={membersIcon} alt="home" />
                  <span className="text-title-s ml-10px font-semibold text-txtlight">
                    Select Your Plan
                  </span>
                </div>
                <img src={crossIcon} alt="cross" onClick={this.hideModal} />
              </div>
              <div className="p-20 max-h-full overflow-auto">
                <div>
                  <div
                    className="mt-16px rounded-12 px-20px py-16px"
                    style={{
                      boxShadow: ' 0px 2px 2px rgba(0, 0, 0, 0.05)',
                      border:
                        this.state.selectedPlan == 1899
                          ? '2px solid #01BFBD'
                          : 'none',
                    }}
                  >
                    <div className="flex flex-row items-center justify-between">
                      <div className="flex flex-row">
                        <input
                          type={'radio'}
                          name="plan"
                          className="mr-5px"
                          value="1899"
                          onChange={this.changeSelectPlanHandler}
                        />
                        <p className="text-body-s font-semibold text-txtlight ">
                          4 Sessions
                        </p>
                      </div>
                      <p className="text-body-s font-semibold text-txtlight">
                        ₹1899
                      </p>
                    </div>
                  </div>
                  <div
                    className="mt-16px rounded-12 px-20px py-16px"
                    style={{
                      boxShadow: ' 0px 2px 2px rgba(0, 0, 0, 0.05)',
                      border:
                        this.state.selectedPlan == 2999
                          ? '2px solid #01BFBD'
                          : 'none',
                    }}
                  >
                    <div className="flex flex-row items-center justify-between ">
                      <div className="flex flex-row">
                        <input
                          type={'radio'}
                          name="plan"
                          className="mr-5px"
                          value="2999"
                          onChange={this.changeSelectPlanHandler}
                          defaultChecked={true}
                        />
                        <p className="text-body-s font-semibold text-txtlight">
                          8 Sessions
                        </p>
                      </div>
                      <p className="text-body-s font-semibold text-txtlight">
                        ₹2999
                      </p>
                    </div>
                  </div>
                  <div
                    className="mt-40px mb-20px bg-primary pl-32px pr-32px rounded-12 hover pt-12px pb-12px text-button-s flex flex-row items-center justify-between font-semibold text-textbox"
                    style={{
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                    }}
                    onClick={() => this.props.checkout(this.state.selectedPlan)}
                  >
                    <p>₹{this.state.selectedPlan}</p>
                    <p>Pay & Proceed</p>
                  </div>
                </div>
              </div>
            </div>
          </FlyInTransition>
        </div>
      </Modal>
    );
  }
}

class PhysioProgram extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showModal: false,
      isAssessmentApplied: false,
    };
  }

  componentDidMount() {
    // GA Tracking
    window.gtag('event', 'screen_view', {
      screen_name: 'Physio Program',
    });
    this.amplitudeClient = initializeAmplitudeClient();
    this.amplitudeClient.init();
    this.getFreeAssessmentStatus();
    // logEvent('Physio Program Homepage');
  }

  getFreeAssessmentStatus = () => {
    const { authToken } = this.props;
    console.log(authToken, 'authToken');
    if (authToken) {
      getFreeAssessmentStatus(authToken)
        .then((res) => {
          this.setState({
            isAssessmentApplied: res.status,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    }
  };

  redirectToBookFreeAssessment = () => {
    console.log('calling booking api');
    const { authToken } = this.props;
    //subPlanTermId for free assessment is 11 in production and 13 in staging
    window.location.href = `${
      config.apiBaseUrl
    }/subscription/transact?subPlanTermId=11&amount=${0}&memberCount=1
    }&auth=${authToken.split(' ')[1]}`;
  };

  closeView = () => {
    if (typeof window.Android !== 'undefined') {
      return window.Android.closeView();
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'closeView',
        })
      );
    }
  };

  hideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  continue = () => {
    if (!this.props.authToken) {
      this.props.history.replace('/login?rt=covid-home');
      return;
    }
    this.setState({
      showModal: true,
    });
  };

  redirectToPaymentPage = (selectedPlan) => {
    console.log('selectedPlan', selectedPlan);
    //subPlanTermId for physio plan  is 13 for 2999 and 12 for 1899 in production
    //and 15 for 2999 and 14 for 1899 in staging

    const { authToken } = this.props;
    window.location.href = `${
      config.apiBaseUrl
    }/subscription/transact?subPlanTermId=${
      selectedPlan == '2999' ? '13' : '12'
    }&amount=${selectedPlan}&memberCount=${1}&auth=${authToken.split(' ')[1]}`;
  };

  render() {
    const { showModal, isAssessmentApplied } = this.state;
    return (
      <div className="overflow-auto w-full">
        <div className="mb-120px">
          <div
            className="header w-full pl-20px pt-48px flex flex-row justify-between items-start"
            style={{ background: '#E7FAFF', color: '#2D0000' }}
          >
            <div className="flex flex-col self-end mb-48px">
              <div className="mt-20px pr-20px">
                <p className="text-title-l font-bold mb-8px text-txtlight">
                  Need Pain relief Today?
                </p>
                <p className="font-bold text-body-l">
                  Subscribe For Physiotherapy Program
                </p>
                <p className="text-body-s mt-4px">Get a Free Assessment Now!</p>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <img
                src={closeIcon}
                alt="close"
                className="hover mb-10px"
                onClick={() => {
                  this.closeView();
                }}
              />
              <img
                src={covidHeader}
                alt="covid-19"
                className="self-end"
                style={{ width: '170px' }}
              />
            </div>
          </div>
          <div className="body px-20px py-24px">
            <div
              className="flex flex-row items-center justify-between py-24px px-16px mb-24px rounded-12"
              style={{ background: 'rgba(30, 184, 101, 0.1)' }}
            >
              <p className="text-title-m text-black font-bold">4 sessions</p>
              <div className="flex fle-row items-center">
                <p className="text-title-m text-black font-bold ml-4px">
                  ₹1899
                </p>
              </div>
            </div>
            <div
              className={`flex flex-row items-center justify-between py-24px px-16px mb-${
                isAssessmentApplied ? 20 : 0
              }px rounded-12`}
              style={{ background: 'rgba(30, 184, 101, 0.1)' }}
            >
              <p className="text-title-m text-black font-bold">8 sessions</p>
              <div className="flex fle-row items-center">
                <p className="text-title-m text-black font-bold ml-4px">
                  ₹2999
                </p>
              </div>
            </div>
            {!isAssessmentApplied && (
              <div className="flex flex-row items-center justify-between py-20px px-0px mb-0px rounded-1">
                <img
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/assets/Group+14147.jpg"
                  alt="package"
                  className="hover pt-16px"
                  onClick={() => {
                    this.redirectToBookFreeAssessment();
                  }}
                />
              </div>
            )}
            <div className="flex flex-row flex-wrap justify-between items-start">
              <div className="pb-12px mb-24px border-b border-txtsecondary-shades4">
                <p className="text-title-m font-bold text-txtlight">
                  Introducing an all new Physiotherapy programs
                </p>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row items-start mb-24px">
                  <img src={teleIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    Dedicated sessions with expert Physiotherapist
                  </p>
                </div>
                <div className="flex flex-row items-start mb-24px">
                  <img src={specialistIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    Free videos on Posture Correction, Mobility Exercises,
                    Stretching Exercises,
                  </p>
                </div>
                <div className="flex flex-row items-start mb-24px">
                  <img src={diagnosticIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    Customize Physio Programs created by our trainers
                  </p>
                </div>
                <div className="flex flex-row items-start mb-24px">
                  <img src={prescriptionIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    DSE(Display Screen Equipment) Assessment by our
                    Physiotherapist
                  </p>
                </div>
                <div className="flex flex-row items-start mb-24px">
                  <img src={hotlineIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    Posture Assessment
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="footer absolute bottom-0 w-full px-24px py-16px">
            <div
              className="pl-20px pr-16px rounded-12 hover pt-12px pb-12px text-button-s font-semibold text-textbox text-center"
              style={{
                background: '#01BFBD',
                backdropFilter: 'blur(4px)',
                minWidth: '100%',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
              }}
              onClick={() => {
                this.continue();
              }}
            >
              Subscribe
            </div>
          </div>
          {showModal ? (
            <SelectPlan
              showModal={showModal}
              user={this.props.user}
              hideModal={this.hideModal}
              checkout={this.redirectToPaymentPage}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  insured: state.app.config.insured,
  user: state.user,
});
export default connect(mapStateToProps)(PhysioProgram);
