import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaChevronLeft } from 'react-icons/fa';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setPlaceholderImageForNodeUsingGender } from '../../utils/placeholder';
import moment from 'moment';
import { updateProfile } from '../../services';
import { setUserEmail, setUserGender, setUserName } from '../../actions';
import DisabledMaleIcon from '../../images/edit-profile/A_Male.svg';
import DisableFemaleIcon from '../../images/edit-profile/D_Female.svg';
import DisableOtherIcon from '../../images/edit-profile/D_Other.svg';
import N1Icon from '../../images/edit-profile/n1.svg';
import ActiveMaleIcon from '../../images/edit-profile/n2.svg';
import N3Icon from '../../images/edit-profile/n3.svg';
import MaleIcon from '../../images/accounts/Male.png';
import FemaleIcon from '../../images/accounts/Female.png';
import OtherIcon from '../../images/accounts/Other.png';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  overflow-y: scroll;
  background: #ffffff;
  position: relative;
`;

const HeaderContainer = styled.div`
  padding: 58px 28px 0px 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const LeftArrowIcon = styled(FaChevronLeft)`
  width: 7px;
  height: 12px;
  color: #0f0b28;
  cursor: pointer;
`;

const HeaderText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #0f0b28;
  margin-left: 21px;
`;

const BodyContainer = styled.div`
  padding: 0px 28px;
  margin-top: 20px;
  background: #ffffff;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-botom: 12px;
`;

const ImageUploder = styled.img`
  width: 150px;
  filter: hue-rotate(303deg) saturate(4.1) sepia(40%);
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #6d7172;
`;

const InputValue = styled.input`
  border-bottom: 1px solid #c0c0c0;
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  resize: none;
  outline: none;
  padding-bottom: 6px;
  background: #ffffff;
`;

const DateOfBirthField = styled.input`
  border-bottom: 1px solid #c0c0c0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  resize: none;
  outline: none;
  padding-bottom: 6px;
  background: #ffffff;
  width: ${(props) => (props.year ? '40%' : '30%')};
  margin-right: 21px;
  text-align: center;
`;

const GenderButtons = styled.div`
  width: 83px;
  height: 38px;
  background: ${(props) => (props.active ? '#01BFBD' : '#FFFFFF')};
  color: ${(props) => (props.active ? '#FFFFFF' : '#01BFBD')};
  border: ${(props) => (props.active ? '' : '1px solid #01BFBD')};
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  cursor: pointer;
`;

const SaveChangesButton = styled.div`
  background: ${(props) => (props.disabled ? '#99E5E5' : '#01BFBD')};
  border-radius: 12px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  padding: 14px;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0px 0px 34px 0px;
  margin-top: 24px;
  bottom: 0;
  left: 0;
`;

const EditAccount = (props) => {
  const validateEmail = (inputText) => {
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (String(inputText).match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };
  const [name, setName] = useState(() => props.user.userName);
  const [email, setEmail] = useState(() => props.user.userEmail);
  const [prevDob, setPrevDob] = useState();
  const [date, setDate] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [gender, setGender] = useState(() => props.user.userGender);
  const [imageUrl, setImageUrl] = useState(() => props.user.profileImage);
  const [validEmail, setValidEmail] = useState(() =>
    validateEmail(props.user.userEmail)
  );
  const [newDob, setNewDob] = useState('');

  useEffect(() => {
    const dob = props.user.dateOfBirth;
    const dobValue = dob.split('-');
    const finalMonth = dobValue[1] - 1;
    const actualDob = moment([dobValue[0], finalMonth, dobValue[2]]);
    setYear(dobValue[0]);
    setMonth(dobValue[1]);
    setDate(dobValue[2]);
    setPrevDob(actualDob);
  }, []);

  useEffect(() => {
    const finalMonth = month - 1;
    console.log(finalMonth);
    let combinedDate = moment([year, finalMonth, date]);
    if (combinedDate.isValid() && !moment(combinedDate).isSame(prevDob)) {
      combinedDate = combinedDate.format('YYYY-MM-DD');
      setNewDob(combinedDate);
    } else {
      setNewDob('');
    }
  }, [date, month, year]);

  const validityCheck = () => {
    if (
      (props.user.userName !== String(name).trim() ||
        props.user.userEmail !== email ||
        newDob ||
        props.user.userGender !== gender) &&
      validEmail
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSaveChanges = () => {
    const body = {};
    let setUser = false,
      setEmail = false,
      setGender = false;
    let notAEmptyBody = false;
    if (props.user.userName !== String(name).trim()) {
      body['name'] = String(name).trim();
      notAEmptyBody = true;
      setUser = true;
    }
    if (props.user.userEmail !== email) {
      body['email'] = email;
      notAEmptyBody = true;
      setEmail = true;
    }
    if (props.user.userGender !== gender) {
      body['gender'] = gender;
      notAEmptyBody = true;
      setGender = true;
    }
    if (notAEmptyBody) {
      updateProfile(body, props.user.authToken)
        .then((res) => {
          console.log(res, setUser);
          if (setUser) {
            props.dispatch(setUserName(String(name).trim()));
          }
          if (setEmail) {
            props.dispatch(setUserEmail(email));
          }
          if (setGender) {
            props.dispatch(setUserGender(gender));
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          props.history.goBack();
        });
    }
  };

  return (
    <OuterContainer>
      <HeaderContainer>
        <LeftArrowIcon onClick={() => props.history.goBack()} />
        <HeaderText>Edit Profile</HeaderText>
      </HeaderContainer>
      <BodyContainer>
        <ImageContainer>
          <ImageUploder
            src={
              gender === 'Male'
                ? MaleIcon
                : gender === 'Female'
                ? FemaleIcon
                : OtherIcon
            }
          />
        </ImageContainer>
        <div className="mt-20px">
          <Title>Name</Title>
          <div className="mt-10px">
            <InputValue
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              autocomplete="off"
            />
          </div>
        </div>
        <div className="mt-20px">
          <Title>Gender</Title>
          <div
            className="mt-10px flex flex-row"
            style={{ width: '80%', marginLeft: '-5px' }}
          >
            {gender === 'Male' ? (
              <>
                <GenderButtons active>
                  <img
                    src={ActiveMaleIcon}
                    width="24px"
                    height="24px"
                    style={{ marginRight: '5px' }}
                  />
                  Male
                </GenderButtons>
              </>
            ) : (
              <>
                <GenderButtons onClick={() => setGender('Male')}>
                  <img
                    src={DisabledMaleIcon}
                    width="24px"
                    height="24px"
                    style={{ marginRight: '5px' }}
                  />
                  Male
                </GenderButtons>
              </>
            )}

            {gender === 'Female' ? (
              <>
                <GenderButtons active>
                  <img
                    src={N3Icon}
                    width="24px"
                    height="24px"
                    style={{ marginRight: '5px' }}
                  />
                  Female
                </GenderButtons>
              </>
            ) : (
              <>
                <GenderButtons onClick={() => setGender('Female')}>
                  <img
                    src={DisableFemaleIcon}
                    width="24px"
                    height="24px"
                    style={{ marginRight: '5px' }}
                  />
                  Female
                </GenderButtons>
              </>
            )}
            {gender === 'Other' ? (
              <>
                <GenderButtons active>
                  <img
                    src={N1Icon}
                    width="24px"
                    height="24px"
                    style={{ marginRight: '5px' }}
                  />
                  Other
                </GenderButtons>
              </>
            ) : (
              <>
                <GenderButtons onClick={() => setGender('Other')}>
                  <img
                    src={DisableOtherIcon}
                    width="24px"
                    height="24px"
                    style={{ marginRight: '5px' }}
                  />
                  Other
                </GenderButtons>
              </>
            )}
          </div>
        </div>
        <div className="mt-20px">
          <Title>Date Of Birth</Title>
          <div
            className="mt-10px flex flex-row text-sub"
            style={{ width: '60%' }}
          >
            <DateOfBirthField
              name="date"
              value={date}
              type="number"
              // onChange={(e) => setDate(e.target.value)}
              min="1"
              max="31"
              placeholder="date"
              disabled={true}
            />
            <DateOfBirthField
              name="month"
              value={month}
              type="number"
              // onChange={(e) => setMonth(e.target.value)}
              min="1"
              max="12"
              placeholder="month"
              disabled={true}
            />
            <DateOfBirthField
              name="year"
              value={year}
              year
              type="number"
              // onChange={(e) => setYear(e.target.value)}
              placeholder="year"
              disabled={true}
            />
          </div>
        </div>
        <div className="mt-20px  text-sub">
          <Title>Phone Number</Title>
          <div className="mt-10px">
            <InputValue
              name="name"
              disabled={true}
              value={props.user.userPhone}
              type="number"
            />
          </div>
        </div>
        <div className="mt-20px text-sub">
          <Title>Email</Title>
          <div className="mt-10px">
            <InputValue
              name="name"
              value={email}
              onChange={(e) => {
                const validEmail = validateEmail(e.target.value);
                setEmail(e.target.value);
                setValidEmail(validEmail);
              }}
              type="email"
            />
          </div>
        </div>
        <ButtonContainer>
          {validityCheck() ? (
            <SaveChangesButton onClick={() => onSaveChanges()}>
              Save Changes
            </SaveChangesButton>
          ) : (
            <SaveChangesButton disabled>Save Changes</SaveChangesButton>
          )}
        </ButtonContainer>
      </BodyContainer>
    </OuterContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps)(EditAccount));
