import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  BoldText,
  ButtonText,
  LinkText,
  RegularText,
  Text,
} from '../../components/common/Text';
import config from '../../config';
import { PrimaryButton } from '../../components/common/Buttons';

import hraIcon from '../../images/hra/hraicon-1.png';
import completeIcon from '../../images/hra/complete-icon.svg';
import retakeIcon from '../../images/hra/retake-icon.svg';
import { fontSize } from '../../utils';
import StyledTappable from '../../components/common/StyledTappable';
import { resetHra } from '../../services';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background: #ffffff;
`;

const BodyContainer = styled.div`
  padding: 50px 32px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Image = styled.img`
  max-width: 100px;
  margin: 20px 0;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleText = styled(BoldText)`
  font-size: ${fontSize(2)};
`;

const CaptionText = styled(RegularText)`
  margin-top: 12px;
  font-size: ${fontSize(0.75)};
`;

const RetakeLink = styled(LinkText)`
  font-size: ${fontSize(0.875)};
  margin-top: 24px;
  color: #01bfbd;
  font-weight: 600;
  display: flex;
`;

const EndScreen = ({ history, userId, sponsorId, authToken }) => {
  return (
    <OuterContainer className="font-mont">
      <BodyContainer className="p-32px">
        <img
          src="hra"
          src={hraIcon}
          className="mt-24"
          style={{ height: '184px', width: '184px' }}
        />

        <ColumnContainer className="font-mont">
          <p className="text-title-xl font-bold text-custom3a2ca0">
            Health Risk Assessment
          </p>
          <Image src={completeIcon} />
          <p className="text-body-l mt-16x font-medium text-brochure">
            You have completed your Health Risk Assessment successfully.
          </p>
        </ColumnContainer>
        <ColumnContainer>
          <PrimaryButton
            onTap={() => {
              const userAgent = navigator.userAgent || navigator.vendor;
              if (window.Android) {
                return window.open(
                  `${
                    config.apiBaseUrl
                  }/health-risk-assessment/${userId}/form/generate?auth=${
                    authToken.split(' ')[1]
                  }`,
                  '_blank'
                );
              } else if (
                window.webkit &&
                window.webkit.messageHandlers.visitIosView
              ) {
                window.webkit.messageHandlers.visitIosView.postMessage(
                  JSON.stringify({
                    method: 'openPDF',
                    url: `${
                      config.apiBaseUrl
                    }/health-risk-assessment/${userId}/form/generate?auth=${
                      authToken.split(' ')[1]
                    }`,
                  })
                );
              } else if (/android/i.test(userAgent)) {
                const anchor = document.createElement('a');
                anchor.setAttribute(
                  'href',
                  `${
                    config.apiBaseUrl
                  }/health-risk-assessment/${userId}/form/generate?auth=${
                    authToken.split(' ')[1]
                  }`
                );
                anchor.setAttribute('target', '_blank');
                anchor.click();
                return;
              } else {
                const anchor = document.createElement('a');
                anchor.setAttribute(
                  'href',
                  `${
                    config.apiBaseUrl
                  }/health-risk-assessment/${userId}/form/generate?auth=${
                    authToken.split(' ')[1]
                  }`
                );
                anchor.setAttribute('target', '_blank');
                anchor.click();
              }
            }}
          >
            <ButtonText>Download Report</ButtonText>
          </PrimaryButton>
          <StyledTappable
            onTap={() => {
              resetHra()
                .then(() => {
                  history.replace('/hra/question');
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            <RetakeLink>
              <img src={retakeIcon} style={{ width: '19px', height: '19px' }} />
              <p className="ml-3 font-semibold">Take HRA again</p>
            </RetakeLink>
          </StyledTappable>
          <p className="text-body-s mt-12 text-brochure">
            *A PDF file of the report has been sent to your registered email
            address
          </p>
        </ColumnContainer>
      </BodyContainer>
    </OuterContainer>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  sponsorId: state.user.sponsorId,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(EndScreen);
