import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TappableCard from '../common/TappableCard';
import { Text } from '../common/Text';
import { FaChevronRight, FaAngleDown, FaAngleUp } from 'react-icons/fa';

import StatusIconPng from '../../images/lab-test/status.png';
import StatusProcessing from '../../images/consultations/processing.png';
import StatusCross from '../../images/consultations/new-flow/cancelled.svg';
import StatusTickGreen from '../../images/consultations/processed.png';
import StatusTick from '../../images/consultations/confirmed.png';
import UserIcon from '../../images/consultations/new-flow/user.svg';
import Time from '../../images/consultations/new-flow/clock.svg';
import DoctorIcon from '../../images/consultations/new-flow/doctor-preview.svg';

const OrderContainer = styled(TappableCard)`
  display: flex;
  flex-direction: row;
  padding: 1.25rem;
  padding-left: 0;
  margin: 0.75rem 0;
  border-radius: 12px;
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : 'none')};
`;

const LabelBar = styled.div`
  background: ${(props) => props.color};
  width: ${(props) => props.barWidth}px;
  height: ${(props) => props.barHeight}px;
  margin-right: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TitleTextContainer = styled.div`
  display: flex;
  flex: 1;
  overflow-x: hidden;
  padding-bottom: 12px;
  border-bottom: 1px solid #f4f6f9;
`;

const TitleText = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusItem = styled.div`
  float: right;
  display: flex;
  align-items: center;
`;

const StatusText = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => (props.color ? props.color : '#0091E5;')};
  margin-left: 4px;
`;

const AngleRight = styled(FaChevronRight)`
  color: #01bfbd;
  font-size: 14px;
  width: 8px;
`;

const ConsultationStatusCard = ({ orderRequest, onTap }) => {
  const status = orderRequest.cancelled
    ? 'Cancelled'
    : !orderRequest.daysRemaining
    ? 'Successful'
    : 'Pending';

  const color =
    status === 'Cancelled'
      ? '#E26767'
      : status === 'Successful'
      ? '#57B56D'
      : '#01BFBD';

  return (
    <OrderContainer borderColor={color} onTap={onTap}>
      <LabelBar color={color} barWidth={6} barHeight={94}></LabelBar>
      <div className="flex flex-col w-full">
        <TitleContainer>
          <TitleTextContainer>
            <TitleText className="txt-black-600-12 flex-center">
              <svg
                width="41"
                height="41"
                viewBox="0 0 41 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.3749 27.1886C12.5445 27.1886 10.2499 29.471 10.2499 32.2865C10.2499 33.225 11.0148 33.9858 11.9583 33.9858H29.0416C29.9851 33.9858 30.7499 33.225 30.7499 32.2865C30.7499 29.471 28.4554 27.1886 25.6249 27.1886H15.3749ZM6.83325 32.2865C6.83325 27.594 10.6575 23.79 15.3749 23.79H25.6249C30.3423 23.79 34.1666 27.594 34.1666 32.2865C34.1666 35.102 31.872 37.3844 29.0416 37.3844H11.9583C9.12779 37.3844 6.83325 35.102 6.83325 32.2865Z"
                  fill="#01BFBD"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.4999 6.79727C17.6695 6.79727 15.3749 9.07967 15.3749 11.8952C15.3749 14.7106 17.6695 16.993 20.4999 16.993C23.3304 16.993 25.6249 14.7106 25.6249 11.8952C25.6249 9.07967 23.3304 6.79727 20.4999 6.79727ZM11.9583 11.8952C11.9583 7.20268 15.7825 3.39868 20.4999 3.39868C25.2174 3.39868 29.0416 7.20268 29.0416 11.8952C29.0416 16.5876 25.2174 20.3916 20.4999 20.3916C15.7825 20.3916 11.9583 16.5876 11.9583 11.8952Z"
                  fill="#01BFBD"
                />
              </svg>
              <span className="pl-5 ml-10px">
                <span className="text-body-l text-txtlight font-bold">
                  {orderRequest.doctorName}
                </span>
                <br />
                <span className="text-chip text-sub font-medium">
                  {orderRequest.vertical}
                </span>
              </span>
            </TitleText>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="23.5"
                y="24.2036"
                width="23"
                height="22.873"
                rx="7.5"
                transform="rotate(-180 23.5 24.2036)"
                stroke="#F4F6FA"
              />
              <path
                d="M9.75 16.2354L12.9949 12.7921L9.75 9.34876L10.749 8.29102L15 12.7921L10.749 17.2931L9.75 16.2354Z"
                fill="#01BFBD"
              />
            </svg>
          </TitleTextContainer>
        </TitleContainer>
        <div className="mt-8px">
          <div className="text-body-s font-semibold text-txtlight mt-8px mb-4 flex flex-row items-center">
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.00008 11.065C4.89551 11.065 4.00008 11.9557 4.00008 13.0545C4.00008 13.4207 4.29856 13.7176 4.66675 13.7176H11.3334C11.7016 13.7176 12.0001 13.4207 12.0001 13.0545C12.0001 11.9557 11.1046 11.065 10.0001 11.065H6.00008ZM2.66675 13.0545C2.66675 11.2233 4.15913 9.73877 6.00008 9.73877H10.0001C11.841 9.73877 13.3334 11.2233 13.3334 13.0545C13.3334 14.1532 12.438 15.0439 11.3334 15.0439H4.66675C3.56218 15.0439 2.66675 14.1532 2.66675 13.0545Z"
                fill="#01BFBD"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.00008 3.10728C6.89551 3.10728 6.00008 3.99798 6.00008 5.0967C6.00008 6.19543 6.89551 7.08612 8.00008 7.08612C9.10465 7.08612 10.0001 6.19543 10.0001 5.0967C10.0001 3.99798 9.10465 3.10728 8.00008 3.10728ZM4.66675 5.0967C4.66675 3.26549 6.15913 1.78101 8.00008 1.78101C9.84103 1.78101 11.3334 3.26549 11.3334 5.0967C11.3334 6.92791 9.84103 8.4124 8.00008 8.4124C6.15913 8.4124 4.66675 6.92791 4.66675 5.0967Z"
                fill="#01BFBD"
              />
            </svg>
            <p className="ml-8px text-body-s">{orderRequest.patientName}</p>
          </div>
          <div className="text-body-s font-semibold text-txtlight mt-10px mb-4 flex flex-row items-center justify-between">
            <div className="flex">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.99992 2.92735C5.0544 2.92735 2.66659 5.30253 2.66659 8.23247C2.66659 11.1624 5.0544 13.5376 7.99992 13.5376C10.9454 13.5376 13.3333 11.1624 13.3333 8.23247C13.3333 5.30253 10.9454 2.92735 7.99992 2.92735ZM1.33325 8.23247C1.33325 4.57005 4.31802 1.60107 7.99992 1.60107C11.6818 1.60107 14.6666 4.57005 14.6666 8.23247C14.6666 11.8949 11.6818 14.8639 7.99992 14.8639C4.31802 14.8639 1.33325 11.8949 1.33325 8.23247Z"
                  fill="#E86570"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.4951 8.33649C11.4951 8.70273 11.1966 8.99963 10.8284 8.99963H7.72386C7.35567 8.99963 7.05719 8.70273 7.05719 8.33649L7.05719 5.79771C7.05719 5.43147 7.35567 5.13457 7.72386 5.13457C8.09205 5.13457 8.39052 5.43147 8.39052 5.79771L8.39052 7.67335L10.8284 7.67335C11.1966 7.67335 11.4951 7.97025 11.4951 8.33649Z"
                  fill="#E86570"
                />
              </svg>

              <p className="ml-8px text-body-s">{orderRequest.consultDate}</p>
            </div>
            <div className="flex">
              {status === 'Cancelled' && (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="8.00104"
                    cy="8"
                    rx="7.00104"
                    ry="7"
                    fill="#E26767"
                  />
                  <rect
                    x="7.3009"
                    y="6.25"
                    width="1.40021"
                    height="5.6"
                    rx="0.700104"
                    fill="white"
                  />
                  <rect
                    x="7.3009"
                    y="4.1499"
                    width="1.40021"
                    height="1.4"
                    rx="0.7"
                    fill="white"
                  />
                </svg>
              )}
              {status === 'Pending' && (
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 15.238C11.866 15.238 15 12.104 15 8.23804C15 4.37204 11.866 1.23804 8 1.23804C4.13401 1.23804 1 4.37204 1 8.23804C1 12.104 4.13401 15.238 8 15.238Z"
                    fill="#01BFBD"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.8571 4.23804C8.33047 4.23804 8.71421 4.66539 8.71421 5.19255V8.4207L10.526 9.42956C10.9494 9.66532 11.121 10.2387 10.9093 10.7102C10.6976 11.1817 10.1828 11.3728 9.7594 11.1371L7.47379 9.86437C7.18342 9.70268 7 9.37217 7 9.01062V5.19255C7 4.66539 7.38374 4.23804 7.8571 4.23804Z"
                    fill="white"
                  />
                </svg>
              )}
              {status === 'Successful' && (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8Z"
                    fill="#57B56D"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.4714 6.19526C10.7318 6.45561 10.7318 6.87772 10.4714 7.13807L7.80478 9.80474C7.54443 10.0651 7.12232 10.0651 6.86197 9.80474L5.52864 8.4714C5.26829 8.21106 5.26829 7.78895 5.52864 7.5286C5.78899 7.26825 6.2111 7.26825 6.47145 7.5286L7.33337 8.39052L9.52864 6.19526C9.78899 5.93491 10.2111 5.93491 10.4714 6.19526Z"
                    fill="white"
                  />
                </svg>
              )}
              <StatusText color={color} active={true}>
                {orderRequest.cancelled
                  ? 'Cancelled'
                  : !orderRequest.daysRemaining
                  ? 'Successful'
                  : 'Pending'}
              </StatusText>
            </div>
          </div>
          <div>
            <StatusItem>
              {/* {orderRequest.status.icon === 'processing' ? (
                <img
                  src={StatusProcessing}
                  alt="status"
                  width={'16px'}
                  height={'16px'}
                />
              ) : (
                ''
              )}
              {orderRequest.status.icon === 'cross' ? (
                <img
                  src={StatusCross}
                  alt="status"
                  width={'16px'}
                  height={'16px'}
                />
              ) : (
                ''
              )}
              {orderRequest.status.icon === 'tick' ? (
                <img
                  src={StatusTickGreen}
                  alt="status"
                  width={'16px'}
                  height={'16px'}
                />
              ) : (
                ''
              )}
              {orderRequest.status.icon === 'tick'  ? (
                <img
                  src={StatusTick}
                  alt="status"
                  width={'16px'}
                  height={'16px'}
                />
              ) : (
                ''
              )} */}
            </StatusItem>
            <div className="flex flex-row items-center">
              <div className="txt-mgrey-600-12">
                {orderRequest.appointmentOn}
              </div>
            </div>
          </div>
        </div>
      </div>
    </OrderContainer>
  );
};

ConsultationStatusCard.propTypes = {
  onTap: PropTypes.func,
  orderRequest: PropTypes.object.isRequired,
};

export default ConsultationStatusCard;
