import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';
import { Text } from '../../common/Text';
import React from 'react';
import NeedHelp from '../need-help';

export const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #f8f8f8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 16px;
  z-index: 10;
  border-bottom: 1px solid #e2e3ea;
`;

export const BackContainer = styled(Tappable)`
  margin-right: 3px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderText = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

const Header = ({ onTap, heading }) => (
  <HeaderContainer>
    <BackContainer onTap={onTap}>
      <svg
        width="7"
        height="13"
        viewBox="0 0 7 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 2.35117L2.67342 6.96667L7 11.5822L5.66802 13L1.56711e-06 6.96667L5.66802 0.933336L7 2.35117Z"
          fill="#0F0B28"
        />
      </svg>
    </BackContainer>
    <HeaderTitleContainer>
      <HeaderText>{heading}</HeaderText>
    </HeaderTitleContainer>
    <NeedHelp></NeedHelp>
  </HeaderContainer>
);

export default Header;
