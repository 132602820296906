import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaChevronRight } from 'react-icons/fa';

import { BoldText, RegularText } from '../../components/common/Text';
import StyledTappable from '../../components/common/StyledTappable';
import { logout, setTicketingUrl } from '../../actions';

import { setPlaceholderImageForNodeUsingGender } from '../../utils/placeholder';
import { initializeAmplitudeClient } from '../../services/analytics';

import logoutIcon from '../../images/common/logout.svg';
import dependantIcon from '../../images/common/dependants.svg';
import faqIcon from '../../images/common/faq.svg';
import { logEvent } from '../../utils/logEvent';
import ChatIcon from '../../images/accounts/ChatSupport.svg';
import ClaimsIcon from '../../images/accounts/Claims.svg';
import ClinicIcon from '../../images/accounts/ClinicAppointment.svg';
import DependentsIcon from '../../images/accounts/DependantIcon.svg';
import FaqIcon from '../../images/accounts/faq.svg';
import InfoIcon from '../../images/accounts/Info.svg';
import LabsIcon from '../../images/accounts/Labs.svg';
import LogoutIcon from '../../images/accounts/Logout.svg';
import MedicineIcon from '../../images/accounts/MedicineIcon.svg';
import OnlineConsultsIcon from '../../images/accounts/OnlineConsults.svg';
import PurchaseIcon from '../../images/accounts/PurchaseIcon.svg';
import TermIcon from '../../images/accounts/TC.svg';
import BackButton from '../../images/accounts/BackButton.svg';
import initializeFreshDesk from '../../utils/freshchat';
import EditProfileIcon from '../../images/edit-profile.png';
import MaleIcon from '../../images/accounts/Male.png';
import FemaleIcon from '../../images/accounts/Female.png';
import OtherIcon from '../../images/accounts/Other.png';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  overflow-y: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  padding: 24px;
  background: white;
  overflow-y: scroll;
  -webkit-overflow-scrolling: scroll;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background: #99e5e5;
  padding: 24px;
  height: 145px;
  width: 100%;
`;

const ProfileImage = styled.img`
  width: 96px;
  margin-left: -16px;
  filter: hue-rotate(303deg) saturate(4.1) sepia(40%);
`;

const UserDetails = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #585969;
`;

const NameText = styled(BoldText)`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #0f0b28;
`;

const UserDetailsText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #ffffff;
`;

const OptionContainer = styled(StyledTappable)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 0;
  margin: 0.5rem 0;
`;

const OptionText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const BlockContainer = styled.div`
  display: flex;
  padding: 20px 0px 0px 0px;
  align-items: center;
  flex-direction: column;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 35px;
  width: 100%;
  cursor: pointer;
`;

const ItemText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #0f0b28;
`;

const RightArrowIcon = styled(FaChevronRight)`
  color: #a5a6bb;
`;

const MobileNumber = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #0f0b28;
  margin-top: 6px;
`;

class AccountContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showLogoutButton: false,
    };
  }

  componentDidMount = () => {
    logEvent('Accounts Tab', {});
    this.props.dispatch(setTicketingUrl(this.props.config.ticketingUrl));
    window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
      .then(() => {})
      .catch(() => {
        // either phonepe SDK does not exist, or something went wrong fetching grant token
        this.setState({
          showLogoutButton: true,
        });
      });
  };

  openSupport = () => {
    const { ticketingEnabled, ticketingUrl } = this.props.config;
    const { authToken } = this.props.user;
    logEvent('Customer Support Button Clicked');
    if (ticketingEnabled) {
      window.open(`${ticketingUrl}?token=${authToken.split(' ')[1]}`, '_blank');
    } else {
      initializeFreshDesk(this.props.user);
    }
  };

  render() {
    const { showLogoutButton } = this.state;
    const {
      userName,
      userGender,
      userAge,
      userPhone,
      countryCode,
      profileImage,
    } = this.props.user;
    const { history, dispatch, config } = this.props;
    console.log(profileImage);

    const renderFirstBlock = () => {
      const { showManageDependent, showFitCoins } = this.props.config;
      if (showManageDependent || showFitCoins) {
        return (
          <BlockContainer style={{ borderBottom: '1px solid #F0F3F6' }}>
            {showManageDependent && this.props.manageDependentLink && (
              <ItemContainer
                onClick={() => window.open(this.props.manageDependentLink)}
              >
                <div className="flex flex-row items-center">
                  <img
                    src={DependentsIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText>Manage Dependents</ItemText>
                </div>
                <RightArrowIcon />
              </ItemContainer>
            )}
            {showFitCoins && (
              <ItemContainer
                onClick={() => this.props.history.push('/rewards/history')}
              >
                <div className="flex flex-row items-center">
                  <img
                    src={PurchaseIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText>Purchase History</ItemText>
                </div>
                <RightArrowIcon />
              </ItemContainer>
            )}
          </BlockContainer>
        );
      }
    };

    const renderSecondBlock = () => {
      const { showOffline, showMeds, showLabs, showReimbursment } =
        this.props.config;
      const { history } = this.props;
      if (showOffline || showMeds || showLabs || showReimbursment) {
        return (
          <BlockContainer style={{ borderBottom: '1px solid #F0F3F6' }}>
            {showOffline && (
              <ItemContainer
                onClick={() => history.push('/consultation/preview')}
              >
                <div className="flex flex-row items-center">
                  <img
                    src={ClinicIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText>My Appointments</ItemText>
                </div>
                <RightArrowIcon />
              </ItemContainer>
            )}
            {showMeds && (
              <ItemContainer onClick={() => history.push('/pharmacy')}>
                <div className="flex flex-row items-center">
                  <img
                    src={MedicineIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText>My Medicine Orders</ItemText>
                </div>
                <RightArrowIcon />
              </ItemContainer>
            )}
            {showOffline && (
              <ItemContainer
                onClick={() => history.push('/consultation/online/preview')}
              >
                <div className="flex flex-row items-center">
                  <img
                    src={OnlineConsultsIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText>My Online Consults</ItemText>
                </div>
                <RightArrowIcon />
              </ItemContainer>
            )}
            {showLabs && (
              <ItemContainer onClick={() => history.push('/lab-test')}>
                <div className="flex flex-row items-center">
                  <img
                    src={LabsIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText>My Labs Tests</ItemText>
                </div>
                <RightArrowIcon />
              </ItemContainer>
            )}
            {showReimbursment && (
              <ItemContainer onClick={() => history.push('/claims')}>
                <div className="flex flex-row items-center">
                  <img
                    src={ClaimsIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText>My Claims</ItemText>
                </div>
                <RightArrowIcon />
              </ItemContainer>
            )}
          </BlockContainer>
        );
      }
    };

    const renderThirdBlock = () => {
      const { ticketingEnabled, ticketingUrl } = this.props.config;
      if (ticketingEnabled) {
        return (
          <BlockContainer>
            {ticketingEnabled && (
              <ItemContainer onClick={this.openSupport}>
                <div className="flex flex-row items-center">
                  <img
                    src={ChatIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                  <ItemText>Contact Support</ItemText>
                </div>
                <RightArrowIcon />
              </ItemContainer>
            )}

            <ItemContainer
              onClick={() =>
                window.open('https://getvisitapp.com/terms.html', 'blank')
              }
            >
              <div className="flex flex-row items-center">
                <img
                  src={TermIcon}
                  style={{ marginRight: '15px', width: '22px', height: '22px' }}
                />
                <ItemText>Terms & Conditions</ItemText>
              </div>
              <RightArrowIcon />
            </ItemContainer>

            <ItemContainer
              onClick={() =>
                window.open(
                  'https://getvisit.atlassian.net/wiki/spaces/VH/overview',
                  'blank'
                )
              }
            >
              <div className="flex flex-row items-center">
                <img
                  src={FaqIcon}
                  style={{ marginRight: '15px', width: '22px', height: '22px' }}
                />
                <ItemText>FAQ</ItemText>
              </div>
              <RightArrowIcon />
            </ItemContainer>

            <ItemContainer
              onClick={() => window.open('https://getvisitapp.com/', 'blank')}
            >
              <div className="flex flex-row items-center">
                <img
                  src={InfoIcon}
                  style={{ marginRight: '15px', width: '20px', height: '20px' }}
                />
                <ItemText>About Visit</ItemText>
              </div>
              <RightArrowIcon />
            </ItemContainer>

            {showLogoutButton && (
              <ItemContainer
                onClick={() => {
                  initializeAmplitudeClient().logoutUser();
                  dispatch(logout(history));
                  logEvent('Onboarding User Logged Out');
                }}
              >
                <div className="flex flex-row items-center">
                  <img
                    src={LogoutIcon}
                    style={{
                      marginRight: '15px',
                      width: '24px',
                      height: '24px',
                    }}
                  />
                  <ItemText>Sign Out</ItemText>
                </div>
                <RightArrowIcon />
              </ItemContainer>
            )}
          </BlockContainer>
        );
      }
    };
    return (
      <OuterContainer>
        <UserContainer
          style={
            !this.props.showBackButton
              ? { justifyContent: 'end' }
              : { justifyContent: 'space-between' }
          }
        >
          <div
            className="flex flex-row items-center justify-between"
            style={{ width: '100%', height: '80%' }}
          >
            <div className="flex flex-row items-center">
              <ProfileImage
                src={
                  userGender === 'Male'
                    ? MaleIcon
                    : userGender === 'Female'
                    ? FemaleIcon
                    : userGender === 'Other'
                    ? OtherIcon
                    : MaleIcon
                }
              />
              <div className="flex flex-col">
                <NameText>{userName}</NameText>
                <UserDetails>
                  {userGender}, {userAge} years
                </UserDetails>
                <MobileNumber>{`${countryCode} ${userPhone}`}</MobileNumber>
              </div>
            </div>
            <div
              onClick={() => this.props.history.push('/edit-account')}
              style={{ cursor: 'pointer' }}
            >
              <div
                onClick={() => this.props.history.push('/edit-account')}
                style={{ cursor: 'pointer' }}
              >
                <img src={EditProfileIcon} width="24px" height="24px" />
              </div>
            </div>
          </div>
        </UserContainer>
        <BodyContainer style={{ display: 'none' }}>
          {/* <OptionContainer
            onTap={() => {
              history.push('/refer');
            }}
          >
            <OptionText>Refer and Earn</OptionText>
            <FaChevronRight />
          </OptionContainer> */}
          {config && config.showManageDependent && (
            <div
              className="flex flex-row justify-between items-center mb-36px"
              onClick={() => {
                // initializeAmplitudeClient().logoutUser();
                // dispatch(logout(history));
                history.push('/manage-dependents');
              }}
            >
              <div className="flex flex-row items-center">
                <img src={dependantIcon} />
                <div className="ml-16px text-txtlight text-body-l font-medium">
                  Manage Dependents
                </div>
              </div>
              <FaChevronRight />
            </div>
          )}
          {config && config.faqsLink && (
            <div
              className="flex flex-row justify-between items-center mb-36px"
              onClick={() => {
                window.open(config.faqsLink, '_blank');
              }}
            >
              <div className="flex flex-row items-center">
                <img src={faqIcon} />
                <div className="ml-16px text-txtlight text-body-l font-medium">
                  FAQ
                </div>
              </div>
              <FaChevronRight />
            </div>
          )}
          {config && config.showAdditionalFaq && config.additionalFaqConfig && (
            <div
              className="flex flex-row justify-between items-center mb-36px"
              onClick={() => {
                window.location.assign(config.additionalFaqConfig.url);
              }}
            >
              <div className="flex flex-row items-center">
                <img src={faqIcon} />
                <div className="ml-16px text-txtlight text-body-l font-medium">
                  {config.additionalFaqConfig.title}
                </div>
              </div>
              <FaChevronRight />
            </div>
          )}
          {showLogoutButton && (
            <div
              className="flex flex-row justify-between items-center"
              onClick={() => {
                initializeAmplitudeClient().logoutUser();
                dispatch(logout(history));
                logEvent('Onboarding User Logged Out');
              }}
            >
              <div className="flex flex-row items-center">
                <img src={logoutIcon} />
                <div className="ml-16px text-txtlight text-body-l font-medium">
                  Sign Out
                </div>
              </div>
              <FaChevronRight />
            </div>
          )}
        </BodyContainer>
        <BodyContainer>
          <div>{renderFirstBlock()}</div>
          <div>{renderSecondBlock()}</div>
          <div>{renderThirdBlock()}</div>
        </BodyContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  config: state.app.config,
  manageDependentLink: state.user.manageDependentLink,
});

export default connect(mapStateToProps)(AccountContainer);
