import React, { Component } from 'react';
import { connect } from 'react-redux';
import benefitIcon1 from '../../images/maternity-care-program/benefit1.png';
import benefitIcon2 from '../../images/maternity-care-program/benefit2.png';
import benefitIcon3 from '../../images/maternity-care-program/benefit3.png';
import benefitIcon4 from '../../images/maternity-care-program/benefit4.png';
import benefitIcon5 from '../../images/maternity-care-program/benefit5.png';
import benefitIcon6 from '../../images/maternity-care-program/benefit6.png';
import nextArror from '../../images/maternity-care-program/nextArrow.png';
import abhiLogo from '../../images/maternity-care-program/abhiLogo.png';
import bgPattern from '../../images/buying-journey/bg-pattern.png';

import { userInfo } from '../../services';
import { initializeAmplitudeClient } from '../../services/analytics';
import { logEvent } from '../../utils/logEvent';

export class MaternityCareProgram extends Component {
  state = {
    benefits: [
      {
        description: 'Unlimited Gynecologist Teleconsultations (via Visit App)',
        icon: benefitIcon1,
      },
      {
        description: 'Unlimited General Physician Teleconsultations (24x7)',
        icon: benefitIcon2,
      },
      {
        description: 'Unlimited 1 on 1 Psychologist Teleconsultations (24x7)',
        icon: benefitIcon4,
      },
      {
        description: 'Curated_Fitness_Content Workout + Yoga',
        icon: benefitIcon3,
      },
      {
        description:
          'Personalised_Nutrition_Management Program (unlimited Teleconsultations)',
        icon: benefitIcon5,
      },
      {
        description:
          '2_Expert_Webinars Prenatal & Postpartum Care (group session)',
        icon: benefitIcon6,
      },
    ],
    hospitalImages: [],

    showCrossButton: false,
    selectedFaq: null,
    showDownloadSection: true,
  };

  componentDidMount() {
    const { dispatch, location } = this.props;
    if (location.search) {
      const params = new URLSearchParams(location.search);
      if (params.get('authToken')) {
        dispatch({
          type: 'AUTH_TOKEN',
          payload: params.get('authToken'),
        });
        userInfo(params.get('authToken'))
          .then((result) => {
            dispatch({
              type: 'SET_USER_INFO',
              payload: result,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (
        params.get('showCrossButton') &&
        params.get('showCrossButton') === 'true'
      ) {
        this.setState({
          showCrossButton: true,
        });
      }
      if (
        params.get('doNotShowDownloadButton') &&
        params.get('doNotShowDownloadButton') === 'true'
      ) {
        this.setState({
          showDownloadSection: false,
        });
      }
    }
    this.amplitudeClient = initializeAmplitudeClient();
    this.amplitudeClient.init();
    logEvent('Visit Maternity Care Card Test');
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  closeView = () => {
    if (typeof window.Android !== 'undefined') {
      return window.Android.closeView();
    }
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'closeView',
        })
      );
    }
  };

  render() {
    const { benefits, showCrossButton } = this.state;
    const { history } = this.props;

    return (
      <div className="w-full font-mont overflow-auto">
        <div className="header">
          <div className="relative">
            <div
              className="w-full
              bg-theme-background
              "
              style={{
                background: `url(${bgPattern}) no-repeat`,
                backgroundSize: 'cover',
              }}
            >
              <div className="mt-14px mb-10px py-10px px-24px">
                <div>
                  <img
                    src="https://visit-public.s3.ap-south-1.amazonaws.com/maternity18jul+(1).png"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="body mb-100px">
          <div className=" p-24px pt-0px pr-32px">
            <div className="benefits">
              <div className="mt-0px">
                {benefits.map((benefit, idx) => {
                  return (
                    <div
                      key={idx}
                      className="flex flex-row items-center border-b border-txtsecondary-shades4 pb-24px mt-24px"
                    >
                      <img
                        src={benefit.icon}
                        className="mr-16px"
                        style={{ width: '72px', height: '72px' }}
                        alt="benefit"
                      />
                      <p className="text-title-s font-semibold">
                        <span className="text-primary font-bold">
                          {benefit.description
                            .split(' ')[0]
                            .replace(/[_]/gm, ' ')}
                        </span>

                        {benefit.description.replace(
                          benefit.description.split(' ')[0],
                          ''
                        )}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          className="footer absolute bottom-0 w-full px-24px py-16px bg-textbox"
          style={{ boxShadow: '0px -1px 20px rgba(0, 0, 0, 0.1)' }}
        >
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
              <p className="font-bold text-title-m font-txtlight">Paid By</p>
              <img src={abhiLogo} className="ml-8px" alt="ABHI" />
            </div>
            <div
              className="pl-20px pr-16px rounded-12 hover pt-12px pb-12px text-button-s font-semibold text-textbox flex flex-row"
              style={{ background: '#01BFBD', backdropFilter: 'blur(4px)' }}
              onClick={() => {
                logEvent('Maternity Upload Prescription Button Click');
                history.push('/maternity-care/doc-verification');
              }}
            >
              <span>Verify Documents</span>
              <img src={nextArror} className="ml-8px" alt="arrow" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  userName: state.user.userName,
  user: state.user,
});

export default connect(mapStateToProps)(MaternityCareProgram);
