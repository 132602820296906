import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reimbursementSteps } from '../../services/reimbursement';
import { FlyInTransition } from '../transitions/transitions';
import Modal from '../../containers/common/Modal';
import crossIcon from '../../images/common/cross.svg';
import { PrimaryButton } from '../common/Buttons';
import { ButtonText } from '../common/Text';

class StepsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      categoryData: [],
      stepsData: [],
    };
    // console.log(props);
  }

  componentDidMount() {
    this.reimbursementSteps();
  }

  reimbursementSteps = () => {
    // console.log(this.props);
    reimbursementSteps(this.props.authToken)
      .then((res) => {
        // console.log(res);
        this.setState({
          stepsData: res.steps,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (
      (this.node && this.node.contains(e.target)) ||
      (this.node2 && this.node2.contains(e.target))
    ) {
      return;
    }
    this.hideModal();
  };

  hideModal = () => {
    this.setState({ showModal: false });
    setTimeout(this.props.hideModal, 200);
  };

  render() {
    const { stepsData, categoryData } = this.state;
    return (
      <Modal>
        <div className="modalContainer" onClick={this.handleOutsideClick}>
          <FlyInTransition
            in={this.state.showModal}
            mountOnEnter
            unmountOnExit
            appear
          >
            <div
              className="bg-textbox absolute bottom-0px w-full rounded-t-12 max-h-full h-full overflow-auto"
              ref={(node) => (this.node = node)}
            >
              <div className="p-20 border-b border-txtsecondary-shades7 border-solid flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <img
                    src={this.props.currentSelectedReimbursement.icon}
                    alt="selectedReimbursment"
                    height={'32px'}
                    width={'32px'}
                    style={{
                      height: '32px',
                      width: '32px',
                    }}
                  />
                  <span className="text-title-s ml-10px font-semibold p-64px text-txtlight">
                    {this.props.currentSelectedReimbursement.title}
                  </span>
                </div>
                <img src={crossIcon} alt="cross" onClick={this.hideModal} />
              </div>

              <div className="p-20px ">
                <div className="rounded-12 border border-txtsecondary-shades7">
                  <div className="p-20 border-b border-txtsecondary-shades7 border-solid flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center">
                      <span className="text-title-s ml-10px font-semibold p-64px text-txtlight">
                        How its works
                      </span>
                    </div>
                  </div>

                  {stepsData.map((e, i) => {
                    return (
                      <div key={i} className="p-20px">
                        <div className="flex flex-row">
                          <img
                            src={e.iconImg}
                            height={'32px'}
                            width={'32px'}
                            style={{
                              height: '32px',
                              width: '32px',
                            }}
                            alt="stepsCategory"
                          />
                          <div className="flex flex-col ml-8px ">
                            <p className="font-semibold">STEP {e.step}</p>
                            <p className="text-body-l font-semibold text-txtlight mb-4px">
                              {e.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="mt-50">
                  <PrimaryButton onTap={() => this.props.onPatientProceed()}>
                    <ButtonText>Continue</ButtonText>
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </FlyInTransition>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(StepsModal);
