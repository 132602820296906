import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tooltip from '../../components/common/Tooltip';
import ErrorModal from '../../containers/common/ErrorModal';
import StyledTappable from '../common/StyledTappable';
import { RegularText, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';

import arrowRight from '../../images/common/arrow-right.svg';
import ellipseIcon from '../../images/common/ellipse.svg';
import tickIcon from '../../images/common/tick.svg';
import cautionIcon from '../../images/common/caution.svg';
import clockIcon from '../../images/lab-test/green-clock.svg';
import PathologyIcon from '../../images/lab-test/pathology.svg';
import PathologyGreenIcon from '../../images/lab-test/microscope-green.svg';
import RadiologyIcon from '../../images/lab-test/radiology.svg';
import cartIcon from '../../images/lab-test/cart-new.svg';
import crossIcon from '../../images/lab-test/cross.svg';
import orderPlacedIcon from '../../images/lab-test/order-placed.svg';
import patientIcon from '../../images/lab-test/patient-icon.svg';
import calendarIcon from '../../images/lab-test/order-calendar.svg';
import partnerIcon from '../../images/lab-test/partner.svg';
import partnerNameIcon from '../../images/lab-test/partner-name.svg';
import completedIcon from '../../images/lab-test/completed.svg';
import completedEarlyIcon from '../../images/lab-test/completed-early.svg';
import partnerConfirmIcon from '../../images/lab-test/partner-icon.svg';
import sampleProcessingIcon from '../../images/lab-test/sample-processing.svg';
import prescriptionIcon from '../../images/lab-test/precription-card-icon.svg';
import samplePickUpIcon from '../../images/lab-test/sample-pickup.svg';
import profileIcon from '../../images/common/profile.svg';
import stethoscope from '../../images/pharmacy/stethoscope.png';
import arrowRightFill from '../../images/lab-test/arrowright-fill.svg';
import crossFillIcon from '../../images/lab-test/cross-fill.svg';
import newCrossIcon from '../../images/lab-test/new-cross.svg';
import SyringeIcon from '../../images/lab-test/syringe.svg';
import oldTimeIcon from '../../images/lab-test/old-time.svg';
import newTimeIcon from '../../images/lab-test/new-time.svg';
import oldPartnerIcon from '../../images/lab-test/old-partner.svg';
import copayIcon from '../../images/lab-test/copay.svg';
import copayWalletIcon from '../../images/lab-test/copay-wallet.svg';
import modalIcon from '../../images/lab-test/emergency-tick.svg';

import {
  intitiateEmergencyCall,
  payLabCopay,
  postSamplePickup,
} from '../../services';
import initializeFreshDesk from '../../utils/freshchat';

const ScreenIcon = styled.img`
  filter: ${(props) =>
    props.type === 'digitisationDelayed' ||
    props.type === 'digitisationRejected' ||
    props.type === 'delayed'
      ? 'brightness(0) saturate(100%) invert(54%) sepia(42%) saturate(985%) hue-rotate(324deg) brightness(102%) contrast(100%)'
      : 'none'};
`;
const EllipseIcon = styled.img`
  filter: ${(props) =>
    props.type === 'digitisationDelayed' ||
    props.type === 'digitisationRejected' ||
    props.type === 'delayed'
      ? 'brightness(0) saturate(100%) invert(54%) sepia(42%) saturate(985%) hue-rotate(324deg) brightness(102%) contrast(100%)'
      : 'none'};
`;

const IconContainer = styled.div`
  padding: 7px 12px;
  align-self: start;
  border: 1px solid
    ${(props) =>
      props.type === 'digitisationRejected' ? '#E86570' : '#E3E3E3'};
  border-radius: 12px;
  margin-right: 12px;
  text-align: center;
`;

const ModalContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  max-width: 400px;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
  z-index: 3;
`;
const CloseContainer = styled.div`
  cursor: pointer;
  align-self: flex-end;
`;

const ModalIcon = styled.img`
  width: 78px;
  height: 78px;
`;

const ModalText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin: 0.625rem 0rem;
  color: #0f0b28;
  font-weight: 500;
`;

const PrescriptionStatusCard = ({ orderRequest, onTap, history }) => {
  const { cardType: type, digitisationId } = orderRequest;
  const authToken = useSelector((state) => state.user.authToken);
  const user = useSelector((state) => state.user);
  const [modalDetails, setModalDetails] = useState({
    showModal: false,
    modalText: '',
  });

  const emergencyCallHandler = (e) => {
    e.stopPropagation();

    if (!orderRequest.orderId && orderRequest.isDelayed) {
      initializeFreshDesk(user);
    } else {
      const orderId = orderRequest.orderId;
      // console.log(orderId);
      intitiateEmergencyCall(authToken, orderId)
        .then((res) => {
          console.log(res);
          setModalDetails({ showModal: true, modalText: res });
        })
        .catch((err) => {});
    }
  };
  const hideModal = (e) => {
    e.stopPropagation();
    setModalDetails({ showModal: false, modalText: '' });
  };

  if (
    type === 'digitisationDelayed' ||
    type === 'digitisationPending' ||
    type === 'digitisationRejected'
  ) {
    return (
      <div
        className="hover pt-16px mt-16px rounded-12"
        style={{
          border: `1px solid ${
            type === 'digitisationPending' ? '#E6E9EC' : '#E86570'
          }`,
        }}
        onClick={onTap}
      >
        <div
          className="flex flex-row justify-between items-center px-16px pb-16px"
          style={{
            borderBottom: `${
              type === 'digitisationPending' ? 'none' : '1px solid #F0F3F6'
            }`,
          }}
        >
          <div className="flex">
            <IconContainer color={type}>
              <img
                src={prescriptionIcon}
                alt="prescription"
                style={{ minWidth: '17.37px', minHeight: '26.12px' }}
              />
            </IconContainer>
            <div className="flex flex-col">
              <span className="text-title-s font-semibold text-txtlight">
                {orderRequest.title}
              </span>
              <div
                className="text-chip font-medium mt-2px"
                style={{
                  color: `${
                    type === 'digitisationRejected' ? '#0F0B28' : '#767787'
                  }`,
                }}
              >
                {orderRequest.infoText}
              </div>
            </div>
          </div>
          <ScreenIcon
            className="float-right self-start"
            src={arrowRight}
            alt="right"
            type={type}
          />
        </div>
        <div
          className="flex flex-row items-center justify-between pb-12px px-16px"
          style={{
            marginTop: `${type === 'digitisationPending' ? '6px' : '12px'}`,
            borderBottom: `${
              type === 'digitisationPending' ? 'none' : '1px solid #F0F3F6'
            }`,
          }}
        >
          <div className="flex flex-row">
            <img src={clockIcon} alt="prescribed-time" />
            <p className="text-body-s text-txtlight font-medium ml-8px">
              Request ID : {orderRequest.digitisationId}
            </p>
          </div>
          <div className="flex flex-row items-center">
            {type === 'digitisationRejected' ? (
              <img src={crossIcon} alt="cross" />
            ) : (
              <EllipseIcon
                src={ellipseIcon}
                alt="ellipse"
                height={11}
                width={12}
                className="icon-spin"
                type={type}
              />
            )}

            <span
              className="text-body-s font-medium ml-4px"
              style={{
                color: `${
                  type !== 'digitisationPending' ? '#E86570' : '#01BFBD'
                }`,
              }}
            >
              {type === 'digitisationPending' && 'Digitizing'}
              {type === 'digitisationDelayed' && 'Delayed'}
              {type === 'digitisationRejected' && 'Rejected'}
            </span>
          </div>
        </div>
        {orderRequest.showEmergencyButtonCall && (
          <div
            className="text-center py-12px text-body-s text-primary font-semibold tracking-wide"
            onClick={emergencyCallHandler}
          >
            {type === 'digitisationDelayed'
              ? 'Contact Support'
              : 'Request Callback'}
          </div>
        )}
        {orderRequest.resubmit && (
          <div
            className="text-center py-12px text-body-s text-primary font-semibold tracking-wide"
            onClick={() => history.push('/lab-test/upload-prescription')}
          >
            Re-upload Prescription
          </div>
        )}
        {modalDetails.showModal && (
          <ErrorModal>
            <ModalContainer onClick={hideModal}>
              <ModalBody onClick={(e) => e.stopPropagation()}>
                <CloseContainer onClick={(e) => hideModal(e)}>
                  <FaTimes />
                </CloseContainer>
                <ModalIcon
                  onClick={(e) => e.stopPropagation()}
                  src={modalIcon}
                />
                <ModalText onClick={(e) => e.stopPropagation()}>
                  {modalDetails.modalText}
                </ModalText>
              </ModalBody>
            </ModalContainer>
          </ErrorModal>
        )}
      </div>
    );
  } else if (type === 'reimbursement') {
    return (
      <div
        className="hover p-16px pb-12px mt-16px border border-txtsecondary-shades7 border-solid rounded-12"
        onClick={onTap}
      >
        <div className="flex flex-row justify-between	items-center pb-4px">
          <span className="text-title-s font-semibold">Reimbursement</span>
          <img className="float-right" src={arrowRight} alt="right" />
        </div>
        <div>
          <p className="lab-test-name mt-13">{orderRequest.patientName}</p>
          <p className="txt-grey-600-12 mt-4 mb-4">
            {orderRequest.prescriptionGivenOn}
          </p>

          <p className="txt-black-600-12 lab-home-btn hover" onClick={onTap}>
            {orderRequest.btnLabel}
          </p>
        </div>
      </div>
    );
  } else if (type === 'booked-order') {
    return (
      <div
        className="p-16px pt-0 pb-12px mt-16px border border-txtsecondary-shades7 border-solid	rounded-12"
        onClick={() => {
          onTap(orderRequest);
        }}
      >
        <span
          className="py-4px px-12px text-secondary-shades2 text-body-s font-semibold"
          style={{
            background: 'rgba(207, 200, 255, 0.2)',
            borderRadius: '0px 0px 8px 8px',
          }}
        >
          {orderRequest.testsType}
        </span>
        <div className="flex flex-row justify-between pt-12px items-center pb-4px">
          <div className="flex flex-row items-center">
            {orderRequest.status === 'pending' ||
            orderRequest.status === 'completed' ? (
              <img src={tickIcon} alt="tick" height={11} width={12} />
            ) : (
              <img src={cautionIcon} alt="tick" height={11} width={12} />
            )}
            <div
              className="text-body-s font-semibold pl-8px"
              style={
                orderRequest.status === 'pending' ||
                orderRequest.status === 'completed'
                  ? { color: '#01BFBD' }
                  : { color: '#E86570' }
              }
            >
              {orderRequest.status === 'payment-pending'
                ? orderRequest.pendingCopayText || orderRequest.status
                : orderRequest.status}
            </div>
          </div>
        </div>
        <div className="text-title-s font-semibold">
          {orderRequest.testNames}
        </div>
        {orderRequest.doctorName && (
          <div className="flex flex-row items-center pb-2px">
            <img src={stethoscope} alt="stethoscope" height={12} width={12} />
            <div className="text-body-s text-txtsecondary font-medium pl-4px ">
              {orderRequest.doctorName}
            </div>
          </div>
        )}
        <div className="flex flex-row items-center pb-24px">
          <img src={profileIcon} alt="profile" height={12} width={12} />
          <div className="text-body-s text-txtsecondary font-medium pl-4px ">
            {orderRequest.patientName}
          </div>
        </div>
        <div className="flex flex-row justify-between items-center">
          <div className="text-chip text-txtsecondary">
            {orderRequest.bookedOn}
          </div>
          <div className="flex flex-row hover">
            <p className="text-body-l text-primary font-semibold mr-8px">
              {orderRequest.status === 'payment-pending'
                ? 'Pay & Confirm'
                : 'View Details'}
            </p>
            <img src={arrowRightFill} />
          </div>
        </div>
      </div>
    );
  } else if (type === 'availableCart') {
    return (
      //   <div className="p-16px pt-0 pb-12px mt-16px border border-txtsecondary-shades7 border-solid	rounded-12">
      //     <div className="flex flex-row items-center mt-16px">
      //       <img src={tickIcon} alt="tick" height={11} width={12} />
      //       <p className="text-body-s font-semibold text-secondary-shades6 ml-4px">
      //         Transcribed
      //       </p>
      //     </div>
      //     <div className="text-body-l font-semibold text-txtlight pt-4px pb-4px">
      //       Tests Ready for order
      //     </div>
      //     <div className="text-title-s font-semibold">
      //       {orderRequest.testNames}
      //     </div>
      //     {orderRequest.doctorName && (
      //       <div className="flex flex-row items-center pb-2px">
      //         <img src={stethoscope} alt="stethoscope" height={12} width={12} />
      //         <div className="text-body-s text-txtsecondary font-medium pl-4px ">
      //           {orderRequest.doctorName}
      //         </div>
      //       </div>
      //     )}
      //     <div className="flex flex-row items-center pb-24px">
      //       <img src={profileIcon} alt="profile" height={12} width={12} />
      //       <div className="text-body-s text-txtsecondary font-medium pl-4px ">
      //         {orderRequest.patientName}
      //       </div>
      //     </div>
      //     <div>
      //       <div className="text-chip text-txtsecondary">
      //         {orderRequest.prescriptionGivenOn}
      //       </div>
      //       <div
      //         className="bg-primary hover rounded-12 max-w-100 mt-8px py-10px text-center"
      //         onClick={() => {
      //           onTap(orderRequest);
      //         }}
      //       >
      //         <p className="text-body-l text-textbox font-semibold">
      //           View & Confirm
      //         </p>
      //       </div>
      //     </div>
      //   </div>
      <div
        className="hover pt-16px mt-16px rounded-12 border border-solid border-boxCard"
        onClick={onTap}
      >
        <div className="flex flex-row justify-between items-center px-16px pb-20px border-b border-solid border-txtsecondary-shades4">
          <div className="flex">
            <IconContainer color={type} style={{ padding: '10px 9px' }}>
              <img
                src={cartIcon}
                alt="cart"
                style={{ minWidth: '23px', minHeight: '19px' }}
              />
            </IconContainer>
            <div className="flex flex-col">
              <span className="text-title-s font-semibold text-txtlight">
                {orderRequest.title}
              </span>
              <div className="text-chip font-medium mt-2px text-sub">
                {orderRequest.infoText}
              </div>
            </div>
          </div>
          <img
            className="float-right self-start"
            src={arrowRight}
            alt="right"
            type={type}
          />
        </div>
        <div
          className="flex flex-col items-start pb-12px px-16px"
          style={{
            marginTop: `${type === 'digitisationPending' ? '6px' : '12px'}`,
            borderBottom: '1px solid #F0F3F6',
          }}
        >
          <div className="flex flex-row">
            {!orderRequest.digitisationId ? (
              <>
                <img
                  src={stethoscope}
                  alt="prescribed-time"
                  width={'16px'}
                  height={'16px'}
                />
                <p className="text-body-s text-txtlight font-medium ml-8px">
                  {orderRequest.consultationStr}
                </p>
              </>
            ) : (
              <>
                <img src={clockIcon} alt="prescribed-time" />
                <p className="text-body-s text-txtlight font-medium ml-8px">
                  Request ID - {orderRequest.digitisationId}
                </p>
              </>
            )}
          </div>
          <div className="flex flex-row items-center mt-8px">
            {orderRequest.testsType === 'Pathology' && (
              <img src={PathologyIcon} alt="test type" className="self-start" />
            )}
            {orderRequest.testsType === 'Radiology' && (
              <img src={RadiologyIcon} alt="test type" className="self-start" />
            )}
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Tests Booked - {orderRequest.testsType}
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.testNames}
              </p>
            </div>
          </div>
        </div>
        <div
          className="text-center py-12px text-body-s text-primary font-semibold tracking-wide"
          onClick={() => {
            history.push('/lab-test/order-status', {
              cartId: orderRequest.cartId,
              digitizationId: orderRequest.digitizationId,
            });
          }}
        >
          Place Order
        </div>
      </div>
    );
  } else if (type === 'orderPlaced') {
    return (
      <div
        className="hover pt-16px mt-16px rounded-12 border border-solid border-boxCard"
        onClick={onTap}
      >
        <div className="flex flex-row justify-between items-center px-16px pb-20px border-b border-solid border-txtsecondary-shades4">
          <div className="flex">
            <IconContainer color={type} style={{ padding: '10px 10.30px' }}>
              <img
                src={orderPlacedIcon}
                alt="order-placed"
                style={{ minWidth: '19.71px', minHeight: '19.71px' }}
              />
            </IconContainer>
            <div className="flex flex-col">
              <span className="text-title-s font-semibold text-txtlight">
                {orderRequest.title}
              </span>
              <div className="text-chip font-medium mt-2px text-sub">
                {orderRequest.infoText}
              </div>
            </div>
          </div>
          <img
            className="float-right self-start"
            src={arrowRight}
            alt="right"
            type={type}
          />
        </div>
        <div
          className="flex flex-col items-start pb-12px px-16px"
          style={{
            marginTop: '12px',
            borderBottom: '1px solid #F0F3F6',
          }}
        >
          <div className="flex flex-row">
            <img src={clockIcon} alt="prescribed-time" />
            <p className="text-body-s text-txtlight font-medium ml-8px">
              Order ID - {orderRequest.orderId}
            </p>
          </div>
          <div className="flex flex-row items-center mt-8px">
            {orderRequest.testsType === 'pathology' && (
              <img src={PathologyIcon} alt="test type" className="self-start" />
            )}
            {orderRequest.testsType === 'radiology' && (
              <img src={RadiologyIcon} alt="test type" className="self-start" />
            )}
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Tests Booked - {orderRequest.testsType}
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.testNames}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={patientIcon} alt="patient" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Patient
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.patientName}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={calendarIcon} alt="calendar" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Date & Time Slot
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.slotDateTime}
              </p>
            </div>
          </div>
          {/* {orderRequest.partnerName && } */}
          <div className="flex flex-row items-center justify-between mt-8px w-full">
            <div className="flex flex-row items-center">
              <img src={partnerIcon} alt="calendar" className="self-start" />
              <div className="flex flex-col">
                <p className="text-body-s text-txtlight font-medium ml-8px">
                  Partner
                </p>
                <p className="text-chip text-txtsecondary-shades8 ml-8px">
                  {orderRequest.partnerName}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <EllipseIcon
                src={ellipseIcon}
                alt="ellipse"
                height={11}
                width={12}
                className="icon-spin"
                type={type}
              />
              <span className="text-body-s text-secondary-shades6 font-medium ml-4px">
                Confirming
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === 'samplePickUp') {
    return (
      <div
        className="hover pt-16px mt-16px rounded-12"
        style={{
          border: `1px solid ${orderRequest.isDelayed ? '#E86570' : '#E6E9EC'}`,
        }}
        onClick={onTap}
      >
        <div className="flex flex-row justify-between items-center px-16px pb-20px border-b border-solid border-txtsecondary-shades4">
          <div className="flex">
            <IconContainer color={type} style={{ padding: '12px 10.50px' }}>
              <img
                src={samplePickUpIcon}
                alt="sample-pickup"
                style={{ minWidth: '19.76px', minHeight: '16.85px' }}
              />
            </IconContainer>
            <div className="flex flex-col">
              <span className="text-title-s font-semibold text-txtlight">
                {orderRequest.title}
              </span>
              <div className="text-chip font-medium mt-2px text-sub">
                {orderRequest.infoText}
              </div>
            </div>
          </div>
          <ScreenIcon
            className="float-right self-start"
            src={arrowRight}
            alt="right"
            type={orderRequest.isDelayed ? 'delayed' : 'notDelayed'}
          />
        </div>
        <div
          className="flex flex-col items-start pb-12px px-16px"
          style={{
            marginTop: '12px',
            borderBottom: '1px solid #F0F3F6',
          }}
        >
          <div className="flex flex-row">
            <img src={clockIcon} alt="prescribed-time" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Order ID - {orderRequest.orderId}
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                Booked on {orderRequest.bookedOnDate}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            {orderRequest.testsType === 'pathology' && (
              <img src={PathologyIcon} alt="test type" className="self-start" />
            )}
            {orderRequest.testsType === 'radiology' && (
              <img
                src={RadiologyIcon}
                alt="test type"
                className="self-start"
                style={{ paddingLeft: '3px', marginRight: '3px' }}
              />
            )}
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Tests Booked - {orderRequest.testsType}
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.testNames}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={patientIcon} alt="patient" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Patient
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.patientName}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={calendarIcon} alt="calendar" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Date & Time Slot
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.slotDateTime}
              </p>
            </div>
          </div>
          {/* {orderRequest.partnerName && } */}
          <div className="flex flex-row items-center justify-between mt-8px w-full">
            <div className="flex flex-row items-center">
              <img src={partnerIcon} alt="calendar" className="self-start" />
              <div className="flex flex-col">
                <p className="text-body-s text-txtlight font-medium ml-8px">
                  Partner
                </p>
                <p className="text-chip text-txtsecondary-shades8 ml-8px">
                  {orderRequest.partnerName}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <EllipseIcon
                src={ellipseIcon}
                alt="ellipse"
                height={11}
                width={12}
                className="icon-spin"
                type={orderRequest.isDelayed ? 'delayed' : 'notDelayed'}
              />
              <span
                className="text-body-s font-medium ml-4px"
                style={{
                  color: `${!orderRequest.isDelayed ? '#01BFBD' : '#E86570'}`,
                }}
              >
                {orderRequest.isDelayed ? 'Delayed' : 'Confirming'}
              </span>
            </div>
          </div>
        </div>
        {orderRequest.showEmergencyButtonCall && (
          <div
            className="text-center text-body-s text-primary font-semibold py-12px w-full border-t border-solid border-txtsecondary-shades4 tracking-wide"
            onClick={emergencyCallHandler}
          >
            {orderRequest.isDelayed &&
              !orderRequest.orderId &&
              'Contact Support'}
            {orderRequest.orderId && 'Request Callback'}
          </div>
        )}
        {orderRequest.questionToAsk && (
          <div>
            <div className="text-center text-body-s text-txtlight font-semibold py-12px w-full border-t border-solid border-txtsecondary-shades4 tracking-wide">
              {orderRequest.questionToAsk}
            </div>
            <div className="text-center text-body-s font-semibold py-12px w-full border-t border-solid border-txtsecondary-shades4 tracking-wide flex flex-row items-center">
              <div
                className="text-primary flex-1"
                onClick={(e) => {
                  e.stopPropagation();
                  postSamplePickup(authToken, true, orderRequest.orderId);
                }}
              >
                Yes, on time!
              </div>
              <div
                style={{ minHeight: '18px', border: '1px solid #F0F3F6' }}
              ></div>
              <div
                className="flex-1"
                style={{ color: '#F25540' }}
                onClick={(e) => {
                  e.stopPropagation();
                  postSamplePickup(authToken, false);
                }}
              >
                No, not yet!
              </div>
            </div>
          </div>
        )}
        {modalDetails.showModal && (
          <ErrorModal>
            <ModalContainer onClick={hideModal}>
              <ModalBody onClick={(e) => e.stopPropagation()}>
                <CloseContainer onClick={(e) => hideModal(e)}>
                  <FaTimes />
                </CloseContainer>
                <ModalIcon
                  onClick={(e) => e.stopPropagation()}
                  src={modalIcon}
                />
                <ModalText onClick={(e) => e.stopPropagation()}>
                  {modalDetails.modalText}
                </ModalText>
              </ModalBody>
            </ModalContainer>
          </ErrorModal>
        )}
      </div>
    );
  } else if (type === 'partnerConfirmation' || type === 'partnerConfirmed') {
    return (
      <div
        className="hover pt-16px mt-16px rounded-12"
        style={{
          border: `1px solid ${orderRequest.isDelayed ? '#E86570' : '#E6E9EC'}`,
        }}
        onClick={onTap}
      >
        <div className="flex flex-row justify-between items-center px-16px pb-20px border-b border-solid border-txtsecondary-shades4">
          <div className="flex">
            <IconContainer color={type} style={{ padding: '12px 9px' }}>
              <img
                src={partnerConfirmIcon}
                alt="sample-pickup"
                style={{ minWidth: '23.1px', minHeight: '15.23px' }}
              />
            </IconContainer>
            <div className="flex flex-col">
              <span className="text-title-s font-semibold text-txtlight">
                {orderRequest.title}
              </span>
              <div className="text-chip font-medium mt-2px text-sub">
                {orderRequest.infoText}
              </div>
            </div>
          </div>
          <ScreenIcon
            className="float-right self-start"
            src={arrowRight}
            alt="right"
            type={orderRequest.isDelayed ? 'delayed' : 'notDelayed'}
          />
        </div>
        <div
          className="flex flex-col items-start pb-12px px-16px"
          style={{
            marginTop: '12px',
            borderBottom: '1px solid #F0F3F6',
          }}
        >
          <div className="flex flex-row">
            <img src={clockIcon} alt="prescribed-time" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Order ID - {orderRequest.orderId}
              </p>
              <p className="text-chip text-txtsecondary ml-8px">
                Booked on {orderRequest.bookedOnDate}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            {orderRequest.testsType === 'pathology' && (
              <img src={PathologyIcon} alt="test type" className="self-start" />
            )}
            {orderRequest.testsType === 'radiology' && (
              <img
                src={RadiologyIcon}
                alt="test type"
                className="self-start"
                style={{ paddingLeft: '3px', marginRight: '3px' }}
              />
            )}
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Tests Booked - {orderRequest.testsType}
              </p>
              <p className="text-chip text-txtsecondary ml-8px">
                {orderRequest.testNames}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={patientIcon} alt="patient" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Patient
              </p>
              <p className="text-chip text-txtsecondary ml-8px">
                {orderRequest.patientName}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={calendarIcon} alt="calendar" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Date & Time Slot
              </p>
              <p className="text-chip text-txtsecondary ml-8px">
                {orderRequest.slotDateTime}
              </p>
            </div>
          </div>
          {/* {orderRequest.partnerName && } */}
          <div className="flex flex-row items-center justify-between mt-8px w-full">
            <div className="flex flex-row items-center">
              <img src={partnerIcon} alt="calendar" className="self-start" />
              <div className="flex flex-col">
                <p className="text-body-s text-txtlight font-medium ml-8px">
                  Partner
                </p>
                <p className="text-chip text-txtsecondary ml-8px">
                  {orderRequest.partnerName}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center">
              {orderRequest.isDelayed ? (
                <>
                  <EllipseIcon
                    src={ellipseIcon}
                    alt="ellipse"
                    height={11}
                    width={12}
                    className="icon-spin"
                    type={orderRequest.isDelayed ? 'delayed' : 'notDelayed'}
                  />
                  <span
                    className="text-body-s font-medium ml-4px"
                    style={{
                      color: `${
                        !orderRequest.isDelayed ? '#01BFBD' : '#E86570'
                      }`,
                    }}
                  >
                    Delayed
                  </span>{' '}
                </>
              ) : (
                <>
                  <img src={partnerNameIcon} alt="lab" className="self-start" />
                  <p className="text-body-s font-medium text-primary ml-4px">
                    {orderRequest.partnerName}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        {orderRequest.showEmergencyButtonCall ? (
          <div
            className="text-center text-body-s text-primary font-semibold py-12px w-full border-t border-solid border-txtsecondary-shades4 tracking-wide"
            onClick={emergencyCallHandler}
          >
            {orderRequest.isDelayed &&
              !orderRequest.orderId &&
              'Contact Support'}
            {orderRequest.orderId && 'Request Callback'}
          </div>
        ) : (
          <div></div>
        )}
        {modalDetails.showModal && (
          <ErrorModal>
            <ModalContainer onClick={hideModal}>
              <ModalBody onClick={(e) => e.stopPropagation()}>
                <CloseContainer onClick={(e) => hideModal(e)}>
                  <FaTimes />
                </CloseContainer>
                <ModalIcon
                  onClick={(e) => e.stopPropagation()}
                  src={modalIcon}
                />
                <ModalText onClick={(e) => e.stopPropagation()}>
                  {modalDetails.modalText}
                </ModalText>
              </ModalBody>
            </ModalContainer>
          </ErrorModal>
        )}
      </div>
    );
  } else if (type === 'sampleProcessing') {
    return (
      <div
        className="hover pt-16px mt-16px rounded-12"
        style={{
          border: `1px solid ${orderRequest.isDelayed ? '#E86570' : '#E6E9EC'}`,
        }}
        onClick={onTap}
      >
        <div className="flex flex-row justify-between items-center px-16px pb-20px border-b border-solid border-txtsecondary-shades4">
          <div className="flex">
            <IconContainer color={type} style={{ padding: '11px 12px' }}>
              <img
                src={sampleProcessingIcon}
                alt="sample-pickup"
                style={{ minWidth: '16.64px', minHeight: '17.05px' }}
              />
            </IconContainer>
            <div className="flex flex-col">
              <span className="text-title-s font-semibold text-txtlight">
                {orderRequest.title}
              </span>
              <div className="text-chip font-medium mt-2px text-sub">
                {orderRequest.infoText}
              </div>
            </div>
          </div>
          <ScreenIcon
            className="float-right self-start"
            src={arrowRight}
            alt="right"
            type={orderRequest.isDelayed ? 'delayed' : 'notDelayed'}
          />
        </div>
        <div
          className="flex flex-col items-start pb-12px px-16px"
          style={{
            marginTop: '12px',
            borderBottom: '1px solid #F0F3F6',
          }}
        >
          <div className="flex flex-row">
            <img src={clockIcon} alt="prescribed-time" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Order ID - {orderRequest.orderId}
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                Booked on {orderRequest.bookedOnDate}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            {orderRequest.testsType === 'pathology' && (
              <img src={PathologyIcon} alt="test type" className="self-start" />
            )}
            {orderRequest.testsType === 'radiology' && (
              <img
                src={RadiologyIcon}
                alt="test type"
                className="self-start"
                style={{ paddingLeft: '3px', marginRight: '3px' }}
              />
            )}
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Tests Booked - {orderRequest.testsType}
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.testNames}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={patientIcon} alt="patient" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Patient
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.patientName}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={calendarIcon} alt="calendar" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Date & Time Slot
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.slotDateTime}
              </p>
            </div>
          </div>
          {/* {orderRequest.partnerName && } */}
          <div className="flex flex-row items-center justify-between mt-8px w-full">
            <div className="flex flex-row items-center">
              <img src={partnerIcon} alt="calendar" className="self-start" />
              <div className="flex flex-col">
                <p className="text-body-s text-txtlight font-medium ml-8px">
                  Partner
                </p>
                <p className="text-chip text-txtsecondary-shades8 ml-8px">
                  {orderRequest.partnerName}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <EllipseIcon
                src={ellipseIcon}
                alt="ellipse"
                height={11}
                width={12}
                className="icon-spin"
                type={orderRequest.isDelayed ? 'delayed' : 'notDelayed'}
              />
              <span
                className="text-body-s font-medium ml-4px"
                style={{
                  color: `${!orderRequest.isDelayed ? '#01BFBD' : '#E86570'}`,
                }}
              >
                {orderRequest.isDelayed ? 'Delayed' : 'Reports Awaited'}
              </span>
            </div>
          </div>
        </div>
        {orderRequest.showEmergencyButtonCall ? (
          <div
            className="text-center text-body-s text-primary font-semibold py-12px w-full border-t border-solid border-txtsecondary-shades4 tracking-wide"
            onClick={emergencyCallHandler}
          >
            {orderRequest.isDelayed &&
              !orderRequest.orderId &&
              'Contact Support'}
            {orderRequest.orderId && 'Request Callback'}
          </div>
        ) : (
          <div></div>
        )}
        {modalDetails.showModal && (
          <ErrorModal>
            <ModalContainer onClick={hideModal}>
              <ModalBody onClick={(e) => e.stopPropagation()}>
                <CloseContainer onClick={(e) => hideModal(e)}>
                  <FaTimes />
                </CloseContainer>
                <ModalIcon
                  onClick={(e) => e.stopPropagation()}
                  src={modalIcon}
                />
                <ModalText onClick={(e) => e.stopPropagation()}>
                  {modalDetails.modalText}
                </ModalText>
              </ModalBody>
            </ModalContainer>
          </ErrorModal>
        )}
      </div>
    );
  } else if (type === 'reportGenerated') {
    return (
      <div
        className="hover pt-16px mt-16px rounded-12"
        style={{
          border: `1px solid ${
            orderRequest.completedRemarks ? '#7DCC18' : '#E6E9EC'
          }`,
        }}
        onClick={onTap}
      >
        <div className="flex flex-row justify-between items-center px-16px pb-20px border-b border-solid border-txtsecondary-shades4">
          <div className="flex">
            <IconContainer color={type} style={{ padding: '11px 12px' }}>
              <img
                src={sampleProcessingIcon}
                alt="sample-pickup"
                style={{ minWidth: '16.64px', minHeight: '17.05px' }}
              />
            </IconContainer>
            <div className="flex flex-col">
              <span className="text-title-s font-semibold text-txtlight">
                {orderRequest.title}
              </span>
              <div className="text-chip font-medium mt-2px text-sub">
                {orderRequest.infoText}
              </div>
            </div>
          </div>
          <ScreenIcon
            className="float-right self-start"
            src={arrowRight}
            alt="right"
            type={orderRequest.isDelayed ? 'delayed' : 'notDelayed'}
          />
        </div>
        <div
          className="flex flex-col items-start pb-12px px-16px"
          style={{
            marginTop: '12px',
            borderBottom: '1px solid #F0F3F6',
          }}
        >
          <div className="flex flex-row">
            <img src={clockIcon} alt="prescribed-time" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Order ID - {orderRequest.orderId}
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                Booked on {orderRequest.bookedOnDate}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            {orderRequest.testsType === 'pathology' && (
              <img src={PathologyIcon} alt="test type" className="self-start" />
            )}
            {orderRequest.testsType === 'radiology' && (
              <img
                src={RadiologyIcon}
                alt="test type"
                className="self-start"
                style={{ paddingLeft: '3px', marginRight: '3px' }}
              />
            )}
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Tests Booked - {orderRequest.testsType}
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.testNames}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={patientIcon} alt="patient" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Patient
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.patientName}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={calendarIcon} alt="calendar" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Date & Time Slot
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.slotDateTime}
              </p>
            </div>
          </div>
          {/* {orderRequest.partnerName && } */}
          <div className="flex flex-row items-center justify-between mt-8px w-full">
            <div className="flex flex-row items-center">
              <img src={partnerIcon} alt="calendar" className="self-start" />
              <div className="flex flex-col">
                <p className="text-body-s text-txtlight font-medium ml-8px">
                  Partner
                </p>
                <p className="text-chip text-txtsecondary-shades8 ml-8px">
                  {orderRequest.partnerName}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center">
              {orderRequest.completedRemarks ? (
                <>
                  <img src={completedEarlyIcon} alt="completed-icon" />
                  <span
                    className="text-body-s font-medium ml-4px"
                    style={{
                      color: `#7DCC18`,
                    }}
                  >
                    {orderRequest.completedRemarks}
                  </span>
                </>
              ) : (
                <>
                  <img src={completedIcon} alt="completed-icon" />
                  <span
                    className="text-body-s font-medium ml-4px"
                    style={{
                      color: `#7DCC18`,
                    }}
                  >
                    Complete
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="py-16px px-20px w-full border-t border-solid border-txtsecondary-shades4 tracking-wide">
          <div
            onClick={(e) => {
              e.stopPropagation();
              window.open(orderRequest.reportUrl, '_blank');
            }}
            className="bg-primary text-center py-12px rounded-12 text-body-s font-bold block"
            style={{ color: 'white' }}
          >
            Download Report
          </div>
        </div>
        <div className="py-16px px-20px w-full border-t border-solid border-txtsecondary-shades4 tracking-wide text-center text-sub text-chip font-medium">
          {orderRequest.description}
        </div>
        <div
          className="py-16px w-full border-t border-solid border-txtsecondary-shades4 tracking-wide text-center text-primary text-body-s font-semibold"
          onClick={(e) => {
            e.stopPropagation();
            history.push('/consultation/start');
          }}
        >
          Consult Doctor
        </div>
      </div>
    );
  } else if (type === 'partialReportGenerated') {
    return (
      <div
        className="hover pt-16px mt-16px rounded-12"
        style={{
          border: '1px solid #E6E9EC',
        }}
        onClick={onTap}
      >
        <div className="flex flex-row justify-between items-center px-16px pb-20px border-b border-solid border-txtsecondary-shades4">
          <div className="flex">
            <IconContainer color={type} style={{ padding: '11px 12px' }}>
              <img
                src={sampleProcessingIcon}
                alt="sample-pickup"
                style={{ minWidth: '16.64px', minHeight: '17.05px' }}
              />
            </IconContainer>
            <div className="flex flex-col">
              <span className="text-title-s font-semibold text-txtlight">
                {orderRequest.title}
              </span>
              <div className="text-chip font-medium mt-2px text-sub">
                {orderRequest.infoText}
              </div>
            </div>
          </div>
          <ScreenIcon
            className="float-right self-start"
            src={arrowRight}
            alt="right"
            type={orderRequest.isDelayed ? 'delayed' : 'notDelayed'}
          />
        </div>
        <div
          className="flex flex-col items-start pb-12px px-16px"
          style={{
            marginTop: '12px',
            borderBottom: '1px solid #F0F3F6',
          }}
        >
          <div className="flex flex-row">
            <img src={clockIcon} alt="prescribed-time" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Order ID - {orderRequest.orderId}
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                Booked on {orderRequest.bookedOnDate}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            {orderRequest.testsType === 'pathology' && (
              <img src={PathologyIcon} alt="test type" className="self-start" />
            )}
            {orderRequest.testsType === 'radiology' && (
              <img
                src={RadiologyIcon}
                alt="test type"
                className="self-start"
                style={{ paddingLeft: '3px', marginRight: '3px' }}
              />
            )}
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Tests Booked - {orderRequest.testsType}
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.testNames}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={patientIcon} alt="patient" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Patient
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.patientName}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={calendarIcon} alt="calendar" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Date & Time Slot
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.slotDateTime}
              </p>
            </div>
          </div>
          {/* {orderRequest.partnerName && } */}
          <div className="flex flex-row items-center justify-between mt-8px w-full">
            <div className="flex flex-row items-center">
              <img src={partnerIcon} alt="calendar" className="self-start" />
              <div className="flex flex-col">
                <p className="text-body-s text-txtlight font-medium ml-8px">
                  Partner
                </p>
                <p className="text-chip text-txtsecondary-shades8 ml-8px">
                  {orderRequest.partnerName}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <EllipseIcon
                src={ellipseIcon}
                alt="ellipse"
                height={11}
                width={12}
                className="icon-spin"
                type={type}
              />
              <span
                className="text-body-s font-medium ml-4px"
                style={{
                  color: `#01BFBD`,
                }}
              >
                Reports Awaited
              </span>
            </div>
          </div>
        </div>
        <div className="py-16px px-20px w-full border-t border-solid border-txtsecondary-shades4 tracking-wide">
          <div
            onClick={(e) => {
              e.stopPropagation();
              window.open(orderRequest.reportUrl, '_blank');
            }}
            className="bg-primary text-center py-12px rounded-12 text-body-s font-bold block"
            style={{ color: 'white' }}
          >
            Download Report
          </div>
        </div>
      </div>
    );
  } else if (type === 'orderCancelled') {
    return (
      <div
        className="hover pt-16px mt-16px rounded-12"
        style={{
          border: '1px solid #E6E9EC',
        }}
        onClick={onTap}
      >
        <div className="flex flex-row justify-between items-center px-16px pb-20px border-b border-solid border-txtsecondary-shades4">
          <div className="flex">
            <IconContainer
              color={type}
              style={{ border: '1px solid #E86570', padding: '11px' }}
            >
              <img
                src={newCrossIcon}
                alt="cancelled"
                style={{ minWidth: '18px', minHeight: '18px' }}
              />
            </IconContainer>
            <div className="flex flex-col">
              <span className="text-title-s font-semibold text-txtlight">
                {orderRequest.title
                  ? orderRequest.title
                  : orderRequest.testNames}
              </span>
              <div className="text-chip font-medium mt-2px text-sub">
                {orderRequest.infoText}
              </div>
            </div>
          </div>
          <ScreenIcon
            className="float-right self-start"
            src={arrowRight}
            alt="right"
            style={{ filter: 'brightness(0) saturate(100%)' }}
          />
        </div>
        <div
          className="flex flex-col items-start pb-12px px-16px"
          style={{
            marginTop: '12px',
            borderBottom: '1px solid #F0F3F6',
          }}
        >
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row">
              <img src={patientIcon} alt="patient" className="self-start" />
              <p className="text-body-s text-txtlight font-medium ml-8px">
                {orderRequest.patientName}
              </p>
            </div>
            <div className="flex flex-row items-center">
              {orderRequest.testsType === 'pathology' && (
                <img
                  src={PathologyGreenIcon}
                  alt="test type"
                  className="self-start"
                />
              )}
              {orderRequest.testsType === 'radiology' && (
                <img
                  src={RadiologyIcon}
                  alt="test type"
                  className="self-start"
                />
              )}
              {orderRequest.testsType === 'vaccination' && (
                <img src={SyringeIcon} alt="test type" className="self-start" />
              )}
              <p className="text-body-s text-txtlight ml-8px">
                {orderRequest.testsType}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between w-full mt-8px">
            <div className="flex flex-row">
              <ScreenIcon
                src={clockIcon}
                alt="patient"
                className="self-start"
                type="delayed"
              />
              <p className="text-body-s text-txtlight font-medium ml-8px">
                {orderRequest.cancelledOn}
              </p>
            </div>
            <div className="flex flex-row items-center">
              <img src={crossFillIcon} alt="cancelled" />
              <p className="text-body-s text-accent2-shades4 ml-4px font-semibold">
                Cancelled
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === 'copay') {
    return (
      <div
        className="hover pt-16px mt-16px rounded-12"
        style={{
          border: `1px solid ${orderRequest.isDelayed ? '#E86570' : '#E6E9EC'}`,
        }}
        onClick={onTap}
      >
        <div className="flex flex-row justify-between items-center px-16px pb-20px border-b border-solid border-txtsecondary-shades4">
          <div className="flex">
            <IconContainer
              color={type}
              style={{ padding: '10px', paddingRight: '9.59px' }}
            >
              <img
                src={copayWalletIcon}
                alt="copay"
                style={{ minWidth: '20.81px', minHeight: '20.41px' }}
              />
            </IconContainer>
            <div className="flex flex-col">
              <span className="text-title-s font-semibold text-txtlight">
                {orderRequest.title}
              </span>
              <div className="text-chip font-medium mt-2px text-sub">
                {orderRequest.infoText}
              </div>
            </div>
          </div>
          <ScreenIcon
            className="float-right self-start"
            src={arrowRight}
            alt="right"
            type={orderRequest.isDelayed ? 'delayed' : 'notDelayed'}
          />
        </div>
        <div
          className="flex flex-col items-start pb-12px px-16px"
          style={{
            marginTop: '12px',
            borderBottom: '1px solid #F0F3F6',
          }}
        >
          <div className="flex flex-row">
            <img src={clockIcon} alt="prescribed-time" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Order ID - {orderRequest.orderId}
              </p>
              <p className="text-chip text-txtsecondary ml-8px">
                Booked on {orderRequest.bookedOnDate}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            {orderRequest.testsType === 'pathology' && (
              <img src={PathologyIcon} alt="test type" className="self-start" />
            )}
            {orderRequest.testsType === 'radiology' && (
              <img
                src={RadiologyIcon}
                alt="test type"
                className="self-start"
                style={{ paddingLeft: '3px', marginRight: '3px' }}
              />
            )}
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Tests Booked - {orderRequest.testsType}
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.testNames}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={patientIcon} alt="patient" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Patient
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.patientName}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={calendarIcon} alt="calendar" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Date & Time Slot
              </p>
              <p className="text-chip text-txtsecondary-shades8 ml-8px">
                {orderRequest.slotDateTime}
              </p>
            </div>
          </div>
          {/* {orderRequest.partnerName && } */}
          <div className="flex flex-row items-center justify-between mt-8px w-full">
            <div className="flex flex-row items-center">
              <img src={partnerIcon} alt="calendar" className="self-start" />
              <div className="flex flex-col">
                <p className="text-body-s text-txtlight font-medium ml-8px">
                  Partner
                </p>
                <p className="text-chip text-txtsecondary-shades8 ml-8px">
                  {orderRequest.partnerName}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <Tooltip content={orderRequest.pendingCopayText}>
                <img src={copayIcon} alt="copay" />
              </Tooltip>
              <p className="text-body-l text-txtlight font-bold ml-8px">
                ₹ {orderRequest.pendingCopayAmount}
              </p>
            </div>
          </div>
        </div>
        <div
          className="py-12px text-center text-body-l font-semibold text-primary"
          onClick={(e) => {
            e.stopPropagation();
            payLabCopay(orderRequest.orderId, history, authToken);
          }}
        >
          Make Payment
        </div>
      </div>
    );
  } else if (
    type === 'orderUpdate' ||
    type === 'orderUpdatePartner' ||
    type === 'orderUpdateReschedule'
  ) {
    return (
      <div
        className="hover pt-16px mt-16px rounded-12"
        style={{
          border: `1px solid #E6E9EC`,
        }}
        onClick={onTap}
      >
        <div className="flex flex-row justify-between items-center px-16px pb-20px border-b border-solid border-txtsecondary-shades4">
          <div className="flex">
            <IconContainer color={type} style={{ padding: '12px 9px' }}>
              <img
                src={partnerConfirmIcon}
                alt="order-update"
                style={{ minWidth: '23.1px', minHeight: '15.23px' }}
              />
            </IconContainer>
            <div className="flex flex-col">
              <span className="text-title-s font-semibold text-txtlight">
                {orderRequest.title}
              </span>
              <div className="text-chip font-medium mt-2px text-sub">
                {orderRequest.infoText}
              </div>
            </div>
          </div>
          <ScreenIcon
            className="float-right self-start"
            src={arrowRight}
            alt="right"
            type={orderRequest.isDelayed ? 'delayed' : 'notDelayed'}
          />
        </div>
        <div
          className="flex flex-col items-start pb-12px px-16px"
          style={{
            marginTop: '12px',
            borderBottom: '1px solid #F0F3F6',
          }}
        >
          <div className="flex flex-row">
            <img src={clockIcon} alt="prescribed-time" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Order ID - {orderRequest.orderId}
              </p>
              <p className="text-chip text-txtsecondary ml-8px">
                Booked on {orderRequest.bookedOnDate}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            {orderRequest.testsType === 'pathology' && (
              <img src={PathologyIcon} alt="test type" className="self-start" />
            )}
            {orderRequest.testsType === 'radiology' && (
              <img
                src={RadiologyIcon}
                alt="test type"
                className="self-start"
                style={{ paddingLeft: '3px', marginRight: '3px' }}
              />
            )}
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Tests Booked - {orderRequest.testsType}
              </p>
              <p className="text-chip text-txtsecondary ml-8px">
                {orderRequest.testNames}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-8px">
            <img src={patientIcon} alt="patient" className="self-start" />
            <div className="flex flex-col">
              <p className="text-body-s text-txtlight font-medium ml-8px">
                Patient
              </p>
              <p className="text-chip text-txtsecondary ml-8px">
                {orderRequest.patientName}
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center w-full">
            <div className="flex flex-row items-center mt-8px">
              <img src={calendarIcon} alt="calendar" className="self-start" />
              <div className="flex flex-col">
                <p className="text-body-s text-txtlight font-medium ml-8px">
                  Date & Time Slot
                </p>
                <p className="text-chip text-txtsecondary ml-8px">
                  {orderRequest.slotDateTime}
                </p>
              </div>
            </div>
            {type === 'orderUpdate' && (
              <div>
                <div className="flex justify-end items-center">
                  <img src={oldTimeIcon} alt="clock" />
                  <p className="text-chip font-medium text-txtsecondary-shades8 ml-2px line-through">
                    {orderRequest.oldTime}
                  </p>
                </div>
                <div className="flex justify-end items-center">
                  <img src={newTimeIcon} alt="clock" />
                  <p className="text-body-l font-medium text-primary ml-4px">
                    {orderRequest.slotDateTime}
                  </p>
                </div>
              </div>
            )}
          </div>
          {/* {orderRequest.partnerName && } */}
          <div className="flex flex-row items-center justify-between mt-8px w-full">
            <div className="flex flex-row items-center">
              <img src={partnerIcon} alt="calendar" className="self-start" />
              <div className="flex flex-col">
                <p className="text-body-s text-txtlight font-medium ml-8px">
                  Partner
                </p>
                <p className="text-chip text-txtsecondary ml-8px">
                  {orderRequest.partnerName}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center">
              {type === 'orderUpdateReschedule' && (
                <div>
                  <div className="flex justify-end items-center">
                    <img src={oldTimeIcon} alt="clock" />
                    <p className="text-chip font-medium text-txtsecondary-shades8 ml-2px line-through">
                      {orderRequest.oldTime}
                    </p>
                  </div>
                  <div className="flex justify-end items-center">
                    <img src={newTimeIcon} alt="clock" />
                    <p className="text-body-l font-medium text-primary ml-4px">
                      {orderRequest.slotDateTime}
                    </p>
                  </div>
                </div>
              )}
              {type === 'orderUpdatePartner' && (
                <div className="flex justify-end items-center">
                  <img src={oldPartnerIcon} alt="lab" />
                  <p className="text-body-s font-medium text-txtsecondary-shades8 ml-4px line-through">
                    {orderRequest.oldPartnerName}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {orderRequest.isDelayed ? (
          <div className="text-center text-body-s text-primary font-semibold py-12px w-full border-t border-solid border-txtsecondary-shades4 tracking-wide">
            Request Callback
          </div>
        ) : (
          <div></div>
        )} */}
      </div>
    );
  } else {
    return <div />;
  }
};

PrescriptionStatusCard.propTypes = {
  onTap: PropTypes.func,
  orderRequest: PropTypes.object.isRequired,
};

export default PrescriptionStatusCard;
