import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { updateLoginState } from '../../actions/index';
import { BoldText } from '../../components/common/Text';
import { PrimaryButton } from '../../components/common/Buttons';
import arrowLeft from '../../images/common/colored-arrow-left.svg';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;
  overflow-x: hidden;
`;

const SlideContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  transform: translateX(0);
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const Dot = styled.div`
  width: ${(props) => (props.active ? '12px' : '4px')};
  height: ${(props) => (props.active ? '4px' : '4px')};
  margin: 0 10px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.active || props.previous ? '#01BFBD' : '#E3E3E3'};
`;

const SlideNavigation = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const ScreenIcon = styled.img`
  max-width: 4.265rem;
  background-color: #f0f3f6;
  padding: 0.5rem;
  border-radius: 100%;
  margin-left: ${(props) =>
    props.marginLeft ? `${props.marginLeft}rem` : '0'};
  margin-right: ${(props) =>
    props.marginRight ? `${props.marginRight}rem` : '0'};
`;

const NavigationButton = styled(PrimaryButton)`
  margin: 2rem 5rem 0rem 5rem;
  background-color: white;
  opacity: ${(props) => (props.disabled ? '0.2' : '1')};
  @media (max-width: 768px) {
    margin: 5rem 5rem 0rem 5rem;
  }
`;

const StyledButtonText = styled(BoldText)`
  color: #0f0b28;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class Carousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIdx: 0,
      totalChildren: 0,
    };
    this.carouselRef = React.createRef();
  }

  componentDidMount = () => {
    // this.setupAutoplay();
    const carouselEle = this.carouselRef.current;
    this.setState({ totalChildren: carouselEle.children.length });
  };

  //   componentWillUnmount() {
  //     clearInterval(this.autoplayTimeout);
  //   }

  setupAutoplay() {
    const { autoplay, delay } = this.props;
    if (autoplay) {
      this.currentOffset = 0;
      this.autoplayTimeout = setInterval(this.scrollCarousel, delay || 2000);
    }
  }

  tweenNext(width, duration) {
    const carouselEle = this.carouselRef.current;
    // const offsetPerTimestamp = width / duration;
    // const animate = (timestamp) => {
    //   const progress = timestamp - start;
    //   carouselEle.style.transform = `translateX(${
    //     -1 * (this.currentOffset + offsetPerTimestamp * progress)
    //   }px)`;
    //   if (progress < duration) {
    //     requestAnimationFrame(animate);
    //   } else {
    //     this.currentOffset += width;
    //     // Remove any discrepancies
    //     carouselEle.style.transform = `translateX(${
    //       -1 * this.currentOffset
    //     }px)`;
    //   }
    // };
    // const start = performance.now();
    // requestAnimationFrame(animate);
    const transformStyle = carouselEle.style.transform;
    const translateX = +transformStyle.replace(/[^\d.]/g, '');
    carouselEle.style.transform = `translateX(${-1 * (translateX + width)}px)`;
  }

  tweenBack(width, duration) {
    const carouselEle = this.carouselRef.current;
    // const offsetPerTimestamp = width / duration;
    // const animate = (timestamp) => {
    //   const progress = timestamp - start;
    //   carouselEle.style.transform = `translateX(${
    //     -1 * (this.currentOffset + offsetPerTimestamp * progress)
    //   }px)`;
    //   if (progress < duration) {
    //     requestAnimationFrame(animate);
    //   } else {
    //     this.currentOffset += width;
    //     // Remove any discrepancies
    //     carouselEle.style.transform = `translateX(${
    //       -1 * this.currentOffset
    //     }px)`;
    //   }
    // };
    // const start = performance.now();
    // requestAnimationFrame(animate);
    const transformStyle = carouselEle.style.transform;
    const translateX = +transformStyle.replace(/[^-?\d.]/g, '');
    carouselEle.style.transform = `translateX(${1 * (translateX + width)}px)`;
  }

  tweenToStart(duration) {
    const carouselEle = this.carouselRef.current;
    const offsetPerTimestamp = this.currentOffset / duration;
    const animate = (timestamp) => {
      const progress = timestamp - start;
      carouselEle.style.transform = `translateX(${
        -1 * (this.currentOffset - offsetPerTimestamp * progress)
      }px)`;
      if (progress < duration) {
        requestAnimationFrame(animate);
      } else {
        this.currentOffset = 0;
        // Reset the position
        carouselEle.style.transform = 'translateX(0)';
      }
    };
    const start = performance.now();
    requestAnimationFrame(animate);
  }

  //   scrollCarousel = () => {
  //     const { currentIdx } = this.state;
  //     const { animateDuration } = this.props;
  //     const carouselEle = this.carouselRef.current;
  //     const childWidth = carouselEle.children[currentIdx].clientWidth;
  //     if (childWidth) {
  //       if (currentIdx === carouselEle.children.length - 1) {
  //         this.tweenToStart(animateDuration || 500);
  //         this.setState({
  //           currentIdx: 0,
  //         });
  //       } else {
  //         this.tweenScroll(childWidth, animateDuration || 500);
  //         this.setState({
  //           currentIdx: currentIdx + 1,
  //         });
  //       }
  //     }
  //   };

  goNext = () => {
    const { currentIdx } = this.state;
    const { animateDuration } = this.props;
    const carouselEle = this.carouselRef.current;
    const childWidth = carouselEle.children[currentIdx].clientWidth;
    if (childWidth) {
      if (currentIdx === carouselEle.children.length - 1) {
        this.tweenToStart(animateDuration || 500);
        this.setState({
          currentIdx: 0,
        });
      } else {
        this.tweenNext(childWidth, animateDuration || 500);
        this.setState({
          currentIdx: currentIdx + 1,
        });
      }
    }
  };

  goBack = () => {
    const { currentIdx } = this.state;
    const { animateDuration } = this.props;
    const carouselEle = this.carouselRef.current;
    const childWidth = carouselEle.children[currentIdx].clientWidth;
    if (childWidth) {
      if (currentIdx !== 0) {
        this.setState({
          currentIdx: currentIdx - 1,
        });
        this.tweenBack(childWidth, animateDuration || 500);
        console.log(this.state.currentIdx);
      }
    }
  };

  render() {
    const { className, children, showDots } = this.props;
    const { currentIdx } = this.state;
    return (
      <OuterContainer className={className}>
        <SlideContainer
          ref={this.carouselRef}
          style={{ transform: 'translateX(0)' }}
        >
          {children}
        </SlideContainer>
        {showDots && (
          <DotContainer>
            {children.map((ele, idx) => (
              <Dot
                key={idx}
                active={currentIdx === idx}
                previous={idx < currentIdx}
              />
            ))}
          </DotContainer>
        )}
        <SlideNavigation>
          {currentIdx === this.state.totalChildren - 1 ? (
            <NavigationButton
              onTap={() => {
                this.props.history.push('/login');
              }}
            >
              <StyledButtonText>
                Get Started
                <ScreenIcon
                  src={arrowLeft}
                  alt="left arrow"
                  marginLeft="0.3"
                  style={{ transform: 'scaleX(-1)' }}
                />
              </StyledButtonText>
            </NavigationButton>
          ) : (
            <>
              <NavigationButton disabled={currentIdx === 0} onTap={this.goBack}>
                <StyledButtonText>
                  <ScreenIcon
                    src={arrowLeft}
                    alt="left arrow"
                    marginRight="0.3"
                  />
                  Back
                </StyledButtonText>
              </NavigationButton>
              <NavigationButton onTap={this.goNext}>
                <StyledButtonText>
                  Next
                  <ScreenIcon
                    src={arrowLeft}
                    alt="left arrow"
                    marginLeft="0.3"
                    style={{ transform: 'scaleX(-1)' }}
                  />
                </StyledButtonText>
              </NavigationButton>
            </>
          )}
        </SlideNavigation>
      </OuterContainer>
    );
  }
}

Carousel.propTypes = {
  showDots: PropTypes.bool,
  autoplay: PropTypes.bool,
  delay: PropTypes.number,
  animateDuration: PropTypes.number,
};

export default Carousel;
