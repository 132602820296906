import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaArrowDown } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';

import Header from '../../components/common/Header';
import { Text, BlackText, RegularText } from '../../components/common/Text';

import referIcon from '../../images/home/refer-title.png';
import rewardsIcon from '../../images/home/rewards.png';
import giftIcon from '../../images/common/gift-gold-icon.svg';
import fitcoinIcon from '../../images/common/rewardicon.png';
import StyledTappable from '../../components/common/StyledTappable';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  overflow-y: no-scroll;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  z-index: 999;
  background: white;
  padding: 1.5rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const TitleIcon = styled.img`
  align-self: center;
  width: 7rem;
  margin-bottom: 0.625rem;
`;

const BodyText = styled(Text)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  text-align: center;
`;

const ArrowIcon = styled(FaArrowDown)`
  align-self: center;
  font-size: ${() => `${0.5 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin: 0.5rem 0;
`;

const GiftIcon = styled.img`
  align-self: center;
  width: 3.125rem;
  margin-bottom: 0.5rem;
`;

const RewardsImage = styled.img`
  align-self: center;
  width: 13rem;
  margin-bottom: 0.75rem;
`;

const FitcoinTextContainer = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
`;

const FitcoinText = styled(BlackText)`
  color: #cbb554;
  font-size: ${(props) =>
    props.fontSize
      ? props.fontSize * process.env.REACT_APP_FONT_MULTIPLIER
      : `${1.5 * process.env.REACT_APP_FONT_MULTIPLIER} rem`};
`;

const FitcoinIcon = styled.img`
  width: 1.125rem;
  margin: 0 0.5rem;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  padding: 0 1rem 1.5rem 1rem;
`;

const InviteContainer = styled(StyledTappable)`
  border: 1px dashed black;
  padding: 0.75rem 1rem;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
`;

const InviteTitle = styled(RegularText)`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  opacity: 0.5;
  text-transform: uppercase;
`;

const InviteText = styled.input`
  font-size: ${() => `${1.125 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const CopyIcon = styled(MdContentCopy)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

class ReferContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    };
  }

  onCopy = () => {
    const { inviteShareText, referralCode } = this.props;
    const inviteCode = document.querySelector('#invite-code');
    const userAgent = navigator.userAgent || navigator.vendor;
    inviteCode.value = inviteShareText;
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      const range = document.createRange();
      inviteCode.contentEditable = true;
      inviteCode.readOnly = false;
      range.selectNodeContents(inviteCode);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      inviteCode.setSelectionRange(0, 9999999);
      try {
        const success = document.execCommand('copy');
        if (success) {
          this.setState({
            copied: true,
          });
        }
      } catch (err) {
        alert(err);
      }
      inviteCode.value = referralCode;
      return;
    }
    inviteCode.select();
    console.log(document.execCommand('copy'));
    this.setState({
      copied: true,
    });
    inviteCode.value = referralCode;
  };

  render() {
    const { referralCode } = this.props;
    const { copied } = this.state;
    return (
      <OuterContainer>
        <Header title="How it works" />
        <BodyContainer>
          <TitleIcon src={referIcon} />
          <BodyText>Your friends signup & transact on VISIT App</BodyText>
          <ArrowIcon />
          <GiftIcon src={giftIcon} />
          <BodyText>Both earn upto</BodyText>
          <FitcoinTextContainer>
            <FitcoinText>1000</FitcoinText>
            <FitcoinIcon src={fitcoinIcon} />
            <FitcoinText>FITCoins</FitcoinText>
          </FitcoinTextContainer>
          <BodyText>on every transaction</BodyText>
          <ArrowIcon />
          <RewardsImage src={rewardsIcon} />
          <FitcoinTextContainer>
            <BodyText>Claim </BodyText>
            <FitcoinText fontSize="0.875rem"> FITCoins </FitcoinText>
            <BodyText> for rewards from brands you love</BodyText>
          </FitcoinTextContainer>
        </BodyContainer>
        <FooterContainer>
          <InviteContainer onTap={this.onCopy}>
            <InviteTitle>your invite code</InviteTitle>
            <InviteText
              id="invite-code"
              className="visit-input"
              value={referralCode}
            />
            {copied ? <InviteTitle>Copied</InviteTitle> : <CopyIcon />}
          </InviteContainer>
        </FooterContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  referralCode: state.user.referralCode,
  inviteShareText: state.user.inviteShareText,
});

export default connect(mapStateToProps)(ReferContainer);
