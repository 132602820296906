import React from 'react';
import ClinicAppointmentIcon from '../../images/new-home-card-images/ClinicAppointment.svg';
import HospitalizationIcon from '../../images/new-home-card-images/Hospitalization.svg';
import HraIcon from '../../images/new-home-card-images/Hra.svg';
import LabTestsIcon from '../../images/new-home-card-images/LabTests.svg';
import MedicineDeliveryIcon from '../../images/new-home-card-images/MedicineDelivery.svg';
import OnlineConsultationsIcon from '../../images/new-home-card-images/OnlineConsultations.svg';
import ReimbursementIcon from '../../images/new-home-card-images/Reimbursement.svg';
import CubesIcon from '../../images/new-home-card-images/Cubes.svg';
import styled from 'styled-components';

const OuterContainer = styled.div`
  position: relative;
  width: calc(100% - 15px);
  background: radial-gradient(
    122.85% 53.75% at 50% 50%,
    #ffffff 0%,
    #f0edfa 100%
  );
  border: 1px solid #dcdae6;
  border-radius: 12px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  flex-direction: column;
  z-index: 10;
  cursor: pointer;
  width: 90%;
  min-width: 157px;
`;

const Title = styled.p`
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 600;
  line-height: 16.8px;
`;

const Offer = styled.div`
  background: #01bfbd;
  color: #ffffff;
  border-radius: 0px 0px 12px 12px;
  padding: 1px 8px 1px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  position: absolute;
  top: 0px;
  display: flex;
`;

const getImageFromIconType = (key) => {
  console.log(key, 'here it comes');
  switch (key) {
    case 'offline-consults':
      return ClinicAppointmentIcon;
    case 'hospitalisation':
      return HospitalizationIcon;
    case 'hra-card':
      return HraIcon;
    case 'diagnostics':
      return LabTestsIcon;
    case 'pharmacy':
      return MedicineDeliveryIcon;
    case 'online-consults':
      return OnlineConsultationsIcon;
    case 'claims':
      return ReimbursementIcon;
    default:
      return null;
  }
};

export default function NewHomeActionCard({ iconType, title, offer, onTap }) {
  return (
    <OuterContainer onClick={onTap} id={'new-action-cards'}>
      <div className="flex items-center justify-center">
        {offer && <Offer>{offer}</Offer>}
      </div>
      <div className="flex flex-col items-center">
        <img
          src={getImageFromIconType(iconType)}
          width={'60px'}
          height={'60px'}
          style={offer ? { marginTop: '21px' } : { marginTop: '0px' }}
        />
        <Title className="text-center mt-8px">{title}</Title>
      </div>

      <img
        src={CubesIcon}
        className="absolute"
        style={{ bottom: 0, right: 0, zIndex: '0' }}
      />
    </OuterContainer>
  );
}
