import React, { Component } from 'react';
import Modal from '../../containers/common/Modal';
import { FlyInTransition } from '../transitions/transitions';
import crossIcon from '../../images/common/cross.svg';
import {
  reimburseUserRelative,
  reimburseCategoryProcedure,
} from '../../services/reimbursement';
import { connect } from 'react-redux';
import userImage from '../../images/reimbursement/Profile.svg';

class ChoosePatients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      relativeUserData: [],
      selectedPatient: '',
    };
    // console.log(props);
  }

  componentDidMount() {
    this.reimburseUserRelative();
  }

  reimburseUserRelative = () => {
    reimburseUserRelative(this.props.authToken)
      .then((res) => {
        console.log(res);
        this.setState({
          relativeUserData: res.patientDetails,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (
      (this.node && this.node.contains(e.target)) ||
      (this.node2 && this.node2.contains(e.target))
    ) {
      return;
    }
    this.hideModal();
  };

  hideModal = () => {
    this.setState({ showModal: false });
    setTimeout(this.props.hideModal, 200);
  };

  render() {
    console.log(this.state);
    const { relativeUserData } = this.state;
    return (
      <Modal>
        <div className="modalContainer" onClick={this.handleOutsideClick}>
          <FlyInTransition
            in={this.state.showModal}
            mountOnEnter
            unmountOnExit
            appear
          >
            <div
              className="bg-textbox absolute bottom-0px w-full rounded-t-12 max-h-104 overflow-auto"
              ref={(node) => (this.node = node)}
            >
              <div className="p-20 border-b border-txtsecondary-shades7 border-solid flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <img
                    src={userImage}
                    alt="user"
                    height={'32px'}
                    width={'32px'}
                    style={{
                      height: '32px',
                      width: '32px',
                    }}
                  />
                  <span className="text-title-s ml-10px font-semibold text-txtlight">
                    Choose Patients
                  </span>
                </div>
                <img src={crossIcon} alt="cross" onClick={this.hideModal} />
              </div>
              <div className="flex flex-col p-20px">
                {relativeUserData.map((el, i) => {
                  return (
                    // <div>
                    <div
                      className="flex flex-col rounded-12 bg-opacity-50 bg-txtsecondary-shades4 px-16px py-24px mb-20px "
                      key={i}
                      onClick={() => {
                        this.props.onPatientSelected(el);
                        // this.props.setPatient(el);
                        console.log(el);
                        // this.setState({
                        //   selectedPatient: el,
                        // });
                      }}
                    >
                      <div className="flex flex-row items-center">
                        <div className="flex flex-row items-center">
                          {this.props.selectedPatient1 &&
                          this.props.selectedPatient1.patientName ===
                            el.patientName ? (
                            <div
                              className="mr-12px bg-txtsecondary-shades7 rounded-full"
                              style={{
                                width: '22px',
                                height: '22px',
                                padding: '5px',
                              }}
                            >
                              <div
                                className="mr-12px bg-primary rounded-full text-center"
                                style={{ width: '12px', height: '12px' }}
                              ></div>
                            </div>
                          ) : (
                            <div
                              className="mr-12px bg-txtsecondary-shades7 rounded-full"
                              style={{ width: '22px', height: '22px' }}
                            ></div>
                          )}
                          <div className="flex flex-col ml-8px">
                            <p className="text-body-l font-semibold text-txtlight mb-4px">
                              {el.patientName}
                            </p>
                            <p className="text-body-s font-regular text-custom-413958">
                              {el.gender}, {el.phone}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </FlyInTransition>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(ChoosePatients);
