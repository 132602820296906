import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';

import {
  BoldText,
  RegularText,
  ButtonText,
  LinkText,
} from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import { PrimaryButton } from '../../components/common/Buttons';
import StyledTappable from '../../components/common/StyledTappable';
import Modal from '../../containers/common/Modal';

import rightGraphic from '../../images/onboarding/onboarding-graphic-right.svg';
import leftGraphic from '../../images/onboarding/onboarding-graphic-left.svg';
import work from '../../images/onboarding/work.svg';
import warningIcon from '../../images/onboarding/exclaimation.svg';
import warningFillIcon from '../../images/onboarding/warning-fill.svg';
import { updateLoginState } from '../../actions';
import { logEvent } from '../../utils/logEvent';
import { verifyCorporate, confirmEmail } from '../../services/index';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 1.25rem;
  background: #ffffff;
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
`;

const ScreenIcon = styled.img`
  max-width: 4.265rem;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.3125 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  word-break: break-all;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  line-height: 32px;
  color: #0f0b28;
`;

const SubtitleText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  color: #76738c;
  letter-spacing: 0.5px;
  line-height: 24px;
`;

const InputContainer = styled.div`
  padding: 13px 35px 13px 13px;
  border: ${(props) => (props.border ? `1.5px solid ${props.border}` : 'none')};
  border-radius: 12px;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-bottom: 8px;
`;

const ConfirmButton = styled(PrimaryButton)`
  position: relative;
  top: 30vh;
`;

const StyledButtonText = styled(BoldText)`
  color: ${(props) => (props.color ? props.color : 'white')};
  line-height: 19.6px;
`;

const StyledInputBox = styled.input`
  color: #0f0b28;
  margin-left: 0.875rem;
  flex: 1;
  ::placeholder {
    font-size: 14px;
    line-height: 21px;
    color: #a5a6bb;
    font-weight: 500;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f3f6;
  padding: 1.5rem 2rem 3rem 2.5rem;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: #0f0b28;
`;

const StyledCheckbox = styled.input`
  width: 1.5rem;
  height: 1.2rem;
  border: 1px solid #a5a6bb;
  border-radius: 6px;
  align-self: flex-start;
  &:checked {
    accent-color: #01bfbd;
  }
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 1.125rem 1.25rem 1rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
`;

const ModalText = styled(RegularText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin: 0.875rem 0 1.25rem 0;
  font-weight: 500;
  color: #585969;
`;

const SecondaryButton = styled(StyledTappable)`
  margin-top: 1rem;
`;

const SecondaryText = styled(BoldText)`
  color: #0f0b28;
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  font-weight: 600;
`;

class NewOnboardingCorporate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isChecked: false,
      loading: false,
      validEmail: true,
      modalVisible: false,
      showContinueButton: false,
      errorMessage: null,
    };
  }

  componentDidMount = () => {
    logEvent('Onboarding Enter Email Screen');
    logEvent('Onboarding Sponsored User Policy Details Screen');
  };

  submitCorporateIdentity = () => {
    this.setState({
      loading: true,
    });
    const { email } = this.state;
    const { history, authToken, dispatch } = this.props;
    console.log(authToken);
    verifyCorporate(email, authToken)
      .then((res) => {
        dispatch(updateLoginState('ONE_LAST_STEP'));
        if (res.message === 'ssoAuthentication') {
          // Redirect the user to the SSO flow to complete authentication
          window.location.href = res.ssoLoginUrl;
        } else {
          history.push('/corporate-activated', {
            sponsorImage: res.sponsorImage,
            sponsorName: res.sponsorName,
          });
        }
      })
      .catch((err) => {
        const errorResponse = err.data;
        console.log(errorResponse);
        switch (errorResponse.message) {
          case 'emailNotFound':
            this.setState({
              modalVisible: true,
              showContinueButton: true,
              errorMessage:
                'We didn’t find any organization offering visit benefits with that email ID.  Continue signing up?',
            });
            break;
          case 'emailTaken':
            this.setState({
              modalVisible: true,
              showContinueButton: false,
              errorMessage:
                'This email is already registered with us. Please login to your existing account, or use another email.',
            });
            break;
          case 'invalidEmail':
            this.setState({
              modalVisible: true,
              showContinueButton: true,
              errorMessage:
                'We didn’t find any organization offering visit benefits with that email ID.  Continue signing up?',
            });
            break;
          default:
            break;
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  continueSignup = () => {
    const { email } = this.state;
    const { authToken, history, dispatch } = this.props;
    this.setState({
      modalVisible: false,
      loading: true,
    });
    confirmEmail(email, authToken)
      .then(() => {
        logEvent('Onboarding Signup Screen');
        dispatch(updateLoginState('ONE_LAST_STEP'));
        history.push('/one-last-step');
      })
      .catch((err) => {
        const errorResponse = err.data;
        console.log(err, errorResponse);
        switch (errorResponse.message) {
          case 'invalidEmail':
            this.setState({
              modalVisible: true,
              showContinueButton: false,
              errorMessage: 'Please enter a valid email address',
            });
            break;
          default:
            this.setState({
              modalVisible: true,
              showContinueButton: false,
              errorMessage: 'Please enter a valid email address',
            });
            break;
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  validateEmail = (e) => {
    const email = e;
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (String(email).match(mailformat)) {
      return this.setState({
        validEmail: true,
      });
    } else {
      return this.setState({
        validEmail: false,
      });
    }
  };

  invertState = () => {
    if (!this.state.validEmail) {
      this.setState({ validEmail: true });
    }
  };

  render() {
    const { phonePeToken } = this.state;
    // const emailLink = Link.state(this, 'email');
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <ScreenIcon
              src={rightGraphic}
              alt="graphic"
              className="absolute top-4 right-0"
            />
            <ScreenIcon
              src={leftGraphic}
              alt="graphic"
              className="absolute top-20 left-0"
            />
          </HeaderContainer>
          <div className="my-20" style={{ width: '90%' }}>
            <TitleText>
              Enter your organization email or identification number
            </TitleText>
            <SubtitleText>
              Please type in the email provided by
              <br />
              your organization.
            </SubtitleText>
            <InputContainer
              border={this.state.validEmail ? '#01BFBD' : '#E86570'}
              className="flex"
            >
              <ScreenIcon src={work} alt="work" />
              <StyledInputBox
                type="text"
                className="visit-input text-body-l font-semibold"
                // value={this.state.email}
                placeholder="Enter your corporate email"
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                  if (this.state.email.length > 14) {
                    this.validateEmail(this.state.email);
                  }
                }}
              />
            </InputContainer>
            {!this.state.validEmail && (
              <div className="flex">
                <img src={warningIcon} alt="warning" />
                <p className="text-body-s text-accent2-shades4 ml-4px">
                  Invalid email address
                </p>
              </div>
            )}
            <ConfirmButton
              onTap={this.submitCorporateIdentity}
              loading={this.state.loading}
              disabled={
                this.state.email.length === 0 ||
                !this.state.validEmail ||
                !this.state.isChecked
              }
              className="text-center mb-4"
              style={{
                padding: '0.75rem 2.5rem 0.75rem 1rem',
              }}
            >
              <StyledButtonText>Confirm</StyledButtonText>
            </ConfirmButton>
          </div>
          <Footer className="w-full absolute bottom-0 left-0 right-0 text-center m-auto">
            <StyledCheckbox
              type="checkbox"
              checked={this.state.isChecked}
              onChange={() =>
                this.setState({ isChecked: !this.state.isChecked })
              }
            ></StyledCheckbox>
            <FooterText>
              I have read and accept the{' '}
              <LinkText style={{ display: 'inline', fontSize: '12px' }}>
                Terms and Condtions
              </LinkText>
              <br />
              <br />I have read and accept the{' '}
              <LinkText style={{ display: 'inline', fontSize: '12px' }}>
                Privacy Policy
              </LinkText>{' '}
              and agree to processing of sensitive personal data
            </FooterText>
          </Footer>
          {this.state.modalVisible && (
            <Modal>
              <ModalContainer>
                <ModalBody>
                  <div
                    className="flex items-start"
                    style={{ textAlign: 'left' }}
                  >
                    <img src={warningFillIcon} alt="warning" />
                    <p className="ml-8px font-semibold text-txtlight">
                      Invalid Email ID
                    </p>
                  </div>
                  <ModalText>{this.state.errorMessage}</ModalText>
                  {this.state.showContinueButton && (
                    <PrimaryButton onTap={this.continueSignup}>
                      <ButtonText>Continue Sign Up</ButtonText>
                    </PrimaryButton>
                  )}
                  <SecondaryButton
                    onTap={() => {
                      this.props.dispatch(updateLoginState('SELECT_USER_TYPE'));
                      this.props.history.goBack();
                    }}
                  >
                    <SecondaryText>Go Back</SecondaryText>
                  </SecondaryButton>
                </ModalBody>
              </ModalContainer>
            </Modal>
          )}
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(NewOnboardingCorporate);
