import styled from 'styled-components';
import arrowRight from '../../../images/common/arrow-right.svg';
import userIcon from '../../../images/consultations/new-flow/user-icon.svg';
import clockIcon from '../../../images/lab-test/green-clock.svg';
import calendarIcon from '../../../images/lab-test/order-calendar.svg';
import crossFillIcon from '../../../images/lab-test/cross-fill.svg';
import pdfIcon from '../../../images/lab-test/pdf-icon.svg';
import eyeIcon from '../../../images/common/eye.svg';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(230, 233, 236, 1);
  margin-bottom: 16px;
  border-radius: 12px;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 24px;
  border-bottom: 1px solid rgba(240, 243, 246, 1);
`;

const DoctorDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowIcon = styled.img`
  filter: ${(props) =>
    props.cancelled
      ? 'brightness(0) saturate(100%)  invert(74%) sepia(58%) saturate(4911%) hue-rotate(327deg) brightness(100%) contrast(103%)'
      : !props.onlinePreview
      ? 'brightness(0) saturate(100%) invert(77%) sepia(34%) saturate(917%) hue-rotate(36deg) brightness(95%) contrast(86%)'
      : 'none'};
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 24px;
`;

const MiddleSubContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const PrescriptionContainer = styled.div`
  display: flex;
  padding-top: 12px;
  padding-left: 16px;
  padding-bottom: 12px;
  border-top: 1px solid rgba(240, 243, 246, 1);
`;

const ConsultationOrderCard = ({ order, onlinePreview, onTap }) => {
  const renderUploadedFile = (ele, idx) => {
    if (idx > 3) return;

    if (ele.fileType === 'pdf') {
      return (
        <div
          className="relative mr-15 w-52px h-76px mt-8px mb-8px rounded-12 bg-contain bg-no-repeat"
          style={{ backgroundImage: `url(${pdfIcon})` }}
          onClick={(e) => {
            e.stopPropagation();
            window.open(ele.fileUrl, '_blank');
          }}
        >
          <a
            href={ele.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            id={`image-anchor-${ele.fileId}`}
            style={{ display: 'none' }}
          >
            {}
          </a>
        </div>
      );
    }
    return (
      <div
        className="relative mr-15 w-52px h-76px mt-8px mb-8px rounded-12 bg-cover bg-no-repeat bg-opacity-20 custom-opacity"
        style={{ backgroundImage: `url(${ele.fileUrl})` }}
      >
        <a
          href={ele.fileUrl}
          target="_blank"
          rel="noopener noreferrer"
          id={`image-anchor-${ele.fileId}`}
          style={{ display: 'none' }}
        >
          {}
        </a>
        <img
          onClick={(e) => {
            e.stopPropagation();
            window.open(ele.fileUrl, '_blank');
          }}
          src={eyeIcon}
          alt="eye"
          className="absolute top-24px left-12px"
        />
      </div>
    );
  };

  return (
    <CardContainer onClick={onTap}>
      <TopContainer>
        <DoctorDetailsContainer>
          <img
            src={order.profileImg}
            alt="doctor"
            width={'56px'}
            height={'56px'}
            style={{ borderRadius: '12px' }}
          />
          <div className="flex flex-col ml-12px">
            <p className="text-body-l font-semibold text-txtlight">
              {order.doctorName}
            </p>
            <p className="text-body-s text-sub">{order.vertical}</p>
          </div>
        </DoctorDetailsContainer>
        <ArrowIcon
          src={arrowRight}
          cancelled={order.cancelled}
          onlinePreview={onlinePreview}
        />
      </TopContainer>
      <div className="flex justify-between">
        <MiddleContainer>
          <MiddleSubContainer>
            <img src={userIcon} alt="patient-icon" className="self-start"></img>
            <div className="ml-8px flex flex-col">
              <p className="text-body-s font-medium text-txtlight">Patient</p>
              <p className="text-caption-s text-txtsecondary">
                {order.patientName}
              </p>
            </div>
          </MiddleSubContainer>
          <MiddleSubContainer>
            <img src={clockIcon} alt="clock-icon" className="self-start"></img>
            <div className="ml-8px flex flex-col">
              <p className="text-body-s font-medium text-txtlight">
                Consultation ID - {order.consultationId}
              </p>
              <p className="text-caption-s text-txtsecondary">
                Started on {order.consultDate}
              </p>
            </div>
          </MiddleSubContainer>
          {((order.cancelled && order.cancelReason) || !order.cancelled) && (
            <MiddleSubContainer style={{ marginBottom: '0px' }}>
              <img
                src={calendarIcon}
                alt="calendar-icon"
                className="self-start"
              ></img>
              <div className="ml-8px flex flex-col">
                <p className="text-body-s font-medium text-txtlight">
                  {order.cancelled
                    ? 'Cancellation Reason'
                    : order.daysRemaining === 0
                    ? 'Appointment completed on'
                    : order.isScheduledConsult
                    ? 'Scheduled date and time'
                    : 'Consultation days left'}
                </p>
                {/* {order.daysRemaining === 0 && !order.isScheduledConsult ? (
                  <p className="text-caption-s text-txtsecondary">
                    {order.cancelled ? order.cancelReason : order.endedOn}
                  </p>
                ) : order.isScheduledConsult ? (
                  <p className="text-caption-s text-txtsecondary">
                    {order.cancelled ? order.cancelReason : order.daysRemaining}
                  </p>
                ) : (
                  ''
                )} */}
                {order.cancelled && (
                  <p className="text-caption-s text-txtsecondary">
                    {order.cancelReason}
                  </p>
                )}
                {order.daysRemaining === 0 && (
                  <p className="text-caption-s text-txtsecondary">
                    {order.endedOn
                      ? order.endedOn
                      : `Started on ${order.consultDate}`}
                  </p>
                )}
                {order.isScheduledConsult && !order.cancelled && (
                  <p className="text-caption-s text-txtsecondary">
                    {order.consultDate} {order.consultTime}
                  </p>
                )}
                {order.daysRemaining > 0 && order.daysRemaining !== null && (
                  <p className="text-caption-s text-txtsecondary">
                    {order.daysRemaining}
                  </p>
                )}
              </div>
            </MiddleSubContainer>
          )}
        </MiddleContainer>
        {order.cancelled && (
          <div
            className="flex items-end"
            style={{
              paddingTop: '12px',
              paddingLeft: '16px',
              paddingBottom: '16px',
              paddingRight: '24px',
              marginBottom: '8px',
            }}
          >
            <img
              src={crossFillIcon}
              width={'14px'}
              height={'14px'}
              className="mb-2px"
            />
            <p className="text-body-s font-semibold text-accent2-shades4 ml-4px">
              Cancelled
            </p>
          </div>
        )}
      </div>
      {order.uploads && order.uploads.length > 0 && (
        <PrescriptionContainer className="flex justify-start flex-row items-center">
          {order.uploads.map(renderUploadedFile)}
        </PrescriptionContainer>
      )}
    </CardContainer>
  );
};

export default ConsultationOrderCard;
