import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BoldText, RegularText, Text } from '../common/Text';
import { fontSize } from '../../utils';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.25rem 0;
`;

const BubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.625rem 0.75rem;
  background: #f7f7f7;
  border-radius: 0.875rem;
`;

const TitleText = styled(Text)`
  font-size: ${fontSize(0.875)};
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
`;

const ConditionSeverity = styled(RegularText)`
  font-size: ${fontSize(0.875)};
  color: ${(props) => props.color};
`;

const ConditionDetails = styled(Text)`
  font-size: ${fontSize(0.875)};
`;

const UserMessage = ({ cardDetails }) => {
  return (
    <OuterContainer>
      <BubbleContainer>
        <TitleText>Possible Conditions</TitleText>
        <BoldText>{cardDetails.conditions[0].title}</BoldText>
        <ConditionSeverity color={cardDetails.conditions[0].severityColor}>
          {cardDetails.conditions[0].severity}
        </ConditionSeverity>
        <ConditionDetails>
          {cardDetails.conditions[0].subTitle}
        </ConditionDetails>
      </BubbleContainer>
    </OuterContainer>
  );
};

UserMessage.propTypes = {
  message: PropTypes.string,
  timestamp: PropTypes.number,
};

export default UserMessage;
