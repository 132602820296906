import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import arrowLeft from '../../images/common/arrow-left.svg';
import {
  BoldText,
  RegularText,
  Text,
  LinkText,
} from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import LoadingComponent from '../../components/common/LoadingComponent';
import { PrimaryButton } from '../../components/common/Buttons';
import { submitUserDetails } from '../../actions/index';

import rightGraphic from '../../images/onboarding/onboarding-graphic-right.svg';
import warningIcon from '../../images/onboarding/exclaimation.svg';
import greenTick from '../../images/onboarding/checked.svg';
import infoIcon from '../../images/onboarding/info.svg';
import maleIcon from '../../images/onboarding/male.png';
import femaleIcon from '../../images/onboarding/female.png';
import { updateLoginState } from '../../actions';
import { logEvent } from '../../utils/logEvent';
import { userInfo } from '../../services';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 1.25rem;
  background: #ffffff;
  overflow-y: scroll;
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
`;

const ScreenIcon = styled.img`
  max-width: 4.265rem;
`;

const BackContainer = styled(PrimaryButton)`
  background-color: #f0f3f6;
  padding: 0;
  width: 2rem;
  position: absolute;
  right: 83%;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.5 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  word-break: break-all;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  line-height: 36px;
`;

const LabelText = styled(Text)`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  color: #0f0b28;
  line-height: 18px;
  margin-top: 1rem;
`;

const InputContainer = styled.div`
  padding: 0.75rem 0rem 0.75rem 0rem;
  border: ${(props) => (props.border ? `1.5px solid ${props.border}` : 'none')};
  border-radius: 12px;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

const StyledButtonText = styled(BoldText)`
  color: ${(props) => (props.color ? props.color : 'white')};
  line-height: 19.6px;
`;

const StyledInputBox = styled(Input)`
  color: #0f0b28;
  margin-left: 0.875rem;
  flex: 1;
  font-size: 1rem;
  font-weight: 600;
  ::placeholder {
    font-size: 16px;
    line-height: 24px;
    color: #585969;
    font-weight: 400;
  }
`;

const StyledDOBContainer = styled(Input)`
  flex: 1;
  width: ${(props) => (props.width ? `${props.width}` : '4rem')};
  height: 3rem;
  padding: 0;
  border: 1px solid
    ${(props) => (props.borderColor ? '#6FCF97' : '#E3E3E3')}!important;
  border-radius: 12px;
  text-align: center;
  margin-right: ${(props) => (props.marginright ? '0.5rem' : '0')};
  margin-left: ${(props) => (props.marginleft ? '0.5rem' : '0')};
  color: #0f0b28;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
  ::placeholder {
    font-size: 16px;
    line-height: 24px;
    color: #585969;
    font-weight: 400;
  }
`;

const StyledRadioButton = styled.div`
  display: flex;
  padding: 0.8rem 2rem;
  border: 1px solid
    ${(props) => (props.borderColor ? `${props.borderColor}` : '#E3E3E3')};
  border-radius: 12px;
  color: #302e6b;
  font-size: 1rem;
  font-weight: 600;
  width: 50%;
`;

const StyledCheckbox = styled.input`
  width: 1.125rem;
  height: 1.125rem;
  visibility: ${(props) => props.visibility};
  &:checked {
    accent-color: #01bfbd;
  }
`;

const GenderIcon = styled.img`
  width: 1.56rem;
  margin-right: 0.625rem;
  @media (max-width: 768px) {
    width: 1rem;
  }
`;

const ApplyLinkText = styled(LinkText)`
  color: #01bfbd;
  padding-right: 2.3rem;
  padding-left: 0.5rem;
  border-left: 1px solid #e6e9ec;
  visibility: ${(props) => (props.visibility ? 'visible' : 'hidden')};
  cursor: pointer;
  font-weight: 600;
`;

const CodeValidationText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.5rem;
  color: ${(props) => props.color};
`;

const NoteText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  color: #0f0b28;
  font-weight: 500;
  margin: 1.5rem 0;
`;

const ConfirmButton = styled(PrimaryButton)`
  width: 100%;
  position: relative;
  top: 20%;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  margin-bottom: 16px;
`;

class OneLastStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.userName || '',
      date: '',
      month: '',
      year: '',
      gender: (props.userGender && props.userGender.toLowerCase()) || '',
      inviteCode: '',
      isValidCode: '',
      loading: false,
      corporateUser: false,
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
    const { location } = this.props;
    if (location.state && location.state.corporateUser) {
      this.state.corporateUser = location.state.corporateUser;
      this.state.loading = true;
      this.getUserInfo();
    }
  }

  getUserInfo = () => {
    userInfo(this.props.authToken)
      .then((result) => {
        const [date, month, year] = result.userInfo.dateOfBirth?.split('-');
        this.setState({
          name: result.userInfo.userName || '',
          loading: false,
          date: date || '',
          month: month || '',
          year: year || '',
          gender:
            (result.userInfo.userGender &&
              result.userInfo.userGender.toLowerCase()) ||
            '',
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleKeyPress = (event) => {
    // console.log({ event, focus: document.activeElement.id });
    if (event.code === 'Backspace') {
      const currentlyFocusedElement = document.activeElement.id;
      switch (currentlyFocusedElement) {
        case 'input-1':
          if (this.state.date === '') {
            document.querySelector('#input-1').focus();
          }
          break;
        case 'input-2':
          console.log(this.state.month);
          if (this.state.month === '') {
            document.querySelector('#input-1').focus();
          }
          break;
        case 'input-3':
          if (this.state.year === '') {
            document.querySelector('#input-2').focus();
          }
          break;
        default:
          break;
      }
    }
  };

  submitUserDetails = () => {
    this.setState({
      loading: true,
    });
    const { dispatch } = this.props;
    const history = { ...this.props.history };
    const { gender, name, date, month, year } = this.state;
    // console.log(date, month, year);
    const dob = year + '-' + month + '-' + date;
    // console.log(dob);
    dispatch(submitUserDetails(name, dob, gender, history));
    // dispatch(updateLoginState('LOGGED_IN'));
  };

  componentDidMount = () => {
    const { history, loginState, authToken } = this.props;
    console.log(history);
    if (loginState === 'LOGGED_OUT' || !authToken) {
      history.push('/');
    } else if (loginState === 'LOGGED_IN') {
      history.replace('/home');
    } else if (loginState === 'SELECT_USER') {
      history.push('/select-user');
    } else {
      // GA Tracking
      window.gtag('event', 'screen_view', {
        screen_name: 'One Last Step Screen',
      });
    }
    window.addEventListener('keydown', this.handleKeyPress, false);
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress, false);
  }

  render() {
    const nameValueLink = Link.state(this, 'name');
    const inviteCodeValueLink = Link.state(this, 'inviteCode');
    const dateValueLink = Link.state(this, 'date')
      .pipe((val) => {
        console.log({ val });
        if (val && val.toString().length === 8) {
          this.setState({
            date: '' + val[0] + val[1],
            month: '' + val[2] + val[3],
            year: '' + val[4] + val[5] + val[6] + val[7],
          });
          document.querySelector('#input-3').focus();
        } else {
          console.log({ val });
          this.setState({
            date: val[0] ? (val[1] ? '' + val[0] + val[1] : val[0]) : '',
          });
        }
      })
      .onChange((val) => {
        console.log({ val });
        this.setState({
          date: '' + val[0] + val[1],
        });
        if (val.toString().length < 2) {
          document.querySelector('#input-1').focus();
        } else {
          document.querySelector('#input-2').focus();
        }
      });
    const monthValueLink = Link.state(this, 'month').onChange((val, as) => {
      console.log({ val, as });
      this.setState({
        month: val[0] ? (val[1] ? '' + val[0] + val[1] : val[0]) : '',
      });
      if (val.toString() === '') {
        document.querySelector('#input-1').focus();
      } else if (val.toString().length < 2) {
        document.querySelector('#input-2').focus();
      } else {
        document.querySelector('#input-3').focus();
      }
    });
    const yearValueLink = Link.state(this, 'year').onChange((val, as) => {
      console.log({ val, as });
      this.setState({
        year: val,
      });
      if (val.toString() === '') {
        document.querySelector('#input-2').focus();
      } else if (val.toString().length < 4) {
        document.querySelector('#input-3').focus();
      }
    });
    if (this.state.loading) {
      return <LoadingComponent />;
    }
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <BackContainer
              loading={this.state.loading}
              onTap={() => {
                this.props.dispatch(updateLoginState('SELECT_USER'));
                this.props.history.push('/select-user');
              }}
            >
              <StyledButtonText>
                <ScreenIcon src={arrowLeft} alt="left arrow" />
              </StyledButtonText>
            </BackContainer>
            <ScreenIcon
              src={rightGraphic}
              alt="graphic"
              className="absolute -top-4 right-0"
            />
          </HeaderContainer>
          <div className="my-14 w-11/12">
            <TitleText style={{ color: '#302E6B' }}>One last step</TitleText>
            <LabelText>Your Name</LabelText>
            <InputContainer border="#01BFBD">
              <StyledInputBox
                type="text"
                className="visit-input"
                valueLink={nameValueLink}
                placeholder="Type your name"
              />
            </InputContainer>
            <LabelText>Date of Birth</LabelText>
            <InputContainer style={{ padding: '0' }}>
              <StyledDOBContainer
                type="tel"
                id="input-1"
                className="visit-input"
                valueLink={dateValueLink}
                placeholder="dd"
                borderColor={this.state.date.length !== 0}
                marginright
              />
              /
              <StyledDOBContainer
                type="tel"
                id="input-2"
                maxLength={2}
                className="visit-input"
                valueLink={monthValueLink}
                placeholder="mm"
                borderColor={this.state.month.length !== 0}
                marginright
                marginleft
              />
              /
              <StyledDOBContainer
                type="tel"
                id="input-3"
                maxLength={4}
                className="visit-input"
                valueLink={yearValueLink}
                placeholder="yyyy"
                borderColor={this.state.year.length !== 0}
                marginleft
                width="10rem"
              />
            </InputContainer>
            <LabelText>Gender</LabelText>
            <InputContainer style={{ padding: '0' }}>
              <StyledRadioButton
                borderColor={this.state.gender === 'male' ? '#01BFBD' : ''}
                onClick={() => this.setState({ gender: 'male' })}
                // className="mr-8"
              >
                <GenderIcon src={maleIcon} />
                Male
              </StyledRadioButton>
              <StyledCheckbox
                type="checkbox"
                id="male"
                checked={this.state.gender === 'male'}
                className="relative bottom-6 right-2"
                visibility={this.state.gender === 'male' ? 'visible' : 'hidden'}
              />
              <StyledRadioButton
                borderColor={this.state.gender === 'female' ? '#01BFBD' : ''}
                onClick={() => this.setState({ gender: 'female' })}
              >
                <GenderIcon src={femaleIcon} />
                Female
              </StyledRadioButton>
              <StyledCheckbox
                type="checkbox"
                id="male"
                checked={this.state.gender === 'female'}
                className="relative bottom-6 right-2"
                visibility={
                  this.state.gender === 'female' ? 'visible' : 'hidden'
                }
              />
            </InputContainer>
            <LabelText>Invite Code (Optional)</LabelText>
            <InputContainer
              border={
                this.state.isValidCode === 'true'
                  ? '#6FCF97'
                  : this.state.isValidCode === 'false'
                  ? '#E86570'
                  : '#E3E3E3'
              }
            >
              <StyledInputBox
                type="text"
                className="visit-input"
                valueLink={inviteCodeValueLink}
                placeholder="Enter Invite Code"
              />
              <ApplyLinkText
                visibility={this.state.inviteCode}
                // onClick={() => this.setState({ isValidCode: 'false' })}
              >
                APPLY
              </ApplyLinkText>
            </InputContainer>
            {this.state.isValidCode === 'true' && (
              <CodeValidationText color="#01BFBD">
                <ScreenIcon
                  src={greenTick}
                  style={{ marginRight: '0.5rem' }}
                ></ScreenIcon>
                {this.state.inviteCode} Applied successfully
              </CodeValidationText>
            )}
            {this.state.isValidCode === 'false' && (
              <CodeValidationText color="#E86570">
                <ScreenIcon
                  src={warningIcon}
                  style={{ marginRight: '0.5rem' }}
                ></ScreenIcon>
                Invalid invite code
              </CodeValidationText>
            )}
            <NoteText>
              <ScreenIcon
                src={infoIcon}
                className="self-start"
                style={{ marginRight: '0.5rem' }}
              ></ScreenIcon>
              <div>All your information will be kept confidential</div>
            </NoteText>
            {this.state.name &&
              this.state.date &&
              this.state.month &&
              this.state.year &&
              this.state.gender && (
                <ConfirmButton
                  //   onTap={() => {
                  //     this.props.dispatch(updateLoginState('BENEFITS'));
                  //     this.props.history.push('/carousel');
                  //   }}
                  onTap={this.submitUserDetails}
                  loading={this.state.loading}
                  className="w-11/12 relative bottom-8 left-0 right-0 text-center m-auto"
                >
                  <StyledButtonText>Confirm</StyledButtonText>
                </ConfirmButton>
              )}
          </div>
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  appError: state.app.appError,
  loginState: state.app.loginState,
  authToken: state.user.authToken,
  userName: state.user.userName,
  userGender: state.user.userGender,
  dateOfBirth: state.user.dateOfBirth,
});

export default connect(mapStateToProps)(OneLastStep);
