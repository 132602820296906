import React, { Component } from 'react';
import styled from 'styled-components';
import { FaCheck, FaTimes } from 'react-icons/fa';

import Modal from '../../containers/common/Modal';
import { FlyInTransition } from '../../components/transitions/transitions';
import StyledTappable from '../../components/common/StyledTappable';
import profile from '../../images/profile.svg';
import cross from '../../images/cross.svg';
const AddContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  flex-shrink: 0;
  border-radius:9px;
  background-color: #e4e4e4
  justify-content: center;
  align-items: center;
  
`;

const AddedContainer = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background: #01bfbd;
`;

const AddText = styled.div`
  color: #01bfbd;
  height: 10px;
  width: 10px;
`;

const AddedIcon = styled(FaCheck)`
  color: #ffffff;
  height: 10px;
  width: 10px;
`;

const CloseContainer = styled(StyledTappable)`
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 8px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeaderText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.25px;
  color: #0f0b28;
`;
const RelativeName = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.25px;
  color: #0f0b28;
`;
const CloseIcon = styled(FaTimes)`
  color: #413958;
`;
const RelativeGender = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.5px;
  color: #585969;
`;
export class RelativeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  hideModal = () => {
    this.setState({ showModal: false });
    setTimeout(this.props.hideModal, 200);
  };
  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.hideModal();
  };
  render() {
    const { relatives } = this.props;
    const renderRelatives = (ele) => {
      return (
        <div
          className="patient-container cursor-pointer"
          key={ele.relativeId}
          style={{ backgroundColor: '#eff3f6' }}
          onClick={() => {
            this.props.selectRelative(ele);
            this.hideModal();
          }}
        >
          {this.props.selectedRelative &&
          ele.id === this.props.selectedRelative.id ? (
            <AddedContainer></AddedContainer>
          ) : (
            <AddContainer></AddContainer>
          )}
          <div className="txt-black-600-12 pl-12 flex-col">
            <RelativeName>{ele.name}</RelativeName>
            <RelativeGender>
              {ele.gender}, {ele.phone}
            </RelativeGender>
          </div>
        </div>
      );
    };
    return (
      <Modal>
        <div className="modalContainer" onClick={this.handleOutsideClick}>
          <FlyInTransition
            in={this.state.showModal}
            mountOnEnter
            unmountOnExit
            onExited={this.hideModal}
            appear
          >
            <div className="modalBody" ref={(node) => (this.node = node)}>
              <div className="modalHeader">
                <div className="flex items-center mx-1">
                  <img src={profile} style={{ marginRight: '8px' }} />
                  <HeaderText>Choose Patient</HeaderText>
                </div>
                <button onClick={this.hideModal} type="button">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5859 12L12 10.5859L7.41407 5.99998L12 1.41405L10.5859 -6.18103e-08L6.00002 4.58593L1.41405 -4.62726e-07L-6.18103e-08 1.41405L4.58593 5.99998L-4.62725e-07 10.5859L1.41405 12L6.00002 7.41407L10.5859 12Z"
                      fill="#585969"
                    />
                  </svg>
                </button>
              </div>
              <div className="modalDetails">
                {relatives.map(renderRelatives)}
              </div>
            </div>
          </FlyInTransition>
        </div>
      </Modal>
    );
  }
}

export default RelativeSelect;
