import React, { Component } from 'react';
import styled from 'styled-components';
import { SlideTransition } from '../transitions/transitions';
import CrossIcon from '../../images/header/NavigationMenuCross.svg';
import HomeIcon from '../../images/header/Home.svg';
import InsurerIcon from '../../images/header/Insurer.svg';
import RewardIcon from '../../images/header/Gift.svg';
import UserIcon from '../../images/header/User.svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AccountContainer from '../../containers/home/AccountContainer';

const OuterContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
`;

const BodyContainer = styled.div`
  width: 85%;
  height: 100%;
  background: white;
  overflow-y: auto;
  scrollbar-width: thin;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  align-items: center;
  justify-content: space-between;
`;

const TabCard = styled.div`
  width: 135px;
  height: 135px;
  background: #99e5e5;
  color: #01bfbd;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  flex-direction: column;
  cursor: pointer;
  margin-top: 37px;
`;

const tabs = [
  {
    title: 'Home',
    icon: HomeIcon,
    link: '/home',
  },
  {
    title: 'Insure',
    icon: InsurerIcon,
    link: '/home/insure',
  },
  {
    title: 'Rewards',
    icon: RewardIcon,
    link: '/home/rewards',
  },
  {
    title: 'Account',
    icon: UserIcon,
    link: '/home/account',
  },
];

class NavigationMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAccount: false,
    };
  }

  handleOutsideClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return null;
    }
    this.hideModal();
  };

  closeAccountTab = () => {
    this.setState({
      showAccount: false,
    });
  };

  hideModal = () => {
    this.props.hideModal();
  };

  handleCardClicks = (link) => {
    const { history } = this.props;
    this.hideModal();
    history.push(link);
  };
  render() {
    const { config } = this.props;
    const renderTab = (tab) => {
      switch (tab.title) {
        case 'Home':
          return (
            <TabCard onClick={() => this.handleCardClicks(tab.link)}>
              <img src={tab.icon} />
              <p>{tab.title}</p>
            </TabCard>
          );
        // case 'Insure': {
        //   if (config.insured || config.subscribed) {
        //     return (
        //       <TabCard onClick={() => this.handleCardClicks(tab.link)}>
        //         <img src={tab.icon} />
        //         <p>{tab.title}</p>
        //       </TabCard>
        //     );
        //   }
        //   return;
        // }
        case 'Rewards': {
          if (config.showFitCoins) {
            return (
              <TabCard onClick={() => this.handleCardClicks(tab.link)}>
                <img src={tab.icon} />
                <p>{tab.title}</p>
              </TabCard>
            );
          }
          return;
        }
        case 'Account':
          return (
            <TabCard onClick={() => this.setState({ showAccount: true })}>
              <img src={tab.icon} />
              <p>{tab.title}</p>
            </TabCard>
          );
        default:
          return;
      }
    };
    return (
      <OuterContainer onClick={this.handleOutsideClick}>
        <SlideTransition
          in={this.props.showNavigationMenu}
          mountOnEnter
          unmounOnExit
          onExited={this.hideModal}
          appear
          classNames="slide-out"
        >
          <BodyContainer
            ref={(node) => (this.node = node)}
            style={
              this.state.showAccount
                ? {}
                : { paddingTop: '20px', paddingLeft: '32px' }
            }
          >
            {this.state.showAccount ? (
              <AccountContainer
                showBackButton={true}
                closeAccountTab={this.closeAccountTab}
                history={this.props.history}
              />
            ) : (
              <>
                <div
                  onClick={() => this.hideModal()}
                  className="cursor-pointer"
                >
                  <img src={CrossIcon} />
                </div>
                <TabsContainer>
                  {tabs.map((tab) => renderTab(tab))}
                </TabsContainer>
              </>
            )}
          </BodyContainer>
        </SlideTransition>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.app.config,
});

export default withRouter(connect(mapStateToProps)(NavigationMenu));
