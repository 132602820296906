import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaAngleRight, FaChevronRight } from 'react-icons/fa';

import LoadingComponent from '../../components/common/LoadingComponent';
import ConsultationCard from '../../components/consultations/ConsultationCard';
import { Text, BoldText, RegularText } from '../../components/common/Text';
import HealthActionCard from '../../components/home/HealthActionCard';
import {
  consultationTab,
  getTranscriptionStatus,
  getConsultationSummary,
} from '../../services';
import PrescriptionStatusCard from '../../components/consultations/PrescriptionStatusCard';
import Modal from '../../containers/common/Modal';
import UploadPrescription from '../consultation/UploadPrescription';
import {
  FadeInTransition,
  FlyInTransition,
} from '../../components/transitions/transitions';
import HospitalizationModal from '../../containers/common/HospitalizationModal';

import { setPlaceholderImageForNode } from '../../utils';
import { showError } from '../../utils';
import symCheckIcon from '../../images/home/sym-check.svg';
import labIcon from '../../images/home/lab.png';
import claimIcon from '../../images/home/claim.png';
import onlineIcon from '../../images/home/online.png';
import offlineIcon from '../../images/home/offline.png';
import medIcon from '../../images/home/meds.png';
import symptonIcon from '../../images/home/symptoms.png';
import ChatIcon from '../../images/home/chatIcon.png';
import initializeFreshDesk from '../../utils/freshchat';
import { logEvent } from '../../utils/logEvent';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #fbfafc;
  overflow: hidden;
`;

const StyledActionCard = styled(HealthActionCard)`
  margin: 0.4rem 0;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  padding: 0.875rem 0.5rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const StyledConsultCard = styled(ConsultationCard)`
  display: flex;
  margin: 0.4rem 0;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const EmptyIcon = styled.img`
  max-width: 11rem;
  margin-bottom: 1.5rem;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
`;

const HospitalizationModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
`;

const ArrowIcon = styled(FaAngleRight)`
  color: #01bfbd;
`;

class ConsultationTabContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      services: [],
      consults: [],
      orders: [],
      showModal: false,
      showPendingConsultBlocker: false,
      showHospitalizationModal: false,
      showPendingHospilationBlocker: false,
      pendingConsult: {
        doctorId: '',
        consultationId: '',
        doctorImg: '',
        doctorName: '',
        dateLabel: '',
      },
      blockers: {},
    };
    // props.history.replace('/home');
    const { search, state } = props.location;
    console.log(props.location);
    if (search) {
      const params = new URLSearchParams(search);
      if (params.get('success') && params.get('success') === 'false') {
        this.consultIdToOpen = params.get('consultationId');
        this.reimbursementIdToOpen = params.get('reimbursementId');
        this.requestId = params.get('requestId');
        if (this.requestId) {
          logEvent('Offline Consultations Booking Payment Failed');
        } else if (this.consultIdToOpen) {
          logEvent('Online Consultations Booking Payment Failed');
        }
        // Dispatch an error modal to show
        const errorMessage = params.get('message');
        const error = new Error('Booking failed');
        error.data = {
          errorMessage,
        };
        showError(error);
        props.history.replace(this.props.selectedScreen);
      } else if (params.get('scheduledConsultationId')) {
        this.props.history.push('/consultation/online/preview', {
          openConsultId: params.get('scheduledConsultationId'),
        });
      } else {
        this.consultIdToOpen = params.get('consultationId');
        this.reimbursementIdToOpen = params.get('reimbursementId');
        if (params.get('reimbursementId')) {
          this.props.history.push('/consultation/reimbursement-details', {
            reimbursementId: params.get('reimbursementId'),
          });
        } else if (params.get('consultationId')) {
          if (params.get('requestId')) {
            logEvent('Offline Consultations Booking Payment Completed', {
              consultationId: params.get('consultationId'),
            });
            this.props.history.push('/consultation/preview', {
              openConsultId: params.get('consultationId'),
            });
          } else {
            logEvent('Online Consultations Booking Payment Completed', {
              consultationId: params.get('consultationId'),
            });
            this.props.history.push('/consultation/online/preview', {
              openConsultId: params.get('consultationId'),
            });
          }
        } else if (params.get('pharmacyTransactionId')) {
          this.props.history.push('/pharmacy/order-details', {
            transactionId: params.get('pharmacyTransactionId'),
          });
        }
      }
    } else if (state) {
      if (state.bookingFailed) {
        // Dispatch an error modal to show
        const errorMessage = state.errorMessage;
        const error = new Error('Booking failed');
        error.data = {
          errorMessage,
        };
        showError(error);
        props.history.replace(this.props.selectedScreen);
      }
      this.consultIdToOpen = state.consultationId;
    }
  }

  componentDidMount = () => {
    // GA tracking
    window.gtag('event', 'screen_view', {
      screen_name: 'Consult Tab',
    });
    logEvent('Care Tab');
    this.getConsults();
  };

  componentDidUpdate() {
    this.setPlaceholderForDoctors();
  }

  setPlaceholderForDoctors() {
    document.querySelectorAll('.doctor-image').forEach((ele) => {
      setPlaceholderImageForNode(ele);
      ele.classList.remove('.doctor-image');
    });
  }

  getConsults() {
    const { authToken, history } = this.props;
    consultationTab(authToken)
      .then((res) => {
        const services = res.console;
        this.setState({
          loading: false,
          services,
          blockers: res.blockers,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  openConsultation(consult) {
    const { history } = this.props;
    const { action } = consult.cardDirective;
    switch (action.redirectTo) {
      case 'chat': {
        history.push('/consultation/chat', {
          consultationId: action.consultationId,
          channel: action.channel,
          doctorName: consult.doctorName,
          doctorVertical: consult.doctorVertical,
          doctorId: consult.doctorId,
          pendingRequest: consult.cardType === 'con-pending',
          requestId: consult.bottomCards[0] && consult.bottomCards[0].requestId,
          profileImg: consult.profileImg,
        });
        break;
      }
      case 'con-req': {
        history.push('/consultation/appointment/appointment-status', {
          requestId: action.requestId,
        });
        break;
      }
      default:
        break;
    }
  }

  startTreament = (showSearch, pendingConsult, cancelledRequest) => {
    const { history, authToken, userId } = this.props;
    if (cancelledRequest > 0) {
      history.push('/consultation/cancel-blocker', {
        requestId: cancelledRequest,
      });
    } else if (pendingConsult > 0) {
      getConsultationSummary(pendingConsult, userId, authToken)
        .then((summary) => {
          const { dateLabel, doctorId, doctorName, doctorImg } =
            summary.consultationInfo;
          this.setState({
            pendingConsult: {
              consultationId: pendingConsult,
              doctorId,
              doctorImg,
              doctorName,
              dateLabel,
            },
            showModal: true,
            showPendingConsultBlocker: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      history.push('/consultation/online/preview', {
        specialistSearch: false,
        searchType: 'find',
        showSearch,
      });
    }
  };

  findSpecialist = (pendingConsult, cancelledRequest) => {
    const { history, authToken, userId } = this.props;
    if (cancelledRequest > 0) {
      history.push('/consultation/cancel-blocker', {
        requestId: cancelledRequest,
      });
    } else if (pendingConsult > 0) {
      getConsultationSummary(pendingConsult, userId, authToken)
        .then((summary) => {
          const { dateLabel, doctorId, doctorName, doctorImg } =
            summary.consultationInfo;
          this.setState({
            pendingConsult: {
              consultationId: pendingConsult,
              doctorId,
              doctorImg,
              doctorName,
              dateLabel,
            },
            showModal: true,
            showPendingConsultBlocker: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      history.push('/consultation/preview', {
        specialistSearch: false,
        searchType: 'find',
      });
    }
  };

  openOrder(order) {
    const { action, cartId, type, orderId, requestId } = order;
    const { history, authToken } = this.props;
    if (type === 'order') {
      history.push('/lab-test/track', {
        orderId,
        goBack: true,
      });
    } else if (type === 'pendingOrder') {
      switch (action) {
        case 'openOrder': {
          getTranscriptionStatus(requestId, authToken).then((res) => {
            const { message, requestInfo } = res;
            const { cartSummary, files } = requestInfo;
            if (message === 'success') {
              history.push('/lab-test/upload-success', {
                cartSummary,
                files,
                goBack: true,
              });
              return;
            }
          });
          break;
        }
        case 'payment': {
          history.push('/lab-test/order-summary', {
            cartId,
            paymentNext: true,
          });
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  hideModal = () => {
    this.setState({ showModal: false });
    this.setState({ showHospitalizationModal: false });
  };

  closeUploadModal = () => {
    this.setState({
      showPendingConsultBlocker: false,
    });
  };

  submitUploadModal = (consultationId) => {
    const { consults } = this.state;
    // Find the consultation with the same ID
    consults.forEach((consult) => {
      if (
        consult.cardDirective.action.consultationId &&
        consult.cardDirective.action.consultationId.toString() ===
          consultationId.toString()
      ) {
        // Replace the page with the query parameters to one without
        this.openConsultation(consult);
      }
    });
  };

  bookTests = () => {
    const { history } = this.props;
    history.push('/lab-test');
  };

  requestReimbursement = (ele) => {
    const { history } = this.props;
    if (ele.fulfillmentDetailsAvailable) {
      history.push('/reimbursement');
    } else {
      history.push('/reimbursement/fulfillment-details', {
        fulfillmentDetailsAvailable: ele.fulfillmentDetailsAvailable,
        from: 'normal-flow',
        details: ele,
      });
    }
  };

  myClaims = () => {
    const { history } = this.props;
    history.push('/claims');
  };

  openSupport = () => {
    const { ticketingEnabled, ticketingUrl } = this.props.userConfig;
    const { authToken } = this.props;
    logEvent('Customer Support Button Clicked');
    if (ticketingEnabled) {
      window.open(`${ticketingUrl}?token=${authToken.split(' ')[1]}`, '_blank');
    } else {
      initializeFreshDesk(this.props.user);
    }
  };

  medicineOrder = () => {
    const { history } = this.props;
    history.push('/pharmacy');
  };

  runAction(ele) {
    const { cardDirective } = ele;
    const { userId, history } = this.props;
    switch (cardDirective.actionType) {
      case 'bot':
        history.push('/bot', {
          chatName: cardDirective.action.text,
          channel: `visit.bot.${userId}`,
          action: cardDirective.action,
        });
        break;
      case 'native':
        switch (cardDirective.action.redirectTo) {
          case 'meal-log':
            history.push('/logmeal');
            break;
          case 'hra':
            history.push('/hra');
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  showHospitalization(ele) {
    this.setState({
      showHospitalizationModal: true,
      showPendingHospilationBlocker: true,
    });
  }

  handleOutsideClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return null;
    }
    this.setState({ showPendingHospilationBlocker: false });
  };

  render() {
    const {
      consults,
      loading,
      orders,
      services,
      showModal,
      showPendingConsultBlocker,
      showHospitalizationModal,
      showPendingHospilationBlocker,
      pendingConsult,
    } = this.state;
    const { history } = this.props;
    const renderOrder = (ele, idx) => {
      return (
        <PrescriptionStatusCard
          key={idx}
          orderRequest={ele}
          onTap={() => this.openOrder(ele)}
        />
      );
    };
    const renderConsultCard = (ele, idx) => {
      const {
        cardType,
        labelIcon,
        label,
        dateLabel,
        doctorId,
        doctorName,
        issues,
        bottomCards,
        profileImg,
      } = ele;
      return (
        <StyledConsultCard
          key={idx}
          onTap={() => this.openConsultation(ele)}
          consultationType={cardType}
          labelIcon={labelIcon}
          consultationLabel={label}
          dateLabel={dateLabel}
          doctorImage={
            profileImg ||
            `https://api.getvisitapp.com/apiv2/user-images/doctor-${doctorId}.png`
          }
          doctorName={doctorName}
          consultationIssues={issues}
          actions={bottomCards}
        />
      );
    };
    const renderService = (ele) => {
      const { cardType } = ele;
      if (ele.eventName) {
        logEvent(ele.eventName, {
          CTAType: ele.ctaType,
          verticalName: ele.verticalName,
        });
      }
      switch (cardType) {
        case 'hospitalisation':
          return (
            <StyledActionCard
              key={cardType}
              onTap={() => {
                logEvent('hospitalisation-request');
                this.showHospitalization(ele);
              }}
              title={ele.label}
              subtitle={ele.description}
              offer={ele.offer}
            />
          );
        case 'online-consults':
          return (
            <StyledActionCard
              key={cardType}
              onTap={() => {
                this.startTreament(
                  ele.allowSearch,
                  ele.pendingConsult,
                  ele.cancelledRequest
                );
              }}
              title={ele.label}
              subtitle={ele.description}
              offer={ele.offer}
              icon={onlineIcon}
            />
          );
        case 'offline-consults':
          return (
            <StyledActionCard
              key={cardType}
              onTap={() =>
                this.findSpecialist(ele.pendingConsult, ele.cancelledRequest)
              }
              title={ele.label}
              subtitle={ele.description}
              offer={ele.offer}
              icon={offlineIcon}
            />
          );
        case 'diagnostics':
          return (
            <StyledActionCard
              key={cardType}
              onTap={this.bookTests}
              title={ele.label}
              icon={labIcon}
              subtitle={ele.description}
              discountText={ele.labelBanner}
              offer={ele.offer}
            />
          );
        case 'sym-check':
          return (
            <StyledActionCard
              key={cardType}
              onTap={() => this.runAction(ele)}
              title={ele.label}
              subtitle={ele.description}
              offer={ele.offer}
              icon={symptonIcon}
            />
          );
        // case 'hra-card':
        //   return (
        //     <StyledActionCard
        //       key={cardType}
        //       onTap={() => this.runAction(ele)}
        //       title="Health Risk Assessment"
        //       subtitle="Get insights into your health"
        //       icon={hraIcon}
        //     />
        //   );
        case 'reimbursement':
          return (
            <StyledActionCard
              key={cardType}
              onTap={() => this.requestReimbursement(ele)}
              title={ele.label}
              subtitle={ele.description}
              offer={ele.offer}
            />
          );

        case 'support':
          return (
            <div
              className="support-card mb-5 hover"
              onClick={this.openSupport}
              key={cardType}
            >
              <div className="flex-center">
                <img src={ChatIcon} alt="chat" />
                <p className="ml-5">{ele.label}</p>
              </div>
              <ArrowIcon />
            </div>
          );
        case 'claims':
          return (
            <StyledActionCard
              key={cardType}
              onTap={() => this.myClaims(ele)}
              title={ele.label}
              subtitle={ele.description}
              offer={ele.offer}
              icon={claimIcon}
            />
          );
        case 'html':
          const link = ele.link;
          const url = new URL(
            decodeURIComponent(ele.cardDirective.action.redirectTo)
          );
          return (
            <div
              className="cursor-pointer"
              onClick={() => {
                if (url) {
                  window.open(url, '_blank');
                }
              }}
            >
              <img src={link.split('=')[1]} alt="ahc" />
            </div>
          );
        case 'pharmacy':
          if (ele.showPharmacy) {
            return (
              <StyledActionCard
                key={cardType}
                onTap={() => this.medicineOrder(ele)}
                title={ele.label}
                subtitle={ele.description}
                offer={ele.offer}
                icon={medIcon}
              />
            );
          }
          return;

        case 'con-assistant':
          return (
            <div
              className="blue-box flex-row-center-end"
              key={cardType}
              style={{
                paddingRight: '14px',
              }}
              onClick={() => {
                if (this.state.blockers.cancelledRequest > 0) {
                  this.props.history.push('/consultation/cancel-blocker', {
                    requestId: this.state.blockers.cancelledRequest,
                  });
                } else {
                  this.props.history.push('/consultation/doctor-select', {
                    specialistSearch: false,
                    showVerticals: ele.showVerticals,
                    searchType: 'treat',
                  });
                }
              }}
            >
              <div>
                <span className="txt-white-600-13">{ele.label}</span>
                <br />
                <span className="txt-500-white-12">{ele.description}</span>
              </div>
              <div className="h-100">
                <span className="vertical-line" />
                <FaChevronRight
                  style={{
                    color: '#ffffff',
                    fontSize: '14px',
                    width: '8px',
                  }}
                />
              </div>
            </div>
          );
        default:
          return false;
      }
    };
    if (loading) {
      return <LoadingComponent />;
    }
    return (
      <OuterContainer>
        <BodyContainer>
          {services && services.map(renderService)}
          {/* {consults && orders && consults.length === 0 && orders.length === 0 && (
            <EmptyContainer>
              <EmptyIcon src={emptyIcon} />
              <BoldText>No Consultations yet!</BoldText>
              <RegularText>
                All your consultations, medicine and lab test orders will appear
                here!
              </RegularText>
            </EmptyContainer>
          )} */}
          {/* {consults && consults.length > 0 && (
            <>
              <Text>Your Consultations</Text>
              {consults.map(renderConsultCard)}
            </>
          )}
          {orders && orders.length > 0 && (
            <>
              <Text>Your Orders</Text>
              {orders.map(renderOrder)}
            </>
          )} */}
        </BodyContainer>
        {showModal && (
          <Modal>
            <ModalContainer>
              <FlyInTransition
                in={showPendingConsultBlocker}
                mountOnEnter
                unmountOnExit
                onExited={this.hideModal}
                appear
              >
                <UploadPrescription
                  doctorId={pendingConsult.doctorId}
                  consultationId={pendingConsult.consultationId}
                  doctorImg={pendingConsult.doctorImg}
                  doctorName={pendingConsult.doctorName}
                  dateLabel={pendingConsult.dateLabel}
                  onClose={this.closeUploadModal}
                  onSubmit={this.submitUploadModal}
                />
              </FlyInTransition>
            </ModalContainer>
          </Modal>
        )}

        {showHospitalizationModal && (
          <Modal>
            <HospitalizationModalContainer onClick={this.handleOutsideClick}>
              <FadeInTransition
                in={showPendingHospilationBlocker}
                mountOnEnter
                unmountOnExit
                onExited={this.hideModal}
                appear
              >
                <div
                  ref={(node) => (this.node = node)}
                  className="bg-textbox flex flex-col items-center justify-center rounded-12"
                  style={{ width: '90%', maxWidth: '400px' }}
                >
                  <HospitalizationModal
                    title={'Hospitalization'}
                    method1={'Claim Reimbursement'}
                    tag1={'Get reimbursed for your previous hospitalisation'}
                    method2={'Cashless Initmation Request'}
                    tag2={'Go cashless for your treatment'}
                    history={this.props.history}
                  />
                </div>
              </FadeInTransition>
            </HospitalizationModalContainer>
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  userId: state.user.userId,
  user: state.user,
  userConfig: state.app.config,
  selectedScreen: state.user.selectedScreen,
});

export default connect(mapStateToProps)(ConsultationTabContainer);
