import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions';
import { showError } from '../../utils';

class RedirectUpdation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: 0,
      subRoute: '',
    };

    console.log(props);

    const { location } = props;
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const u = JSON.parse(
        decodeURIComponent(Buffer.from(params.get('u'), 'base64'))
      );
      console.log(u, 'u');
      const phoneNumber = u.phone;
      const subRoute = u.rt;
      if (!phoneNumber || !subRoute) {
        const errorMessage = 'Invalid response';
        const error = new Error('Invalid response');
        error.data = {
          errorMessage,
        };
        showError(error);
      }
      if (phoneNumber) {
        this.state.phone = phoneNumber;
      }
      if (subRoute) {
        this.state.subRoute = subRoute;
      }
      this.handleRedirectionForLogin(this.state.phone, subRoute);
    }
  }

  handleRedirectionForLogin = (phone, subRoute) => {
    console.log(phone, this.props.userPhone);
    const alreadyLoggedIn = phone == this.props.userPhone;
    if (!alreadyLoggedIn) {
      this.props.dispatch(logout(this.props.history));
    }
    this.props.history.replace(`/login?rt=${subRoute}&disableEdit=true`, {
      phone,
      reVerify: alreadyLoggedIn,
      to: 're-verify'
    });
  };

  render() {
    return <div></div>;
  }
}

const mapStateToProps = (state) => ({
  appError: state.app.appError,
  loginState: state.app.loginState,
  userPhone: state.user.userPhone,
});

export default connect(mapStateToProps)(RedirectUpdation);
