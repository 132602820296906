import React, { Component } from 'react';
import styled from 'styled-components';
import Link from 'valuelink';

import { BoldText, ButtonText } from '../../components/common/Text';
import ProgressBar from '../../components/common/ProgressBar';
import { PrimaryButton } from '../../components/common/Buttons';
import { fontSize } from '../../utils';
import {
  getHraForm,
  submitHraResponse,
  goToPreviousHra,
  resetHra,
} from '../../services';
import MultipleSelect from '../../components/hra/MultipleSelect';
import SingleSelect from '../../components/hra/SingleSelect';
import StringInput from '../../components/hra/StringInput';
import StartScreen from './HraStartContainer';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import backIcon from '../../images/hra/back.svg';
import nextIcon from '../../images/hra/next.svg';
import arrowLeft from '../../images/common/arrow-left.svg';
import { connect } from 'react-redux';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const HeaderTitle = styled(BoldText)`
  font-size: ${fontSize(2)};
  margin-bottom: 10px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const QuestionText = styled(BoldText)`
  font-size: ${fontSize(1)};
  margin: 14px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 24px;
  width: 100%;
  position: relative;
  top: 10rem;
`;

class HraQuestionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentQuestion: null,
      end: false,
      userResponse: null,
      stringResponse: '',
      showInfo: false,
      age: '',
      gender: '',
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const { history } = this.props;
    if (this.props.config.showUpdatedHra) {
      this.setState({
        showInfo: true,
      });
    }
    getHraForm()
      .then((res) => {
        if (res.message === 'success') {
          this.setState({
            currentQuestion: res.question,
          });
          if (res.age && res.question.questionId === 2 && !res.userResponse) {
            this.setState({
              stringResponse: String(res.age),
            });
          }
          if (
            res.gender &&
            res.question.questionId === 1 &&
            !res.userResponse
          ) {
            this.setState({
              userResponse: String(res.gender),
            });
          }
        } else if (res.message === 'end') {
          history.replace('/hra/end');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  findOptionIndex(option) {
    const { userResponse } = this.state;
    console.log(userResponse, option);
    if (!userResponse || !Array.isArray(userResponse)) {
      return -1;
    }
    return userResponse.findIndex((ele) => ele === option);
  }

  setResponse = (res) => {
    const { type } = this.state.currentQuestion;
    const { userResponse } = this.state;
    console.log(type, res);
    switch (type) {
      case 'multiple_select': {
        const index = this.findOptionIndex(res);
        if (index === -1) {
          console.log('-1');
          // Add the response
          if (res === 'None of the above') {
            this.setState({
              userResponse: [res],
            });
          } else if (Array.isArray(userResponse)) {
            const updatedResponses = userResponse.filter(
              (ele) => ele !== 'None of the above'
            );
            this.setState({
              userResponse: updatedResponses.concat([res]),
            });
          } else {
            this.setState({
              userResponse: [res],
            });
          }
        } else {
          // Remove the response
          console.log('Remove the response');
          const updatedUserResponse = Array.from(userResponse);
          updatedUserResponse.splice(index, 1);
          console.log(updatedUserResponse);
          this.setState({
            userResponse: updatedUserResponse,
          });
        }
        break;
      }
      case 'single_select': {
        this.setState({
          userResponse: res,
        });
        break;
      }
      default:
        break;
    }
  };

  submitResponse = (res) => {
    const { userResponse, stringResponse, currentQuestion } = this.state;
    const { history } = this.props;
    this.setState({
      userResponse: '',
      stringResponse: '',
    });
    const body = {
      questionId: currentQuestion.questionId,
      questionOrder: currentQuestion.questionOrder,
      choice:
        res ||
        (currentQuestion.type === 'text_input' ? stringResponse : userResponse),
      type: currentQuestion.type,
      parentOrder: currentQuestion.parentOrder,
      conditional: currentQuestion.conditional,
      title: currentQuestion.title,
    };

    submitHraResponse(body)
      .then((res) => {
        if (res.message === 'success') {
          this.setState({
            currentQuestion: res.question,
            userResponse: res.userResponse || '',
            stringResponse:
              res.question.type === 'text_input' && res.userResponse
                ? res.userResponse
                : '',
          });
          if (res.age && res.question.questionId === 2 && !res.userResponse) {
            this.setState({
              stringResponse: String(res.age),
            });
          }
          if (
            res.gender &&
            res.question.questionId === 1 &&
            !res.userResponse
          ) {
            this.setState({
              userResponse: String(res.gender),
            });
          }
        } else if (res.message === 'end') {
          history.replace('/hra/end');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  goToPrevious = () => {
    const { parentOrder, questionOrder } = this.state.currentQuestion;
    const { history } = this.props;
    this.containerRef.current.scrollTo(0, 0);
    goToPreviousHra(parentOrder, questionOrder)
      .then((res) => {
        if (res.message === 'success') {
          this.setState({
            currentQuestion: res.question,
            userResponse: res.userResponse,
            stringResponse:
              res.question.type === 'text_input' && res.userResponse
                ? res.userResponse
                : '',
          });
        } else if (res.message === 'end') {
          history.replace('/hra/end');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  resetHra = () => {
    const { history } = this.props;
    resetHra()
      .then((res) => {
        if (res.message === 'success') {
          this.setState({
            end: false,
            currentQuestion: res.question,
            userResponse: '',
            stringResponse: '',
          });
        } else if (res.message === 'end') {
          history.replace('/hra/end');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { currentQuestion, userResponse, showInfo } = this.state;
    if (!currentQuestion) {
      return false;
    }
    const {
      currentOrder,
      questionOrder,
      parentOrder,
      title,
      text,
      type,
      options,
      totalQuestions,
      info,
    } = currentQuestion;
    if (questionOrder === 1 && parentOrder === 0) {
      return <StartScreen submitResponse={this.submitResponse} />;
    }
    console.log(userResponse);
    const responseLink = Link.state(this, 'stringResponse');
    const progress = ((currentOrder - 1) / totalQuestions) * 100;
    const renderResponse = () => {
      switch (type) {
        case 'single_select':
          return (
            <SingleSelect
              value={userResponse}
              options={options}
              setResponse={this.setResponse}
              className="text-black"
            />
          );
        case 'multiple_select':
          return (
            <MultipleSelect
              value={userResponse}
              options={options}
              setResponse={this.setResponse}
              className="text-black"
            />
          );
        case 'text_input':
          return <StringInput value={responseLink} />;
        default:
          return;
      }
    };
    return (
      <div
        className="font-mont custom-scrollbar overflow-y-scroll h-full"
        style={{ background: 'white', height: '100vh' }}
        ref={this.containerRef}
      >
        <div>
          <div
            className="p-24px relative"
            style={{
              background: '#01BFBD',
            }}
          >
            <img
              src={arrowLeft}
              height={'32px'}
              width={'32px'}
              alt={'back'}
              onClick={this.props.history.goBack}
              style={{ filter: 'invert(100%)', cursor: 'pointer' }}
            />
            {this.props.config.hraLogo && this.props.config.showUpdatedHra ? (
              <img
                src={this.props.config.hraLogo}
                style={{
                  height: '60px',
                  borderRadius: '10px',
                  position: 'absolute',
                  right: '24px',
                }}
              />
            ) : (
              ''
            )}
            <div className="flex flex-row justify-between pt-60px">
              <p className="text-title-xl font-bold text-textbox mb-16px">
                {title}
              </p>
            </div>
            <ProgressBar
              width={`${progress}%`}
              height="10px"
              gradient={['#fff', '#fff']}
            />
          </div>
          <BodyContainer className="p-24px pt-36px overflow-y-auto">
            <p className="text-title-m font-semibold text-txtlight mb-24px">
              {text}
            </p>
            {renderResponse()}
            {info ? (
              <div className="mt-24px">
                <div
                  className="text-body-l font-medium btn p-0"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="info"
                  onClick={() => {
                    this.setState({
                      showInfo: !showInfo,
                    });
                  }}
                  style={{
                    color: '#01BFBD',
                  }}
                >
                  See Information
                </div>
                {showInfo ? (
                  <div className="collapse mt-12px" id="info">
                    <p className="text-txtlight font-medium text-body-l">
                      {info}
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </BodyContainer>
        </div>
        <div
          className=" w-100"
          style={{
            width: '100%',
            maxWidth: '800px',
          }}
        >
          <ButtonsContainer>
            <PrimaryButton
              color=" #99E5E5"
              onTap={this.goToPrevious}
              className="hra-button"
            >
              <div className="flex flex-row  items-center">
                {' '}
                <img
                  src={backIcon}
                  alt="back"
                  style={{
                    height: '16px',
                    width: '16px',
                  }}
                />
                <p className="ml-12px text-body-l text-txtlight font-semibold">
                  Back
                </p>
              </div>
            </PrimaryButton>
            <PrimaryButton
              color="#01BFBD"
              onTap={() => {
                if (
                  userResponse ||
                  (this.state.stringResponse &&
                    this.state.stringResponse.trim().length > 0)
                ) {
                  this.submitResponse();
                  this.containerRef.current.scrollTo(0, 0);
                } else {
                  return null;
                }
              }}
              className={`${
                userResponse ||
                (this.state.stringResponse &&
                  this.state.stringResponse.trim().length > 0)
                  ? ''
                  : 'opacity-50'
              } hra-button`}
              style={{ borderRadius: '40px !important' }}
            >
              <div className={`flex flex-row text-textbox items-center`}>
                <p className="mr-12px text-body-l text-textbox font-semibold">
                  Next
                </p>
                <img
                  src={nextIcon}
                  alt="back"
                  style={{
                    height: '16px',
                    width: '16px',
                  }}
                />{' '}
              </div>
            </PrimaryButton>
          </ButtonsContainer>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    config: state.app.config,
  };
};

export default connect(mapStateToProps)(HraQuestionContainer);
