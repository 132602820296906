import React from 'react';
import { useSelector } from 'react-redux';
import initializeFreshDesk from '../../../utils/freshchat';

const NeedHelp = ({ config }) => {
  const user = useSelector((state) => state.user);

  return (
    <button
      onClick={() =>
        window.open(
          `${user.ticketingUrl}?token=${user.authToken.split(' ')[1]}`,
          '_blank'
        )
      }
      className="text-body-s flex text-primary font-semibold"
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.1698 7.20034C15.1698 -0.95907 2.83236 -1.17379 2.83236 7.20034M2.83236 7.20034H3.68322V13.1051M2.83236 7.20034C2.17646 7.4662 0.893148 8.45808 1.00712 10.2988C1.12108 12.1394 2.83867 12.9366 3.68322 13.1051M3.68322 13.1051C3.68322 15.8965 5.73679 17 8.25659 17V15.8965M15.1676 7.20033H14.3168V13.1051C15.1613 12.9366 16.8789 12.1394 16.9929 10.2988C17.1069 8.45807 15.8235 7.46618 15.1676 7.20033Z"
          stroke="#01BFBD"
          stroke-width="1.25"
          stroke-linejoin="round"
        />
      </svg>
      <p className="ml-5">Need help?</p>
    </button>
  );
};

export default NeedHelp;
