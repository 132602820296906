import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TappableCard from '../common/TappableCard';
import { Text } from '../common/Text';
import { FaChevronRight, FaAngleDown, FaAngleUp } from 'react-icons/fa';

import StatusIconPng from '../../images/lab-test/status.png';
import StatusProcessing from '../../images/consultations/processing.png';
import StatusCross from '../../images/consultations/cancelled.png';
import StatusTickGreen from '../../images/consultations/processed.png';
import StatusTick from '../../images/consultations/confirmed.png';
import UserIcon from '../../images/Claims-Page/User.svg';
import CheckIcon from '../../images/Claims-Page/Check cr-fr.svg';

const OrderContainer = styled(TappableCard)`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0.875rem;
  margin: 0.5rem 0;
  border-radius: 8px !important;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TitleTextContainer = styled.div`
  display: flex;
  flex: 1;
  overflow-x: hidden;
  padding-bottom: 12px;
  border-bottom: 1px solid #d3d6db;
`;

const TitleText = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusItem = styled.div`
  float: right;
  display: flex;
  align-items: center;
`;

const StatusText = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => (props.color ? props.color : '#0091E5;')};
  margin-right: 5px;
  margin-left: 5px;
`;

const ClaimsStatusCard = ({ orderRequest, onTap }) => {
  return (
    <OrderContainer onTap={onTap}>
      <TitleContainer>
        <TitleTextContainer>
          <TitleText className="txt-black-600-12 flex-center">
            <span className="ml-10">{orderRequest.label}</span>
          </TitleText>
          <FaChevronRight />
        </TitleTextContainer>
      </TitleContainer>
      <div>
        <div className="flex flex-row items-center  mt-5">
          <img src={UserIcon} alt="user_icon" className="mr-5 ml-10" />
          <p className="lab-test-name mt-4 mb-4 ">
            {orderRequest.claimInfo.patientName}
          </p>
        </div>
        <div className="flex-start-end">
          <div className="flex flex-row items-center ">
            <img src={CheckIcon} alt="check_icon" className="mr-5 ml-10" />
            <p className="txt-grey-500-12">
              {orderRequest.claimInfo.claimDate}
            </p>
          </div>
          <StatusItem>
            {orderRequest.claimInfo.status.icon === 'processing' ? (
              <img
                src={StatusProcessing}
                alt="status"
                style={{ height: '15px', width: '15px' }}
              />
            ) : (
              ''
            )}
            {orderRequest.claimInfo.status.icon === 'cross' ? (
              <img
                src={StatusCross}
                alt="status"
                style={{ height: '15px', width: '15px' }}
              />
            ) : (
              ''
            )}
            {orderRequest.claimInfo.status.icon === 'tick' &&
            orderRequest.claimInfo.status.hex === '#01BFBD' ? (
              <img
                src={StatusTickGreen}
                alt="status"
                style={{ width: '15px', height: '15px' }}
              />
            ) : (
              ''
            )}
            {orderRequest.claimInfo.status.icon === 'tick' &&
            orderRequest.claimInfo.status.hex !== '#01BFBD' ? (
              <img
                src={StatusTick}
                alt="status"
                style={{ width: '15px', height: '15px' }}
              />
            ) : (
              ''
            )}
            <StatusText active={true} color={orderRequest.claimInfo.status.hex}>
              {orderRequest.claimInfo.status.label}
            </StatusText>
          </StatusItem>
        </div>
        <TitleTextContainer />
        <div className="flex-start-end mt-5">
          <span className="txt-black-600-13 ml-10">Claim amount</span>
          <span className="txt-black-600-14">
            ₹{orderRequest.claimInfo.claimAmount}
          </span>
        </div>
        {/* </TitleTextContainer> */}
      </div>
    </OrderContainer>
  );
};

ClaimsStatusCard.propTypes = {
  onTap: PropTypes.func,
  orderRequest: PropTypes.object.isRequired,
};

export default ClaimsStatusCard;
