import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

import { store } from '../store';

export function getLabOrders(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/dashboard-v3`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getLabTestsCart(authToken, cartId) {
  const headers = {
    authorization: authToken,
  };

  let url = `${config.apiBaseUrl}/labs-v2/cart`;
  if (cartId) {
    url += `/${cartId}`;
  }
  return axios
    .get(url, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getLabTests(searchQuery, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/all`, {
      headers,
      params: {
        s: searchQuery,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addLabTest(labId, cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/add`,
      {
        labId,
        cartId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (
        responseBody.message === 'success' ||
        responseBody.message === 'changeTestType'
      ) {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadPrescription(requestData, onUploadProgress, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs/prescriptions/file`, requestData, {
      headers,
      onUploadProgress,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeFileFromPrescription(fileId, prescriptionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs/prescriptions/file/remove`,
      {
        fileId,
        prescriptionId,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeLabTest(cartItemId, cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs/cart/remove`,
      {
        cartItemId,
        cartId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

// export function getLabSlots(authToken) {
//   const headers = {
//     authorization: authToken,
//   };

//   return axios
//     .get(`${config.apiBaseUrl}/labs/slots`, {
//       headers,
//     })
//     .then(resp => {
//       const responseBody = resp.data;
//       if (responseBody.message === 'success') {
//         return responseBody;
//       } else {
//         const err = new Error('Invalid response');
//         err.data = responseBody;
//         showError(err);
//         throw err;
//       }
//     })
//     .catch(err => {
//       showError(err);
//       throw err;
//     });
// }

export function selectLabSlot(slotId, date, cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs/cart/select-slot`,
      {
        slotId,
        date,
        cartId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getSavedAddresses(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/address`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getSavedPatients(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/patients`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function saveNewPatient(patientDetails, authToken) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };
  return axios
    .post(`${config.apiBaseUrl}/labs/patients`, patientDetails, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function editPatient(patientDetails, authToken) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/patients/edit`, patientDetails, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removePatient(patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs/patients/remove`,
      {
        patientId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function saveNewAddress(addressDetails, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/address`, addressDetails, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function editAddress(addressDetails, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/address/edit`, addressDetails, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeAddress(addressId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/address/remove`,
      {
        addressId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function selectPatient(cartId, patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/select-patient`,
      {
        patientId,
        cartId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function selectAddress(addressId, cartId, authToken) {
  const headers = {
    authorization: authToken,
  };
  console.log(addressId, cartId);
  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/select-address`,
      {
        addressId,
        cartId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function selectSlot(
  slotId,
  cartId,
  date,
  authToken,
  isMondelezAhc = false,
  testType = false
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/select-slot`,
      {
        slotId,
        date,
        cartId,
        isMondelezAhc,
        testType,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getOrderSummary(cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/cart/summary/${cartId}`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        if (
          responseBody &&
          responseBody.errorMessage ===
            "Cannot read property 'partnerId' of undefined"
        ) {
          return responseBody;
        }
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      console.log(err);
      if (
        err &&
        err.response &&
        err.response.data &&
        (err.response.data.errorMessage ===
          "Cannot read property 'partnerId' of undefined" ||
          err.response.data.errorMessage === 'Address details not selected' ||
          err.response.data.errorMessage === 'Patient details not selected')
      ) {
        return { page: 'lab-select' };
      }
      showError(err);
      throw err;
    });
}

export function getUserPrescriptions(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/prescriptions`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function payLabCart(amount, cartId, history, authToken) {
  const headers = {
    authorization: authToken,
  };
  const redirectToPaymentGateway = () => {
    // Not on phonepe in-app or some other error.
    // send to payment
    window.location.assign(
      `${
        config.apiBaseUrl
      }/labs-v2/transact?amount=${amount}&isBimaplanKettoUser=true&cartId=${cartId}&auth=${
        authToken.split(' ')[1]
      }`
    );
  };
  if (amount === 0) {
    return redirectToPaymentGateway();
  }
  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      // Call the API to fetch the data context
      return axios
        .get(`${config.apiBaseUrl}/labs-v2/transact`, {
          headers,
          params: {
            amount,
            cartId,
          },
        })
        .then((res) => {
          const responseBody = res.data;
          if (responseBody.message === 'success') {
            const context = responseBody.data;
            if (sdk.isMethodSupported('openPaymentsPage')) {
              history.replace('/processing-payment', {
                orderType: 'lab',
                transactionId: responseBody.transactionId,
                successPage: `lab-test/track`,
                failurePage: `lab-test/order-summary`,
                failureStateParameters: {
                  paymentNext: true,
                  cartId: cartId,
                  paymentFailed: true,
                },
              });
              return sdk.openPaymentsPage(
                'Visit',
                context,
                `${config.rootUrl}lab-test`,
                'https://getvisitapp.com/img/png/Visit_logo.png',
                [
                  {
                    'Order For': 'Lab Tests',
                  },
                ]
              );
            }
            throw new Error('Phonepe method not supported');
          } else {
            throw new Error('Failed to get payment context');
          }
        });
    })
    .catch((err) => {
      console.log(err);
      try {
        const parsedError = JSON.parse(err);
        if (
          parsedError &&
          (parsedError.error_code === 'PAYMENT_DISMISS' ||
            parsedError.error_code === 'PAYMENT_TIMEOUT')
        ) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('lab-test/order-summary', {
            cartId,
            paymentNext: true,
          });
          return;
        }
        // in case of android, it could still be a null error.
        if (parsedError === 'null' || parsedError === null) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('lab-test/order-summary', {
            cartId,
            paymentNext: true,
          });
          return;
        }
      } catch (e) {
        //  Since we couldn't parse the error, it means it's not a string and hence not a phonepe dismiss/timeout error.
        // Not on phonepe in-app or some other error.
        // send to payment
        redirectToPaymentGateway();
      }
    });
}

export function getTranscriptionPreSubmitSummary(
  prescriptionId,
  patientId,
  addressId,
  slotId,
  collectionDate,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs/transcription/pre-submit`,
      {
        prescriptionId,
        patientId,
        addressId,
        slotId,
        collectionDate,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function submitForTranscription(
  prescriptionId,
  patientId,
  addressId,
  slotId,
  collectionDate,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs/prescriptions/submit-request`,
      {
        prescriptionId,
        patientId,
        addressId,
        slotId,
        collectionDate,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getTranscriptionStatus(prescriptionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/transcription/request/${prescriptionId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getOrderStatus(orderId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/order/status/${orderId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function verifyPincode(pincode, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/pincode/check`, {
      headers,
      params: {
        pincode,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchPrescription(prescriptionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/prescriptions/${prescriptionId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getVisitPresciptions(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/online/prescriptions`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function searchCities(searchQuery, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/cities`, {
      headers,
      params: {
        s: searchQuery,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function searchLocation(searchQuery, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/location/auto-suggest`, {
      headers,
      params: {
        q: searchQuery,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function trackLabOrder(transactionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs/phonepe/transaction/status/${transactionId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchLabOrderDetail(orderId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/order/${orderId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function getDigitsationStatus(digitisationId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/digitisation/${digitisationId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAllLabsOrder(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/dashboard-v3`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function submitDigitsationRequest(prescriptionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/digitisation/submit-request`,
      {
        prescriptionId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getLabs(cartId, lat, long, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/cart/partners/${cartId}?lat=${lat}&long=${long}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function selectLabPartner(
  cartId,
  partnerId,
  meta,
  authToken,
  isMondelezAhc,
  testsType
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/select-partner`,
      {
        cartId,
        partnerId,
        meta,
        isMondelezAhc,
        testsType,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getLabSlots(cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/slots?cartId=${cartId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAddress(authToken, cartId) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/address${
        cartId ? `?cartId=${cartId}` : ''
      }`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getPatients(authToken, type, cartId = null) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };
  const queryParams = new URLSearchParams();
  if (type) {
    queryParams.append('type', type);
  }
  if (cartId) {
    queryParams.append('cartId', cartId);
  }
  console.log(queryParams.toString());

  const url = `${config.apiBaseUrl}/labs-v2/patients?${queryParams.toString()}`;

  // const url = type
  //   ? `${config.apiBaseUrl}/labs-v2/patients?type=${type}`
  //   : `${config.apiBaseUrl}/labs-v2/patients`;
  return axios
    .get(url, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementSubmitLabs(cartId, patientId, date, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/reimburse/submit`,
      {
        cartId,
        patientId,
        date,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementDetails(reimbursementId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/reimburse/details/${reimbursementId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementInvoiceSubmit(formData, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/reimbursements/invoice`, formData, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function bookAhcRequest(patientId, addressId, preferredDate, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/ahc/add-request`,
      {
        patientId,
        addressId,
        preferredDate,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAhcRequestForUser(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/get-request-for-user`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function fetchCopayOrderDetail(orderId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/copay/checkout?orderId=${orderId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function payLabCopay(orderId, history, authToken) {
  const headers = {
    authorization: authToken,
  };
  const redirectToPaymentGateway = () => {
    // Not on phonepe in-app or some other error.
    // send to payment
    window.location.assign(
      `${config.apiBaseUrl}/labs-v2/copay/transact?orderId=${orderId}&auth=${
        authToken.split(' ')[1]
      }`
    );
  };

  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      // Call the API to fetch the data context
      return axios
        .get(`${config.apiBaseUrl}/labs-v2/copay/transact`, {
          headers,
          params: {
            orderId,
          },
        })
        .then((res) => {
          const responseBody = res.data;
          if (responseBody.message === 'success') {
            const context = responseBody.data;
            if (sdk.isMethodSupported('openPaymentsPage')) {
              history.replace('/processing-payment', {
                orderType: 'lab',
                transactionId: responseBody.transactionId,
                successPage: `lab-test/track`,
                failurePage: `lab-test/copay`,
                failureStateParameters: {
                  paymentNext: true,
                },
              });
              return sdk.openPaymentsPage(
                'Visit',
                context,
                `${config.rootUrl}lab-test`,
                'https://getvisitapp.com/img/png/Visit_logo.png',
                [
                  {
                    'Order For': 'Lab Tests',
                  },
                ]
              );
            }
            throw new Error('Phonepe method not supported');
          } else {
            throw new Error('Failed to get payment context');
          }
        });
    })
    .catch((err) => {
      console.log(err);
      try {
        const parsedError = JSON.parse(err);
        if (
          parsedError &&
          (parsedError.error_code === 'PAYMENT_DISMISS' ||
            parsedError.error_code === 'PAYMENT_TIMEOUT')
        ) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('lab-test/copay', {
            orderId,
            paymentNext: true,
          });
          return;
        }
        // in case of android, it could still be a null error.
        if (parsedError === 'null' || parsedError === null) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('lab-test/copay', {
            orderId,
            paymentNext: true,
          });
          return;
        }
      } catch (e) {
        //  Since we couldn't parse the error, it means it's not a string and hence not a phonepe dismiss/timeout error.
        // Not on phonepe in-app or some other error.
        // send to payment
        redirectToPaymentGateway();
      }
    });
}

export function getSuezAhcPackage(patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/suez?patientId=${patientId}`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getMcdermottAhcPackage(patientId, testType, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/ahc/mcdermott?patientId=${patientId}&testType=${testType}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getOracleAhcPackage(patientId, testType, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/ahc/oracle?patientId=${patientId}&testType=${testType}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getMondelezRevampAhcPackage(patientId, testType, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/ahc/mondelez-revamp?patientId=${patientId}&testType=${testType}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getUniversalAhcPackage(patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/universal?patientId=${patientId}`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getHitachiAhcPackage(patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/hitachi?patientId=${patientId}`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getValmetAhcPackage(patientId, testType, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/ahc/valmet?patientId=${patientId}&testType=${testType}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getMondelenzeAhcPackage(
  patientId,
  isPathologyAdded,
  noTmt,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/ahc/mondelez?patientId=${patientId}&isPathologyAdded=${isPathologyAdded}&noTmt=${noTmt}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getBiracAhcPackage(patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/birac?patientId=${patientId}`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getEqualExpertsAhcPackage(patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/ahc/equal-experts?patientId=${patientId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getHindalcoAhcPackage(patientId, testType, authToken, self) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/ahc/hindalco?patientId=${patientId}&testType=${testType}&self=${self}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function bookAhcForMondelez(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(`${config.apiBaseUrl}/labs-v2/book-mondelez-ahc`, null, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function get3MAhcPackage(patientId, testType, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/ahc/3m?patientId=${patientId}&testType=${testType}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getWomensDayPackage(testType, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/womensDay?testType=${testType}`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getDream11AhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/dream11`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getIndigridAhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/indigrid`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getAxisAhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/axis`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getBacardiAhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/bacardi`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function get8spadesAhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/8spades`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getO9AhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/o9`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getAuAhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/au`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getAegonPackage(patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/aegon?patientId=${patientId}`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getJNJAhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/jnj`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getSelAhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/sel`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getAhcPartners(authToken, lat, long) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/list-partners`, {
      headers,
      params: {
        lat,
        long,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function bookAhcOrder(
  patientId,
  preferredDate,
  partnerId,
  centerId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/ahc/book-order`,
      {
        patientId,
        partnerId,
        centerId,
        collectionDate: preferredDate,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function packageDetails(packageId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/package-details?packageId=${packageId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addPackageToCart(cartId, packageId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/add`,
      {
        cartId,
        packageId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (
        responseBody.message === 'success' ||
        responseBody.message === 'changeTestType'
      ) {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addAddonToCart(cartId, addOnTestId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/add`,
      {
        addOnTestId,
        cartId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getBottomCartText(cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/cart/summary/${cartId}?bottomCart=1`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        if (
          responseBody &&
          responseBody.errorMessage ===
            "Cannot read property 'partnerId' of undefined"
        ) {
          responseBody.page = 'lab-select';
          return responseBody;
        }
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      console.log(err);
      if (
        err &&
        err.response &&
        err.response.data &&
        (err.response.data.errorMessage ===
          "Cannot read property 'partnerId' of undefined" ||
          err.response.data.errorMessage === 'Address details not selected' ||
          err.response.data.errorMessage === 'Patient details not selected')
      ) {
        return { page: 'lab-select' };
      }
      showError(err);
      throw err;
    });
}

export function fetchSamplePrescription(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/upload-prescription/details`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteCart(cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/remove-cart/${cartId}`, null, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadAddressProof(
  requestData,
  onUploadProgress,
  cartId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/upload/address-proof/${cartId}`,
      requestData,
      {
        headers,
        onUploadProgress,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteAddressProof(url, cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/delete/address-proof/${cartId}`,
      {
        url,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchAllAddressProofFile(cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/address-proof/${cartId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getCovidDashboard(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/labs-v2/covid-dashboard`, { headers })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function covidPackageDetails(id, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/labs-v2/covid-test/${id}`, { headers })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getCovidHomeCareDashboard(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/subscription/covid-home-care-dashboard`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function markSamplePickedUp(orderId, samplePickup, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/order/sample-pickup`,
      {
        orderId,
        samplePickup,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function covidDeclaration(body, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/covid-test-result`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function submitAhcReimbursementRequest(
  requestData,
  onUploadProgress,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/ahc/create-reimbursement-request`,
      requestData,
      {
        headers,
        onUploadProgress,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAhcParentalRelatives() {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/parental/patients`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addAhcParentRelative(name, dob, gender, phone, relationId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    name,
    dob,
    gender,
    phone,
    relationId,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/ahc/parental/patients`, requestData, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function hindalcoSaveConsent(consentResponse) {
  console.log({ consentResponse }, '[hindalcoSaveConsent]');
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    consentResponse,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/ahc/hindalco/consent-response`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addInfoedgeAhcToCart(cartId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/infoedge/addParentalAHCToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addDream11AhcToCart(cartId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/dream11/addAHCTestToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addGartnerAhcToCart(cartId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/gartner/addAHCTestToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addO9AhcToCart(cartId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/O9/addAHCTestToCart`, requestData, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addAuAhcToCart(cartId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/au/addAHCTestToCart`, requestData, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addIndigridAhcToCart(cartId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/indigrid/addAHCTestToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addAxisAhcToCart(cartId, testId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
    testId,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/axis/addAHCTestToCart`, requestData, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addBacardiAhcToCart(cartId, testId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
    testId,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/bacardi/addAHCTestToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addJNJAhcToCart(cartId, testId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
    testId,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/jnj/addAHCTestToCart`, requestData, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addSelAhcToCart(cartId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/sel/addAHCTestToCart`, requestData, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addCanaraHSBCAhcToCart(cartId, testId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    testId,
    cartId,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/canaraHSBC/addAHCTestToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addAegonAhcToCart(cartId, packageId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    packageId,
    cartId,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/aegon/aegonAHCTestToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addMcdermottAhcToCart(cartId, packageId, testType) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  if (testType === 'radiology tmt') {
    testType = 'radiology';
  }

  const requestData = {
    packageId,
    cartId,
    testType,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/mcdermott/addAHCTestToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getCanaraHSBCAhcPackage(patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/canarahsbc?patientId=${patientId}`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getAngloAhcPackage(patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/anglo?patientId=${patientId}`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getAecAhcPackage(patientId, testsType, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/ahc/aec?patientId=${patientId}&testsType=${testsType}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getCitiesListForIsolationCenters(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/isolation-centers/cities`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getIsolationCentersListForACity(authToken, lat, long, city) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/isolation-centers/centers?lat=${lat}&long=${long}&city=${city}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getDetailsOfAParticularCenter(authToken, centerId, lat, long) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/isolation-centers/center-details?lat=${lat}&long=${long}&centerId=${centerId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function placeBookingRequest(authToken, body) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(`${config.apiBaseUrl}/isolation-centers/book-request`, body, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getBookingStatus(authToken, id) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/isolation-centers/booking-status?requestId=${id}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getAllPreviousBookings(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/isolation-centers/previous-bookings`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function crosswordStart(authToken, puzzleId) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/crossword/crossword-start`,
      { puzzleId },
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getCrossword(authToken, puzzleId) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/crossword/get-crossword?crosswordId=${puzzleId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function crosswordSubmit(authToken, sessionId, puzzleId) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/crossword/submit-crossword`,
      { sessionId, puzzleId },
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getCrosswordPuzzleData(authToken, puzzleId) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/crossword/get-crossword-id?puzzleId=${puzzleId}`,

      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function intitiateEmergencyCall(authToken, orderId) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/order/initiate-call-by-user`,
      { orderId },
      { headers }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'SUCCESS') {
        return responseBody.showMessage;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody.showMessage;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export const getLabOrdersTimeline = (
  authToken,
  orderId,
  digitisationId,
  cartId
) => {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/dashboard-timeline?dRequestId=${
        digitisationId ? digitisationId : 0
      }&orderId=${orderId ? orderId : 0}&cartId=${cartId ? cartId : 0}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody.showMessage;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const postSamplePickup = async (authToken, isAllowed, orderId) => {
  const headers = {
    authorization: authToken,
  };

  const payload = { samplePickup: isAllowed, orderId: orderId };

  try {
    const result = await axios.post(
      `${config.apiBaseUrl}/labs-v2/order/sample-pickup`,
      payload,
      { headers }
    );
    console.log(result);
  } catch (err) {
    console.log(err);
  }
};

export const copay = async (authToken, orderId) => {
  const headers = {
    authorization: authToken,
  };

  try {
    const result = await axios.get(
      `${config.apiBaseUrl}/labs-v2/copay/transact?orderId=${orderId}`,
      { headers }
    );
    console.log(result);
  } catch (err) {
    console.log(err);
  }
};

export const submitFeedback = async (
  authToken,
  text,
  event,
  orderId,
  rating,
  reason
) => {
  const headers = {
    authorization: authToken,
  };

  try {
    const result = await axios.post(
      `${config.apiBaseUrl}/labs-v2/submit-feedback`,
      {
        text,
        event,
        orderId,
        rating,
        reason,
      },
      { headers }
    );
    console.log(result);
  } catch (err) {
    console.log(err);
  }
};
