import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import arrowLeft from '../../images/common/arrow-left.svg';
import discountIcon from '../../images/common/discount.svg';
import checkedIcon from '../../images/pharmacy/checked.svg';
import Modal from '../../containers/common/Modal';
import { FlyInTransition } from '../../components/transitions/transitions';
import infoIcon from '../../images/buying-journey/info.svg';
import successIcon from '../../images/buying-journey/success.svg';
import membersIcon from '../../images/buying-journey/members.svg';
import detailsIcon from '../../images/buying-journey/details.svg';
import calendarIcon from '../../images/buying-journey/calendar.svg';
import crossIcon from '../../images/common/cross.svg';
import greenInfoIcon from '../../images/buying-journey/green-info.svg';
import maleIcon from '../../images/buying-journey/male.png';
import femaleIcon from '../../images/buying-journey/female.png';
import sonIcon from '../../images/buying-journey/son.png';
import daughterIcon from '../../images/buying-journey/daughter.png';
import fatherIcon from '../../images/buying-journey/father.png';
import motherIcon from '../../images/buying-journey/mother.png';
import config from '../../config';
import profileIcon from '../../images/manage-dependents/profile.svg';
import editIcon from '../../images/common/edit.svg';
import {
  getAllRelatives,
  updateRelative,
  addNewRelative,
} from '../../services';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

class EditMemberModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: true,
      name: '',
      phone: '',
      gender: props.gender || '',
      age: '',
      editing: false,
      relation: '',
    };
    if (props.editingMember) {
      this.state.editing = true;
      this.state.name = props.editingMember.name;
      this.state.age = props.editingMember.age;
      this.state.phone = props.editingMember.phone;
      this.state.gender = props.editingMember.gender;
      this.state.relation = props.editingMember.relation;
    }
    this.dateNodeRef = React.createRef();
    this.monthNodeRef = React.createRef();
    this.yearNodeRef = React.createRef();
    this.mobileNodeRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { showModal } = this.props;
    if (prevProps.showModal && !showModal) {
      this.hideModal();
    }
    if (!prevProps.showModal && showModal) {
      this.setState({
        showModal: true,
      });
    }
  }

  hideModal = () => {
    this.setState({
      name: '',
      age: '',
      gender: '',
      phone: '',
      editing: false,
    });
    this.setState({ showModal: false });
    setTimeout(this.props.hideModal, 200);
  };

  handleOutsideClick = (e) => {
    if (this.modalNode && this.modalNode.contains(e.target)) {
      return;
    }
    this.hideModal();
  };

  addRelative = () => {
    const { name, editing, age, gender, phone } = this.state;
    const { editingMember } = this.props;
    this.props.addRelative(name, phone, age, gender, editingMember);
  };

  render() {
    const { editing } = this.state;
    return (
      <Modal>
        <div className="modalContainer" onClick={this.handleOutsideClick}>
          <FlyInTransition
            in={this.state.showModal}
            mountOnEnter
            unmountOnExit
            appear
          >
            <div
              className="bg-textbox absolute bottom-0px w-full rounded-t-3xl max-h-full overflow-auto"
              ref={(node) => (this.modalNode = node)}
            >
              <div className="p-20 border-b border-txtsecondary-shades7 border-solid flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <img src={membersIcon} alt="home" />
                  <span className="text-title-s ml-10px font-semibold text-txtlight">
                    {this.state.relation} Details
                  </span>
                </div>
                <img src={crossIcon} alt="cross" onClick={this.hideModal} />
              </div>
              <div className="p-20 max-h-full overflow-auto">
                <div>
                  <p className="text-body-s text-txtlight font-medium">
                    Full Name
                  </p>
                  <input
                    className="mt-8px new-address-input text-title-s font-semibold"
                    placeholder="Enter name here"
                    value={this.state.name}
                    disabled={editing}
                    onChange={(e) => {
                      this.setState({
                        name: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="mt-24px">
                  <p className="text-body-s text-txtlight font-medium">Age</p>
                  <input
                    className="mt-8px new-address-input text-title-s font-semibold"
                    placeholder="Your 10 digit valid number"
                    value={this.state.age}
                    disabled={editing}
                    onChange={(e) => {
                      this.setState({
                        age: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="mt-24px">
                  <p className="text-body-s text-txtlight font-medium">
                    Mobile Number
                  </p>
                  <input
                    className="mt-8px new-address-input text-title-s font-semibold"
                    placeholder="Your 10 digit valid number"
                    value={this.state.phone}
                    ref={this.mobileNodeRef}
                    onChange={(e) => {
                      this.setState({
                        phone: e.target.value,
                      });
                    }}
                  />
                  <div className="mt-8px flex flex-row items-center">
                    <img src={infoIcon} height={'16px'} />
                    <p className="ml-8px text-chip text-sub font-medium">
                      This number will be used to contact the patient
                    </p>
                  </div>
                </div>
              </div>
              {this.state.name.length > 0 &&
                this.state.phone.length > 5 &&
                this.state.gender && (
                  <div
                    className="pt-42px pl-20px pr-20px pb-32px w-full"
                    style={{
                      background:
                        'linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 31.77%, #FFFFFF 66.15%, rgba(255, 255, 255, 0) 100%)',
                    }}
                  >
                    <div
                      className="bg-primary rounded-12 pt-12px pb-10px center"
                      onClick={this.addRelative}
                    >
                      {!this.props.adding ? (
                        <>
                          <span className="text-button-s font-bold text-textbox mr-10px">
                            Add
                          </span>
                        </>
                      ) : (
                        <span className="text-button-s font-bold text-textbox mr-10px">
                          Loading....
                        </span>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </FlyInTransition>
        </div>
      </Modal>
    );
  }
}
class AddMemberModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: true,
      name: '',
      dobDate: '',
      dobMonth: '',
      dobYear: '',
      mobileNumber: '',
      gender: '',
      relation: '',
      showRelations: false,
    };
    this.dateNodeRef = React.createRef();
    this.monthNodeRef = React.createRef();
    this.yearNodeRef = React.createRef();
    this.mobileNodeRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { showModal } = this.props;
    if (prevProps.showModal && !showModal) {
      this.hideModal();
    }
    if (!prevProps.showModal && showModal) {
      this.setState({
        showModal: true,
      });
    }
  }

  hideModal = () => {
    this.setState({
      name: '',
      dobDate: '',
      dobMonth: '',
      dobYear: '',
      gender: '',
      relation: '',
      mobileNumber: '',
    });
    this.setState({ showModal: false });
    setTimeout(this.props.hideModal, 200);
  };

  handleOutsideClick = (e) => {
    if (this.modalNode && this.modalNode.contains(e.target)) {
      if (this.relationNode.contains(e.target)) {
        return;
      } else {
        this.setState({
          showRelations: false,
        });
        return;
      }
    }
    this.hideModal();
  };

  addRelative = () => {
    const { name, dobDate, dobMonth, dobYear, gender, mobileNumber, relation } =
      this.state;
    this.props.addRelative({
      name,
      dateOfBirth: new Date(`${dobDate}-${dobMonth}-${dobYear}`)
        .toISOString()
        .split('T')[0],
      mobileNumber,
      relationId: relation.relationId,
      gender,
    });
  };

  render() {
    return (
      <Modal>
        <div className="modalContainer" onClick={this.handleOutsideClick}>
          <FlyInTransition
            in={this.state.showModal}
            mountOnEnter
            unmountOnExit
            appear
          >
            <div
              className="bg-textbox absolute bottom-0px w-full rounded-t-3xl max-h-full overflow-auto"
              ref={(node) => (this.modalNode = node)}
            >
              <div className="p-20 border-b border-txtsecondary-shades7 border-solid flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <img src={membersIcon} alt="home" />
                  <span className="text-title-s ml-10px font-semibold text-txtlight">
                    Add Dependent Details
                  </span>
                </div>
                <img src={crossIcon} alt="cross" onClick={this.hideModal} />
              </div>
              <div className="p-20 max-h-full overflow-auto">
                <div>
                  <p className="text-body-s text-txtlight font-medium">
                    Full Name
                  </p>
                  <input
                    className="mt-8px new-address-input text-title-s font-semibold"
                    placeholder="Enter name here"
                    value={this.state.name}
                    onChange={(e) => {
                      this.setState({
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mt-24px">
                  <p className="text-body-s text-txtlight font-medium">
                    Date of Birth
                  </p>
                  <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-1">
                      <input
                        className="mt-8px new-address-input text-title-s font-semibold text-center"
                        placeholder="dd"
                        type="number"
                        value={this.state.dobDate}
                        maxLength="2"
                        ref={this.dateNodeRef}
                        onChange={(e) => {
                          if (e.target.value.length === 2) {
                            this.monthNodeRef.current &&
                              this.monthNodeRef.current.focus();
                          }
                          this.setState({
                            dobDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-span-1">
                      <input
                        className="mt-8px new-address-input text-title-s font-semibold text-center"
                        placeholder="mm"
                        maxLength="2"
                        type="number"
                        ref={this.monthNodeRef}
                        value={this.state.dobMonth}
                        onChange={(e) => {
                          if (e.target.value.length === 2) {
                            this.yearNodeRef.current &&
                              this.yearNodeRef.current.focus();
                          }
                          this.setState({
                            dobMonth: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-span-2">
                      <input
                        className="mt-8px new-address-input text-title-s font-semibold text-center"
                        placeholder="yyyy"
                        type="number"
                        maxLength="4"
                        ref={this.yearNodeRef}
                        value={this.state.dobYear}
                        onChange={(e) => {
                          if (e.target.value.length === 4) {
                            this.mobileNodeRef.current &&
                              this.mobileNodeRef.current.focus();
                          }
                          this.setState({
                            dobYear: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-24px">
                  <p className="text-body-s text-txtlight font-medium">
                    Mobile Number
                  </p>
                  <input
                    className="mt-8px new-address-input text-title-s font-semibold"
                    placeholder="Your 10 digit valid number"
                    type="number"
                    value={this.state.mobileNumber}
                    ref={this.mobileNodeRef}
                    onChange={(e) => {
                      this.setState({
                        mobileNumber: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mt-24px">
                  <p className="text-body-s text-txtlight font-medium">
                    Select Relation
                  </p>
                  <div
                    className="new-address-input"
                    ref={(node) => (this.relationNode = node)}
                    style={
                      this.state.showRelations
                        ? { marginTop: '10px', borderColor: '#01BFBD' }
                        : { marginTop: '10px' }
                    }
                    onClick={() => {
                      this.setState({
                        showRelations: !this.state.showRelations,
                      });
                    }}
                  >
                    {this.state.relation ? (
                      <span className="text-title-s font-semibold">
                        {' '}
                        {this.state.relation.title}{' '}
                      </span>
                    ) : (
                      <span className="txt-grey-500-12">Select Relation</span>
                    )}
                    <span className="float-right">
                      {this.state.showRelations ? (
                        <FaAngleUp />
                      ) : (
                        <FaAngleDown />
                      )}
                    </span>
                    {this.state.showRelations && (
                      <div className="custom-select mt-10">
                        {this.props.relations.map((ele) => {
                          return (
                            <span
                              className="custom-select-option text-body-s"
                              style={{
                                fontSize: '12px',
                              }}
                              onClick={() => {
                                this.setState({
                                  relation: ele,
                                  showRelations: false,
                                  gender: ele.gender,
                                });
                              }}
                            >
                              {ele.title}
                            </span>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {this.state.name.length > 0 &&
                this.state.gender &&
                this.state.relation &&
                this.state.mobileNumber.length > 5 &&
                this.state.dobDate.length > 0 &&
                this.state.dobMonth.length > 0 &&
                this.state.dobYear.length > 0 && (
                  <div
                    className="pt-20px pl-20px pr-20px pb-32px w-full"
                    style={{
                      background:
                        'linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 31.77%, #FFFFFF 66.15%, rgba(255, 255, 255, 0) 100%)',
                    }}
                  >
                    <div
                      className="bg-primary rounded-12 pt-12px pb-10px center"
                      onClick={this.addRelative}
                    >
                      {!this.props.adding ? (
                        <>
                          <span className="text-button-s font-bold text-textbox mr-10px">
                            Add
                          </span>
                        </>
                      ) : (
                        <span className="text-button-s font-bold text-textbox mr-10px">
                          Loading....
                        </span>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </FlyInTransition>
        </div>
      </Modal>
    );
  }
}

export class ManageDependents extends Component {
  state = {
    memberInfo: [],
    relations: [
      {
        relation: 'Mother',
      },
    ],
    showEditMemberModal: false,
    addingRelation: '',
    addingRelationGender: '',
    editingMember: '',
    adding: false,
    showAddMemberModal: false,
    coveredRelations: [],
  };

  componentDidMount() {
    if (!this.props.authToken) {
      this.props.history.replace('/login?rt=manage-dependents');
      return;
    }
    this.getRelatives();
  }

  hideModal = () => {
    this.setState({
      purchaseSuccess: false,
      showEditMemberModal: false,
      editingMember: null,
      adding: false,
      showAddMemberModal: false,
    });
  };

  getRelatives = () => {
    getAllRelatives(this.props.authToken).then((res) => {
      this.setState({
        memberInfo: res.relatives || [],
        allowAddition: res.allowAddition,
        coveredRelations: res.relations,
      });
    });
  };

  editRelative = (name, phone, age, gender, relativeDetails) => {
    this.setState({
      adding: true,
    });
    const body = {
      relativeId: relativeDetails.id,
      name: relativeDetails.name,
      relationId: relativeDetails.relationId,
      dob: relativeDetails.dob,
      phone: phone,
    };
    console.log(relativeDetails);
    updateRelative(body, this.props.authToken)
      .then((res) => {
        console.log(res);
        this.hideModal();
        this.getRelatives();
      })
      .catch((err) => {
        console.log(err);
      });
    //     const { transactionId } = this.state;
    // addRelative(relativeDetails, transactionId)
    //   .then((res) => {
    //     this.hideModal();
    //     this.getOrderSummary();
    //     logEvent('Visit Prime Relative Saved');
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  addRelative = (relativeDetails) => {
    const { authToken, user } = this.props;
    const { name, dateOfBirth, mobileNumber, relationId } = relativeDetails;
    addNewRelative(
      relationId,
      name,
      dateOfBirth,
      mobileNumber,
      user.userId,
      authToken
    )
      .then((res) => {
        this.hideModal();
        this.getRelatives();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showEditRelativeModal = (member) => () => {
    this.setState({
      showEditMemberModal: true,
      editingMember: member,
    });
  };
  showAddRelativeModal = () => () => {
    this.setState({
      showAddMemberModal: true,
    });
  };

  render() {
    const {
      memberInfo,
      relations,
      editingMember,
      allowAddition,
      coveredRelations,
    } = this.state;
    const getRelationIcon = (relation, gender) => {
      switch (relation) {
        case 'Self':
          if (gender && gender.toLowerCase() === 'male') {
            return maleIcon;
          } else {
            return femaleIcon;
          }
        case 'Spouse':
          if (gender && gender.toLowerCase() === 'male') {
            return maleIcon;
          } else {
            return femaleIcon;
          }
        case 'Child':
          if (gender && gender.toLowerCase() === 'male') {
            return sonIcon;
          } else {
            return daughterIcon;
          }
        case 'Father':
          return fatherIcon;
        case 'Mother':
          return motherIcon;
        default:
          return femaleIcon;
      }
    };
    // const renderAddMemberButton = (relation, gender, key) => {
    //   return (
    //     <div
    //       key={key}
    //       onClick={this.showEditRelativeModal(relation, gender)}
    //       className="cursor-pointer p-16px mb-16px flex items-center justify-between w-100 rounded-xl shadow-card border border-txtsecondary-shades4"
    //     >
    //       <div className="flex items-center">
    //         <img
    //           className="w-32px mr-8px"
    //           src={getRelationIcon(relation, gender)}
    //           alt={relation}
    //         />
    //         <p className="text-body-l text-txtlight font-semibold">
    //           {relation}
    //         </p>
    //       </div>
    //       <div className="flex items-center">
    //         <img src={detailsIcon} alt="Add" />
    //         <p className="text-caption-l text-primary font-semibold">
    //           Add Details
    //         </p>
    //       </div>
    //     </div>
    //   );
    // };
    const renderAddMemberButton = () => {
      return (
        <div
          onClick={this.showAddRelativeModal()}
          className="cursor-pointer p-16px mb-16px flex items-center justify-between w-100 rounded-12 border border-txtsecondary-shades7"
        >
          <div className="flex items-center">
            <p className="text-body-l text-txtlight font-semibold">
              Add new dependent
            </p>
          </div>
          <div className="flex items-center">
            <img src={detailsIcon} alt="Add" />
            <p className="text-caption-l text-primary font-semibold">
              Add Details
            </p>
          </div>
        </div>
      );
    };
    const renderEditMemberButton = (member, idx) => {
      return (
        <div
          key={idx}
          onClick={this.showEditRelativeModal(member)}
          className="cursor-pointer p-16px mb-20px flex items-center justify-between w-100 rounded-12  border border-txtsecondary-shades7"
        >
          <div className="flex items-center">
            <img
              className="w-16px mr-8px"
              src={profileIcon}
              alt={member.relation}
            />
            <p className="text-body-l text-txtlight font-semibold">
              {member.name}
            </p>
            <p className="ml-4px text-body-s text-txtlight font-regular">
              ({member.relation})
            </p>
          </div>
          <div className="flex items-center">
            <img src={editIcon} alt="Add" />
            <p className="ml-4px text-caption-l text-primary font-semibold">
              Edit
            </p>
          </div>
        </div>
      );
    };

    return (
      <div className="w-full overflow-auto">
        <div className="header p-16px pt-0 mt-52px border-b border-txtsecondary-shades4 flex flex-row items-center">
          <img
            src={arrowLeft}
            alt="back"
            onClick={() => {
              this.props.history.goBack();
            }}
          />
          <p className="ml-16px text-title-m text-txtlight font-semibold">
            Manage Dependents
          </p>
        </div>
        <div className="body pt-24px pl-20px pr-20px pb-200px">
          <div className="pb-20px border-b border-txtsecondary-shades4">
            <div className="bg-primary-shades1 bg-opacity-20 p-16px rounded-12">
              <p className="font-regular text-txtlight text-body-s">
                <span className="font-semibold">Note:</span> Benefits for you
                and your dependents may differ{' '}
                {/* <span className="text-primary font-medium">See Benefits</span> */}
              </p>
            </div>
          </div>
          {memberInfo.length > 0 ? (
            <div className="mt-20px">
              <p className="text-txtsecondary text-body-s font-semibold mb-16px">
                ADDED DEPENDENTS
              </p>
              {memberInfo.map(renderEditMemberButton)}
            </div>
          ) : (
            <p className="mt-20px text-txtsecondary text-body-s font-semibold mb-16px">
              You have no dependents added
            </p>
          )}
          {/* {relations.length > 0 && (
            <div className="mt-20px">
              <p className="text-txtsecondary text-body-s font-semibold mb-16px">
                REMAINING DEPENDENTS
              </p>
              {memberInfo.map(renderEditMemberButton)}
            </div>
          )} */}
          {allowAddition ? renderAddMemberButton() : ''}
        </div>

        {this.state.showEditMemberModal && (
          <EditMemberModal
            showModal={this.state.showEditMemberModal}
            editingMember={editingMember}
            hideModal={this.hideModal}
            addRelative={this.editRelative}
            adding={this.state.adding}
          />
        )}
        {this.state.showAddMemberModal && (
          <AddMemberModal
            showModal={this.state.showAddMemberModal}
            hideModal={this.hideModal}
            relations={coveredRelations}
            addRelative={this.addRelative}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  user: state.user,
});

export default connect(mapStateToProps)(ManageDependents);
