import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCovidDashboard } from '../../services';
import LoadingComponent from '../../components/common/LoadingComponent';
import backIcon from '../../images/lab-test/back.svg';
import covidHeader from '../../images/lab-test/covid-header.svg';
import cautionIcon from '../../images/lab-test/report-fail.svg';
import { initializeAmplitudeClient } from '../../services/analytics';
import { logEvent } from '../../utils/logEvent';

class CovidPackages extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      packages: [],
      consultBanners: [],
      packageBanners: [],
      covidHomeCareCards: [],
      header: {},
      isolationBanners: [],
    };
    const { state } = props.location;
    console.log(state, props);
    if (state && state.packageId) {
      console.log(state.packageId);
      this.packageId = state.packageId;
      this.state.packageId = state.packageId;
    }
    if (state && state.goBack) {
      this.state.goBack = state.goBack;
    }
  }

  componentDidMount() {
    // GA Tracking
    window.gtag('event', 'screen_view', {
      screen_name: 'Covid Home Page',
    });
    this.amplitudeClient = initializeAmplitudeClient();
    this.amplitudeClient.init();
    logEvent('Covid Home Page');
    this.getCovidDashboard();
  }

  getCovidDashboard = () => {
    const { authToken } = this.props;
    getCovidDashboard(authToken)
      .then((res) => {
        this.setState({
          header: res.title,
          consultBanners: res.consultBanners || [],
          packageBanners: res.packageBanners,
          covidHomeCareCards: res.covidHomeCareCards || [],
          isolationBanners: res.isolationBanners,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  openPackage = (ele) => {
    if (!this.props.authToken) {
      this.props.history.replace('/login?rt=covid-packages');
      return;
    }
    if (ele.type === 'test-banner') {
      this.props.history.replace(`/lab-test/covid-package/${ele.id}`, {
        fromCovidDashboard: true,
      });
    } else if (ele.type === 'package-banner') {
      this.props.history.replace(`/lab-test/package-details`, {
        fromCovidDashboard: true,
        packageId: ele.packageId,
      });
    }
    logEvent('Covid Package Selected', {
      packageId: ele.id || ele.packageId,
    });
  };

  openIsolationBanner = (ele) => {
    if (ele.type === 'isolation-card') {
      this.props.history.replace(`/lab-test/city-list`, {
        fromCovidDashboard: true,
        packageId: ele.packageId,
      });
    }
  };

  closeView = () => {
    if (typeof window.Android !== 'undefined') {
      return window.Android.closeView();
    }
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'closeView',
        })
      );
    }
  };

  bookCovidConsult = () => {
    logEvent('Covid Specialist Selected');
    if (
      window.Android !== undefined &&
      window.Android.openDoctorVerticalScreen
    ) {
      window.Android.openDoctorVerticalScreen('treat-covid');
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'openDoctorVerticalScreen',
          prereqsType: 'treat-covid',
        })
      );
    }
  };

  openConsult = (ele) => {
    const { history } = this.props;
    if (ele.action === 'covidGuidelinesBanner') {
      this.amplitudeClient.logEvent('Covid Vaccine Guideline Card Click');
      history.push('/lab-test/covid-guidelines', {
        fromCovidDashboard: true,
      });
    }
    if (ele.action === 'showCovidHomeCare') {
      logEvent('Covid Home Care Selected');
      history.push('/covid-home', {
        fromCovidDashboard: true,
      });
    }
    if (ele.action === 'showSwiggyCovidHomeCare') {
      this.amplitudeClient.logEvent('Covid Home Care Selected');
      history.push('/covid-swiggy', {
        fromCovidDashboard: true,
      });
    }
    if (ele.action === 'showGartnerCovidHomeCare') {
      this.amplitudeClient.logEvent('Covid Home Care Selected');
      history.push('/covid-gartner', {
        fromCovidDashboard: true,
      });
    }
    if (ele.action === 'showDoctors') {
      if (window.Android !== undefined && window.Android.openDoctorListScreen) {
        window.Android.openDoctorListScreen(ele.verticalId);
      } else if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            method: 'openDoctorListScreen',
            doctorVerticalId: ele.verticalId,
          })
        );
      }
      logEvent('Covid GP Selected');
    }
    if (ele.action === 'showVerticals') {
      if (
        window.Android !== undefined &&
        window.Android.openDoctorVerticalScreen
      ) {
        window.Android.openDoctorVerticalScreen(ele.preReqTypes);
      } else if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            method: 'openDoctorVerticalScreen',
            prereqsType: ele.preReqTypes,
          })
        );
      }
      logEvent('Covid Specialist Selected');
    }
  };

  render() {
    const {
      consultBanners,
      covidHomeCareCards,
      loading,
      header,
      packageBanners,
      isolationBanners,
    } = this.state;

    return (
      <div
        className="overflow-auto w-full"
        style={{ height: '100vh', background: 'white' }}
      >
        {loading ? (
          <div className=" center h-full h-full">
            <LoadingComponent />
          </div>
        ) : (
          <div className="mb-120px">
            <div
              className="w-full pl-20px pt-48px flex flex-row justify-between items-start bg-primary text-textbox"
              style={{ background: '#01BFBD' }}
            >
              <div className="flex flex-col items-start mb-48px">
                <img
                  src={backIcon}
                  alt="close"
                  className="hover"
                  onClick={() => {
                    this.closeView();
                  }}
                />
                <div className="mt-20px">
                  <p className="text-title-l font-bold mb-6px">
                    {header ? header.name || '' : ''}
                  </p>
                  <p className="text-body-l text-textbox font-semibold opacity-80">
                    Get all the care you need to battle COVID-19
                  </p>
                </div>
              </div>
              <img
                src={header ? header.URL || '' : ''}
                alt="covid-19"
                className="self-end"
                style={{ width: '160px' }}
              />
            </div>
            <div className="px-20px py-24px">
              <div
                className="flex flex-row items-start py-12px px-16px rounded-12"
                style={{ background: '#FFF3EF' }}
              >
                <img
                  src={cautionIcon}
                  width={'16px'}
                  height={'16px'}
                  className="mr-10px opacity-80"
                />
                <p className="text-body-s text-black font-regular">
                  Due to the surge in demand, there might be delay in the test
                  report
                </p>
              </div>
              <div className="flex flex-row flex-wrap justify-between items-start">
                {covidHomeCareCards.map((ele, idx) => {
                  return (
                    <img
                      src={ele.banner_image_url}
                      key={ele.id}
                      alt="package"
                      onClick={() => this.openConsult(ele)}
                      className="hover pt-16px"
                      style={
                        ele.banner_image_orientation === 'vertical'
                          ? {
                              width: '45%',
                              marginRight:
                                idx % 2 === 1 || idx === 0 ? '16px' : 0,
                              flex: 'auto',
                            }
                          : {
                              width: '100%',
                            }
                      }
                    />
                  );
                })}
                {packageBanners.map((ele, idx) => {
                  return (
                    <img
                      src={ele.banner_image_url}
                      key={ele.id || ele.packageId}
                      alt="package"
                      onClick={() => this.openPackage(ele)}
                      className="hover pt-16px"
                      style={
                        ele.banner_image_orientation === 'vertical'
                          ? {
                              width: '48%',
                              maxWidth: '48%',
                              flex: 'auto',
                            }
                          : {
                              width: '100%',
                            }
                      }
                    />
                  );
                })}
                {consultBanners.map((ele, idx) => {
                  return (
                    <img
                      src={ele.banner_image_url}
                      key={ele.id}
                      alt="package"
                      onClick={() => this.openConsult(ele)}
                      className="hover pt-16px"
                      style={
                        ele.banner_image_orientation === 'vertical'
                          ? {
                              width: '45%',
                              marginRight:
                                idx % 2 === 1 || idx === 0 ? '16px' : 0,
                              flex: 'auto',
                            }
                          : {
                              width: '100%',
                            }
                      }
                    />
                  );
                })}
                {isolationBanners && isolationBanners.length > 0
                  ? isolationBanners.map((ele, idx) => {
                      return (
                        <img
                          src={ele.banner_image_url}
                          key={ele.id}
                          alt="package"
                          onClick={() => this.openIsolationBanner(ele)}
                          className="hover pt-16px"
                          style={
                            ele.banner_image_orientation === 'vertical'
                              ? {
                                  width: '45%',
                                  marginRight:
                                    idx % 2 === 1 || idx === 0 ? '16px' : 0,
                                  flex: 'auto',
                                }
                              : {
                                  width: '100%',
                                }
                          }
                        />
                      );
                    })
                  : ''}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  insured: state.app.config.insured,
});

export default connect(mapStateToProps)(CovidPackages);
