import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BalanceBadge } from './Fitcoin';
import TappableCard from './TappableCard';
import HamburgerMenuIcon from '../../images/header/HamburgerMenu.svg';

import NavigationMenu from './NavigationMenu';

const HeaderContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 15px 34px;
  border-bottom: 1px solid #f0f3f6;
`;

const HeaderLogo = styled.img`
  height: 1.875rem;
`;

const HomeHeader = ({ balance, history, showFitCoins, sponsorLogo }) => {
  const [showNavigationMenu, setShowNavigationMenu] = useState(false);

  const hideModal = () => {
    setShowNavigationMenu(false);
  };
  return (
    <HeaderContainer>
      <div>
        <HeaderLogo src={sponsorLogo} alt="Visit Logo" />
      </div>
      {showFitCoins ? (
        <TappableCard
          onTap={() => history.push('/home/rewards')}
          radius={'38px'}
          border={'1px solid #f0f3f6'}
        >
          <BalanceBadge balance={balance} />
        </TappableCard>
      ) : (
        <div></div>
      )}
      {showNavigationMenu && (
        <NavigationMenu
          hideModal={hideModal}
          showNavigationMenu={showNavigationMenu}
          history={history}
        />
      )}
    </HeaderContainer>
  );
};

HomeHeader.propTypes = {
  balance: PropTypes.string.isRequired,
  showFitCoins: PropTypes.bool.isRequired,
};

export default HomeHeader;
