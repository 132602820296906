import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link as Goto } from 'react-router-dom';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';
import { FaArrowLeft } from 'react-icons/fa';

import { verifyOtp, login } from '../../actions/index';
import {
  BoldText,
  RegularText,
  SemiBoldText,
} from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import { LinkText, PrimaryButton } from '../../components/common/Buttons';

import greenTick from '../../images/onboarding/greenTick.svg';
import StyledTappable from '../../components/common/StyledTappable';
import rightGraphic from '../../images/onboarding/onboarding-graphic-right.svg';
import leftGraphic from '../../images/onboarding/onboarding-graphic-left.svg';
import { logEvent } from '../../utils/logEvent';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 1.25rem;
  background: #ffffff;
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
`;

const ScreenIcon = styled.img`
  max-width: 4.265rem;
`;

const Resend = styled(LinkText)`
  color: ${(props) => (props.disabled ? '#EAE5FF' : '#01BFBD')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.5 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  line-height: 36px;
`;

const SubtitleText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  color: #76738c;
  letter-spacing: 0.5px;
  line-height: 24px;
`;

const MobileNumberText = styled(SemiBoldText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  letter-spacing: 0.25px;
  line-height: 19.6px;
  margin-top: 0.4rem;
`;

const TimerText = styled(SubtitleText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  font-weight: 600;
  margin-right: 0.4rem;
`;

const InputContainer = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1.625rem;
`;

const ResendContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: 1.875rem;
  margin-bottom: 1.625rem;
`;

const ResendText = styled(SubtitleText)`
  margin-top: 0;
  font-size: 0.875rem;
  color: #585969;
`;

const StyledButtonText = styled(BoldText)`
  color: white;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
  width: 3rem;
  height: 3rem;
  padding: 0;
  border: 1px solid
    ${(props) => (props.borderColor ? '#6FCF97' : '#E3E3E3')}!important;
  border-radius: 12px;
  text-align: center;
  margin-right: 1.5rem;
`;

const OtpResendContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: 0.875rem;
`;

const SelectIcon = styled.img`
  max-width: 1.25rem;
  align-self: center;
`;

const OtpResendText = styled(ResendText)`
  color: #5cbf3d;
  margin-left: 0.625rem;
`;

class ReverifyOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpValue1: '',
      otpValue2: '',
      otpValue3: '',
      otpValue4: '',
      loading: false,
      resendOtp: false,
      seconds: 59,
      disableEdit: false,
    };
    this.otpValue2 = React.createRef();
    this.handleKeyPress = this.handleKeyPress.bind(this);

    const { location } = props;
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const disableEdit = params.get('disableEdit');
      if (disableEdit) {
        this.state.disableEdit = disableEdit;
      }
    }
    props.dispatch({
      type: 'DONT_SHOW_NAVIGATOR',
      payload: true,
    });
  }

  verifyOtp = () => {
    this.setState({
      loading: true,
    });
    const otp = `${this.state.otpValue1}${this.state.otpValue2}${this.state.otpValue3}${this.state.otpValue4}`;
    const { dispatch, history, location } = this.props;
    console.log(history);
    dispatch(verifyOtp(this.props.phoneNumber, otp, history, location.search));
  };

  handleKeyPress = (event) => {
    // console.log({ event, focus: document.activeElement.id });
    if (event.code === 'Backspace') {
      const currentlyFocusedElement = document.activeElement.id;
      switch (currentlyFocusedElement) {
        case 'input-1':
          if (this.state.otpValue1 === '') {
            document.querySelector('#input-1').focus();
          }
          break;
        case 'input-2':
          if (this.state.otpValue2 === '') {
            document.querySelector('#input-1').focus();
          }
          break;
        case 'input-3':
          if (this.state.otpValue3 === '') {
            document.querySelector('#input-2').focus();
          }
          break;
        case 'input-4':
          if (this.state.otpValue4 === '') {
            document.querySelector('#input-3').focus();
          }
          break;
        default:
          break;
      }
    }
  };

  submitPhoneNumber = () => {
    this.setState({
      resendOtp: true,
      seconds: 59,
    });
    console.log('Phone number submitted ', this.props.phoneNumber);
    const { dispatch, history } = this.props;
    dispatch(login(this.props.phoneNumber, 91, null, history, true, ''));
  };

  componentDidMount = () => {
    document.querySelector('#input-1').focus();

    window.addEventListener('keydown', this.handleKeyPress, false);

    this.myInterval = setInterval(() => {
      const { seconds } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
    }, 1000);
  };

  componentDidUpdate = () => {
    // otp error then change loading back to false
    if (this.state.loading && this.props.appError.hasError) {
      this.setState({
        loading: false,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress, false);
    clearInterval(this.myInterval);
  }

  render() {
    const { history } = this.props;
    const otpValue1Link = Link.state(this, 'otpValue1')
      .pipe((val) => {
        console.log({ val });
        if (val && val.toString().length === 4) {
          this.setState({
            otpValue1: val[0],
            otpValue2: val[1],
            otpValue3: val[2],
            otpValue4: val[3],
          });
          document.querySelector('#input-4').focus();
        } else {
          console.log({ val });
          this.setState({
            otpValue1: val[0] ? val[0] : '',
          });
        }
      })
      .onChange((val) => {
        console.log({ val });
        this.setState({
          otpValue1: val[0],
        });
        if (val.toString() === '') {
          document.querySelector('#input-1').focus();
        } else {
          document.querySelector('#input-2').focus();
        }
      });
    const otpValue2Link = Link.state(this, 'otpValue2').onChange((val, as) => {
      console.log({ val, as });
      this.setState({
        otpValue2: val[0],
      });
      if (val.toString() === '') {
        document.querySelector('#input-1').focus();
      } else {
        document.querySelector('#input-3').focus();
      }
    });
    const otpValue3Link = Link.state(this, 'otpValue3').onChange((val, as) => {
      console.log({ val, as });
      this.setState({
        otpValue3: val[0],
      });
      if (val.toString() === '') {
        document.querySelector('#input-2').focus();
      } else {
        document.querySelector('#input-4').focus();
      }
    });
    const otpValue4Link = Link.state(this, 'otpValue4').onChange((val, as) => {
      console.log({ val, as });
      this.setState({
        otpValue4: val[0],
      });
      if (val.toString() === '') {
        document.querySelector('#input-3').focus();
      }
    });

    const resendOtpContainer = () => {
      let element = null;

      if (this.state.resendOtp) {
        element = (
          <OtpResendContainer>
            <SelectIcon src={greenTick} alt="Success" />
            <OtpResendText>Otp resent successfully.</OtpResendText>
          </OtpResendContainer>
        );
      }

      return element;
    };

    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <ScreenIcon
              src={rightGraphic}
              alt="graphic"
              className="absolute -top-3 left-0"
              style={{ transform: 'scale(-1,-1)' }}
            />
            <ScreenIcon
              src={leftGraphic}
              alt="graphic"
              className="absolute top-16 right-0"
            />
          </HeaderContainer>
          <div className="m-auto">
            <TitleText style={{ color: '#302E6B' }}>Enter OTP</TitleText>
            <SubtitleText>
              An OTP has been sent to the
              <br />
              below number
            </SubtitleText>
            <MobileNumberText style={{ color: '#302E6B' }}>
              {this.props.phoneNumber}
              {!this.state.disableEdit && (
                <span className="text-primary ml-2">
                  <Goto to="/login">edit</Goto>
                </span>
              )}
            </MobileNumberText>
            <InputContainer>
              <StyledInputBox
                type="tel"
                id="input-1"
                className="visit-input"
                valueLink={otpValue1Link}
                borderColor={this.state.otpValue1.length !== 0}
              />
              <StyledInputBox
                type="tel"
                id="input-2"
                maxLength={1}
                className="visit-input"
                valueLink={otpValue2Link}
                borderColor={this.state.otpValue2.length !== 0}
              />
              <StyledInputBox
                type="tel"
                id="input-3"
                maxLength={1}
                className="visit-input"
                valueLink={otpValue3Link}
                borderColor={this.state.otpValue3.length !== 0}
              />
              <StyledInputBox
                type="tel"
                id="input-4"
                maxLength={1}
                className="visit-input"
                valueLink={otpValue4Link}
                borderColor={this.state.otpValue4.length !== 0}
              />
            </InputContainer>
            <ResendContainer>
              <TimerText style={{ color: '#0F0B28' }}>
                00:
                {this.state.seconds > 9
                  ? this.state.seconds
                  : '0' + this.state.seconds}
              </TimerText>
              <ResendText>Didn’t get the OTP?</ResendText>
              <div
                style={{
                  cursor: `${
                    this.state.seconds > 0 ? 'not-allowed' : 'pointer'
                  }`,
                }}
              >
                <Resend
                  onClick={this.submitPhoneNumber}
                  style={{ marginLeft: '0.5rem' }}
                  disabled={this.state.seconds > 0}
                >
                  Resend
                </Resend>
              </div>
            </ResendContainer>
            {!(
              this.state.otpValue1.length === 0 ||
              this.state.otpValue2.length === 0 ||
              this.state.otpValue3.length === 0 ||
              this.state.otpValue4.length === 0
            ) ? (
              <PrimaryButton
                onTap={this.verifyOtp}
                loading={this.state.loading}
                className="w-11/12 absolute bottom-12 left-0 right-0 text-center m-auto"
              >
                <StyledButtonText>Confirm</StyledButtonText>
              </PrimaryButton>
            ) : (
              ''
            )}

            {resendOtpContainer()}
          </div>
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  phoneNumber: state.app.loginNumber,
  countryCode: state.app.loginCountryCode,
  appError: state.app.appError,
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(ReverifyOtp);
