import React, { Component } from 'react';
import { reimbursementCategory } from '../../services';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Header from '../../components/common/Header';

import { FadeInTransition } from '../../components/transitions/transitions';
import dental from '../../images/reimbursement/dental.svg';
import ivf from '../../images/reimbursement/ivf.svg';
import emergency from '../../images/reimbursement/emergency.svg';
import optical from '../../images/reimbursement/optical.svg';
import vaccine from '../../images/reimbursement/vaccine.svg';
import fracture from '../../images/reimbursement/fracture.svg';
import { ButtonText } from '../../components/common/Text';
import { PrimaryButton } from '../../components/common/Buttons';
import CalendarModal from '../../components/reimbursement/CalendarModal';
import StepsModal from '../../components/reimbursement/StepsModal';
import ClaimsList from '../../images/reimbursement/claims-list.svg';
import RightArrow from '../../images/reimbursement/right-arrow.svg';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { setCurrentActiveTab } from '../../actions';
const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background: #fff;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 20px;
`;

export class ReimbursementStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      topNote: '',
      showCalendarModal: false,
      stepsModal: false,
      showPatientModel: false,
      currentSelectedReimbursement: '',
      showSelectCategoryProcedure: false,
      selectCategory: [],
      patientDetailModel: false,
      selectedCategory: null,
      selectedPatient: null,
      selectedDate: null,
      seltectedCategoryPatient: null,
      showAdditionalCommentModal: false,
      attachreimbursePicture: false,
      additionalCommen: '',
    };
  }
  componentDidMount() {
    this.reimbursementCategory();
    this.props.dispatch(setCurrentActiveTab('my-claims'));
  }
  reimbursementCategory = () => {
    reimbursementCategory(this.props.authToken)
      .then((res) => {
        this.setState({
          category: res.result,
          topNote: res.topNote,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  categoryIcon(name) {
    switch (name) {
      case 'fracture':
        return fracture;
      case 'ivf':
        return ivf;
      case 'emergency':
        return emergency;
      case 'optical':
        return optical;
      case 'dental':
        return dental;
      case 'vaccine':
        return vaccine;
      default:
        return emergency;
    }
  }

  onProceed = (ele) => {
    if (this.props.showReimbursment) {
      this.setState({
        showCalendarModal: true,
        currentSelectedReimbursement: ele,
      });
    } else {
      this.props.history.push('/reimbursement/request', {
        category: ele,
      });
    }
  };

  hideStepsModal = () => {
    this.setState({
      stepsModal: false,
    });
  };

  onDateSelection = () => {
    this.hideModal();
    const categoryName = this.state.currentSelectedReimbursement.categoryName;
    const selectedDate = this.state.selectedDate;
    if (
      moment().diff(moment(selectedDate), 'days') <= 0 &&
      (categoryName === 'labs' || categoryName === 'consult')
    ) {
      if (categoryName === 'labs') {
        return this.props.history.push('/lab-test');
      } else if (categoryName === 'consult') {
        return this.props.history.push('/consultation/preview');
      }
    } else {
      console.log('hereee');
      this.props.history.push('/reimbursement/preview-works', {
        currentSelectedReimbursement: this.state.currentSelectedReimbursement,
        selectedDate: this.state.selectedDate,
      });
    }
  };

  onPatientProceed = () => {
    this.setState({
      showPatientModel: true,
    });
    this.hideStepsModal();
  };

  hideModal = () => {
    this.setState({
      showCalendarModal: false,
    });
  };

  setSlectedReimburseDate = (data) => {
    this.setState({
      selectedDate: data,
    });
  };

  render() {
    console.log('mainstate', this.state);
    const { category } = this.state;
    const renderCategory = (ele) => {
      return (
        <div
          key={ele.categoryId}
          className="flex flex-col rounded-12 border border-boxCard p-16px mt-24px"
        >
          <div className="flex flex-row">
            <img
              src={ele.icon}
              height={'32px'}
              width={'32px'}
              style={{
                height: '32px',
                width: '32px',
              }}
              alt="category"
            />
            <div className="flex flex-col ml-8px">
              <p className="text-body-l font-semibold text-txtlight mb-4px">
                {ele.title}
              </p>
              <p className="text-body-s font-regular text-custom-413958">
                {ele.description}
              </p>
            </div>
          </div>
          <div style={{ alignSelf: 'stretch' }} className="mt-20px">
            <PrimaryButton color={'#01BFBD'} onTap={() => this.onProceed(ele)}>
              <ButtonText>Reimburse</ButtonText>
            </PrimaryButton>
          </div>
        </div>
      );
    };
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <Header
            title="Reimbursement"
            onTap={() => {
              this.props.history.goBack();
            }}
          />
          <BodyContainer>
            <div>
              <div className="bg-custom01BFBD bg-opacity-10 rounded-12 p-16px text-body-l text-txtlight font-regular">
                {this.state.topNote}
              </div>
              <div className="flex flex-col rounded-12 border border-boxCard p-16px mt-24px">
                <Link to="/claims">
                  <div className="flex flex-row">
                    <img
                      src={ClaimsList}
                      alt="claim"
                      height={'42px'}
                      width={'42px'}
                      style={{
                        height: '42px',
                        width: '42px',
                      }}
                    />
                    <div className="flex flex-row items-center">
                      <span className="text-title-m ml-10px font-semibold p-64px text-txtlight">
                        My Claims
                      </span>
                    </div>
                    <img
                      src={RightArrow}
                      alt="claim-arrow"
                      className="ml-auto"
                    />
                  </div>
                </Link>
              </div>
              <div className="flex flex-col">
                {category.map(renderCategory)}
              </div>
            </div>
          </BodyContainer>
          {this.state.showCalendarModal ? (
            <CalendarModal
              hideModal={this.hideModal}
              onDateSelection={this.onDateSelection}
              selectedDate={(data) => this.setSlectedReimburseDate(data)}
              currentSelectedReimbursement={
                this.state.currentSelectedReimbursement
              }
            />
          ) : (
            ''
          )}
          {this.state.stepsModal && ''}
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.user.authToken,
    showReimbursment: state.app.config.showReimbursment,
  };
};

export default connect(mapStateToProps)(ReimbursementStart);
