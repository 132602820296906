import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import LogoutIcon from '../../images/accounts/Logout.svg';
import { FaChevronRight } from 'react-icons/fa';
import { BoldText } from '../../components/common/Text';
import ChatIcon from '../../images/accounts/ChatSupport.svg';
import TermIcon from '../../images/accounts/TC.svg';
import { logout, setTicketingUrl } from '../../actions';
import { setPlaceholderImageForNodeUsingGender } from '../../utils/placeholder';
import { initializeAmplitudeClient } from '../../services/analytics';
import initializeFreshDesk from '../../utils/freshchat';
import { logEvent } from '../../utils/logEvent';
import FaqIcon from '../../images/accounts/faq.svg';
import InfoIcon from '../../images/accounts/Info.svg';
import EditProfileIcon from '../../images/edit-profile.png';
import { withRouter } from 'react-router-dom';
import MaleIcon from '../../images/accounts/Male.png';
import FemaleIcon from '../../images/accounts/Female.png';
import OtherIcon from '../../images/accounts/Other.png';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 375px;
  max-width: 375px;
  border-radius: 16px;
  float: right;
`;

const BlockContainer = styled.div`
  display: flex;
  padding: 20px 0px 20px 0px;
  align-items: center;
  flex-direction: column;
`;

const ItemContainers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 35px;
  width: 100%;
  cursor: pointer;
  padding: 16px 30px 16px 30px;
  background: #ffffff;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  background: #ffffff;
  padding: 16px 30px 16px 30px;
  border-radius: 16px;
  margin-bottom: 8px;
`;

const ItemText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #0f0b28;
`;

const RightArrowIcon = styled(FaChevronRight)`
  width: 6px;
  color: #a5a6bb;
`;

const RightArrowOrange = styled(FaChevronRight)`
  width: 6px;
  color: #e86570;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background: #ffffff;
  padding: 24px;
  height: 124px;
  width: 100%;
`;

const ProfileImage = styled.img`
  width: 96px;
  margin-left: -16px;
`;

const UserDetails = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #585969;
  opacity: 0.8;
`;

const NameText = styled(BoldText)`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #0f0b28;
`;

const MobileNumber = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #585969;
  opacity: 0.8;
`;

class AccountSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogoutButton: false,
    };
  }

  componentDidMount = () => {
    logEvent('Accounts Tab', {});
    this.props.dispatch(setTicketingUrl(this.props.config.ticketingUrl));
    window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
      .then(() => {})
      .catch(() => {
        // either phonepe SDK does not exist, or something went wrong fetching grant token
        this.setState({
          showLogoutButton: true,
        });
      });
  };

  openSupport = () => {
    const { ticketingEnabled, ticketingUrl } = this.props.config;
    const { authToken } = this.props.user;
    logEvent('Customer Support Button Clicked');
    if (ticketingEnabled) {
      window.open(`${ticketingUrl}?token=${authToken.split(' ')[1]}`, '_blank');
    } else {
      initializeFreshDesk(this.props.user);
    }
  };

  render() {
    const {
      userName,
      userGender,
      userAge,
      userPhone,
      countryCode,
      profileImage,
    } = this.props.user;
    const { history, dispatch, config } = this.props;
    const renderThirdBlock = () => {
      const { ticketingEnabled, ticketingUrl } = this.props.config;
      if (ticketingEnabled) {
        return (
          <BlockContainer style={{ borderRadius: '16px' }}>
            {ticketingEnabled && (
              <ItemContainers
                onClick={this.openSupport}
                style={{ borderRadius: '16px 16px 0px 0px' }}
              >
                <div className="flex flex-row items-center">
                  {/* <img
                    src={ChatIcon}
                    style={{
                      marginRight: '15px',
                      width: '22px',
                      height: '22px',
                    }}
                  /> */}
                  <ItemText>Contact Support</ItemText>
                </div>
                <RightArrowIcon />
              </ItemContainers>
            )}

            <ItemContainers
              onClick={() =>
                window.open('https://getvisitapp.com/terms.html', 'blank')
              }
            >
              <div className="flex flex-row items-center">
                {/* <img
                  src={TermIcon}
                  style={{
                    marginRight: '15px',
                    width: '22px',
                    height: '22px',
                  }}
                /> */}
                <ItemText>Terms & Conditions</ItemText>
              </div>
              <RightArrowIcon />
            </ItemContainers>

            <ItemContainers
              onClick={() =>
                window.open(
                  'https://getvisit.atlassian.net/wiki/spaces/VH/overview',
                  'blank'
                )
              }
            >
              <div className="flex flex-row items-center">
                {/* <img
                  src={FaqIcon}
                  style={{
                    marginRight: '15px',
                    width: '22px',
                    height: '22px',
                  }}
                /> */}
                <ItemText>FAQ</ItemText>
              </div>
              <RightArrowIcon />
            </ItemContainers>

            <ItemContainers
              style={{ borderRadius: '0px 0px 16px 16px' }}
              onClick={() => window.open('https://getvisitapp.com/', 'blank')}
            >
              <div className="flex flex-row items-center">
                {/* <img
                  src={InfoIcon}
                  style={{
                    marginRight: '15px',
                    width: '20px',
                    height: '20px',
                  }}
                /> */}
                <ItemText>About Visit</ItemText>
              </div>
              <RightArrowIcon />
            </ItemContainers>
          </BlockContainer>
        );
      }
    };
    return (
      <OuterContainer>
        <UserContainer style={{ borderRadius: '16px' }}>
          <div
            className="flex flex-row items-center justify-between"
            style={{ width: '100%', height: '80%' }}
          >
            <div className="flex flex-row items-center">
              <ProfileImage
                src={
                  userGender === 'Male'
                    ? MaleIcon
                    : userGender === 'Female'
                    ? FemaleIcon
                    : userGender === 'Other'
                    ? OtherIcon
                    : MaleIcon
                }
              />
              <div className="flex flex-col">
                <NameText>{userName}</NameText>
                <UserDetails>
                  {userGender}, {userAge} years
                </UserDetails>
                <MobileNumber>{`${countryCode} ${userPhone}`}</MobileNumber>
              </div>
            </div>
            <div
              onClick={() => this.props.history.push('/edit-account')}
              style={{ cursor: 'pointer' }}
            >
              <img src={EditProfileIcon} width="24px" height="24px" />
            </div>
            {/* <div
              onClick={() => {
                if (this.props.openEditAccount) {
                  this.props.openEditAccount();
                } else {
                  this.props.history.push('/home/edit-account');
                }
              }}
            >
              <img src={EditProfileIcon} width="24px" height="24px" />
            </div> */}
          </div>
        </UserContainer>
        {renderThirdBlock()}
        {this.state.showLogoutButton && (
          <ItemContainer
            onClick={() => {
              initializeAmplitudeClient().logoutUser();
              dispatch(logout(history));
              logEvent('Onboarding User Logged Out');
              history.replace('/');
            }}
          >
            <div className="flex flex-row items-center">
              <img
                src={LogoutIcon}
                style={{
                  marginRight: '15px',
                  width: '24px',
                  height: '24px',
                }}
              />
              <ItemText style={{ color: '#E86570' }}>Sign Out</ItemText>
            </div>
            <RightArrowOrange />
          </ItemContainer>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  config: state.app.config,
});

export default withRouter(connect(mapStateToProps)(AccountSection));
