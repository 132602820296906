import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import arrowLeft from '../../images/common/arrow-left.svg';
import {
  BoldText,
  SemiBoldText,
  Text,
  LinkText,
} from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import { PrimaryButton } from '../../components/common/Buttons';
import Modal from '../../containers/common/Modal';
import { FlyInTransition } from '../../components/transitions/transitions';

import rightGraphic from '../../images/onboarding/onboarding-graphic-right.svg';
import corporateIcon from '../../images/onboarding/new-corporate-icon.svg';
import userIcon from '../../images/onboarding/new-user-icon.svg';
import { updateLoginState } from '../../actions';
import { logEvent } from '../../utils/logEvent';

const OuterContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 1.25rem;
  background: #ffffff;
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
`;

const ScreenIcon = styled.img`
  max-width: 4.265rem;
`;

const BackContainer = styled(PrimaryButton)`
  background-color: #f0f3f6;
  padding: 0;
  width: 2rem;
  position: absolute;
  right: 83%;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.5 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  word-break: break-all;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  line-height: 36px;
`;

const StyledButtonText = styled(BoldText)`
  color: ${(props) => (props.color ? props.color : 'white')};
  line-height: 19.6px;
  font-size: 0.875rem;
  letter-spacing: 0.25px;
`;

const Card = styled.div`
  border: 1px solid #e6e9ec;
  border-radius: 12px;
  padding: 1.25rem;
  margin-bottom: 1.5rem;
`;

const CardText = styled(SemiBoldText)`
  font-size: 1rem;
  line-height: 24px;
  margin: 0.5rem 0 1.5rem 0;
  color: #0f0b28;
`;

const ModalContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  rounded: 8px 8px 0 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
`;

const StyledCheckbox = styled.input`
  width: 1.5rem;
  height: 1.2rem;
  border: 1px solid #a5a6bb;
  border-radius: 6px;
  align-self: flex-start;
  &:checked {
    accent-color: #01bfbd;
  }
`;

const ModalDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 1.5rem 2rem 0rem 2.5rem;
  @media (max-width: 768px) {
    padding: 1.5rem 2rem 0rem 2.5rem;
  }
`;

const ModalText = styled.div`
  align-self: flex-start;
  font-size: 12px;
  color: #0f0b28;
  margin-left: 0.5rem;
`;

class SelectUserType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalDetails: { showModa: false, redirectTo: '' },
      isChecked: false,
    };
  }

  componentDidMount = () => {
    const { history, loginState, dispatch } = this.props;
    if (loginState === 'LOGGED_OUT') {
      history.push('/');
      return false;
    } else if (loginState === 'SPONSOR_EMAIL') {
      history.push('/corporate-onboard');
      return false;
    } else if (loginState === 'LOGGED_IN') {
      history.push('/home');
      return false;
    } else if (loginState === 'ONE_LAST_STEP') {
      history.push('/one-last-step');
      return false;
    } else {
      // GA Tracking
      window.gtag('event', 'screen_view', {
        screen_name: 'Login Option Screen',
      });
    }
    logEvent('Onboarding Login Option Screen');
  };

  render() {
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <BackContainer
              loading={this.state.loading}
              onTap={() => this.props.history.push('/select-user')}
            >
              <StyledButtonText>
                <ScreenIcon src={arrowLeft} alt="left arrow" />
              </StyledButtonText>
            </BackContainer>
            <ScreenIcon
              src={rightGraphic}
              alt="graphic"
              className="absolute -top-4 right-0"
            />
          </HeaderContainer>
          <div className="mx-auto my-14 w-11/12">
            <TitleText style={{ color: '#302E6B' }}>
              How would you like to
              <br />
              proceed?
            </TitleText>
            <Card>
              <ScreenIcon src={corporateIcon} alt="corporate"></ScreenIcon>
              <CardText>
                Does your organization/corporate
                <br /> offer VISIT Health Benefits?
              </CardText>
              <PrimaryButton
                // onTap={()=>this.props.history.push('/corporate-onboard')}
                onTap={() => {
                  this.props.dispatch(updateLoginState('SPONSOR_EMAIL'));
                  this.props.history.push('/corporate-onboard');
                }}
                loading={this.state.loading}
                className="text-center"
              >
                <StyledButtonText>I am Sponsored User</StyledButtonText>
              </PrimaryButton>
            </Card>
            <Card>
              <ScreenIcon src={userIcon} alt="user"></ScreenIcon>
              <CardText>
                Not a sponsored user?
                <br /> Continue using the VISIT App
              </CardText>
              <PrimaryButton
                // onTap={()=>{
                // // this.props.dispatch(updateLoginState('ONE_LAST_STEP'));
                // // this.props.history.push('/one-last-step');
                // }}
                onTap={() => {
                  this.props.dispatch(updateLoginState('ONE_LAST_STEP'));
                  this.setState({
                    modalDetails: {
                      showModal: true,
                      redirectTo: '/one-last-step',
                    },
                  });
                }}
                loading={this.state.loading}
                className="text-center"
              >
                <StyledButtonText>Continue Signup</StyledButtonText>
              </PrimaryButton>
            </Card>
            {this.state.modalDetails.showModal && (
              <Modal>
                <ModalContainer>
                  <FlyInTransition
                    in={this.state.modalDetails.showModal}
                    mountOnEnter
                    unmountOnExit
                    appear
                  >
                    <div
                      className="modalBody"
                      ref={(node) => (this.node = node)}
                    >
                      <ModalDetails className="modalDetails">
                        <StyledCheckbox
                          type="checkbox"
                          checked={this.state.isChecked}
                          onChange={() =>
                            this.setState({ isChecked: !this.state.isChecked })
                          }
                        ></StyledCheckbox>
                        <ModalText>
                          I have read and accept the{' '}
                          <a href="https://getvisitapp.com/terms.html">
                            <LinkText
                              style={{ display: 'inline', fontSize: '12px' }}
                            >
                              Terms and Condtions
                            </LinkText>
                          </a>
                          <br />
                          <br />I have read and accept the{' '}
                          <a href="https://getvisitapp.com/privacy.html">
                            <LinkText
                              style={{ display: 'inline', fontSize: '12px' }}
                            >
                              Privacy Policy
                            </LinkText>
                          </a>{' '}
                          and agree to processing of sensitive personal data
                        </ModalText>
                      </ModalDetails>
                      {this.state.isChecked && (
                        <PrimaryButton
                          onTap={() => {
                            const { redirectTo } = this.state.modalDetails;
                            this.props.dispatch(
                              updateLoginState('ONE_LAST_STEP')
                            );
                            this.props.history.push(redirectTo);
                          }}
                          className=" w-11/12 m-auto mb-8 text-center"
                        >
                          <StyledButtonText>Next</StyledButtonText>
                        </PrimaryButton>
                      )}
                    </div>
                  </FlyInTransition>
                </ModalContainer>
              </Modal>
            )}
          </div>
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  appError: state.app.appError,
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(SelectUserType);
