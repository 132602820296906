import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { updateLoginState, loginMagicLink } from '../../actions/index';
import { Text, BoldText } from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import rightGraphic from '../../images/onboarding/onboarding-graphic-right.svg';
import leftGraphic from '../../images/onboarding/onboarding-graphic-left.svg';
import Carousel from '../../components/common/NewCarousel';
import Modal from '../common/Modal';
import LoadingComponent from '../../components/common/LoadingComponent';

import heroImage1 from '../../images/onboarding/new-hero-1.svg';
import heroImage2 from '../../images/onboarding/new-hero-2.svg';
import heroImage3 from '../../images/onboarding/new-hero-3.svg';
import heroImage4 from '../../images/onboarding/new-hero-4.svg';
import heroImage5 from '../../images/onboarding/new-hero-5.svg';
import chatIcon from '../../images/onboarding/chat.svg';
import stethoscopeIcon from '../../images/onboarding/stethoscope.svg';
import beakerIcon from '../../images/onboarding/beaker.svg';
import tabletIcon from '../../images/onboarding/tablets.svg';
import { logEvent } from '../../utils/logEvent';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #ffffff;
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
`;

const ScreenIcon = styled.img`
  max-width: 4.265rem;
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: stretch;
  align-self: stretch;
  overflow-x: hidden;
`;

const BannerImageContainer = styled.div`
  width: ${(props) => props.width};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  flex-shrink: 0;
`;

const HeroImage = styled.img`
  width: 50%;
  max-width: 12.5rem;
  @media (max-width: 768px) {
    min-width: 70%;
  }
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #0f0b28;
  margin-top: 2.25rem;
  text-align: center;
  line-height: 1.8rem;
`;

const SubtitleText = styled(Text)`
  font-size: ${() => `${0.9 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #302e6b;
  margin-top: 1rem;
  text-align: center;
  width: 80%;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
`;

class OnboardingCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoadingModal: false,
    };
  }

  componentDidMount = () => {
    const { dispatch, history, location, loginState } = this.props;
    logEvent('Onboarding Intro Screen');
    console.log(loginState);
    console.log(this.props.location.state);
    window.baseLandingUrl = window.location.href;
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    const magicLink = searchParams.get('mluib7c');
    if (magicLink) {
      logEvent('Onboarding Magic Code Applied');
      this.setState({ showLoadingModal: true });
      dispatch(updateLoginState('LOGGED_OUT'));
      dispatch({
        type: 'UNSET_USER_INFO',
      });
      dispatch(loginMagicLink(magicLink, history, searchParams.get('tab')));
    } else if (loginState === 'LOGGED_IN') {
      history.replace('/home');
    } else if (loginState === 'ONE_LAST_STEP') {
      history.push('/one-last-step');
    } else if (loginState === 'SPONSOR_EMAIL') {
      history.push('/corporate-onboard');
      return false;
    } else if (loginState === 'SELECT_USER') {
      history.push('/select-user');
    } else {
      // GA Tracking
      logEvent('Onboarding Carousel Screen');
    }
  };

  onNext = () => {
    window.gtag('event', 'conversion', {
      send_to: 'AW-753807922/L-FUCPC27ZgBELLkuOcC',
    });
    const { history, location } = this.props;
    history.push(`/login${location.search}`);
  };

  render() {
    return (
      <FadeInTransition
        in={true}
        timeout={2000}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <ScreenIcon
              src={rightGraphic}
              alt="graphic"
              className="absolute top-0 right-0"
            />
            <ScreenIcon
              src={leftGraphic}
              alt="graphic"
              className="absolute top-16 left-0"
            />
          </HeaderContainer>
          <div className="my-20" style={{ width: '100%' }}>
            <CarouselContainer>
              <Carousel autoplay showDots history={this.props.history}>
                <BannerImageContainer
                  width={`${Math.min(document.body.clientWidth, 440)}px`}
                >
                  <TitleText>
                    Consult Health Specialists
                    <br /> Anytime, Anywhere
                  </TitleText>
                  <HeroImage src={heroImage2} />
                  <SubtitleText>
                    Consult top Health Specialists over Video, Voice or Chat
                    anytime
                    {/* <div className="flex text-left">
                      <ScreenIcon
                        src={stethoscopeIcon}
                        className="self-start pr-2"
                      />
                      Unlimited offline consultations Upto
                      <br /> &#8377;1000
                    </div> */}
                  </SubtitleText>
                </BannerImageContainer>
                <BannerImageContainer
                  width={`${Math.min(document.body.clientWidth, 440)}px`}
                >
                  <TitleText>
                    Labs Tests &
                    <br /> Medicine Delivery
                    <br /> at your doorstep
                  </TitleText>
                  <HeroImage src={heroImage3} />
                  <SubtitleText>
                    Get Medicines Delivered and Lab Tests Done from the comfort
                    of your home
                  </SubtitleText>
                </BannerImageContainer>
                <BannerImageContainer
                  width={`${Math.min(document.body.clientWidth, 440)}px`}
                >
                  <TitleText>
                    Wellness workouts and
                    <br /> Meditation podcasts
                  </TitleText>
                  <HeroImage src={heroImage4} />
                  <SubtitleText>
                    Stay fit from home with wide range of Fitness Workout
                    playlists and Mindfulness podcast
                  </SubtitleText>
                </BannerImageContainer>
                <BannerImageContainer
                  width={`${Math.min(document.body.clientWidth, 440)}px`}
                >
                  <TitleText style={{ marginBottom: '8px' }}>
                    Hospitalisation Coverage <br />
                    and Reimbursements
                  </TitleText>
                  <HeroImage src={heroImage5} />
                  <SubtitleText>
                    Cashless Hospitalisations and Fast Claim Reimbursements for
                    the covered members
                  </SubtitleText>
                </BannerImageContainer>
                <BannerImageContainer
                  width={`${Math.min(document.body.clientWidth, 440)}px`}
                >
                  <TitleText>
                    Stay Motivated by <br /> Winning Rewards
                  </TitleText>
                  <HeroImage src={heroImage1} />
                  <SubtitleText>
                    Win Fitcoins by completing challenges and tracking your
                    fitness
                  </SubtitleText>
                </BannerImageContainer>
              </Carousel>
            </CarouselContainer>
          </div>
          {this.state.showLoadingModal && (
            <Modal>
              <ModalContainer>
                <LoadingComponent color="white" />
              </ModalContainer>
            </Modal>
          )}
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(OnboardingCarousel);
