import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaArrowLeft, FaChevronRight } from 'react-icons/fa';

import Tappable from 'react-tappable/lib/Tappable';

import {
  fetchPreviousOnlineConsultation,
  getAllRelatives,
  fetchConsultationBlocker,
  getConsultationSummary,
  getScheduledConsultSummary,
} from '../../services/consultation';
import { FadeInTransition } from '../../components/transitions/transitions';
import TappableCard from '../../components/common/TappableCard';
import StyledTappable from '../../components/common/StyledTappable';
import ConsultationUser from '../../components/consultations/ConsultationUser';
import OnlineConsultationCard from '../../components/consultations/OnlineConsultationCard';
import NewOnlineConsultationCard from '../../components/consultations/new-flow/ConsultationOrderCard';
import FAB from '../../components/common/FAB';
import { Text, ButtonText } from '../../components/common/Text';
import RelativeSelect from '../../components/consultations/RelativeSelect';
import { resetChat, setCurrentActiveTab } from '../../actions';
import { logEvent } from '../../utils/logEvent';
import noConsultations from '../../images/no-consultations.svg';
import LoadingComponent from '../../components/common/LoadingComponent';
import Header from '../../components/hospitalization/header';
import { PrimaryButton } from '../../components/common/Buttons';
import noAppointment from '../../images/consultations/new-flow/medical-appointment.png';
import cartIcon from '../../images/consultations/new-flow/cart-icon.svg';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #f8f8f8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 16px;
  z-index: 10;
`;

const BackContainer = styled(Tappable)`
  margin-right: 3px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5rem;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  background: white;
  position: relative;
`;

class OnlineConsultationStartPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verticals: [],
      showVerticalsLeftScroll: false,
      showVerticalsRightScroll: true,
      ongoingAppointment: [],
      completedAppointment: [],
      cancelledAppointment: [],
      scheduledAppointment: [],
      cards: [],
      loading: true,
      relatives: [],
      selectedRelative: null,
      showRelativeModal: false,
      blockers: {},
      from: 'home',
    };
    this.verticalScrollContainerRef = React.createRef();
    const { state } = this.props.location;
    if (state && state.openConsultId) {
      this.state.openConsultId = parseInt(state.openConsultId);
    }
    if (state && state.from === 'home') {
      props.dispatch(setCurrentActiveTab('home'));
    } else {
      props.dispatch(setCurrentActiveTab('online-orders'));
    }
  }

  componentDidMount() {
    // GA tracking
    window.gtag('event', 'screen_view', {
      screen_name: 'Consult Screen',
    });
    this.props.dispatch(resetChat());
    logEvent('Online Consultations Home Screen');
    this.setState({
      loading: true,
    });
    this.getAllRelatives();
    this.fetchConsultationBlocker();
    this.getConsults('all');
    // if (this.state.from === 'home') {
    //   this.props.dispatch(setCurrentActiveTab('home'));
    // } else {
    //   this.props.dispatch(setCurrentActiveTab('online-orders'));
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location.hash === '#profile' &&
      this.props.location.hash !== '#profile'
    ) {
      this.closeProfile();
      return;
    }

    if (
      prevState.selectedRelative &&
      !this.state.selectedRelative &&
      this.state.selectedRelative !== prevState.selectedRelative
    ) {
      this.getConsults('all');
    }
    if (
      this.state.selectedRelative &&
      this.state.selectedRelative !== prevState.selectedRelative &&
      !this.state.loading
    ) {
      this.getConsults();
    }
  }

  openOrder(order) {
    this.props.dispatch(resetChat());
    const { consultationId } = order;
    const { history, authToken, userId } = this.props;
    this.setState({
      openConsultId: null,
    });
    if (order.type === 'reimbursement') {
      history.push('/consultation/reimbursement-details', {
        reimbursementId: order.reimbursementId,
        goBack: true,
      });
    } else if (order.isScheduledConsult) {
      getScheduledConsultSummary(consultationId, authToken)
        .then((res) => {
          console.log(res);
          history.push('/consultation/online/appointment-status', {
            doctorInfo: res.data.doctorInfo,
            appointmentDateTime: res.data.appointmentDateTime,
            patientInfo: res.data.patientInfo,
            paymentInfo: res.data.paymentLine,
            cancelledAt: res.data.cancelledAt,
            scheduledConsultationId: res.data.scheduledConsultationId,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getConsultationSummary(consultationId, userId, authToken)
        .then((res) => {
          const { consultationInfo } = res;
          history.push('/consultation/chat', {
            consultationId: consultationInfo.consultationId,
            channel: consultationInfo.chatChannel,
            doctorName: consultationInfo.doctorName,
            doctorVertical: consultationInfo.doctorDegrees,
            doctorId: consultationInfo.doctorId,
            pendingRequest: consultationInfo.isOver === false,
            requestId: consultationInfo.requestId,
            profileImg: consultationInfo.doctorImg,
            isFreeConsult: consultationInfo.freeConsult,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  onNext = () => {
    const { history } = this.props;
    const { selectedRelative, selectedIssues, selectedIssuesIndex } =
      this.state;
    if (this.searchType === 'treat') {
      history.push('/consultation/request-summary', {
        relative: selectedRelative,
        selectedIssues,
        selectedIssuesIndex,
      });
    } else {
      // Specialist search. Take directly to search screen
      history.push('/consultation/specialist-search', {
        issueIds: selectedIssuesIndex,
        onlineFilters: this.filters.ONLINE,
        offlineFilters: this.filters.OFFLINE,
        showOnlineAndOffline: true,
        consultRequestInfo: {
          selectedIssuesIndex,
          relative: selectedRelative,
        },
      });
    }
  };

  onVerticalListScrolled = (event) => {
    const { showVerticalsLeftScroll, showVerticalsRightScroll } = this.state;
    if (event.target.scrollLeft > 0) {
      !showVerticalsLeftScroll &&
        this.setState({
          showVerticalsLeftScroll: true,
        });
    } else {
      showVerticalsLeftScroll &&
        this.setState({
          showVerticalsLeftScroll: false,
        });
    }
    if (
      event.target.scrollLeft + event.target.clientWidth <
      event.target.scrollWidth
    ) {
      !showVerticalsRightScroll &&
        this.setState({ showVerticalsRightScroll: true });
    } else {
      showVerticalsRightScroll &&
        this.setState({ showVerticalsRightScroll: false });
    }
  };

  smoothScroll(ele, left, reverse) {
    const start = performance.now();
    const duration = 300;
    const startValue = ele.scrollLeft;
    const scrollPerTimestamp = Math.abs(left - startValue) / duration;
    const multiplier = reverse ? 1 : -1;
    const animate = (timestamp) => {
      const progress = timestamp - start;
      ele.scrollTo(startValue + multiplier * scrollPerTimestamp * progress, 0);
      if (progress < duration) {
        requestAnimationFrame(animate);
      }
    };
    requestAnimationFrame(animate);
  }

  scrollVerticalsLeft = () => {
    const { scrollLeft, clientWidth } = this.verticalScrollContainerRef.current;
    if (scrollLeft === 0) {
      return;
    }
    if (scrollLeft < clientWidth / 2) {
      this.smoothScroll(this.verticalScrollContainerRef.current, 0);
      return;
    }
    this.smoothScroll(
      this.verticalScrollContainerRef.current,
      scrollLeft - clientWidth / 2
    );
  };

  scrollVerticalsRight = () => {
    const { scrollLeft, clientWidth, scrollWidth } =
      this.verticalScrollContainerRef.current;
    if (scrollLeft + clientWidth >= scrollWidth) {
      return;
    }
    if (scrollWidth - scrollLeft + clientWidth < clientWidth / 2) {
      this.smoothScroll(
        this.verticalScrollContainerRef.current,
        scrollWidth - clientWidth,
        true
      );
      return;
    }
    this.smoothScroll(
      this.verticalScrollContainerRef.current,
      scrollLeft + clientWidth / 2,
      true
    );
  };

  getAllRelatives = () => {
    const { authToken, user } = this.props;
    getAllRelatives(authToken)
      .then((res) => {
        const self = {
          age: user.userAge,
          dob: user.dateOfBirth,
          gender: user.userGender,
          id: 'self',
          name: user.userName,
          phone: user.userPhone,
          relation: user.userType,
        };
        const relatives = [self, ...res.relatives];
        this.setState({
          relatives,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getConsults(id = null) {
    const { authToken } = this.props;

    if (!id) {
      id =
        this.state.selectedRelative && this.state.selectedRelative.id
          ? this.state.selectedRelative.id
          : 'all';
    }
    fetchPreviousOnlineConsultation(id, authToken)
      .then((res) => {
        const cards = res.cards;
        const ongoingAppointment = res.consultations.filter((consultation) => {
          return consultation.daysRemaining > 0 && !consultation.cancelled;
        });
        const completedAppointment = res.consultations.filter(
          (consultation) => {
            return consultation.daysRemaining === 0 && !consultation.cancelled;
          }
        );
        const cancelledAppointment = res.consultations.filter(
          (consultation) => consultation.cancelled
        );
        const scheduledAppointment = res.consultations.filter(
          (consultation) =>
            consultation.isScheduledConsult && !consultation.cancelled
        );

        if (this.state.openConsultId && res.consultations.length > 0) {
          console.log(true);
          const orderToOpen = res.consultations.filter((ele) => {
            return ele.consultationId === this.state.openConsultId;
          });
          console.log(orderToOpen, res.consultations);
          if (orderToOpen.length === 1) {
            console.log(true);
            this.openOrder(orderToOpen[0]);
          }
        }

        if (res.consultations.length === 0 && this.state.selectedRelative) {
          this.setState({ selectedRelative: null });
        }
        this.setState(
          {
            completedAppointment,
            ongoingAppointment,
            cancelledAppointment,
            scheduledAppointment,
            cards,
          },
          () => {
            this.setState({ loading: false });
          }
        );
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  fetchConsultationBlocker = () => {
    fetchConsultationBlocker(this.props.authToken)
      .then((res) => {
        this.setState({
          blockers: res.blockers,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  hideModal = () => {
    this.setState({
      showRelativeModal: false,
    });
  };

  selectRelative = (ele) => {
    this.setState({
      selectedRelative: ele,
    });
  };

  render() {
    const {
      selectedRelative,
      cards,
      ongoingAppointment,
      completedAppointment,
      showRelativeModal,
      relatives,
      cancelledAppointment,
      scheduledAppointment,
    } = this.state;
    const { history } = this.props;

    const ConsultationCard = (ele) => {
      if (ele.ongoing) {
        logEvent('Online Consultation Ongoing Card', {
          screenName: 'Online Home Screen',
          consultationId: ele.consultationId,
          requestId: ele.requestId,
        });
      }
      return (
        <NewOnlineConsultationCard
          key={ele.consultationId}
          order={ele}
          onlinePreview
          onTap={() => this.openOrder(ele)}
        />
      );
    };
    const EmptyAppointmentsMessage = () => {
      return (
        <div className="flex flex-col self-stretch justify-center h-full items-center">
          <img
            src={noConsultations}
            style={{ height: '90px', width: '90px' }}
          />
          <span style={{ color: '#777777', fontWeight: 'bold' }}>
            No Consultations Yet.
          </span>
        </div>
      );
    };
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          {/* <HeaderContainer>
            <BackContainer onTap={() => history.goBack()}>
              <StyledArrow />
            </BackContainer>
            <HeaderTitleContainer>
              <HeaderText>Online Consultation </HeaderText>
              <ConsultationUser
                // icon={selectedRelative.relativeIcon}
                text={selectedRelative ? selectedRelative.relation : 'All'}
                onTap={() => {
                  this.setState({
                    showRelativeModal: true,
                  });
                }}
              />
            </HeaderTitleContainer>
          </HeaderContainer> */}
          {/* <Header
            heading="Online Consultation"
            onTap={() => history.push('/home')}
          ></Header> */}
          <HeaderContainer>
            <BackContainer onTap={() => history.push('/home')}>
              <svg
                width="7"
                height="13"
                viewBox="0 0 7 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 2.35117L2.67342 6.96667L7 11.5822L5.66802 13L1.56711e-06 6.96667L5.66802 0.933336L7 2.35117Z"
                  fill="#0F0B28"
                />
              </svg>
            </BackContainer>
            <HeaderTitleContainer>
              <HeaderText>Online Consultation</HeaderText>
            </HeaderTitleContainer>
            <div
              className="flex justify-center items-center border border-boxCard cursor-pointer "
              style={{ borderRadius: '20px', padding: '6px 12px' }}
              onClick={() => history.push('/consultation/online/my-orders')}
            >
              <img
                src={cartIcon}
                alt="orders"
                className="backdrop-hue-rotate-300"
              />
              <p className="ml-6px text-body-s font-semibold text-txtlight">
                My Consults
              </p>
            </div>
          </HeaderContainer>
          <BodyContainer>
            {/* {(ongoingAppointment.length > 0 ||
              completedAppointment.length > 0) &&
              cards.map((card, idx) => {
                return (
                  <div
                    className="blue-box flex-row-center-end"
                    key={idx}
                    style={{
                      paddingRight: '14px',
                      borderRadius: '12px',
                    }}
                    onClick={() => {
                      if (this.state.blockers.cancelledRequest > 0) {
                        this.props.history.push(
                          '/consultation/cancel-blocker',
                          {
                            requestId: this.state.blockers.cancelledRequest,
                          }
                        );
                      } else {
                        this.props.history.push('/consultation/doctor-select', {
                          specialistSearch: false,
                          showVerticals: card.showVerticals,
                          searchType: 'treat',
                        });
                      }
                    }}
                  >
                    <div>
                      <span className="txt-white-600-13">{card.label}</span>
                      <br />
                      <span className="txt-500-white-12">
                        {card.description}
                      </span>
                    </div>
                    <div className="h-100">
                      <svg
                        width="1"
                        height="40"
                        viewBox="0 0 1 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="0.5"
                          y1="2.18557e-08"
                          x2="0.499998"
                          y2="40"
                          stroke="white"
                        />
                      </svg>
                      <div className="ml-24px mr-10px">
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.749999 8.48662L3.99494 5.025L0.75 1.56337L1.74899 0.5L6 5.025L1.74899 9.55L0.749999 8.48662Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })} */}
            {this.state.loading ? (
              <div style={{ height: '100%', background: '#ffffff' }}>
                <LoadingComponent />
              </div>
            ) : (
              <>
                {(ongoingAppointment.length > 0 ||
                  completedAppointment.length > 0 ||
                  cancelledAppointment.length > 0) &&
                  cards.map((card, idx) => {
                    return (
                      <div
                        className="blue-box flex-row-center-end"
                        key={idx}
                        style={{
                          paddingRight: '14px',
                          borderRadius: '12px',
                        }}
                        onClick={() => {
                          if (this.state.blockers.cancelledRequest > 0) {
                            this.props.history.push(
                              '/consultation/cancel-blocker',
                              {
                                requestId: this.state.blockers.cancelledRequest,
                              }
                            );
                          } else {
                            this.props.history.push(
                              '/consultation/doctor-select',
                              {
                                specialistSearch: false,
                                showVerticals: card.showVerticals,
                                searchType: 'treat',
                              }
                            );
                          }
                        }}
                      >
                        <div>
                          <span className="txt-white-600-13">{card.label}</span>
                          <br />
                          <span className="txt-500-white-12">
                            {card.description}
                          </span>
                        </div>
                        <div className="h-100">
                          <svg
                            width="1"
                            height="40"
                            viewBox="0 0 1 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <line
                              x1="0.5"
                              y1="2.18557e-08"
                              x2="0.499998"
                              y2="40"
                              stroke="white"
                            />
                          </svg>
                          <div className="ml-24px mr-10px">
                            <svg
                              width="6"
                              height="10"
                              viewBox="0 0 6 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.749999 8.48662L3.99494 5.025L0.75 1.56337L1.74899 0.5L6 5.025L1.74899 9.55L0.749999 8.48662Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {ongoingAppointment.length > 0 && (
                  <div>
                    <Label className="txt-grey-500-12 mb-8 mt-8">Ongoing</Label>
                    {ongoingAppointment.map(ConsultationCard)}
                  </div>
                )}
                {scheduledAppointment.length > 0 && (
                  <div>
                    <Label className="txt-grey-500-12 mb-8 mt-8">
                      Scheduled
                    </Label>
                    {scheduledAppointment.map(ConsultationCard)}
                  </div>
                )}
                {completedAppointment.length > 0 && (
                  <div>
                    <Label className="txt-grey-500-12 mb-8 mt-8">
                      Completed
                    </Label>
                    {completedAppointment.map(ConsultationCard)}
                  </div>
                )}
                {cancelledAppointment.length > 0 && (
                  <div>
                    <Label className="txt-grey-500-12 mb-8 mt-8">
                      Cancelled
                    </Label>
                    {cancelledAppointment.map(ConsultationCard)}
                  </div>
                )}
                {(ongoingAppointment.length > 0 ||
                  scheduledAppointment.length > 0 ||
                  completedAppointment.length > 0 ||
                  cancelledAppointment.length > 0) && (
                  <div className="mt-32px">
                    <p
                      className="text-title-m font-medium text-center"
                      style={{ color: 'rgba(15, 11, 40, 0.6)' }}
                    >
                      Searching for your previous consultations?
                    </p>
                    <PrimaryButton
                      className="mt-24px mb-36px"
                      onTap={() =>
                        history.push('/consultation/online/my-orders')
                      }
                    >
                      <ButtonText>View Past Consultations</ButtonText>
                    </PrimaryButton>
                  </div>
                )}
                {ongoingAppointment.length === 0 &&
                  completedAppointment.length === 0 &&
                  scheduledAppointment.length === 0 &&
                  cancelledAppointment.length === 0 &&
                  !this.state.loading && (
                    <div className="flex flex-col justify-center items-center w-full h-full">
                      <img src={noAppointment} alt="no appointment" />
                      <p className="text-body-l text-brochure text-center my-11">
                        You don't have an ongoing teleconsult, click below to
                        consult now.
                      </p>
                      <PrimaryButton
                        className="w-full"
                        onTap={() =>
                          history.push('/consultation/doctor-select')
                        }
                      >
                        <div className="text-body-l font-bold text-center py-4px">
                          Consult Now
                        </div>
                      </PrimaryButton>
                    </div>
                  )}
                {/* {ongoingAppointment.length > 0 && (
                  <div>
                    <Label className="txt-grey-500-12 mb-8 mt-8">Ongoing</Label>
                    {ongoingAppointment.map(ConsultationCard)}
                  </div>
                )}

                {completedAppointment.length > 0 && (
                  <div>
                    <Label className="txt-grey-500-12 mb-8 mt-8">
                      Completed
                    </Label>
                    {completedAppointment.map(ConsultationCard)}
                  </div>
                )} */}
              </>
            )}

            {showRelativeModal && (
              <RelativeSelect
                relatives={relatives}
                hideModal={this.hideModal}
                selectedRelative={selectedRelative}
                selectRelative={this.selectRelative}
              />
            )}
            <div className="absolute fab">
              <FAB />
            </div>
          </BodyContainer>
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const Label = ({ children }) => {
  return <div className="text-body-l font-semibold my-24px">{children}</div>;
};

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  userId: state.user.userId,
  sponsorId: state.user.sponsorId,
  user: state.user,
});

export default connect(mapStateToProps)(OnlineConsultationStartPreview);
