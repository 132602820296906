import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/common/Header';
import MedicineStatusCard from '../../components/pharmacy/MedicineStatusCard';
import { PharmacyDashboard } from '../../services';
import uploadIcon from '../../images/common/upload.svg';
import LoadingComponent from '../../components/common/LoadingComponent';
import UploadSuccessModal from '../../components/pharmacy/UploadSuccessModal';
import CardImage from '../../images/pharmacy/card.jsx';
import NoMedsIcon from '../../images/pharmacy/no-meds-orders.png';
import NoMedsIcon_Cyan from '../../images/pharmacy/no-meds-order-cyan.png';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/common/Buttons';
import { setCurrentActiveTab } from '../../actions';
import PrescriptionStatusCard from '../../components/pharmacy/StatusCard';

const NoMedsText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;

  /* Text/Brochure */
  color: #302e6b;
  margin-top: 44px;
`;

class PharmacyOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      selectedCity: null,
      modalVisible: false,
      showMedUploadRx: true,
      loading: true,
      ongoingOrders: [],
      orders: [],
      consultationRequests: [],
    };
    const { state } = props.location;
    if (state) {
      this.state.selectedCity = state.city;
      this.disableBack = state.disableBack;
      this.state.modalVisible = state.uploadSuccess;
    }
  }

  componentDidMount = () => {
    // GA Tracking
    window.gtag('event', 'screen_view', {
      screen_name: 'Pharmacy Home Screen',
    });
    this.getOrders();
    this.props.dispatch(setCurrentActiveTab('medicine-orders'));
  };

  getOrders() {
    const { authToken } = this.props;
    PharmacyDashboard(authToken)
      .then((res) => {
        const ongoing = res.sections.filter((ele) => {
          return ele.sectionType === 'ongoing';
        });
        console.log(ongoing);
        const orders = res.sections.filter((ele) => {
          return ele.sectionType === 'order';
        });
        const consultationRequests = res.sections.filter(
          (ele) => ele.sectionType === 'consultation-requests'
        );
        console.log(orders);
        this.setState({
          ongoingOrders: ongoing[0],
          orders: orders[0],
          consultationRequests: consultationRequests[0],
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  openOrder(order) {
    const {
      cardType: type,
      cartId,
      transactionId,
      openSection,
      orderId,
      consultationId,
      prescriptionId,
      digitisationId,
    } = order;
    const { history } = this.props;
    if (type === 'transcribed') {
      return history.push('/pharmacy/cart-review', {
        cartId,
        goBack: true,
      });
    }

    history.push('/pharmacy/order-status', {
      orderId,
      digitisationId,
      cartId,
      transactionId,
    });
  }

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  hideModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  selectCity = () => {
    const { history } = this.props;
    history.replace('/lab-test/select-city', {
      forwardRoute: '/lab-test',
    });
  };

  render() {
    const { ongoingOrders, loading, orders, consultationRequests } = this.state;
    const { history } = this.props;

    const renderOrder = (ele, idx) => {
      console.log(ele);
      return (
        <PrescriptionStatusCard
          key={
            ele.transactionId ||
            ele.requestId ||
            ele.digitisationId ||
            ele.consultationId ||
            idx
          }
          history={history}
          orderRequest={{ cardType: 'order', ...ele }}
          onTap={() => this.openOrder(ele)}
        />
      );
    };

    return (
      // <LoadingComponent />
      <div className="overflow-auto w-full bg-white h-full">
        <Header
          title="Medicine Delivery"
          color="#fcfcfc"
          // onTap={() => {
          //   this.props.history.push('/home/consult');
          // }}
        />
        {loading ? (
          <div className="center h-full">
            <LoadingComponent />
          </div>
        ) : (
          <div
            className="p-20 w-full h-full overflow-auto"
            style={{ background: '#ffffff' }}
          >
            {consultationRequests &&
            consultationRequests.card &&
            !consultationRequests.card.length > 0 &&
            ongoingOrders &&
            ongoingOrders.card &&
            !ongoingOrders.card.length > 0 &&
            orders &&
            orders.card &&
            !orders.card.length > 0 ? (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <img src={NoMedsIcon_Cyan} />
                <NoMedsText>
                  You don't have any ongoing order, click below to order now.
                </NoMedsText>
                <div className="w-full" style={{ marginTop: '45px' }}>
                  <PrimaryButton
                    className="w-full"
                    onTap={() => history.push('/pharmacy')}
                  >
                    <div className="text-body-l font-bold text-center py-4px">
                      Order Now
                    </div>
                  </PrimaryButton>
                </div>
              </div>
            ) : (
              <div>
                <div
                  className="text-center flex flex-col hover items-center p-20 border border-txtsecondary-shades7 border-dashed	rounded-12"
                  onClick={() => history.push('/pharmacy/upload-prescription')}
                >
                  <img
                    src={uploadIcon}
                    alt="upload"
                    className="mb-16 h-px-30 w-25"
                  ></img>
                  <div className="px-16">
                    <p className="text-body-s font-semibold text-txtlight leading-5 tracking-normal">
                      Upload your prescription to order prescribed medicines
                    </p>
                  </div>
                </div>
              </div>
            )}
            {consultationRequests &&
              consultationRequests.card &&
              consultationRequests.card.length > 0 && (
                <div className="mt-20px pt-16px pb-16px border-t border-b border-txtsecondary-shades4">
                  <div className="text-title-s text-txtlight font-semibold">
                    {consultationRequests.title}
                  </div>
                  {consultationRequests.card.map(renderOrder)}
                </div>
              )}

            {ongoingOrders &&
              ongoingOrders.card &&
              ongoingOrders.card.length > 0 && (
                <div className="mt-20px pt-16px pb-16px border-t border-b border-txtsecondary-shades4">
                  <div className="text-title-s text-txtlight font-semibold">
                    Ongoing
                  </div>
                  {ongoingOrders.card.map(renderOrder)}
                </div>
              )}
            {orders && orders.card && orders.card.length > 0 && (
              <div className="mt-20px pt-16px pb-16px border-t border-b border-txtsecondary-shades4">
                <div className="text-title-s text-txtlight font-semibold">
                  Orders
                </div>
                {orders.card
                  .filter((ele) => ele.orderId !== null)
                  .map(renderOrder)}
              </div>
            )}
            {this.state.modalVisible && (
              <UploadSuccessModal hideModal={this.hideModal} />
            )}
            {/* <Card></Card> */}
          </div>
        )}
      </div>
    );
  }
}

const Card = () => (
  <div
    className="w-full mt-35 rounded-12 flex p-6"
    style={{
      height: '322px',
      background: 'linear-gradient(122.07deg, #23BAFF 0%, #5183F0 100.36%)',
    }}
  >
    <div className="w-2/4 text-textbox flex flex-col">
      <div>
        <p className="font-semibold text-body-l">Chronic patients?</p>
        <h1 className="font-bold text-title-l mt-1">
          Subscribe & Order Medicine with 2 Tap
        </h1>
        <div style={{ marginTop: '12px' }}>
          {[
            'Upto 20% off',
            'Monthly refill at your doorstep',
            'Free Shipping',
            'Stress free procedure',
          ].map((text, i) => (
            <p
              className={!i ? 'font-semibold text-card-body' : 'text-card-body'}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <svg
                width="4"
                height="4"
                viewBox="0 0 4 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2" cy="2" r="2" fill="#B5DEFF" />
              </svg>
              <p className="ml-2">{text}</p>
            </p>
          ))}
        </div>
      </div>
      <h2 className="mt-auto font-semibold text-title-s flex items-center">
        <p style={{ marginRight: '8px' }}>See Details</p>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse cx="8.5" cy="8.5" rx="8" ry="8" fill="white" />
          <path
            d="M6.5 11.56L9.36845 8.5L6.5 5.44L7.38308 4.5L11.1409 8.5L7.38308 12.5L6.5 11.56Z"
            fill="#3A9FF8"
          />
        </svg>
      </h2>
    </div>
    <div className="w-2/4 flex items-end justify-end">
      <CardImage></CardImage>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  insured: state.app.config.insured,
});

export default connect(mapStateToProps)(PharmacyOrders);
