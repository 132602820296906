import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BalanceBadge } from './Fitcoin';
import { BoldText, LinkText } from './Text';
import { PrimaryButton } from './Buttons';

const HeaderContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 0.625rem;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 38px;
  border: 1px solid #f0f3f6;
`;

const HeaderText = styled(BoldText)`
  font-size: 1.125rem;
`;

const HeaderButton = styled(PrimaryButton)`
  background: none;
  padding: 0.25rem 1rem;
  border: 1px solid #01bfbd;
`;

const ButtonText = styled(LinkText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const MyPurchaseContainer = styled.div`
  border-radius: 6px;
  border: 1px solid #01bfbd;
  padding: 6px;
  font-size: 14px;
  color: #01bfbd;
  font-weight: 600;
  cursor: pointer;
`;

const RewardsHeader = ({ balance, history }) => (
  <HeaderContainer>
    <HeaderTitleContainer>
      <BalanceBadge balance={balance} />
    </HeaderTitleContainer>
    <MyPurchaseContainer onClick={() => history.push('/rewards/history')}>
      MY PURCHASES
    </MyPurchaseContainer>
  </HeaderContainer>
);

RewardsHeader.propTypes = {
  balance: PropTypes.string.isRequired,
};

export default RewardsHeader;
