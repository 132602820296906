import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaArrowLeft, FaChevronRight } from 'react-icons/fa';

import Tappable from 'react-tappable/lib/Tappable';

import {
  fetchPreviousOfflineConsultation,
  getAllRelatives,
  fetchConsultationBlocker,
  getConsultationSummary,
} from '../../services/consultation';
import initializeFreshDesk from '../../utils/freshchat';
import { FadeInTransition } from '../../components/transitions/transitions';
import TappableCard from '../../components/common/TappableCard';
import StyledTappable from '../../components/common/StyledTappable';
import { PrimaryButton } from '../../components/common/Buttons';
import ConsultationUser from '../../components/consultations/ConsultationUser';
import ConsultationStatusCard from '../../components/consultations/ConsultationStatusCard';
import arrowLeft from '../../images/common/arrow-left.svg';
import supportIcon from '../../images/common/support1.svg';
import noAppointment from '../../images/consultations/new-flow/medical-appointment.png';
import noAppointment_cyan from '../../images/consultations/new-flow/No_appointments_cyan.png';
import { Text } from '../../components/common/Text';
import RelativeSelect from '../../components/consultations/RelativeSelect';
import { resetChat, setCurrentActiveTab } from '../../actions';
import { logEvent } from '../../utils/logEvent';
import LoadingComponent from '../../components/common/LoadingComponent';
import NeedHelp from '../../components/hospitalization/need-help';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 16px 10px 0 10px;
  z-index: 10;
  border-bottom: 1px solid #e2e3ea;
`;

const BackContainer = styled(Tappable)`
  //   margin-right: 0.5rem;
  padding: 0.5rem;
  display: flex;
  width: 44px;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled(Text)`
  margin-right: 0.625rem;
  color: #0f0b28;
  font-weight: 600;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5rem;
  padding-left: 14px;
  padding-right: 14px;
`;

class ConsultationStartPreview extends Component {
  openSupport = () => {
    console.log(this.props);
    initializeFreshDesk(this.props.user);
  };

  constructor(props) {
    super(props);
    this.state = {
      verticals: [],
      showVerticalsLeftScroll: false,
      showVerticalsRightScroll: true,
      ongoingAppointment: [],
      completedAppointment: [],
      cards: [],
      relatives: [],
      selectedRelative: null,
      showRelativeModal: false,
      blockers: {},
      from: '',
    };
    this.verticalScrollContainerRef = React.createRef();

    const { state } = this.props.location;
    if (state && state.openConsultId) {
      this.state.openConsultId = parseInt(state.openConsultId);
      this.openOrder(this.state.openConsultId);
    }
    if (state && state.from === 'home') {
      props.dispatch(setCurrentActiveTab('home'));
    } else {
      props.dispatch(setCurrentActiveTab('clinic-orders'));
    }
  }

  componentDidMount() {
    // GA tracking
    window.gtag('event', 'screen_view', {
      screen_name: 'Consult Screen',
    });
    this.props.dispatch(resetChat());
    this.getConsults('all');
    this.getAllRelatives();
    this.fetchConsultationBlocker();
    logEvent('Offline Appointments Home Screen');
    // if (this.state.from === 'home') {
    //   this.props.dispatch(setCurrentActiveTab('home'));
    // } else {
    //   this.props.dispatch(setCurrentActiveTab('clinic-orders'));
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location.hash === '#profile' &&
      this.props.location.hash !== '#profile'
    ) {
      this.closeProfile();
      return;
    }

    if (
      prevState.selectedRelative &&
      !this.state.selectedRelative &&
      this.state.selectedRelative !== prevState.selectedRelative
    ) {
      this.getConsults('all');
    }
    if (
      this.state.selectedRelative &&
      this.state.selectedRelative !== prevState.selectedRelative &&
      !this.state.loading
    ) {
      this.getConsults();
    }
  }

  openOrder = (order) => {
    const { history } = this.props;
    if (order.type === 'reimbursement') {
      history.push('/consultation/reimbursement-details', {
        reimbursementId: order.reimbursementId,
        goBack: true,
      });
      return;
    }
    history.push('/consultation/appointment/appointment-status', {
      requestId: order.requestId,
    });
  };

  onNext = () => {
    const { history } = this.props;
    const { selectedRelative, selectedIssues, selectedIssuesIndex } =
      this.state;
    if (this.searchType === 'treat') {
      history.push('/consultation/request-summary', {
        relative: selectedRelative,
        selectedIssues,
        selectedIssuesIndex,
      });
    } else {
      // Specialist search. Take directly to search screen
      history.push('/consultation/specialist-search', {
        issueIds: selectedIssuesIndex,
        onlineFilters: this.filters.ONLINE,
        offlineFilters: this.filters.OFFLINE,
        showOnlineAndOffline: true,
        consultRequestInfo: {
          selectedIssuesIndex,
          relative: selectedRelative,
        },
      });
    }
  };

  onVerticalListScrolled = (event) => {
    const { showVerticalsLeftScroll, showVerticalsRightScroll } = this.state;
    if (event.target.scrollLeft > 0) {
      !showVerticalsLeftScroll &&
        this.setState({
          showVerticalsLeftScroll: true,
        });
    } else {
      showVerticalsLeftScroll &&
        this.setState({
          showVerticalsLeftScroll: false,
        });
    }
    if (
      event.target.scrollLeft + event.target.clientWidth <
      event.target.scrollWidth
    ) {
      !showVerticalsRightScroll &&
        this.setState({ showVerticalsRightScroll: true });
    } else {
      showVerticalsRightScroll &&
        this.setState({ showVerticalsRightScroll: false });
    }
  };

  smoothScroll(ele, left, reverse) {
    const start = performance.now();
    const duration = 300;
    const startValue = ele.scrollLeft;
    const scrollPerTimestamp = Math.abs(left - startValue) / duration;
    const multiplier = reverse ? 1 : -1;
    const animate = (timestamp) => {
      const progress = timestamp - start;
      ele.scrollTo(startValue + multiplier * scrollPerTimestamp * progress, 0);
      if (progress < duration) {
        requestAnimationFrame(animate);
      }
    };
    requestAnimationFrame(animate);
  }

  scrollVerticalsLeft = () => {
    const { scrollLeft, clientWidth } = this.verticalScrollContainerRef.current;
    if (scrollLeft === 0) {
      return;
    }
    if (scrollLeft < clientWidth / 2) {
      this.smoothScroll(this.verticalScrollContainerRef.current, 0);
      return;
    }
    this.smoothScroll(
      this.verticalScrollContainerRef.current,
      scrollLeft - clientWidth / 2
    );
  };

  scrollVerticalsRight = () => {
    const { scrollLeft, clientWidth, scrollWidth } =
      this.verticalScrollContainerRef.current;
    if (scrollLeft + clientWidth >= scrollWidth) {
      return;
    }
    if (scrollWidth - scrollLeft + clientWidth < clientWidth / 2) {
      this.smoothScroll(
        this.verticalScrollContainerRef.current,
        scrollWidth - clientWidth,
        true
      );
      return;
    }
    this.smoothScroll(
      this.verticalScrollContainerRef.current,
      scrollLeft + clientWidth / 2,
      true
    );
  };

  getAllRelatives = () => {
    const { authToken, user } = this.props;
    getAllRelatives(authToken)
      .then((res) => {
        const self = {
          age: user.userAge,
          dob: user.dateOfBirth,
          gender: user.userGender,
          id: 'self',
          name: user.userName,
          phone: user.userPhone,
          relation: user.userType,
        };
        const relatives = [self, ...res.relatives];
        this.setState({
          relatives,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  getConsults(id = null) {
    const { authToken } = this.props;

    this.setState({
      loading: true,
    });
    if (!id) {
      id =
        this.state.selectedRelative && this.state.selectedRelative.id
          ? this.state.selectedRelative.id
          : 'all';
    }
    fetchPreviousOfflineConsultation(id, authToken)
      .then((res) => {
        const cards = res.cards;
        const ongoingAppointment = res.appointments.filter((appointments) => {
          return appointments.status.ongoing === true;
        });
        const completedAppointment = res.appointments.filter((appointments) => {
          return appointments.status.ongoing === false;
        });

        if (this.state.openConsultId && res.appointments.length > 0) {
          console.log(true);
          const orderToOpen = res.appointments.filter((ele) => {
            return ele.consultationId === this.state.openConsultId;
          });
          console.log(orderToOpen, res.appointments);
          if (orderToOpen.length === 1) {
            console.log(true);
            this.openOrder(orderToOpen[0]);
          }
        }
        if (res.appointments.length === 0 && this.state.selectedRelative) {
          this.setState({ selectedRelative: null });
        }
        this.setState({
          completedAppointment,
          ongoingAppointment,
          cards,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  fetchConsultationBlocker = () => {
    fetchConsultationBlocker(this.props.authToken)
      .then((res) => {
        this.setState({
          blockers: res.blockers,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  hideModal = () => {
    this.setState({
      showRelativeModal: false,
    });
  };

  selectRelative = (ele) => {
    this.setState({
      selectedRelative: ele,
    });
  };

  render() {
    const {
      selectedRelative,
      cards,
      ongoingAppointment,
      completedAppointment,
      showRelativeModal,
      relatives,
    } = this.state;
    const { history } = this.props;

    const ConsultationCard = (ele) => {
      if (ele.ongoing) {
        logEvent('Offline Consultation Ongoing Card', {
          screenName: 'Offline Home Screen',
          consultationId: ele.consultationId,
          requestId: ele.requestId,
        });
      }
      return (
        <ConsultationStatusCard
          key={ele.requestId}
          orderRequest={ele}
          onTap={() => this.openOrder(ele)}
        />
      );
    };
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <BackContainer onTap={() => history.push('/home')}>
              <img src={arrowLeft} alt="back" />
            </BackContainer>
            <HeaderTitleContainer>
              <HeaderText>Clinic Consultation </HeaderText>
              {/* <ConsultationUser
                // icon={selectedRelative.relativeIcon}
                text={selectedRelative ? selectedRelative.relation : 'All'}
                onTap={() => {
                  this.setState({
                    showRelativeModal: true,
                  });
                }}
              /> */}
            </HeaderTitleContainer>
            <NeedHelp></NeedHelp>
          </HeaderContainer>
          <BodyContainer>
            {(ongoingAppointment.length > 0 ||
              completedAppointment.length > 0) &&
              cards.map((card, idx) => {
                return (
                  <div
                    className="blue-box flex-row-center-end"
                    key={idx}
                    style={{
                      paddingRight: '14px',
                      marginTop: '18px',
                      marginBottom: '18px',
                      borderRadius: '12px',
                    }}
                    onClick={() => {
                      if (this.state.blockers.cancelledRequest > 0) {
                        this.props.history.push(
                          '/consultation/cancel-blocker',
                          {
                            requestId: this.state.blockers.cancelledRequest,
                          }
                        );
                      } else if (this.state.blockers.pendingRequestId > 0) {
                        this.props.history.push(
                          '/consultation/pending-blocker',
                          {
                            requestId: this.state.blockers.pendingRequestId,
                            goBack: true,
                          }
                        );
                      } else {
                        this.props.history.push('/consultation/start', {
                          showVertical: card.showVerticals,
                        });
                      }
                    }}
                  >
                    <div>
                      <span className="txt-white-600-13">{card.label}</span>
                      <br />
                      <span className="txt-500-white-12">
                        {card.description}
                      </span>
                    </div>
                    <div className="h-100">
                      <span className="vertical-line" />
                      <FaChevronRight
                        style={{
                          color: '#ffffff',
                          fontSize: '14px',
                          width: '8px',
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            {ongoingAppointment.length > 0 && (
              <div>
                <div className="text-body-l font-semibold text-txtlight mb-20px ">
                  Ongoing
                </div>
                {ongoingAppointment.map(ConsultationCard)}
              </div>
            )}

            {completedAppointment.length > 0 && (
              <div>
                <div className="text-body-l font-semibold text-txtlight mb-8 mt-8">
                  Completed
                </div>
                {completedAppointment.map(ConsultationCard)}
              </div>
            )}
            {this.state.loading ? (
              <div style={{ height: '100vh', background: '#ffffff' }}>
                <LoadingComponent />
              </div>
            ) : (
              ongoingAppointment.length === 0 &&
              completedAppointment.length === 0 && (
                <div className="flex flex-col justify-center items-center w-full h-full">
                  <img src={noAppointment_cyan} alt="no appointment" />
                  <p className="text-body-l text-brochure text-center my-11">
                    You don't have an appointment, to schedule the appointment
                    click the below button
                  </p>
                  <PrimaryButton
                    className="w-full"
                    onTap={() => history.push('/consultation/start')}
                  >
                    <div className="text-body-l font-bold text-center py-4px">
                      Book Appointment
                    </div>
                  </PrimaryButton>
                </div>
              )
            )}
            {showRelativeModal && (
              <RelativeSelect
                relatives={relatives}
                hideModal={this.hideModal}
                selectedRelative={selectedRelative}
                selectRelative={this.selectRelative}
              />
            )}
          </BodyContainer>
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  userId: state.user.userId,
  sponsorId: state.user.sponsorId,
  user: state.user,
});

export default connect(mapStateToProps)(ConsultationStartPreview);
