import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { RegularText, Text } from '../../components/common/Text';
import processingIcon from '../../images/common/phonepe-processing.jpg';
import {
  trackLabOrder,
  trackConsultationPayment,
  trackPolicyPayment,
} from '../../services';
import { showError } from '../../utils';
import LoadingComponent from '../../components/common/LoadingComponent';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const ProcessingIcon = styled.img`
  max-width: 15rem;
  margin-bottom: 2.5rem;
`;

const ProcessingText = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-bottom: 1rem;
  text-align: center;
`;

const ProcessingSubtext = styled(RegularText)`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  text-align: center;
`;

class PhonepePaymentProcessing extends PureComponent {
  constructor(props) {
    super(props);

    const { state, search } = props.location;
    const { history } = props;
    if (state) {
      this.transactionId = state.transactionId || 97;
      this.successPage = state.successPage;
      this.failurePage = state.failurePage;
      this.failureStateParameters = state.failureStateParameters;
      this.orderType = state.orderType;
    } else if (search) {
      const params = new URLSearchParams(search);
      this.transactionId = params.get('transactionId');
      this.orderType = params.get('orderType');
      if (!this.transactionId || !this.orderType) {
        history.goBack();
      }
      this.showLoading = true;
    } else {
      history.replace('/home');
    }
  }

  componentDidMount = () => {
    const { loginState, authToken, history, location } = this.props;
    if (loginState !== 'LOGGED_IN' || !authToken) {
      history.push(
        `/?rt=processing-payment&params=${encodeURIComponent(location.search)}`
      );
      return false;
    } else {
      this.setOrderTrackingPolling();
    }
  };

  componentWillUnmount() {
    console.log('Unmounted');
    // Set the name of the hidden property and the change event for visibility
    let visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      visibilityChange = 'webkitvisibilitychange';
    }
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
    clearTimeout(this.timeout);
  }

  handleVisibilityChange = () => {
    // Set the name of the hidden property and the change event for visibility
    let hidden;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
    }
    console.log(document[hidden]);
    if (document[hidden]) {
      console.log('Tab not visible anymore!');
      this.stopTracking = true;
    } else {
      console.log('Tab visible!');
      this.stopTracking = false;
    }
  };

  setOrderTrackingPolling() {
    // Set the name of the hidden property and the change event for visibility
    let hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }
    if (
      typeof document.addEventListener === 'undefined' ||
      hidden === undefined
    ) {
      console.log('Visibility not supported');
      this.visibilityUnsupported = true;
      this.timeout = setTimeout(this.trackOrder, 3000);
    } else {
      console.log('Visibility supported');
      document.addEventListener(
        visibilityChange,
        this.handleVisibilityChange,
        false
      );
      this.trackOrder();
    }
  }

  trackOrder = () => {
    const { authToken, history } = this.props;
    if (this.visibilityUnsupported || !this.stopTracking) {
      switch (this.orderType) {
        case 'lab':
          trackLabOrder(this.transactionId, authToken)
            .then((res) => {
              const { message, cartId } = res;
              if (message === 'pending') {
                this.timeout = setTimeout(this.trackOrder, 3000);
              } else if (message === 'success') {
                console.log('Successful payment');
                history.replace('lab-test/track', {
                  ...res,
                  goHome: true,
                });
              } else if (message === 'failed') {
                console.log('Failed payment');
                const error = new Error('Payment failed');
                error.data = {
                  errorMessage: `We were unable to process your payment. Please try again later. In case this was a mistake, please reach out to us. Your transaction ID is ${this.transactionId}`,
                };
                showError(error);
                history.replace('order-summary', {
                  cartId,
                  paymentNext: true,
                  goHome: true,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              const error = new Error('Payment failed');
              error.data = {
                errorMessage: `We were unable to process your payment. Please try again later. In case this was a mistake, please reach out to us. Your transaction ID is ${this.transactionId}`,
              };
              showError(error);
              history.goBack();
            });
          break;
        case 'consultation':
          trackConsultationPayment(this.transactionId, authToken)
            .then((res) => {
              const { message, cartId } = res;
              if (message === 'pending') {
                this.timeout = setTimeout(this.trackOrder, 3000);
              } else if (message === 'success') {
                console.log('Successful payment');
                history.replace('home/consult', {
                  ...res,
                  goHome: true,
                });
              } else if (message === 'failed') {
                console.log('Failed payment');
                const error = new Error('Payment failed');
                error.data = {
                  errorMessage: `We were unable to process your payment. Please try again later. In case this was a mistake, please reach out to us. Your transaction ID is ${this.transactionId}`,
                };
                showError(error);
                history.replace('home/consult', {
                  cartId,
                  paymentFailed: true,
                  goHome: true,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              const error = new Error('Payment failed');
              error.data = {
                errorMessage: `We were unable to process your payment. Please try again later. In case this was a mistake, please reach out to us. Your transaction ID is ${this.transactionId}`,
              };
              showError(error);
              history.goBack();
            });
          break;
        case 'opd':
          trackPolicyPayment(this.transactionId, authToken)
            .then((res) => {
              const { message, cartId } = res;
              if (message === 'pending') {
                this.timeout = setTimeout(this.trackOrder, 3000);
              } else if (message === 'success') {
                console.log('Successful payment');
                history.replace('home/policy-purchased', {
                  ...res,
                });
              } else if (message === 'failed') {
                console.log('Failed payment');
                const error = new Error('Payment failed');
                error.data = {
                  errorMessage: `We were unable to process your payment. Please try again later. In case this was a mistake, please reach out to us. Your transaction ID is ${this.transactionId}`,
                };
                showError(error);
                history.replace('home', {
                  cartId,
                  paymentFailed: true,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              const error = new Error('Payment failed');
              error.data = {
                errorMessage: `We were unable to process your payment. Please try again later. In case this was a mistake, please reach out to us. Your transaction ID is ${this.transactionId}`,
              };
              showError(error);
              history.replace('home', {
                paymentFailed: true,
              });
            });
          break;
        default:
          break;
      }
    } else {
      this.timeout = setTimeout(this.trackOrder, 3000);
    }
  };

  render() {
    if (this.showLoading) {
      return <LoadingComponent />;
    }
    return (
      <OuterContainer>
        <ProcessingIcon src={processingIcon} />
        <ProcessingText>Processing your payment</ProcessingText>
        <ProcessingSubtext>
          Please wait while we confirm your transaction with phonepe
        </ProcessingSubtext>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(PhonepePaymentProcessing);
