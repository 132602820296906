import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import styled from 'styled-components';
import BackdropCircleIcon from '../../images/policy/card-background.png';
import IpdBackdropCircleIcon from '../../images/policy/ipd-card-background.png';
import moment from 'moment';
import { useInView } from 'react-intersection-observer';
import { InView } from 'react-intersection-observer';

const CardContainer = styled.div`
  border-bottom: 8px solid #ededf0;
  padding-bottom: 18px;
  position: relative;
  padding-top: 16px;
`;

const SubHeadings = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin-left: 30px;
  margin-top: 4px;
  margin-bottom: 20px;
`;

const Ecard = styled.div`
  width: 100%;
  min-width: 320px;
  border: 1px solid #e2dbec;
  border-radius: 12px;
  padding-bottom: 20px;
`;

const PolicyType = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #0f0b28;
`;

const ExpiryDate = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: right;
  color: #0f0b28;
`;

const PolicyNumber = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #01bfbd;
  padding-left: 16px;
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #0f0b28;
  padding-left: 16px;
`;

const Covered = styled.div`
  color: #01bfbd;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  margin-right: 6px;
`;

const SumInsured = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #0f0b28;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const RightArrow = styled(FaChevronRight)`
  width: 10px;
  height: 12px;
`;

const LeftArrow = styled(FaChevronLeft)`
  width: 10px;
  height: 12px;
`;

const InsurerContainer = styled.div`
  margin-right: 15px;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const DotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  background: #d6d6d6;
  border-radius: 4px;
  margin-right: 6px;
`;

const ActiveDot = styled.div`
  width: 6px;
  height: 6px;
  background: #01bfbd;
  border-radius: 4px;
  margin-right: 6px;
`;

const EcardsContainer = (props) => {
  const [colors, setColors] = useState([
    '#D5E5FD',
    '#F7F2FF',
    '#EAF7EE',
    '#FFF6F2',
    '#EAF7EE',
  ]);
  const [arrows, setArrows] = useState(() =>
    props.policyCards.map(() => 'left')
  );

  const [dots, setDots] = useState(() => props.policyCards.map(() => '.'));

  const [firstRender, setFirsRender] = useState(true);

  const [currentActiveDot, setCurrentActiveDot] = useState(0);

  const [ref0, inView0] = useInView({
    onChange: () => {
      if (inView0) {
        setCurrentActiveDot(0);
        setFirsRender(false);
        // const arrows2 = arrows;
        // if (arrows2[0] === 'left') {
        //   arrows2[0] = 'right';
        //   setArrows(arrows2);
        // } else {
        //   arrows2[0] = 'left';
        //   setArrows(arrows2);
        // }
      }
    },
    threshold: 1,
  });

  const [ref1, inView1] = useInView({
    onChange: () => {
      if (inView1) {
        setCurrentActiveDot(1);
        // const arrows2 = arrows;
        // if (arrows2[0] === 'left') {
        //   arrows2[0] = 'right';
        //   setArrows(arrows2);
        // } else {
        //   arrows2[0] = 'left';
        //   setArrows(arrows2);
        // }
      }
    },
    threshold: 0.7,
  });

  const [ref2, inView2] = useInView({
    onChange: () => {
      if (inView2) {
        setCurrentActiveDot(2);
      }
    },
    threshold: 1,
  });

  const [ref3, inView3] = useInView({
    onChange: () => {
      if (inView3) {
        setCurrentActiveDot(3);
      }
    },
    threshold: 1,
  });
  const [ref4, inView4] = useInView({
    onChange: () => {
      if (inView4) {
        setCurrentActiveDot(4);
      }
    },
    threshold: 1,
  });
  const [ref5, inView5] = useInView({
    onChange: () => {
      if (inView5) {
        setCurrentActiveDot(5);
      }
    },
    threshold: 1,
  });

  const [ref6, inView6] = useInView({
    onChange: () => {
      if (inView6) {
        setCurrentActiveDot(6);
      }
    },
    threshold: 1,
  });
  const [ref7, inView7] = useInView({
    onChange: () => {
      if (inView7) {
        setCurrentActiveDot(7);
      }
    },
    threshold: 1,
  });

  const [ref8, inView8] = useInView({
    onChange: () => {
      if (inView8) {
        setCurrentActiveDot(8);
      }
    },
    threshold: 1,
  });
  const [ref9, inView9] = useInView({
    onChange: () => {
      if (inView9) {
        setCurrentActiveDot(9);
      }
    },
    threshold: 1,
  });

  const [ref10, inView10] = useInView({
    onChange: () => {
      if (inView10) {
        setCurrentActiveDot(10);
      }
    },
    threshold: 1,
  });

  const refs = [
    ref0,
    ref1,
    ref2,
    ref3,
    ref4,
    ref5,
    ref6,
    ref7,
    ref8,
    ref9,
    ref10,
  ];
  const views = [
    inView0,
    inView1,
    inView2,
    inView3,
    inView4,
    inView5,
    inView6,
    inView7,
    inView8,
    inView9,
    inView10,
  ];

  const renderEcards = (cards) => {
    return cards.map((card, idx) => {
      const itsOpd = card.cardType === 'opdCard';
      const covered = itsOpd ? ['Self'] : [];
      const singleCard = cards.length === 1 ? 'single-card' : '';
      const members = card.details.covered
        ? card.details.covered.split(',')
        : [];
      covered.push(...members);

      return (
        <div
          className="flex flex-row items-center justify-center"
          style={idx === cards.length - 1 ? { marginRight: '15px' } : {}}
        >
          <Ecard
            id="policy-card"
            ref={refs[idx]}
            className={`relative overflow-hidden ${singleCard}`}
            style={{
              background: `${colors[idx]}`,
              backgroundImage: `url(${
                itsOpd ? BackdropCircleIcon : IpdBackdropCircleIcon
              })`,
              objectFit: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom right',
              backgroundSize: '250px',
            }}
          >
            <div
              className="w-full px-16px flex flex-row items-center justify-between pt-22px"
              // id={`policy-card${idx}`}
            >
              <PolicyType>{itsOpd ? 'OPD Policy' : 'IPD Policy'}</PolicyType>
              <ExpiryDate>
                Exp Date:{' '}
                {moment(card.details.terminatedAt).format('DD-MM-YYYY')}
              </ExpiryDate>
            </div>
            <PolicyNumber>{card.details.policyNumber}</PolicyNumber>
            <div className="pt-12px">
              <Name>{card.details.policyHolderName}</Name>
              <PolicyNumber style={{ fontWeight: '700', fontSize: '12px' }}>
                {card.details.age} Years, Male
              </PolicyNumber>
            </div>
            <div className="pt-24px flex flex-row items-center justify-between pl-16px">
              <div className="flex flex-col items-start">
                <div className="flex flex-row">
                  {covered.map((cover, id) => (
                    <div className="flex flex-row items-center justify-center">
                      <Covered>{cover}</Covered>
                      {covered[id + 1] && (
                        <div
                          style={{
                            width: '6px',
                            height: '6px',
                            background: '#8655D6',
                            borderRadius: '50%',
                            marginRight: '6px',
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
                </div>
                <SumInsured>
                  Sum Insured:
                  <Covered style={{ marginLeft: '4px' }}>
                    {Number(card.details.sumInsured)}
                  </Covered>
                </SumInsured>
              </div>
              <InsurerContainer>
                <img
                  src={card.details.insurerLogo}
                  style={{
                    height: '64px',
                    width: '72px',
                  }}
                />
              </InsurerContainer>
            </div>
          </Ecard>
          {idx !== cards.length - 1 && (
            <div
              id="scroll-next"
              className="flex items-center justify-center cursor-pointer"
              style={{
                background: '#F8F8F9',
                width: '28px',
                height: '28px',
                borderRadius: '50%',
                marginLeft: '-4px',
                zIndex: '12',
                marginRight: '-4px',
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
              }}
              onClick={() => {
                const ele = document.querySelector('#root-scroll');
                const cardSize =
                  document.getElementById('policy-card').clientWidth;
                const arrows2 = [...arrows];
                if (arrows2[idx] === 'left') {
                  ele.scrollLeft += cardSize + 18;
                  arrows2[idx] = 'right';
                  setArrows(arrows2);
                  console.log(idx + 1);
                  setCurrentActiveDot(currentActiveDot + 1);
                } else {
                  ele.scrollLeft -= cardSize + 18;
                  arrows2[idx] = 'left';
                  setArrows(arrows2);
                  console.log(idx - 1);
                  setCurrentActiveDot(currentActiveDot - 1 ? idx - 1 : 0);
                }
              }}
            >
              {arrows[idx] === 'left' ? <RightArrow /> : <LeftArrow />}
            </div>
          )}
        </div>
      );
    });
  };
  return (
    <CardContainer>
      <SubHeadings>My Policy</SubHeadings>
      <div
        className="flex overflow-x-scroll justify-start mt-12px"
        style={{ paddingLeft: '30px' }}
        id="root-scroll"
      >
        {props.policyCards.length > 0 && renderEcards(props.policyCards)}
        {/* {this.state.opdCards.length > 0 &&
          renderEcards(this.state.opdCards, 'ipd')} */}
      </div>
      <DotsContainer>
        {dots.length >= 2 &&
          dots.map((d, idx) => {
            return <>{idx === currentActiveDot ? <ActiveDot /> : <Dot />}</>;
          })}
      </DotsContainer>
    </CardContainer>
  );
};

export default EcardsContainer;
