import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingComponent from '../../components/common/LoadingComponent';
import closeIcon from '../../images/lab-test/close.svg';
import covidHeader from '../../images/covid/header.svg';
import teleIcon from '../../images/covid/tele.svg';
import diagnosticIcon from '../../images/covid/diagnostic.svg';
import hotlineIcon from '../../images/covid/helpline.svg';
import prescriptionIcon from '../../images/covid/prescription.svg';
import activeIcon from '../../images/covid/active.svg';
import specialistIcon from '../../images/covid/specialist.svg';
import phoneIcon from '../../images/covid/phone.svg';
import { initializeAmplitudeClient } from '../../services/analytics';
import config from '../../config';
import { getCovidHomeCareDashboard } from '../../services';
import { MdContentCopy } from 'react-icons/md';
import styled from 'styled-components';
import { logEvent } from '../../utils/logEvent';

const CopyIcon = styled(MdContentCopy)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #27ae60;
`;

class CovidHomeCareActive extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      packages: [],
      consultBanners: [],
      copied: false,
    };
    const { state } = props.location;
    console.log(state, props);
  }
  componentDidMount() {
    // GA Tracking
    window.gtag('event', 'screen_view', {
      screen_name: 'Covid Home Care Active Page',
    });
    this.amplitudeClient = initializeAmplitudeClient();
    this.amplitudeClient.init();
    logEvent('Covid Home Care Active Page');
    this.getCovidHomeCareDashboard();
  }

  closeView = () => {
    if (typeof window.Android !== 'undefined') {
      return window.Android.closeView();
    }
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'closeView',
        })
      );
    }
  };

  hideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  getCovidHomeCareDashboard = () => {
    const { authToken } = this.props;
    getCovidHomeCareDashboard(authToken)
      .then((res) => {
        this.setState({
          packages: res.labCards,
          consultBanners: res.consultCards || [],
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  bookCovidConsult = () => {
    logEvent('Covid Specialist Selected');
    if (
      window.Android !== undefined &&
      window.Android.openDoctorVerticalScreen
    ) {
      window.Android.openDoctorVerticalScreen('treat-covid');
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'openDoctorVerticalScreen',
          prereqsType: 'treat-covid',
        })
      );
    }
  };

  openPackage = (ele) => {
    if (!this.props.authToken) {
      this.props.history.replace('/login?rt=covid-packages');
      return;
    }
    this.props.history.replace(`/lab-test/covid-package/${ele.packageId}`, {
      fromCovidHomeCareDashboard: true,
    });
    logEvent('Covid Package Selected', {
      packageId: ele.packageId,
    });
  };

  openConsult = (ele) => {
    const { history } = this.props;
    if (ele.action === 'covidGuidelinesBanner') {
      this.amplitudeClient.logEvent('Covid Vaccine Guideline Card Click');
      history.push('/lab-test/covid-guidelines', {
        fromCovidHomeCareDashboard: true,
      });
    }
    if (ele.action === 'showDoctors') {
      if (window.Android !== undefined && window.Android.openDoctorListScreen) {
        window.Android.openDoctorListScreen(ele.verticalId);
      } else if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            method: 'openDoctorListScreen',
            doctorVerticalId: ele.verticalId,
          })
        );
      }
      logEvent('Covid GP Selected');
    }
    if (ele.action === 'showVerticals') {
      if (
        window.Android !== undefined &&
        window.Android.openDoctorVerticalScreen
      ) {
        window.Android.openDoctorVerticalScreen(ele.preReqTypes);
      } else if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            method: 'openDoctorVerticalScreen',
            prereqsType: ele.preReqTypes,
          })
        );
      }
      logEvent('Covid Specialist Selected');
    }
  };

  onCopy = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    const hotlineNumber = document.querySelector('#hotline-number');
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      hotlineNumber.click();
      return;
    }

    var textField = document.createElement('textarea');
    textField.innerText = '01143092060';
    document.body.appendChild(textField);
    textField.select();
    const success = document.execCommand('copy');
    textField.remove();
    if (success) {
      this.setState({
        copied: true,
      });
    }
  };

  render() {
    const { packages, consultBanners } = this.state;
    return (
      <div className="overflow-auto w-full">
        <div className="mb-120px">
          <div
            className="header w-full pl-20px pt-20px flex flex-row justify-between items-start"
            style={{ background: '#E7FAFF', color: '#2D0000' }}
          >
            <div className="flex flex-col mb-48px self-end">
              <div className="flex flex-row items-center">
                <img src={activeIcon} alt="active" />
                <p className="ml-4px text-body-l font-semibold text-secondary-shades6">
                  Active
                </p>
              </div>
              <div className="mt-8px pr-20px">
                <p className="text-title-l font-bold">COVID-19</p>
                <p className="text-title-l font-bold">Homecare Plan</p>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <img
                src={closeIcon}
                alt="close"
                className="hover"
                onClick={() => {
                  this.closeView();
                }}
              />
              <img
                src={covidHeader}
                alt="covid-19"
                className="self-end"
                style={{ width: '170px' }}
              />
            </div>
          </div>
          <div className="body px-20px py-24px">
            <div className="flex flex-row flex-wrap justify-between items-start">
              {packages.map((ele, idx) => {
                return (
                  <img
                    src={ele.bannerImageUrl}
                    key={ele.packageId}
                    alt="package"
                    onClick={() => this.openPackage(ele)}
                    className="hover pt-16px"
                    style={
                      ele.bannerImageOrientation === 'vertical'
                        ? {
                            width: '45%',
                            maxWidth: '50%',
                            marginRight:
                              idx % 2 === 1 || idx === 0 ? '16px' : 0,
                            flex: 'auto',
                          }
                        : {
                            width: '100%',
                          }
                    }
                  />
                );
              })}
              {consultBanners.map((ele, idx) => {
                return (
                  <img
                    src={ele.bannerImageUrl}
                    key={ele.id}
                    alt="package"
                    onClick={() => this.openConsult(ele)}
                    className="hover pt-16px"
                    style={
                      ele.bannerImageOrientation === 'vertical'
                        ? {
                            width: '45%',
                            marginRight:
                              idx % 2 === 1 || idx === 0 ? '16px' : 0,
                            flex: 'auto',
                          }
                        : {
                            width: '100%',
                          }
                    }
                  />
                );
              })}
            </div>
            <div className="bg-txtsecondary-shades4 bg-opacity-50 rounded-12 mt-20px p-16px">
              <p className="text-title-m font-bold text-txtlight">
                Other Benefits
              </p>
              <div className="flex flex-col mt-20px">
                <div className="flex flex-row items-start mb-24px">
                  <img src={teleIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    A COVID Specialist doctor connects 7 times in a period of 15
                    days
                  </p>
                </div>
                <div className="flex flex-row items-start mb-24px">
                  <img src={specialistIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    Access to consultations with COVID Specialists,
                    Pulmonologists & Psychologists
                  </p>
                </div>
                <div className="flex flex-row items-start mb-24px">
                  <img src={diagnosticIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    An RT PCR home collection is provided to every employee
                    signing up for the program
                  </p>
                </div>
                <div className="flex flex-row items-start mb-24px">
                  <img src={prescriptionIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    Follow up with Care Specialists for regular monitoring of
                    Vitals & Symptoms
                  </p>
                </div>
                <div className="flex flex-row items-start mb-12px">
                  <img src={hotlineIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    A dedicated 24x7 emergency helpline for urgent query
                    resolutions
                  </p>
                </div>
                <a
                  class="cursor pointer text-body-l font-regular text-secondary ml-4px"
                  id="hotline-number"
                  style={{ display: 'none' }}
                  href="tel:01143092060"
                >
                  01143092060
                </a>
                <div
                  className="flex flex-row items-center py-10px justify-center mb-12px rounded-60"
                  style={{ border: '1px solid #01BFBD' }}
                  onClick={this.onCopy}
                >
                  {this.state.copied ? (
                    <>
                      <CopyIcon />
                      <p className="ml-6px text-body-l font-medium text-txtlight">
                        Number Copied
                      </p>
                    </>
                  ) : (
                    <>
                      <img src={phoneIcon} className="mr-6px" alt="call" />
                      <p className="text-body-l font-medium text-txtlight">
                        Connect with executive directly
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  insured: state.app.config.insured,
  user: state.user,
});
export default connect(mapStateToProps)(CovidHomeCareActive);
