import React from 'react';
import styled from 'styled-components';

import { Text, BoldText } from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import { PrimaryButton } from '../../components/common/Buttons';

import subscriptionIcon from '../../images/onboarding/subscription-logo.svg';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 1.25rem;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const SubtitleText = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #848484;
  margin-top: 0.5rem;
`;

const SubscriptionIcon = styled.img`
  margin-top: 1.375rem;
  width: 4.625rem;
`;

const BodyText = styled(BoldText)`
  font-size: ${() => `${1.125 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-top: 1.625rem;
`;

const PoweredText = styled(BoldText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  opacity: 0.5;
  margin-top: 2rem;
`;

const PolicyText = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-top: 1.75rem;
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: 1.5rem;
`;

const ButtonText = styled(BoldText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: white;
  text-transform: uppercase;
`;

const SubscriptionActivated = ({ location, history }) => {
  console.log(location);
  if (!location.state) {
    history.push('/');
    return null;
  }
  return (
    <FadeInTransition in={true} timeout={200} mountOnEnter unmountOnExit appear>
      <OuterContainer>
        <TitleText>{location.state.userName}</TitleText>
        <SubtitleText>{`${location.state.userGender}, ${location.state.userAge} years`}</SubtitleText>
        <SubscriptionIcon src={subscriptionIcon} />
        <BodyText>VISIT OPD Benefits</BodyText>
        <BodyText>Activated Successfully</BodyText>
        <PoweredText>Powered by</PoweredText>
        <PolicyText>{`Policy No.: ${location.state.policyNumber}`}</PolicyText>
        <StyledButton
          onTap={() => {
            history.push('/one-last-step');
          }}
        >
          <ButtonText>continue</ButtonText>
        </StyledButton>
      </OuterContainer>
    </FadeInTransition>
  );
};

export default SubscriptionActivated;
