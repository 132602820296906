import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Header from '../../components/common/Header';
import PrescriptionStatusCard from '../../components/lab-test/StatusCard';
import { getAllLabsOrder, getDigitsationStatus } from '../../services/lab-test';
import LoadingComponent from '../../components/common/LoadingComponent';
import arrowLeft from '../../images/common/arrow-left.svg';
import noTests from '../../images/lab-test/noLab.svg';
import { PrimaryButton } from '../../components/common/Buttons';
import { logEvent } from '../../utils/logEvent';
import { setCurrentActiveTab } from '../../actions';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: white;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  z-index: 999;
  background: #ffffff;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const OrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  padding-top: 0;
  align-self: stretch;
  align-items: stretch;
`;

class AllLabOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      loading: true,
    };
    // const { state } = props.location;

    // if (state) {
    //   this.goBack = state.goBack;
    //   this.goHome = state.goHome;
    // }
  }

  componentDidMount = () => {
    // GA Tracking
    window.gtag('event', 'screen_view', {
      screen_name: 'Labs Home Screen',
    });
    logEvent('Labs Orders Screen');
    this.getOrders();
    this.props.dispatch(setCurrentActiveTab('labs-orders'));
  };

  getOrders() {
    const { authToken } = this.props;
    getAllLabsOrder(authToken)
      .then((res) => {
        console.log(res);
        const { cards } = res;
        this.setState({
          orders: cards,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  openOrder(order) {
    const {
      cardType: type,
      orderId,
      digitisationId,
      cartId,
      reimbursementId,
      status,
      testsType,
    } = order;
    const { history, authToken } = this.props;
    // console.log(orderId)
    history.push('/lab-test/order-status', {
      orderId: orderId,
      digitisationId: digitisationId,
      cartId: cartId,
    });
    // if (type === 'booked-order') {
    //   logEvent('Labs Dashboard Order Booked Card', {
    //     orderId,
    //   });
    //   if (status === 'payment-pending') {
    //     return history.push('/lab-test/copay', {
    //       orderId,
    //       goBack: true,
    //     });
    //   } else {
    //     history.push('/lab-test/track', {
    //       orderId,
    //       goBack: true,
    //     });
    //   }
    // } else if (type === 'pending-digitisation') {
    //   logEvent('Labs Report Pending Digitization Card Click');
    //   // switch (action) {
    //   //   case 'openOrder': {
    //   getDigitsationStatus(digitisationId, authToken).then((res) => {
    //     console.log(res);
    //     const { message } = res;
    //     if (message === 'success') {
    //       history.push('/lab-test/upload-success', {
    //         res,
    //         goBack: true,
    //       });
    //       return;
    //     }
    //   });
    //     break;
    //   }
    //   case 'payment': {
    //     history.push('/lab-test/order-summary', {
    //       cartId,
    //       paymentNext: true,
    //     });
    //     break;
    //   }
    //   default: {
    //     break;
    //   }
    // }
    // } else if (type === 'available-cart') {
    //   logEvent('Labs Prescription Visit Doctor Recommended Test', {
    //     cartId: cartId,
    //   });
    //   // logEvent('Labs Available Carts',{
    //   //   cartId: cartId
    //   // });
    //   history.push('/lab-test/review-and-book', {
    //     cartId: cartId,
    //     testsType,
    //   });
    // } else if (type === 'reimbursement') {
    //   logEvent('Labs Reimbursement Status Card Click');
    //   history.push('/lab-test/reimbursement-detail', {
    //     reimbursementId: reimbursementId,
    //   });
    // }
  }

  render() {
    const { orders, loading } = this.state;
    const { history } = this.props;

    const renderOrder = (ele) => {
      return (
        <PrescriptionStatusCard
          key={ele.orderId || ele.requestId || ele.digitisationId}
          orderRequest={ele}
          onTap={() => this.openOrder(ele)}
          dontShowPickupTime={true}
        />
      );
    };

    return (
      <OuterContainer>
        <div className="header p-16px pt-0 mt-48px border-b border-txtsecondary-shades4 flex flex-row items-center justify-between">
          <div className="flex flex-row items-center cursor-pointer">
            <img
              src={arrowLeft}
              alt="back"
              onClick={() => {
                this.props.history.goBack();
              }}
            />
            <p className="text-title-m font-semibold text-txtlight">
              My orders
            </p>
          </div>
        </div>

        {loading ? (
          <LoadingComponent />
        ) : (
          <BodyContainer>
            {orders.length > 0 ? (
              <OrdersContainer>{orders.map(renderOrder)}</OrdersContainer>
            ) : (
              <div className="flex flex-col justify-center items-center w-full h-full">
                <img src={noTests} alt="no test" />
                <p
                  className="text-body-l text-brochure text-center"
                  style={{ marginTop: '34px', marginBottom: '34px' }}
                >
                  You don't have an ongoing lab tests, click below to request a
                  lab test.
                </p>
                <PrimaryButton
                  className="w-full text-center"
                  onTap={() => history.push('/lab-test')}
                >
                  <div className="text-body-l font-bold py-4px">
                    Book Lab Test
                  </div>
                </PrimaryButton>
              </div>
            )}
          </BodyContainer>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  insured: state.app.config.insured,
});

export default connect(mapStateToProps)(AllLabOrders);
