/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import UploadingComponent from '../../components/common/UploadingComponent';
import Header from '../../components/common/Header';
import StyledTappable from '../../components/common/StyledTappable';
import { FaTimes } from 'react-icons/fa';
import pdfIcon from '../../images/lab-test/pdf-icon.svg';
import config from '../../config';

import sparkleAnimation from '../../images/crosswordImages/sparkle.json';
import completeTickAnimation from '../../images/crosswordImages/tick.json';
import Modal from '../common/Modal';
import LoadingComponent from '../../components/common/LoadingComponent';
import UploadModal from '../../components/common/UploadModal';
import { PrimaryButton } from '../../components/common/Buttons';
import { ButtonText } from '../../components/common/Text';
import eyeIcon from '../../images/common/eye.svg';
import { logEvent } from '../../utils/logEvent';
import { uploadMaternityDocuments } from '../../services/maternity-care-program';
import Lottie from 'react-lottie';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: hidden;
  width: 100%;
`;

const StyledUploadingComponent = styled(UploadingComponent)`
  margin: 0;
`;

const AttachContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
`;

const AttachedFilesContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1rem;
  flex-wrap: wrap;
`;

const CloseContainer = styled(StyledTappable)`
  position: absolute;
  top: -5px;
  right: -5px;
  background: #e86570;
  border-radius: 6px;
  padding: 2px;
`;

const CloseIcon = styled(FaTimes)`
  font-size: 14px;
  color: white;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

class MaternityUploadPrescriptionDemo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      uploadProgress: 0,
      uploadedConfirmationReport: [],
      borderConfirmationReport: '#A5A6BB',
      uploadedUltraSound: [],
      borderUltraSound: '#A5A6BB',
      loading: false,
      showModal: false,
      finalModal: false,
      uploadDoctype: false,
      showUploadOption: false,
      showOption: '',
      samplePrescription: null,
      faq: [],
    };
  }

  componentDidMount = () => {
    // GA Tracking
    window.gtag('event', 'screen_view', {
      screen_name: 'Pharmacy Upload Prescription Screen',
    });

    logEvent('Maternity Document Verification');
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.hasError !== this.props.hasError && this.props.hasError) {
      this.setState({
        uploading: false,
        uploadProgress: 0,
      });
    }
    if (
      !this.state.showUploadOption &&
      this.state.showUploadOption !== prevState.showUploadOption &&
      this.state.showModal
    ) {
      setTimeout(() => {
        this.setState({ showModal: false });
      }, 200);
    }
  };

  hideModal = () => {
    this.setState({
      showUploadOption: false,
    });
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.hideModal();
  };

  onAttachPhoto() {
    document.querySelector('#photoAttachment').click();
  }

  onAttachFile() {
    document.querySelector('#fileAttachment').click();
  }

  handleAttachment(type) {
    const { uploadDoctype } = this.state;
    console.log(uploadDoctype);
    let inputElem = document.getElementById('fileAttachment');
    if (type === 'photo') {
      inputElem = document.getElementById('photoAttachment');
    }
    // const { prescriptionId } = this.state;
    // const { authToken } = this.props;

    const file = inputElem.files[0];
    console.log(file);
    if (!file) {
      return;
    }
    if (uploadDoctype === 'pregnancy_confirmation') {
      const { uploadedConfirmationReport } = this.state;
      uploadedConfirmationReport.push(file);
    } else if (uploadDoctype === 'ultrasound_report') {
      const { uploadedUltraSound } = this.state;
      uploadedUltraSound.push(file);
    }
    this.setState({
      uploading: false,
      showModal: false,
      uploadProgress: 100,
    });

    // const data = new FormData();

    // data.append('file', file);
    // if (prescriptionId) {
    //   data.append('prescriptionId', prescriptionId);
    // }
    // this.setState({
    //   uploading: true,
    //   showModal: false,
    // });
    // const onUploadProgress = (progressEvent) => {
    //   if (progressEvent.lengthComputable) {
    //     const { loaded, total } = progressEvent;
    //     this.setState({
    //       uploadProgress: (loaded / total) * 100,
    //     });
    //   } else {
    //     this.setState({
    //       uploadProgress: 100,
    //     });
    //   }
    // };

    // uploadPrescription(data, onUploadProgress, authToken)
    //   .then((res) => {
    //     console.log(res);
    //     this.setState({
    //       uploading: false,
    //       prescriptionId: res.fileInfo.prescriptionId,
    //     });
    //     logEvent('Document Upload Completed', {
    //       screenName: '',
    //       categoryType: 'Labs',
    //     });
    //     this.hideModal();
    //     this.fetchPrescription();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     logEvent('Document Upload Failed', {
    //       screenName: '',
    //       categoryType: 'Labs',
    //       reason: err,
    //     });
    //   });
  }

  removeFile(fileIndex, fileType) {
    const { uploadedConfirmationReport, uploadedUltraSound } = this.state;

    if (fileType === 'pregnancy_confirmation') {
      const updatedUploadFiles = Array.from(uploadedConfirmationReport);
      updatedUploadFiles.splice(fileIndex, 1);
      this.setState({
        uploadedConfirmationReport: updatedUploadFiles,
      });
    } else if (fileType === 'ultrasound_report') {
      const updatedUploadFiles = Array.from(uploadedUltraSound);
      updatedUploadFiles.splice(fileIndex, 1);
      this.setState({
        uploadedUltraSound: updatedUploadFiles,
      });
    }
  }

  // redirectToPaymentPage = () => {
  //   //subPlanTermId for maternity plan is 17 in prod
  //   //and 17 in staging
  //   const { authToken } = this.props;
  //   window.location.href = `${
  //     config.apiBaseUrl
  //   }/subscription/transact?subPlanTermId=17&amount=5000&memberCount=${1}&auth=${
  //     authToken.split(' ')[1]
  //   }`;
  // };

  onProceed = () => {
    this.setState({ updating: true });
    const data = new FormData();

    for (let i = 0; i < this.state.uploadedConfirmationReport.length; i++) {
      data.append('confirmationDoc', this.state.uploadedConfirmationReport[i]);
    }
    // for (let i = 0; i < this.state.uploadedUltraSound.length; i++) {
    //   data.append('ultrasound', this.state.uploadedUltraSound[i]);
    // }
    uploadMaternityDocuments(data, this.props.authToken)
      .then((res) => {
        this.setState({ finalModal: true });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ updating: false });
      });
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.hideModal();
  };

  setOptionView = (ele) => {
    if (ele === this.state.showOption) {
      this.setState({
        showOption: null,
      });
    } else {
      this.setState({
        showOption: ele,
      });
    }
  };

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: sparkleAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    const tickOptions = {
      loop: false,
      autoplay: true,
      animationData: completeTickAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    const {
      loading,
      uploadProgress,
      uploading,
      uploadedUltraSound,
      uploadedConfirmationReport,
      showModal,
      borderConfirmationReport,
      borderUltraSound,
    } = this.state;

    const renderUploadedFile = (ele, idx, fileType) => {
      console.log(ele, 'rendering doc');
      if (ele.type === 'application/pdf') {
        return (
          <div
            className="relative mr-24px w-52px h-76px mt-8px rounded-12 bg-contain bg-no-repeat"
            style={{ backgroundImage: `url(${pdfIcon})` }}
            onClick={() => {
              document
                .querySelector(`#image-anchor-${fileType}_${idx}`)
                .click();
            }}
          >
            <CloseContainer onTap={() => this.removeFile(idx, fileType)}>
              <CloseIcon />
            </CloseContainer>
            <a
              href={URL.createObjectURL(ele)}
              target="_blank"
              rel="noopener noreferrer"
              id={`image-anchor-${fileType}_${idx}`}
              style={{ display: 'none' }}
            >
              {}
            </a>
          </div>
        );
      }
      return (
        <div
          className="relative mr-24px w-52px h-76px mt-8px rounded-12 bg-cover bg-no-repeat bg-opacity-20 custom-opacity"
          style={{ backgroundImage: 'url(' + URL.createObjectURL(ele) + ')' }}
        >
          <CloseContainer
            onTap={() => this.removeFile(idx, 'pregnancy_confirmation')}
          >
            <CloseIcon />
          </CloseContainer>
          <a
            href={URL.createObjectURL(ele)}
            target="_blank"
            rel="noopener noreferrer"
            id={`image-anchor-${fileType}_${idx}`}
            style={{ display: 'none' }}
          >
            {}
          </a>
          <img
            onClick={() => {
              document
                .querySelector(`#image-anchor-${fileType}_${idx}`)
                .click();
            }}
            src={eyeIcon}
            alt="eye"
            className="absolute top-24px left-12px"
          />
        </div>
      );
    };

    return (
      <OuterContainer>
        <Header title="Verify Documents" hideBackButton={true} />
        <div className="overflow-auto pb-100px">
          {uploading && (
            <StyledUploadingComponent
              uploadProgress={`${uploadProgress}%`}
              borderRadius={0}
            />
          )}
          <input
            type="file"
            value=""
            id="photoAttachment"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={() => this.handleAttachment('photo')}
          />
          <input
            type="file"
            value=""
            id="fileAttachment"
            accept=".pdf"
            style={{ display: 'none' }}
            onChange={() => this.handleAttachment('file')}
          />

          <AttachContainer className="pt-20px  pr-20px pl-20px">
            <div className="flex flex-row items-center justify-between">
              <span
                className="text-body-l font-regular text-txtlight"
                style={{ fontWeight: 600 }}
              >
                Upload Supporting Documents
              </span>
              <span
                className="pl-7px pr-7px ml-10px rounded-xl"
                style={{
                  backgroundColor: '#F4F0FF',
                  fontSize: '9px',
                  fontFamily: 'Montserrat',
                }}
              >
                Original Required
              </span>
            </div>
            <div
              className="border border-solid pl-20px rounded-2xl mt-2"
              style={{ borderColor: borderConfirmationReport }}
            >
              <AttachedFilesContainer>
                {uploadedConfirmationReport.map((ele, idx) =>
                  renderUploadedFile(ele, idx, 'pregnancy_confirmation')
                )}
                {uploadedConfirmationReport.length < 2 && (
                  <div
                    className="dashed-add w-52px h-76px "
                    style={{
                      borderRadius: '12px',
                      borderColor: '#E3E3E3',
                      color: '#585969',
                      marginTop: '8px',
                    }}
                    onClick={() => {
                      this.setState({
                        borderConfirmationReport: '#01BFBD',
                        borderUltraSound: '#A5A6BB',
                        uploadDoctype: 'pregnancy_confirmation',
                        showModal: true,
                        showUploadOption: true,
                      });
                    }}
                  >
                    <span
                      style={{
                        color: borderConfirmationReport,
                        fontSize: '25px',
                      }}
                    >
                      +
                    </span>
                  </div>
                )}
              </AttachedFilesContainer>
            </div>
          </AttachContainer>
          <div
            className="pt-20px  pr-20px pl-20px"
            style={{ fontSize: '14px' }}
          >
            <span
              className="text-body-l font-regular text-txtlight"
              style={{ fontWeight: 600 }}
            >
              List of Eligible Documents
            </span>
            <p
              style={{
                color: '#767787',
                fontSize: '12px',
                marginTop: '10px',
                marginBottom: '15px',
                fontWeight: 500,
              }}
            >
              Prescription / Report confirming pregnancy and due date.
            </p>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid #01BFBD',
                margin: '10px 0 15px',
                lineHeight: '0.1em',
              }}
            >
              <span
                style={{
                  background: '#fff',
                  padding: '0 10px',
                  fontSize: '12px',
                  color: '#767787',
                  fontWeight: 500,
                }}
              >
                OR
              </span>
            </div>
            <p style={{ color: '#767787', fontSize: '12px', fontWeight: 500 }}>
              Discharge summary or consultation report mentioning the delivery
              date.
            </p>
            <div
              style={{
                background: '#FFF3EF',
                borderRadius: '12px',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '8px',
                paddingBottom: '8px',
                marginTop: '20px',
              }}
            >
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: 500,
                  zIndex: '2',
                  color: '#767787',
                }}
              >
                These documents will be sent for verification.
              </p>
              <p
                style={{ fontSize: '12px', fontWeight: 500, color: '#767787' }}
              >
                This process usually takes 48-72 hours to complete.
              </p>
            </div>
          </div>
          {showModal && (
            <UploadModal
              hideModal={this.hideModal}
              handleAttachment={this.handleAttachment}
            />
          )}
        </div>

        {uploadedConfirmationReport.length > 0 && (
          <div
            className="pt-42px pl-20px pr-20px pb-32px absolute bottom-0px w-full"
            style={{
              background:
                'linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 31.77%, #FFFFFF 66.15%, rgba(255, 255, 255, 0) 100%)',
            }}
          >
            <div
              className="bg-primary rounded-12 pt-12px pb-10px center"
              onClick={this.onProceed}
            >
              {!this.state.updating ? (
                <>
                  <span className="text-button-s font-bold text-textbox mr-10px">
                    Submit{' '}
                  </span>
                </>
              ) : (
                <span className="text-button-s font-bold text-textbox mr-10px">
                  Loading....
                </span>
              )}
            </div>
          </div>
        )}
        {loading && (
          <Modal>
            <ModalContainer>
              <LoadingComponent />
            </ModalContainer>
          </Modal>
        )}
        {this.state.finalModal && (
          <Modal>
            <ModalContainer>
              <div
                className="mt-10px mb-20px -mx-10px rounded-3xl"
                style={{
                  textAlign: 'center',
                  backgroundColor: 'white',
                  maxWidth: '300px',
                }}
              >
                <div
                  className="relative mx-auto pt-50"
                  style={{ width: '100%', height: '270px' }}
                >
                  <div className="absolute m-auto left-0 right-0">
                    <Lottie
                      options={defaultOptions}
                      speed={0.8}
                      width={374}
                      height={219}
                    />
                  </div>
                  <div className="absolute m-auto left-0 right-0">
                    <Lottie
                      options={tickOptions}
                      speed={0.8}
                      width={208}
                      height={208}
                    />
                  </div>
                </div>
                <div className="mt-15px " style={{ textAlign: 'center' }}>
                  <p className="text-primary font-bold text-title-m2">
                    Documents Successfully Uploaded!
                  </p>
                  <p className="m-2 text-txtsecondary font-medium text-title-s">
                    These documents will be sent to Paramount TPA for
                    verification. This process might take 48-72 hours to
                    complete.
                  </p>
                </div>
                <div className="m-20 ">
                  <PrimaryButton
                    onTap={() => {
                      if (typeof window.Android !== 'undefined') {
                        return window.Android.closeView();
                      } else if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({
                            method: 'closeView',
                          })
                        );
                      } else {
                        this.props.history.replace('/', {
                          disableBack: true,
                        });
                      }
                    }}
                  >
                    <ButtonText>Okay</ButtonText>
                  </PrimaryButton>
                </div>
              </div>
            </ModalContainer>
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  hasError: state.app.appError.hasError,
  insured: state.app.config.insured,
});

export default connect(mapStateToProps)(MaternityUploadPrescriptionDemo);
