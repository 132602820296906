import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

import { store } from '../store';

export function reimbursementCategory(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/reimbursements/v2/categories`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementCategoryDetail(categoryId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/reimbursements/v2/procedures/${categoryId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementRequestSubmit(formData, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(`${config.apiBaseUrl}/reimbursements/v2/reimbursements`, formData, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else if (responseBody.message === 'warn') {
        const err = new Error('Invalid response');
        err.data = responseBody;
        err.data.errorHeader = 'Warning';
        err.data.errorMessage = responseBody.warningMessage;
        showError(err);
        return responseBody;
      } else {
        if (responseBody.hospitalization) {
          return responseBody;
        }
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementCategoryDetailV1(categoryId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/reimbursements/procedures/${categoryId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementRequestSubmitV1(formData, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(`${config.apiBaseUrl}/reimbursements/reimbursements`, formData, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else if (responseBody.message === 'warn') {
        const err = new Error('Invalid response');
        err.data = responseBody;
        err.data.errorHeader = 'Warning';
        err.data.errorMessage = responseBody.warningMessage;
        showError(err);
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateBankDetails(formData, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(`${config.apiBaseUrl}/users/addBankDetails`, formData, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function emergencyReimbursement(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/reimbursements/v2/emergency-reimbursement`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementSteps(categoryId, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/reimbursements/v2/steps?categoryId=${categoryId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimburseUserRelative(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/reimbursements/v2/user-relatives`, { headers })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimburseCategoryProcedure(authToken, categoryId) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/reimbursements/v2/procedures/${categoryId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
