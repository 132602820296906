import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

import { store } from '../store';
import { logEvent } from '../utils/logEvent';

export function PharmacyDashboard(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.pharmaServer}/newDashboard`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getPharmacyPartners(lat, long, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/medicine-delivery/v2/partners?lat=${lat}&lng=${long}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getPharmacyPatients(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/medicine-delivery/v2/patients`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getPharmacyAddresses(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/medicine-delivery/v2/addresses`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadPrescriptionForMedDashboard(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.pharmaServer}/digitisation/upload-prescription`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadPrescriptionForMed(
  requestData,
  onUploadProgress,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/digitisation/add-multiple-files`,
      requestData,
      {
        headers,
        onUploadProgress,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadInvoiceForMed(requestData, onUploadProgress, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/medicine-delivery/v2/upload-invoice`,
      requestData,
      {
        headers,
        onUploadProgress,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeFileFromPrescriptionMeds(
  fileId,
  prescriptionId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/digitisation/prescriptions/file/remove`,
      { prescriptionId, fileId },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getDigitsationStatusMeds(pharmacyPrescriptionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/medicine-delivery/v2/digitisation/${pharmacyPrescriptionId}/status`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function submitDigitsationRequestMeds(
  pharmacyPrescriptionId,
  addressId,
  patientId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/digitisation/submit-request`,
      {
        pharmacyPrescriptionId,
        addressId,
        patientId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function saveNewPatientMeds(patientDetails, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/medicine-delivery/v2/`, patientDetails, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function editPatientMeds(patientDetails, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/medicine-delivery/v2/patients`,
      patientDetails,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removePatientMeds(patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/medicine-delivery/v2/patients/remove`,
      {
        patientId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function saveNewAddressMeds(addressDetails, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/medicine-delivery/v2/addresses`,
      addressDetails,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function editAddressMeds(addressDetails, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/address/edit`, addressDetails, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeAddressMeds(addressId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/medicine-delivery/v2/addresses/remove`,
      {
        addressId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementRequestMeds(
  patientId,
  pharmacyPrescriptionId,
  invoiceId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/medicine-delivery/v2/reimbursement-request`,
      {
        patientId,
        pharmacyPrescriptionId,
        invoiceId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementRequestUpdateMeds(
  reimbursementId,
  invoiceId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/medicine-delivery/v2/reimbursement-update`,
      {
        reimbursementId,
        invoiceId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
export function reimbursementSummaryMeds(
  pharmacyPrescriptionId,
  patientId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/medicine-delivery/v2/reiumbursement-summary?pharmacyPrescriptionId=${pharmacyPrescriptionId}&patientId=${patientId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function orderSummaryMeds(
  pharmacyPrescriptionId,
  patientId,
  addressId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/medicine-delivery/v2/order-summary?pharmacyPrescriptionId=${pharmacyPrescriptionId}&patientId=${patientId}&addressId=${addressId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function placeMedOrder(
  patientId,
  pharmacyPrescriptionId,
  addressId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/medicine-delivery/v2/place-order`,
      {
        patientId,
        pharmacyPrescriptionId,
        addressId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementDetailsMed(reimbursementId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/medicine-delivery/v2/reimbursement/${reimbursementId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchCartDetails(cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.pharmaServer}/cart/prescription/cartId/${cartId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchCartAddress(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.pharmaServer}/cart/addresses`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function modifyCartQuantity(cartId, items, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/cart/prescription/cartId/${cartId}/modify`,
      {
        items,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addAddressToCart(cartId, addressId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/cart/addresses/add`,
      {
        cartId,
        addressId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchPartnerList(cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.pharmaServer}/partners/list-partners?cartId=${cartId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchPrebookingSummary(cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.pharmaServer}/cart/prescription/cartId/${cartId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addNewAddress(body, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/address`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeAddressCart(addressId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/cart/addresses/remove`,
      { addressId },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function bookOrderForPharmacy(
  sponsorId,
  sponsorName,
  authToken,
  cartId,
  storeId,
  orderDeliveryType,
  amount = 0,
  history,
  isCareWallet,
  careWalletAmount
) {
  const headers = {
    authorization: authToken,
  };
  const redirectToPaymentGateway = () => {
    // Not on phonepe in-app or some other error.
    // send to payment

    const queryParams = new URLSearchParams();
    if (cartId) {
      queryParams.append('cartId', cartId);
    }
    if (storeId) {
      queryParams.append('storeId', storeId);
    }
    if (orderDeliveryType) {
      queryParams.append(
        'deliveryType',
        storeId ? 'pick-up' : orderDeliveryType
      );
    }
    if (isCareWallet) {
      queryParams.append('careWalletAmount', careWalletAmount);
      queryParams.append('isCareWallet', isCareWallet);
      queryParams.append(
        'careWalletUserPayableAmount',
        Number(amount - careWalletAmount)
      );
    }
    queryParams.append('amount', amount.toString());
    console.log('Proceed processing');
    window.location.assign(
      `${config.pharmaServer}/cart/submit?${queryParams.toString()}&auth=${
        authToken.split(' ')[1]
      }`
    );
  };
  if (amount === 0) {
    return redirectToPaymentGateway();
  }
  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      // Call the API to fetch the data context
      return axios
        .get(`${config.pharmaServer}/cart/transact`, {
          params: {
            cartId,
            amount,
          },
          headers,
        })
        .then((res) => {
          console.log(res);
          const responseBody = res.data;
          if (responseBody.message === 'success') {
            const context = responseBody.data;
            if (sdk.isMethodSupported('openPaymentsPage')) {
              history.replace('/processing-payment', {
                orderType: 'pharmacy',
                transactionId: responseBody.transactionId,
                successPage: `home/consult`,
                failurePage: `home/consult`,
                failureStateParameters: {
                  paymentFailed: true,
                },
              });
              return sdk.openPaymentsPage(
                'Visit',
                context,
                `${config.rootUrl}pharmacy`,
                'https://getvisitapp.com/img/png/Visit_logo.png',
                [
                  {
                    'Order For': 'Pharmacy',
                  },
                ]
              );
            }
            throw new Error('Phonepe method not supported');
          } else if (responseBody.message === 'failure') {
            history.push('/home', {
              bookingFailed: true,
              errorMessage: responseBody.errorMessage,
            });
            return;
          } else {
            throw new Error('Failed to get payment context');
          }
        });
    })
    .catch((err) => {
      console.log(err);
      try {
        const parsedError = JSON.parse(err);
        if (
          parsedError &&
          (parsedError.error_code === 'PAYMENT_DISMISS' ||
            parsedError.error_code === 'PAYMENT_TIMEOUT')
        ) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.replace('/home', {
            paymentFailed: true,
          });

          return;
        }
        // in case of android, it could still be a null error.
        if (parsedError === 'null' || parsedError === null) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('/home', {
            paymentFailed: true,
          });

          return;
        }
      } catch (err) {
        console.log(err);
        //  Since we couldn't parse the error, it means it's not a string and hence not a phonepe dismiss/timeout error.
        // Not on phonepe in-app or some other error.
        // send to payment
        return redirectToPaymentGateway();
      }
    });
}

export function fetchPharmacyOrderDetails(transactionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.pharmaServer}/cart/order/details/${transactionId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function selectPartner(cartId, selectionKeys, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/partners/select-partner`,
      {
        cartId,
        selectionKeys,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function selectPartnerStore(
  cartId,
  partnerId,
  partnerStoreId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/partners/select-partner-store`,
      {
        cartId,
        partnerId,
        partnerStoreId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function discountCalculator(
  cartId,
  sectionKey,
  selectionKeys,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/partners/discount-calculator`,
      {
        cartId,
        sectionKey,
        selectionKeys,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchPharmacyDigitizationDetails(
  orderId,
  consultationId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/digitisation/pharmeasy-order`,
      { orderId, consultationId },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchPrescriptionDetails(prescriptionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/fetch-prescription`,
      { prescriptionId },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function submitPrescriptionRequest(
  prescriptionId,
  addressId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/digitisation/submit-prescription`,
      {
        prescriptionId,
        addressId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export const getPharmacyTimeline = (
  authToken,
  transactionId,
  digitisationId
) => {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.pharmaServer}/cart/order/detailsTrack/${
        digitisationId ?? transactionId
      }`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody.showMessage;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const getCancelOrders = (authToken) => {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.pharmaServer}/cart/order/cancelreasons`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody.showMessage;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const pharmacyCompletedOrder = (authToken, offset = 0) => {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/myOrders`,
      {
        status: 'completed',
        limit: '10',
        offset,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody.showMessage;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const pharmacyCancelledOrder = (authToken, offset = 0) => {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/myOrders`,
      {
        status: 'cancelled',
        limit: '10',
        offset,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody.showMessage;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const pharmacySubmitReview = (
  authToken,
  orderId,
  stars,
  comment,
  reason
) => {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/addReview`,
      {
        orderId,
        stars,
        comment,
        reason,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody.showMessage;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const pharmacyCancelOrder = async (
  authToken,
  OrderNo,
  Remarks,
  Comment
) => {
  const headers = {
    authorization: authToken,
  };

  const resp = await axios.post(
    `${config.pharmaServer}/cancelOrder`,

    {
      OrderNo,
      Remarks,
      VendorName: 'VISITHEALTH',
      Comment,
    },
    {
      headers,
    }
  );

  const responseBody = resp.data;
  if (responseBody.message === 'success') {
    return responseBody;
  } else {
    const err = new Error('Invalid response');
    err.data = responseBody.showMessage;
    showError(err);
    throw err;
  }
};

export const cartModify = (authToken, body, cartId) => {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.pharmaServer}/cart/prescription/cartId/${cartId}/modify`,
      body,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody.showMessage;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
};
