/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

import { RegularText } from '../../common/Text';
import StyledTappable from '../../common/StyledTappable';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  margin: 0.25rem 0;
`;

const BubbleContainer = styled(StyledTappable)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 8px;
  background: #01bfbd;
  border-radius: 12px 12px 0px 12px;
`;

const Image = styled.div`
  background-size: cover;
  width: 12rem;
  height: 12rem;
  border-radius: 0.75rem;
  background-image: ${(props) => `url(${props.url})`};
  background-repeat: no-repeat;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
`;

const TickIcon = styled(FaCheck)`
  color: #fff;
  opacity: 0.5;
  font-size: ${() => `${0.625 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-right: 0.25rem;
`;

const TimestampText = styled(RegularText)`
  color: #fff;
  opacity: 0.5;
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const UserImageMessage = ({ filePreviewUrl, url, messageId, timestamp }) => (
  <OuterContainer>
    <BubbleContainer
      onTap={() => {
        document.querySelector(`#${messageId}`).click();
      }}
    >
      <Image url={filePreviewUrl} />
      {timestamp && (
        <TimestampContainer>
          <TickIcon />
          <TimestampText>
            {new Date(timestamp).toLocaleTimeString('nu', {
              hour: 'numeric',
              minute: '2-digit',
            })}
          </TimestampText>
        </TimestampContainer>
      )}
    </BubbleContainer>
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      id={messageId}
      style={{ display: 'none' }}
    />
  </OuterContainer>
);

UserImageMessage.propTypes = {
  filePreviewUrl: PropTypes.string,
  url: PropTypes.string,
  messageId: PropTypes.string,
  timestamp: PropTypes.number,
};

export default UserImageMessage;
