import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';
import { FaArrowLeft } from 'react-icons/fa';

import { Text } from './Text';
import arrowLeft from '../../images/common/arrow-left.svg';

const HeaderContainer = styled.div`
  z-index: 10;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid #f0f3f6;
  padding: 16px;
  padding-left: 24px;
  width: 100%;
`;

const BackContainer = styled(Tappable)`
  margin-right: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const HeaderText = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0f0b28;
`;

const Header = ({
  title,
  history,
  onTap,
  hideBackButton,
  color,
  textColor,
  img,
  icon,
  hasCrossButton,
  noBorder,
  children,
}) => (
  <HeaderContainer style={{ border: noBorder ? 'none' : '' }} color={color}>
    <div className="flex">
      {hideBackButton && <div className="mr-10">{icon}</div>}
      {!hideBackButton && (
        <BackContainer
          onTap={() => {
            if (onTap) {
              onTap();
            } else {
              history.goBack();
            }
          }}
        >
          <svg
            width="7"
            height="13"
            viewBox="0 0 7 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 2.35117L2.67342 6.96667L7 11.5822L5.66802 13L1.49449e-06 6.96667L5.66802 0.933335L7 2.35117Z"
              fill="#0F0B28"
            />
          </svg>
        </BackContainer>
      )}
      <HeaderText color={textColor}>{title}</HeaderText>
    </div>
    {hasCrossButton && (
      <button
        onClick={() => {
          if (onTap) {
            onTap();
          } else {
            history.goBack();
          }
        }}
        className="ml-auto"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.2993 11.625L11.625 10.2993L7.32569 5.99998L11.625 1.70068L10.2993 0.375L6.00002 4.67431L1.70068 0.375L0.375001 1.70067L4.67431 5.99998L0.375001 10.2993L1.70068 11.625L6.00001 7.32569L10.2993 11.625Z"
            fill="#333333"
          />
        </svg>
      </button>
    )}
    {children}
  </HeaderContainer>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  hideBackButton: PropTypes.bool,
  onTap: PropTypes.func,
  color: PropTypes.string,
  textColor: PropTypes.string,
};

Header.defaultProps = {
  hideBackButton: false,
  color: 'white',
  textColor: 'black',
};

export default withRouter(Header);
