import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PodcastAlbumPage from './PodcastAlbumPage';
import PodcastsHome from './PodcastsHome';
import SeeAllPodcasts from './SeeAllPodcasts';
import FitnessHome from './FitnessHome';
import SeeAllFitness from './SeeAllFitness';
import FitnessAlbumPage from './FitnessAlbumPage';

class HealthContentNavigator extends Component {
  constructor(props) {
    super(props);

    if (!props.authToken || props.loginState !== 'LOGGED_IN') {
      this.props.history.push('/');
    }
  }
  render() {
    return (
      <Switch>
        <Route
          path="/health-content/podcasts-home/:type"
          component={SeeAllPodcasts}
        />
        <Route
          path="/health-content/fitness-home/:type"
          component={SeeAllFitness}
        />
        <Route path="/health-content/podcasts-home" component={PodcastsHome} />
        <Route
          path="/health-content/sub-podcasts"
          component={PodcastAlbumPage}
        />
        <Route
          path="/health-content/sub-fitness"
          component={FitnessAlbumPage}
        />
        <Route path="/health-content/fitness-home" component={FitnessHome} />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(HealthContentNavigator);
