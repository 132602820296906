import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';

import { login } from '../../actions/index';
import { BoldText, RegularText } from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import { PrimaryButton } from '../../components/common/Buttons';

import warningIcon from '../../images/onboarding/exclaimation.svg';
import rightGraphic from '../../images/onboarding/onboarding-graphic-right.svg';
import leftGraphic from '../../images/onboarding/onboarding-graphic-left.svg';
import CheckedIcon from '../../images/onboarding/checked.svg';
import { logEvent } from '../../utils/logEvent';
import LoadingComponent from '../../components/common/LoadingComponent';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 1.25rem;
  background: #ffffff;
  position: relative;
  overflow: scroll;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
`;

const ScreenIcon = styled.img`
  max-width: 4.265rem;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.5 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  word-break: break-all;
  margin-top: 1rem;
  margin-bottom: 4px;
`;

const SubtitleText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  color: #76738c;
  letter-spacing: 0.5px;
  line-height: 24px;
`;

const MobileNumberText = styled(RegularText)`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  font-weight: 500;
  line-height: 18px;
  margin-top: 1.75rem;
  color: #0f0b28;
`;

const InputContainer = styled.div`
  padding: 0.75rem 1rem 0.75rem 1rem;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 12px;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const StyledButtonText = styled(BoldText)`
  color: white;
  line-height: 19.6px;
`;

const ConfirmButton = styled(PrimaryButton)`
  width: 100%;
  position: relative;
  top: 40%;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
  line-height: 24px;
  font-weight: 600;
  color: #0f0b28;
`;

const PhoneValidationText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.color};
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.25px;
  font-weight: 500;
`;

class OnboardingStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneValue: '',
      loading: false,
      phonePeToken: '',
      componentLoading: false,
      reVerify: false,
    };
  }

  componentDidMount = () => {
    const { history, loginState, location } = this.props;
    console.log(loginState);
    console.log(location.state);
    if (
      loginState === 'LOGGED_IN' &&
      location.state &&
      !location.state.reVerify
    ) {
      history.replace('/home');
    } else if (
      loginState === 'ONE_LAST_STEP' &&
      location.state &&
      !location.state.reVerify
    ) {
      history.push('/one-last-step');
    } else if (
      loginState === 'SPONSOR_EMAIL' &&
      location.state &&
      !location.state.reVerify
    ) {
      history.push('/corporate-onboard');
      return false;
    } else if (
      loginState === 'SELECT_USER' &&
      location.state &&
      !location.state.reVerify
    ) {
      history.push('/select-user');
    } else {
      // GA Tracking
      if (location.state && location.state.phone) {
        this.state.componentLoading = true;
        console.log('its here');
        this.state.phoneValue = location.state.phone;
        this.state.reVerify = location.state.reVerify;
        this.submitPhoneNumber();
      }
      logEvent('Onboarding Phone Number Screen');
    }
  };

  componentWillUpdate = () => {
    // otp error then change loading back to false
    if (this.state.loading && this.props.appError.hasError) {
      this.setState({
        loading: false,
      });
    }
  };

  submitPhoneNumber = () => {
    this.setState({
      loading: true,
    });
    console.log('Phone number submitted ', this.state.phoneValue);
    const { dispatch, history, location } = this.props;
    let encData;
    if (location.state) {
      encData = location.state.encData;
    }
    console.log(history);
    dispatch(
      login(
        this.state.phoneValue,
        91,
        encData,
        history,
        false,
        location.search,
        this.state.reVerify
      )
    );
  };

  render() {
    const { phonePeToken, componentLoading } = this.state;
    const phoneValueLink = Link.state(this, 'phoneValue');

    if (componentLoading) {
      return (
        <div className="w-full h-full">
          <LoadingComponent />
        </div>
      );
    }
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <ScreenIcon
              src={rightGraphic}
              alt="graphic"
              className="absolute top-0 right-0"
            />
            <ScreenIcon
              src={leftGraphic}
              alt="graphic"
              className="absolute top-16 left-0"
            />
          </HeaderContainer>
          <div className="my-36" style={{ width: '90%', height: '100%' }}>
            <TitleText style={{ color: '#302E6B' }}>
              Enter Mobile to
              <br />
              Login/Signup
            </TitleText>
            <SubtitleText>You will recieve an OTP</SubtitleText>
            <TitleText>{phonePeToken}</TitleText>
            <MobileNumberText>Mobile Number</MobileNumberText>
            <InputContainer
              borderColor={
                this.state.phoneValue.length === 10
                  ? '#6FCF97'
                  : this.state.phoneValue.length > 10
                  ? '#E86570'
                  : '#E3E3E3'
              }
            >
              <StyledInputBox
                type="tel"
                className="visit-input"
                valueLink={phoneValueLink}
                placeholder="Enter here"
              />
              <ScreenIcon
                src={CheckedIcon}
                alt="graphic"
                style={{
                  visibility:
                    this.state.phoneValue.length === 10 ? 'visible' : 'hidden',
                }}
              />
            </InputContainer>
            {this.state.phoneValue.length > 10 && (
              <PhoneValidationText color="#E86570">
                <ScreenIcon
                  src={warningIcon}
                  style={{ marginRight: '0.5rem' }}
                ></ScreenIcon>
                Invalid mobile number
              </PhoneValidationText>
            )}
            {this.state.phoneValue.length === 10 ? (
              <ConfirmButton
                onTap={this.submitPhoneNumber}
                loading={this.state.loading}
                // className="w-10/12 absolute bottom-12 left-0 right-0 text-center m-auto"
              >
                <StyledButtonText>Confirm</StyledButtonText>
              </ConfirmButton>
            ) : (
              ''
            )}
          </div>
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  appError: state.app.appError,
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(OnboardingStart);
