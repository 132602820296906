import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  FaTimes as RemoveIcon,
  FaAngleRight,
  FaChevronRight,
} from 'react-icons/fa';

import {
  dashboardApi,
  getConsultationSummary,
  homeTab,
  fetchConsultationBlocker,
  consultationTab,
  getHraPdfUrl,
  resetHra,
} from '../../services';
import {
  setCurrentActiveTab,
  setFitcoinsBalance,
  setManageDependentLink,
} from '../../actions';
import FitnessCard from '../../images/home/Fitness-Workout.png';
import PodcastCardImage from '../../images/home/Mindfulness-Podcast.png';

import LoadingComponent from '../../components/common/LoadingComponent';
import { BoldText, Text, ButtonText } from '../../components/common/Text';
import StyledTappable from '../../components/common/StyledTappable';
import DashboardActionCard from '../../components/home/DashboardActionCard';
import HealthActionCard from '../../components/home/HealthActionCard';
import HomeActionCard from '../../components/home/HomeActionCard';
import QuickActionCard from '../../components/home/QuickActionCard';
import SubscriptionCard from '../../components/home/SubscriptionCard';
import RewardsCard from '../../components/home/RewardsCard';
import ReferCard from '../../components/home/ReferCard';
import {
  FadeInTransition,
  FlyInTransition,
} from '../../components/transitions/transitions';
import Modal from '../common/Modal';
import UploadPrescription from '../consultation/UploadPrescription';

import treatIcon from '../../images/home/treat.svg';
import fitcoinLine from '../../images/common/fitcoin-line.png';
import fitcoinIconWhite from '../../images/common/rewardiconwhite.png';
import specialistIcon from '../../images/home/specialist.svg';
import labTestIcon from '../../images/home/lab-test.svg';
import medDelIcon from '../../images/home/med-del.svg';
import mealLogIcon from '../../images/home/meal-log.svg';
import metabolicProfileIcon from '../../images/home/metabolic-profile.svg';
import PodcastIcon from '../../images/home/podcast.png';
import FitnessIcon from '../../images/home/fitness.png';
import hraIcon from '../../images/home/hra.png';
import giftIcon from '../../images/common/gift-gold-icon.svg';
import backgroundPattern from '../../images/common/pattern.png';
import { RegularText } from '../../components/common/Text';
import { FitcoinButton } from '../../components/common/Buttons';
import symCheckIcon from '../../images/home/sym.svg';
import labIcon from '../../images/home/lab.svg';
import onlineIcon from '../../images/home/online.svg';
import offlineIcon from '../../images/home/offline.svg';
import medIcon from '../../images/home/med.svg';
import mealIcon from '../../images/home/meal.svg';
import metaIcon from '../../images/home/meta.png';
import PolicyActivationCard from '../../components/home/PolicyActivationCard';
import ImageCard from '../../components/home/ImageCard';
import ChatIcon from '../../images/home/chatIcon.png';
import membersIcon from '../../images/home/members.svg';
import initializeFreshDesk from '../../utils/freshchat';
import { logEvent } from '../../utils/logEvent';
import TinderBenefitsCard from '../../components/home/TinderBenefitsCard';
import WaterLogCard from '../../components/home/WaterLogCard';
import DependantIcon from '../../images/home/dependant.svg';
import NewHomeActionCard from '../../components/home/NewHomeActionCard';
import HospitalizationModal from '../common/HospitalizationModal';
import HraLogoIcon from '../../images/hra/hra-icon.png';
import RetakeHraIcon from '../../images/hra/retake.png';
import HraDownloadIcon from '../../images/hra/hra-download.png';
import CheckSymptomsIcon from '../../images/new-home-card-images/CheckSymptoms.png';
import EcardsContainer from './EcardsContainer';

const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #ffffff;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: stretch;
  padding: 0 0.5rem;
  margin-top: 0.4rem;
  margin-bottom: 1.5rem;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  max-width: 100%;
`;

const StyledActionCard = styled(DashboardActionCard)`
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0rem')};
  flex: 1 0;
`;

const QuickActionsContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-shrink: 0;
  margin-top: 0.4rem;
  margin-bottom: 1.5rem;
  overflow: scroll;
`;

const StyledQuickActionCard = styled(QuickActionCard)`
  width: 27%;
  flex: none;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.9);
  background-image: ${() => `url(${backgroundPattern})`};
  background-repeat: repeat;
  padding: 0.75rem;
  border-radius: 0.625rem 0.625rem 0 0;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
  padding: 0.5rem;
`;

const CloseIcon = styled(RemoveIcon)`
  color: white;
`;

const GiftImage = styled.img`
  width: 4rem;
  margin-bottom: 1.125rem;
`;

const LineSeparator = styled.img`
  width: 10rem;
  margin-bottom: 0.875rem;
`;

const ModalTitle = styled(BoldText)`
  font-size: ${() => `${1.125 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: white;
`;

const StepsContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const StepContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 1rem 0;
`;

const StepNumber = styled(RegularText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  border: 1px solid white;
  color: white;
  width: 2rem;
  height: 1.5rem;
  border-radius: 3rem;
`;

const StepTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1.125rem;
`;

const StepTextTile = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: white;
`;

const StepTextSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StepTextSubtitle = styled(RegularText)`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  text-align: left;
  opacity: 0.6;
  color: white;
`;

const FitcoinIcon = styled.img`
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.25rem;
`;

const ArrowIcon = styled(FaAngleRight)`
  color: #01bfbd;
`;

const AdditionalCardContainer = styled.div`
  margin: 0.4rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e3e3e3;
`;

const AdditionalCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

const PodcastCard = styled.div`
  width: 46%;
  margin-left: 2%;
  background: linear-gradient(128deg, #ff8d58 0%, #e3623b 100%);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 21px 16px;
  border-radius: 12px;
  margin-top: 12px;
`;

const PodcastHeading = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  margin-bottom: 4px;
`;

const PodcastDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.5px;
  color: #ffffff;
`;

const SubHeadings = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin-left: 30px;
  margin-top: 4px;
  margin-bottom: 20px;
`;

const HospitalizationModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
`;

const HraDownloadContainer = styled.div`
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
`;

const HraHeader = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  margin-right: 20px;
`;

const HraSubText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.5px;
  color: #767787;
  padding-top: 12px;
  margin-right: 24px;
`;

const HraDownloadAndResetButton = styled.div`
  background: ${(props) => (props.reset ? '#ffffff' : '#01BFBD')};
  border-radius: 8px;
  padding: 8px 0px;
  color: ${(props) => (props.reset ? '#01BFBD' : '#ffffff')};
  border: ${(props) => (props.reset ? '1px solid #01BFBD' : 'none')};
`;

const DownloadHraReportText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
`;

class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardApiLoaded: false,
      servicesCards: [],
      fitCoinsCards: [],
      additionalCards: [],
      blockers: {},
      tinderBenefits: null,
      quickActionCards: [
        // {
        //   cardType: 'meal-log',
        //   collapse: false,
        //   pendingConsult: 0,
        //   cardDirective: {
        //     actionType: 'native',
        //     action: { redirectTo: 'meal-log' },
        //   },
        //   labelIcon: 'search',
        //   label: 'Log Meal',
        //   labelInfo: 'Find a doctor nearby',
        //   actionIcon: 'arrow',
        // },
        // {
        //   cardType: 'metabolic-profile',
        //   collapse: false,
        //   pendingConsult: 0,
        //   cardDirective: {
        //     actionType: 'bot-chat',
        //     action: {
        //       entryPoint: 'log-meal-fitness-goal',
        //       evidenceId: 'go',
        //       flowType: 'fitnessGoal',
        //       text: 'Metabolic Profile',
        //     },
        //   },
        //   labelIcon: 'search',
        //   label: 'Metabolic Profile',
        //   labelInfo: 'Find a doctor nearby',
        //   actionIcon: 'arrow',
        // },
      ],
      hraCard: [
        {
          cardType: 'hra-card',
          collapse: false,
          pendingConsult: 0,
          cardDirective: {
            actionType: 'native',
            action: {
              redirectTo: 'hra',
            },
          },
          labelIcon: 'search',
          label: 'Metabolic Profile',
          labelInfo: 'Find a doctor nearby',
          actionIcon: 'arrow',
        },
      ],
      opdCards: [],
      inviteCards: [],
      showModal: false,
      modalVisible: false,
      showPendingConsultBlocker: false,
      pendingConsult: {
        doctorId: '',
        consultationId: '',
        doctorImg: '',
        doctorName: '',
        dateLabel: '',
      },
      enrollmentCard: [],
      ahcCard: [],
      healthContentCards: [],
      consoleCards: [],
      showHospitalizationModal: false,
      showPendingHospilationBlocker: false,
      htmlCards: [],
      hraDownloadableLink: '',
      policyCards: '',
    };
  }

  componentDidMount() {
    // GA Tracking
    logEvent('Home Tab');
    this.callDashboardApi();
    // // this.homeTab();
    this.fetchConsultationBlocker(); //please enable this code for consulation pending blocker
    this.getConsoleApiResponse(); // take the console cards for new design implementation
    this.props.dispatch(setCurrentActiveTab('home'));
    this.props.history.replace(this.props.selectedScreen);
  }

  getConsoleApiResponse() {
    const { authToken } = this.props;
    consultationTab(authToken)
      .then((res) => {
        const services = res.console;
        services.push(this.state.hraCard[0]);
        this.setState({
          loading: false,
          consoleCards: services,
          htmlCards: services.filter((s) => s.cardType === 'html'),
          // blockers: res.blockers,
        });
        this.getHraPdfLink();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ dashboardApiLoaded: true });
      });
  }

  callDashboardApi() {
    const { dispatch } = this.props;
    dashboardApi(this.props.authToken)
      .then((result) => {
        console.log('data', result);
        dispatch(setFitcoinsBalance(result.userInfo.walletBalance));
        result.home.forEach((categories) => {
          switch (categories.category) {
            case 'tinder-benefits':
              this.setState({
                tinderBenefits: categories,
              });
              break;
            case 'enrollment-card':
              this.setState({
                enrollmentCard: [categories],
              });
              break;
            case 'ahc':
              this.setState({
                ahcCard: [categories],
              });
              break;
            case 'services':
              this.setState({
                servicesCards: categories.cards,
              });
              break;
            case 'fitness-mind':
              this.setState({
                healthContentCards: categories.cards,
              });
            case 'fit-coins':
              this.setState({
                fitCoinsCards: categories.cards,
              });
              break;
            case 'opd-policy':
              this.setState({
                opdCards: categories.cards,
              });
              break;
            case 'additional-cards': {
              this.setState({
                additionalCards: categories.cards.filter(
                  (card) => card.cardType !== 'dependentAddCard'
                ),
              });
              const dependentCards = categories.cards.filter(
                (c) => c.cardKey === 'dependent-add-card'
              );
              console.log(dependentCards, categories.cards, 'mannn');
              if (dependentCards.length > 0)
                this.updateManageDependentLink(dependentCards);
              break;
            }
            case 'invite-earn':
              // this.setState({
              //   inviteCards: categories.cards,
              // });
              break;
            case 'policy-cards':
              this.setState({ policyCards: categories.cards[0].policyCards });
            default:
              break;
          }
        });
        // this.setState({
        //   dashboardApiLoaded: false,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  homeTab = () => {
    homeTab(this.props.authToken)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateManageDependentLink = (dependentCards) => {
    console.log(dependentCards, 'mannnn');
    const { cardDirective } = dependentCards[0];
    console.log(cardDirective.elements[0].redirectTo, 'yoooo');
    this.props.dispatch(
      setManageDependentLink(cardDirective.elements[0].redirectTo)
    );
  };

  startTreament = (
    showSearch,
    pendingConsult,
    cancelledRequest,
    showVerticals,
    verticalId
  ) => {
    const { history, authToken, userId } = this.props;
    if (cancelledRequest > 0) {
      history.push('/consultation/cancel-blocker', {
        requestId: cancelledRequest,
      });
    } else if (pendingConsult > 0) {
      getConsultationSummary(pendingConsult, userId, authToken)
        .then((summary) => {
          const { dateLabel, doctorId, doctorName, doctorImg } =
            summary.consultationInfo;
          this.setState({
            pendingConsult: {
              consultationId: pendingConsult,
              doctorId,
              doctorImg,
              doctorName,
              dateLabel,
            },
            showModal: true,
            showPendingConsultBlocker: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      history.push('/consultation/online/preview', {
        specialistSearch: false,
        searchType: 'find',
        showSearch,
        showVerticals: showVerticals
          ? showVerticals
          : verticalId
          ? false
          : true,
        verticalId: verticalId,
      });
    }
  };

  findSpecialist = (pendingConsult, cancelledRequest) => {
    const { history, authToken, userId } = this.props;

    if (this.state.blockers.cancelledRequest > 0) {
      this.props.history.push('/consultation/cancel-blocker', {
        requestId: this.state.blockers.cancelledRequest,
        goBack: true,
      });
    } else if (this.state.blockers.pendingRequestId > 0) {
      this.props.history.push('/consultation/pending-blocker', {
        requestId: this.state.blockers.pendingRequestId,
        goBack: true,
      });
    } else {
      history.push('/consultation/preview', {
        specialistSearch: false,
        searchType: 'find',
        from: 'home',
      });
    }
  };

  bookTests = () => {
    const { history } = this.props;
    history.push('/lab-test');
  };

  orderMedicine = () => {
    const { history } = this.props;
    history.push('/pharmacy');
  };

  hideModal = () => {
    this.setState({ showModal: false, showHospitalizationModal: false });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  openRewards = () => {
    const { history } = this.props;
    history.push('/home/rewards');
  };

  showFitcoinModal = () => {
    this.setState({
      showModal: true,
      modalVisible: true,
    });
  };

  requestReimbursement = (ele) => {
    const { history } = this.props;
    if (ele.fulfillmentDetailsAvailable) {
      history.push('/reimbursement');
    } else {
      history.push('/reimbursement/fulfillment-details', {
        fulfillmentDetailsAvailable: ele.fulfillmentDetailsAvailable,
      });
    }
  };

  medicineOrder = () => {
    const { history } = this.props;
    history.push('/pharmacy');
  };

  runAction(ele) {
    const { cardDirective } = ele;
    const { userId, history } = this.props;
    switch (cardDirective.actionType) {
      case 'bot':
        history.push('/bot', {
          chatName: cardDirective.action.text,
          channel: `visit.bot.${userId}`,
          action: cardDirective.action,
        });
        break;
      case 'native':
        switch (cardDirective.action.redirectTo) {
          case 'meal-log':
            logEvent('Meal Tracking Dashboard Card Clicked');
            history.push('/logmeal');
            break;
          case 'hra':
            logEvent('HRA Home Tab Card');
            history.push('/hra');
            break;
          case 'ahc-detail':
            history.push('/lab-test/ahc-package?showCloseButton=false');
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  closeUploadModal = () => {
    this.setState({
      showPendingConsultBlocker: false,
    });
  };

  submitUploadModal = (consultationId) => {
    const { history } = this.props;
    history.push('/home', {
      consultationId,
    });
  };

  fetchConsultationBlocker = () => {
    fetchConsultationBlocker(this.props.authToken)
      .then((res) => {
        this.setState({
          blockers: res.blockers,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleOutsideClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return null;
    }
    this.setState({ showPendingHospilationBlocker: false });
  };

  showHospitalization(ele) {
    this.setState({
      showHospitalizationModal: true,
      showPendingHospilationBlocker: true,
    });
  }

  openSupport = () => {
    initializeFreshDesk(this.props.user);
  };

  getHraPdfLink = () => {
    getHraPdfUrl()
      .then((result) => {
        if (result.message === 'success') {
          this.setState({ hraDownloadableLink: result.url });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  runAction(ele) {
    const { cardDirective } = ele;
    const { userId, history } = this.props;
    switch (cardDirective.actionType) {
      case 'bot':
        history.push('/bot', {
          chatName: cardDirective.action.text,
          channel: `visit.bot.${userId}`,
          action: cardDirective.action,
        });
        break;
      case 'native':
        switch (cardDirective.action.redirectTo) {
          case 'meal-log':
            history.push('/logmeal');
            break;
          case 'hra':
            history.push('/hra');
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  handleResetHra() {
    resetHra()
      .then((res) => {
        console.log(res, 'reset is done for hra');
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.props.history.push('/hra');
      });
  }

  render() {
    const { history, showFitCoins } = this.props;
    const {
      servicesCards,
      fitCoinsCards,
      opdCards,
      quickActionCards,
      hraCard,
      inviteCards,
      showModal,
      modalVisible,
      showPendingConsultBlocker,
      pendingConsult,
      tinderBenefits,
      enrollmentCard,
      ahcCard,
      additionalCards,
      healthContentCards,
      consoleCards,
      showHospitalizationModal,
      showPendingHospilationBlocker,
      htmlCards,
      hraDownloadableLink,
      policyCards,
    } = this.state;

    const symptomsCard = consoleCards.filter((c) => c.cardType === 'sym-check');
    const renderServicesCard = (ele, idx) => {
      switch (ele.cardType) {
        case 'con-assistant':
          return (
            <HomeActionCard
              key={idx}
              onTap={() =>
                this.startTreament(
                  ele.cardDirective.action.allowSearch,
                  ele.pendingConsult,
                  ele.cancelledRequest,
                  ele.cardDirective.action.showVerticals,
                  ele.cardDirective.action.verticalId
                )
              }
              offer={ele.offer}
              title={ele.label}
              subtitle={ele.labelInfo}
              earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
              icon={onlineIcon}
            />
          );
        case 'con-search':
          return (
            <HomeActionCard
              key={idx}
              onTap={() =>
                this.findSpecialist(ele.pendingConsult, ele.cancelledRequest)
              }
              offer={ele.offer}
              title={ele.label}
              subtitle={ele.labelInfo}
              earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
              icon={offlineIcon}
            />
          );
        // case 'sym-check':
        //   return (
        //     <HomeActionCard
        //       key={idx}
        //       onTap={() => this.runAction(ele)}
        //       title={ele.label}
        //       subtitle={ele.labelInfo}
        //       earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
        //       icon={symCheckIcon}
        //     />
        //   );
        // case 'hra-card':
        //   logEvent('HRA Home Tab Card');
        //   return (
        //     <HomeActionCard
        //       key={idx}
        //       onTap={() => this.runAction(ele)}
        //       title="Health Risk Assessment"
        //       subtitle="Get insights into your health"
        //       icon={hraIcon}
        //     />
        //   );
        case 'ahc-card':
          return (
            <ImageCard
              onTap={() => this.runAction(ele)}
              imageLink={ele.image}
            />
          );
        case 'support':
          return (
            <div
              className="support-card mb-5 mx-8px hover"
              onClick={this.openSupport}
              key={ele.cardType}
            >
              <div className="flex-center">
                <img src={ChatIcon} alt="chat" />
                <p className="ml-5">{ele.label}</p>
              </div>
              <ArrowIcon />
            </div>
          );
        default:
          return false;
      }
    };

    const renderHealthContentCards = (cards) => {
      return cards.map((card) => {
        switch (card.cardKey) {
          case 'tik-podcast':
            return (
              <button
                onClick={() =>
                  this.props.history.push('/health-content/podcasts-home')
                }
                className="mb-20px"
              >
                <img src={PodcastCardImage}></img>
              </button>
            );
          case 'tik-album':
            return (
              <button
                onClick={() =>
                  this.props.history.push('/health-content/fitness-home')
                }
                className="mb-20px"
              >
                <img src={FitnessCard}></img>
              </button>
            );
        }
      });
    };

    const renderActionsCard = (ele, idx) => {
      switch (ele.cardType) {
        case 'pharmacy':
          if (ele.showPharmacy) {
            return (
              <HomeActionCard
                onTap={() => this.orderMedicine(ele)}
                title={ele.label}
                icon={medIcon}
                offer={ele.offer}
                discountText={ele.labelBanner}
                earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
              />
            );
          }
          return;
        case 'lab-tests':
          return (
            <HomeActionCard
              onTap={this.bookTests}
              title={ele.label}
              icon={labIcon}
              offer={ele.offer}
              discountText={ele.labelBanner}
              earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
            />
          );
        default:
          return false;
      }
    };

    const renderQuickActionCard = (ele) => {
      switch (ele.cardType) {
        case 'meal-log':
          return (
            <HomeActionCard
              key={ele.cardType}
              onTap={() => this.runAction(ele)}
              title={ele.label}
              icon={mealIcon}
              discountText={ele.labelBanner}
              earnBadge={showFitCoins}
            />
          );
        case 'metabolic-profile':
          return (
            <HomeActionCard
              key={ele.cardType}
              onTap={() => this.runAction(ele)}
              title={ele.label}
              icon={metaIcon}
              discountText={ele.labelBanner}
              earnBadge={showFitCoins}
            />
          );
        default:
          return false;
      }
    };
    const renderOpdCard = (ele, idx) => {
      switch (ele.cardType) {
        // case 'buy-policy':
        //   return (
        //     <SubscriptionCard
        //       key={idx}
        //       alternatives={ele.alternatives}
        //       onTap={() => history.replace('/home/policy-purchased')}
        //     />
        //   );
        case 'activate-opd':
          return <PolicyActivationCard onTap={() => {}} />;
        default:
          return false;
      }
    };
    const renderInviteCard = (ele, idx) => {
      switch (ele.cardType) {
        case 'ftInvite':
          return <ReferCard key={idx} onTap={() => history.push('/refer')} />;
        default:
          return false;
      }
    };
    const renderFitcoinCard = (ele, idx) => {
      switch (ele.cardType) {
        case 'claim-coins':
          return (
            <RewardsCard
              key={idx}
              onSeeAllTapped={this.openRewards}
              onKnowMoreTapped={this.showFitcoinModal}
            />
          );
        default:
          return false;
      }
    };
    const renderEnrollmentCard = (ele, idx) => {
      console.log(ele);
      return (
        <div
          className="border border-txtsecondary-shades7 m-8px bg-textbox rounded-8 cursor-pointer"
          onClick={() => {
            window.location.assign(
              ele.cards[0].cardDirective.action.redirectTo
            );
          }}
          style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}
        >
          <div className="p-20px flex flex-row justify-between items-center">
            <div className="mr-16px">
              <p className="text-body-l font-semibold text-txtlight mb-12px">
                {ele.title}
              </p>
              <p className="text-body-s font-medium text-txtlight">
                {ele.text}
              </p>
            </div>
            <img
              src={ele.image}
              alt="enroll"
              style={{
                width: '30%',
              }}
            />
          </div>
          <div className="border-t border-txtsecondary-shades7 p-20px flex flex-row items-center justify-center">
            <img
              src={membersIcon}
              alt="enroll"
              style={{
                height: '20px',
              }}
            />
            <p className="text-body-l font-semibold text-primary ml-8px">
              {ele.buttonText}
            </p>
          </div>
        </div>
      );
    };

    const renderAhcCard = (ele, idx) => {
      console.log('ahc', ele);
      console.log(ele.cards.cardDirective.action.redirectTo);
      const url = new URL(ele.cards.cardDirective.action.redirectTo);
      return (
        <div
          // className="border border-txtsecondary-shades7 bg-textbox rounded-8 cursor-pointer"
          className="cursor-pointer"
          onClick={() => {
            // window.location.assign(ele.cards.cardDirective.action.redirectTo);
            history.push(url.searchParams.get('rt'));
          }}
        >
          <img
            src={ele.cards.link.split('=')[1]}
            // style={{ height: '40%', width: '100%' }}
            alt="ahc"
          />
        </div>
      );
    };

    const renderAdditionalCards = (ele, idx) => {
      console.log('additional card', ele);

      let url;
      let openInSameTab = false;
      if ((ele.cardDirective.action || {}).redirectTo) {
        url = new URL((ele.cardDirective.action || {}).redirectTo);
        openInSameTab = (ele.cardDirective.action || {}).openInSameTab;
      }
      if (ele.cardType === 'html') {
        return (
          <div style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}>
            <div
              // className="border border-txtsecondary-shades7 bg-textbox rounded-8 cursor-pointer"
              className="cursor-pointer my-16px mx-auto"
              onClick={() => {
                // window.location.assign(ele.cards.cardDirective.action.redirectTo);
                if (url && !openInSameTab) {
                  window.open(url, '_blank');
                } else if (url) {
                  window.location.assign(url);
                }
              }}
            >
              <img
                src={ele.link.split('=')[1]}
                // style={{ height: '40%', width: '100%' }}
                alt="ahc"
              />
            </div>
          </div>
        );
      }
      return (
        <AdditionalCardContainer style={{ width: '90%', marginLeft: '5%' }}>
          <AdditionalCard>
            <div className="border-b border-customE5E9FE">
              <div className="p-20px pr-16px">
                <div>
                  <p
                    style={{
                      color: '#0F0B28',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}
                  >
                    {ele.cardInfo.heading}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-center">
                  <p
                    style={{
                      fontSize: '12px',
                      color: '#72777A',
                      textAlign: 'start',
                      marginRight: '34px',
                      lineHeight: '150%',
                    }}
                  >
                    {ele.cardInfo.text}
                  </p>
                  <img
                    src={ele.cardInfo.image}
                    style={{
                      width: '96px',
                      height: '70px',
                      paddingRight: '12px',
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="p-16px flex flex-row items-center justify-center cursor-pointer"
              onClick={() => {
                if (ele.cardDirective.actionType === 'pwa-redirection') {
                  const link = ele.cardDirective.elements[0].redirectTo;
                  window.location.assign(link);
                }
              }}
            >
              <img
                src={DependantIcon}
                style={{ width: '20px', height: '20px', marginRight: '4px' }}
              />
              <p
                style={{
                  color: '#01BFBD',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                Manage Now
              </p>
            </div>
          </AdditionalCard>
        </AdditionalCardContainer>
      );
    };

    const renderHtmlCards = (ele, idx) => {
      console.log('additional card', ele);
      let url;
      let openInSameTab = false;
      try{
      if ((ele.cardDirective.action || {}).redirectTo) {
        url = new URL((ele.cardDirective.action || {}).redirectTo);
        openInSameTab = (ele.cardDirective.action || {}).openInSameTab;
      }
    }
    catch(err){
      console.log(err);
    }
      if (ele.cardType === 'html' && ele.link) {
        return (
          <div
            // className="border border-txtsecondary-shades7 bg-textbox rounded-8 cursor-pointer"
            className="cursor-pointer mt-16px mx-auto"
            onClick={() => {
              // window.location.assign(ele.cards.cardDirective.action.redirectTo);
              if (url && !openInSameTab) {
                window.open(url, '_blank');
              } else if (url) {
                window.location.assign(url);
              }
            }}
          >
            <img
              src={ele.link.split('=')[1]}
              // style={{ height: '40%', width: '100%' }}
              alt="ahc"
            />
          </div>
        );
      }
      return;
    };

    const renderHraDownloadReportCard = (link) => {
      return (
        <div
          style={{
            width: '90%',
            marginLeft: '5%',
            marginTop: '20px',
            paddingTop: '20px',
            borderTop: `${
              (htmlCards && htmlCards.length > 0) ||
              (ahcCard && ahcCard.length > 0)
                ? '2px solid #E6E9EC'
                : ''
            }`,
          }}
        >
          <HraDownloadContainer>
            <div
              className="flex flex-row"
              // style={{ border: '1px solid #F0F3F6' }}
            >
              <div className="p-20px">
                <HraHeader>Health Risk Assessment</HraHeader>
                <HraSubText>
                  You can now check report from your last HRA or take HRA again
                </HraSubText>
              </div>
              <div className="p-20px">
                <img
                  src={HraLogoIcon}
                  style={{ width: '117px', height: '78px' }}
                />
              </div>
            </div>
            <div className="px-20px mb-2">
              <HraDownloadAndResetButton
                className="flex flex-row items-center justify-center cursor-pointer"
                onClick={() => window.open(link, '_blank')}
              >
                <img
                  src={HraDownloadIcon}
                  style={{
                    marginRight: '10px',
                    width: '18px',
                    height: '18px',
                  }}
                />
                <DownloadHraReportText>Download Report</DownloadHraReportText>
              </HraDownloadAndResetButton>
            </div>
            <div className="px-20px mb-16px">
              <HraDownloadAndResetButton
                reset
                className="flex flex-row items-center justify-center cursor-pointer"
                onClick={() => this.handleResetHra()}
              >
                <img
                  src={RetakeHraIcon}
                  style={{
                    marginRight: '10px',
                    width: '18px',
                    height: '18px',
                  }}
                />
                <DownloadHraReportText>Take HRA Again</DownloadHraReportText>
              </HraDownloadAndResetButton>
            </div>
          </HraDownloadContainer>
        </div>
      );
    };

    const renderHealthContent = (cards) => {
      console.log(cards, 'dudeee');
      const card = cards[0];
      switch (card.cardType) {
        case 'sym-check':
          return (
            <AdditionalCardContainer>
              <AdditionalCard>
                <div className="border-b border-customE5E9FE w-full">
                  <div
                    className="flex flex-row items-center justify-between w-full pl-20px pb-5px"
                    style={{ paddingRight: '39px', paddingTop: '13px' }}
                  >
                    <div>
                      <div className="pt-18px">
                        <p
                          style={{
                            color: '#2D2066',
                            fontSize: '14px',
                            fontWeight: '600',
                            letterSpacing: '0.25px',
                          }}
                        >
                          {card.label}
                        </p>
                      </div>
                      <div className="mt-5px" style={{ paddingBottom: '22px' }}>
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#767787',
                            textAlign: 'start',
                            marginRight: '34px',
                            lineHeight: '150%',
                            fontWeight: '400',
                          }}
                        >
                          {card.description}
                        </p>
                      </div>
                    </div>
                    <div>
                      <img
                        src={CheckSymptomsIcon}
                        style={{
                          width: '75px',
                          height: '90px',
                          paddingRight: '12px',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="p-16px flex flex-row items-center justify-center cursor-pointer"
                  onClick={() => this.runAction(card)}
                >
                  <p
                    style={{
                      color: '#01BFBD',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}
                  >
                    Check Now
                  </p>
                </div>
              </AdditionalCard>
            </AdditionalCardContainer>
          );
      }
    };

    const renderConsoleCards = (card) => {
      switch (card.cardType) {
        case 'hospitalisation':
          return (
            <div className="flex justify-center items-center">
              <NewHomeActionCard
                iconType={card.cardType}
                title={'Hospitalisation'}
                offer={card.offer}
                onTap={() => {
                  logEvent('hospitalisation-request');
                  this.showHospitalization(card);
                }}
              />
            </div>
          );
        case 'claims':
          return (
            <div className="flex justify-center items-center">
              <NewHomeActionCard
                iconType={card.cardType}
                title={'Reimbursements'}
                offer={card.offer}
                onTap={() => this.requestReimbursement(card)}
              />
            </div>
          );
        case 'online-consults':
          return (
            <div className="flex justify-center items-center">
              <NewHomeActionCard
                iconType={card.cardType}
                title={'Online Consultations'}
                offer={card.offer}
                onTap={() => {
                  this.startTreament(
                    card.allowSearch,
                    card.pendingConsult,
                    card.cancelledRequest
                  );
                }}
              />
            </div>
          );
        case 'offline-consults':
          return (
            <div className="flex justify-center items-center">
              <NewHomeActionCard
                iconType={card.cardType}
                title={'Clinic Appointment'}
                offer={card.offer}
                onTap={() =>
                  this.findSpecialist(
                    card.pendingConsult,
                    card.cancelledRequest
                  )
                }
              />
            </div>
          );
        case 'pharmacy':
          return (
            <div className="flex justify-center items-center">
              <NewHomeActionCard
                iconType={card.cardType}
                title={'Medicine Delivery'}
                offer={card.offer}
                onTap={() => this.medicineOrder(card)}
              />
            </div>
          );
        case 'diagnostics':
          return (
            <div className="flex justify-center items-center">
              <NewHomeActionCard
                iconType={card.cardType}
                title={'Lab Tests'}
                offer={card.offer}
                onTap={this.bookTests}
              />
            </div>
          );
        // case 'hra-card':
        //   return (
        //     <>
        //       {!hraDownloadableLink && (
        //         <div className="flex justify-center items-center">
        //           <NewHomeActionCard
        //             iconType={card.cardType}
        //             title={'Health Risk Assessment'}
        //             offer={card.offer}
        //             onTap={() => this.props.history.push('/hra')}
        //           />
        //         </div>
        //       )}
        //     </>
        //   );
      }
    };

    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <>
          {this.state.dashboardApiLoaded ? (
            <BodyContainer>
              {policyCards.length > 0 && (
                <EcardsContainer policyCards={policyCards} />
              )}

              <div
                className="flex flex-col"
                style={{
                  paddingBottom: '30px',
                  borderBottom: '8px solid #EDEDF0',
                  paddingTop: '16px',
                }}
              >
                <SubHeadings>Services</SubHeadings>
                <div className="gridContainer">
                  {consoleCards &&
                    consoleCards.length > 0 &&
                    consoleCards.map(renderConsoleCards)}
                </div>
              </div>

              {additionalCards && additionalCards.length > 0 && (
                <div
                  style={{
                    paddingTop: '30px',
                    paddingBottom: '32px',
                    borderBottom: '8px solid #EDEDF0',
                  }}
                >
                  {additionalCards.map(renderAdditionalCards)}
                </div>
              )}

              {this.props.showWaterTracking && (
                <div
                  style={{
                    paddingTop: '30px',
                    paddingBottom: '32px',
                    borderBottom: `${
                      (htmlCards && htmlCards.length > 0) ||
                      (ahcCard && ahcCard.length > 0) ||
                      (symptomsCard && symptomsCard.length > 0) ||
                      (healthContentCards && !!healthContentCards.length)
                        ? '8px solid #EDEDF0'
                        : ''
                    }`,
                  }}
                >
                  <div>
                    <SubHeadings>Health Tracking</SubHeadings>
                  </div>
                  <WaterLogCard
                    authToken={this.props.authToken}
                    history={history}
                  />
                </div>
              )}

              {htmlCards && htmlCards.length > 0 && (
                <div
                  style={{ width: '90%', marginLeft: '5%', marginTop: '20px' }}
                >
                  {htmlCards.map(renderHtmlCards)}
                </div>
              )}

              {ahcCard && ahcCard.length > 0 && (
                <div
                  style={{ width: '90%', marginLeft: '5%', marginTop: '20px' }}
                >
                  {ahcCard.map(renderAhcCard)}
                </div>
              )}

              {hraDownloadableLink &&
                renderHraDownloadReportCard(hraDownloadableLink)}

              {symptomsCard && symptomsCard.length > 0 && (
                <div
                  className="mt-20px pt-20px"
                  style={{
                    borderTop: `${
                      htmlCards.length > 0 || ahcCard.length > 0
                        ? '2px solid #E6E9EC'
                        : ''
                    }`,
                    width: '90%',
                    marginLeft: '5%',
                  }}
                >
                  <SubHeadings style={{ marginLeft: '10px', marginTop: '0px' }}>
                    My Symptoms
                  </SubHeadings>
                  {renderHealthContent(symptomsCard)}
                </div>
              )}
              {!!healthContentCards.length && (
                <div
                  style={{
                    borderTop: `${
                      htmlCards.length > 0 ||
                      hraDownloadableLink ||
                      ahcCard.length > 0
                        ? '2px solid #E6E9EC'
                        : ''
                    }`,
                    margin: '0 20px',
                    marginTop: '20px',
                  }}
                >
                  <SubHeadings
                    style={{
                      marginLeft: '10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }}
                  >
                    Health Content
                  </SubHeadings>
                  <div className="px-10px">
                    {renderHealthContentCards(healthContentCards)}
                  </div>
                </div>
              )}

              {enrollmentCard && enrollmentCard.length > 0
                ? enrollmentCard.map(renderEnrollmentCard)
                : ''}

              {/* {tinderBenefits ? (
                <TinderBenefitsCard
                  benefits={tinderBenefits}
                  history={this.props.history}
                />
              ) : (
                ''
              )} */}

              {/* {opdCards.map(renderOpdCard)} */}

              {/* {servicesCards.map(renderServicesCard)} */}

              {/* <QuickActionsContainer>
                {quickActionCards.map(renderQuickActionCard)}
              </QuickActionsContainer> */}

              {/* {!tinderBenefits ? hraCard.map(renderServicesCard) : ''} */}

              {/* {servicesCards.map(renderActionsCard)} */}

              {/* {fitCoinsCards.map(renderFitcoinCard)} */}

              {inviteCards.map(renderInviteCard)}
            </BodyContainer>
          ) : (
            <LoadingComponent />
          )}
          {showModal && (
            <Modal>
              <ModalContainer>
                <FlyInTransition
                  in={modalVisible}
                  mountOnEnter
                  unmountOnExit
                  onExited={this.hideModal}
                  appear
                >
                  <ModalBody>
                    <CloseContainer onTap={this.closeModal}>
                      <CloseIcon />
                    </CloseContainer>
                    <GiftImage src={giftIcon} />
                    <LineSeparator src={fitcoinLine} />
                    <ModalTitle>Introducing FIT Coins</ModalTitle>
                    <StepsContainer>
                      <StepContainer>
                        <StepNumber>1</StepNumber>
                        <StepTextContainer>
                          <StepTextTile>Complete transactions</StepTextTile>
                          <StepTextSubtitle>
                            Book consultations, order medicines & Lab tests via
                            VISIT App
                          </StepTextSubtitle>
                        </StepTextContainer>
                      </StepContainer>
                      <StepContainer>
                        <StepNumber>2</StepNumber>
                        <StepTextContainer>
                          <StepTextTile>Earn FIT Coins</StepTextTile>
                          <StepTextSubtitle>
                            For every successful transaction, you earn upto 1000
                            FITCoins
                          </StepTextSubtitle>
                          <StepTextSubtitleContainer>
                            <StepTextSubtitle>
                              Spending ₹ 1 = 1{' '}
                            </StepTextSubtitle>
                            <FitcoinIcon src={fitcoinIconWhite} />
                          </StepTextSubtitleContainer>
                        </StepTextContainer>
                      </StepContainer>
                      <StepContainer>
                        <StepNumber>3</StepNumber>
                        <StepTextContainer>
                          <StepTextTile>Claim your rewards</StepTextTile>
                          <StepTextSubtitle>
                            Spend your FITcoins to claim vouchers, Phonepe Cash
                            and more offers.
                          </StepTextSubtitle>
                        </StepTextContainer>
                      </StepContainer>
                    </StepsContainer>
                    <FitcoinButton onTap={this.openRewards}>
                      <ButtonText>SEE REWARDS</ButtonText>
                    </FitcoinButton>
                  </ModalBody>
                </FlyInTransition>
                <FlyInTransition
                  in={showPendingConsultBlocker}
                  mountOnEnter
                  unmountOnExit
                  onExited={this.hideModal}
                  appear
                >
                  <UploadPrescription
                    doctorId={pendingConsult.doctorId}
                    consultationId={pendingConsult.consultationId}
                    doctorImg={pendingConsult.doctorImg}
                    doctorName={pendingConsult.doctorName}
                    dateLabel={pendingConsult.dateLabel}
                    onClose={this.closeUploadModal}
                    onSubmit={this.submitUploadModal}
                  />
                </FlyInTransition>
              </ModalContainer>
            </Modal>
          )}
          {showHospitalizationModal && (
            <Modal>
              <HospitalizationModalContainer onClick={this.handleOutsideClick}>
                <FadeInTransition
                  in={showPendingHospilationBlocker}
                  mountOnEnter
                  unmountOnExit
                  onExited={this.hideModal}
                  appear
                >
                  <div
                    ref={(node) => (this.node = node)}
                    className="bg-textbox flex flex-col items-center justify-center rounded-12"
                    style={{ width: '90%', maxWidth: '400px' }}
                  >
                    <HospitalizationModal
                      title={'Hospitalization'}
                      method1={'Claim Reimbursement'}
                      tag1={'Get reimbursed for your previous hospitalisation'}
                      method2={'Cashless Initmation Request'}
                      tag2={'Go cashless for your treatment'}
                      method3="Pre-Post Hospitalisation Claim"
                      tag3="Get reimbursed for your consultation/lab tests"
                      history={this.props.history}
                    />
                  </div>
                </FadeInTransition>
              </HospitalizationModalContainer>
            </Modal>
          )}
        </>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  userId: state.user.userId,
  user: state.user,
  showFitCoins: state.app.config.showFitCoins,
  sponsorLogo: state.app.config.sponsorLogo,
  showWaterTracking: state.app.config.showWaterTracking,
  selectedScreen: state.user.selectedScreen,
});

export default connect(mapStateToProps)(HomeContainer);
