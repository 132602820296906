import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CardContainer from '../common/CardContainer';
import { BoldText, Text } from '../common/Text';
import DownArrowIcon from '../../images/policy/down-arrow.png';

const OuterCard = styled(CardContainer)`
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  align-self: stretch;
  margin: 10px 10px 12px ${(props) => (props.index === 0 ? '24px' : '10px')};
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  max-width: 316px;
`;

const HeaderContainer = styled.div`
  padding: 20px 20px 18px 20px;
  // border: 1px solid rgba(235, 235, 235, 0.5);
  background: #99e5e5;
  display: flex;
  justify-content: space-between;
`;

const InsurerLogo = styled.img`
  height: 24px;
`;

const HeaderText = styled(BoldText)`
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #413958;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 20px 0 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  border-bottom: ${(props) =>
    props.noborder ? 'none' : '0.5px solid #e6e9ec'};
  padding-bottom: 8px;
`;

const Col = styled.div`
  flex: 1 0 auto;
  width: 50%;
  margin: 0 4px;
  display: inline-block;
`;

const Label = styled(Text)`
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  color: #666666;
`;

const SponsorLogo = styled.img`
  margin-top: 9px;
  height: 16px;
`;

const IpdPolicyCard = ({ policyDetails, index, onTap }) => (
  <OuterCard index={index}>
    <HeaderContainer>
      <InsurerLogo src={policyDetails.insurerLogo} />
      <p className="mt-4px text-title-m font-bold text-custom-413958">
        IPD E-Card
      </p>
      {/* <InsurerLogo src={policyDetails.tpaLogo} /> */}
    </HeaderContainer>
    <BodyContainer>
      <Row>
        <Label>Member Name</Label>
        <p className="text-body-s font-semibold text-custom-373737 truncate">
          {policyDetails.name}
        </p>
      </Row>
      <Row>
        <Label>Age</Label>
        <p className="text-body-s font-semibold text-custom-373737 truncate">
          {policyDetails.age}
        </p>
      </Row>
      <Row>
        {policyDetails.policyNumber && (
          <>
            <Label>Benefits Number</Label>
            <p className="text-body-s font-semibold text-custom-373737 truncate">
              {policyDetails.policyNumber}
            </p>
          </>
        )}
      </Row>
      <Row>
        <Label>Valid Till</Label>
        <p className="text-body-s font-semibold text-custom-373737 truncate">
          {policyDetails.validTill}
        </p>
      </Row>
      <Row noborder>
        <Col>
          <Label>Corporate Name</Label>
          <SponsorLogo src={policyDetails.sponsorLogo} />
        </Col>
        <Col>
          <div
            className="rounded-12 border mx-auto border-txtsecondary-shades7 hover text-center mr-12px flex flex-row items-center justify-center"
            onClick={() => {
              onTap(policyDetails.ecardUrl);
            }}
            style={{ padding: '9px 0px 11px' }}
          >
            <img
              src={DownArrowIcon}
              style={{
                marginRight: '6px',
                width: '7px',
                height: '8.33px',
                paddingTop: '1px',
              }}
            />
            <p
              className="text-chip font-semibold truncate"
              style={{ color: '#301D66' }}
            >
              Download COI
            </p>
          </div>
        </Col>
      </Row>
    </BodyContainer>
  </OuterCard>
);

IpdPolicyCard.propTypes = {
  onTap: PropTypes.func,
};

export default IpdPolicyCard;
