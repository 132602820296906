import { Component } from 'react';
import styled from 'styled-components';
import NeedHelp from '../../components/hospitalization/need-help';

const ModalBoundary = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  color: #131836;
  background: #f4f1f9;
  padding: 24px 27px;
`;

const InnerBoundary = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Button1 = styled.button`
  width: 90%;
  padding: 13px;
  background-color: #01bfbd;
  color: #ffffff;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
`;

const Button2 = styled.button`
  width: 90%;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #01bfbd;
  color: #0f0b28;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
`;

const Tag1 = styled.p`
  height: 16px;
  left: 8.75%;
  right: 8.75%;
  top: calc(50% - 16px / 2 + 2px);
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #263069;
  margin-bottom: 10px;
`;

const Tag2 = styled.p`
  height: 16px;
  left: 14.67%;
  right: 14.93%;
  top: calc(50% - 16px / 2 + 110px);
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #263069;
  margin-bottom: 10px;
`;

const OrContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px 0;
  margin-left: 5%;
  margin-right: 5%;
  position: relative;
`;

const Line = styled.div`
  width: 70%;
  border-bottom: 1px solid #d9ddf0;
`;

const Or = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #ffffff;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #263069;
`;

export default class HospitalizationModal extends Component {
  constructor(props) {
    super(props);
  }

  onClaimReimbursementHandler = () => {
    console.log('onClaimReimbursement');
    this.props.history.push('/hospitalization/claim-reimbursement');
  };

  onCashlessInitmationHandler = () => {
    console.log('onCashlessInitmationHandler');
    this.props.history.push('/hospitalization/cashless-intimation');
  };

  render() {
    const { title, method1, method2, tag1, tag2, tag3, method3 } = this.props;
    return (
      <ModalBoundary>
        <Title>{title}</Title>
        <div className="flex w-full justify-center items-center my-20px">
          <NeedHelp></NeedHelp>
        </div>
        <InnerBoundary style={{ paddingTop: '5px' }}>
          <Tag1>{tag1}</Tag1>
          <Button1 onClick={this.onClaimReimbursementHandler}>
            {method1}
          </Button1>
        </InnerBoundary>
        <OrContainer>
          <Line></Line>
          <Or>Or</Or>
        </OrContainer>
        <InnerBoundary>
          <Tag2>{tag2}</Tag2>
          <Button2 onClick={this.onCashlessInitmationHandler}>
            {method2}
          </Button2>
        </InnerBoundary>
        {/* <OrContainer>
          <Line></Line>
          <Or>Or</Or>
        </OrContainer>
        <InnerBoundary>
          <Tag1>{tag3}</Tag1>
          <Button1 onClick={this.onClaimReimbursementHandler}>
            {method3}
          </Button1>
        </InnerBoundary> */}
      </ModalBoundary>
    );
  }
}
