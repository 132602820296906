import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Header from '../../components/common/Header';
import LoadingComponent from '../../components/common/LoadingComponent';
import { PrimaryButton } from '../../components/common/Buttons';
import { getRewardsHistory } from '../../services';
import RewardCard from '../../components/common/RewardCard';
import { setCurrentActiveTab } from '../../actions';
import emptyIcon from '../../images/rewards/emptyIcon.svg';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  height: 100vh;
  background: #ffffff;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.875rem;
  align-items: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

class RewardsHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rewards: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    this.getRewardsHistory();
    this.props.dispatch(setCurrentActiveTab('purchase-history'));
  };

  getRewardsHistory() {
    const { authToken } = this.props;
    getRewardsHistory(authToken)
      .then((res) => {
        this.setState({
          //   rewards: res.purchases,
          rewards: [],
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  onTap = (ele) => {
    const { history } = this.props;
    console.log(ele);
    ele.couponCode = ele.couponCode ? ele.couponCode : 'NA';
    history.replace('/rewards/confirmation', {
      product: ele,
    });
  };
  render() {
    const { rewards, loading } = this.state;
    const renderReward = (ele, idx) => (
      <RewardCard
        key={idx}
        index={idx}
        productId={ele.productId}
        bgColor={ele.bgColor}
        logoUrl={ele.logoUrl}
        productName={ele.productName}
        offerLabel={ele.offerLabel}
        serviceImage={ele.serviceImage}
        redeemed
        couponCode={ele.couponCode}
        cost={ele.cost}
        purchaseDate={ele.purchaseDate}
        imageUrl={ele.imageLinks}
        layout={ele.layout}
        title={ele.title}
        onTap={() => this.onTap(ele)}
      />
    );
    return (
      <OuterContainer>
        <Header title="My Purchases" />
        <BodyContainer>
          {loading ? (
            <LoadingComponent />
          ) : rewards.length === 0 ? (
            <div className="flex flex-col mt-48px">
              <img
                src={emptyIcon}
                alt="empty"
                width={203.31}
                height={207}
                className="center"
              ></img>
              <p
                className="text-body-s text-center font-semibold text-txtlight"
                style={{ marginTop: '48px', marginBottom: '92px' }}
              >
                You have not redeemed any rewards yet
              </p>
              <PrimaryButton
                onTap={() => this.props.history.push('/home/rewards')}
              >
                <p className="font-body-l font-bold text-center">Redeem Now</p>
              </PrimaryButton>
            </div>
          ) : (
            <div className="reward-cards-container">
              {rewards.map(renderReward)}
            </div>
          )}
        </BodyContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(RewardsHistory);
