import React from 'react';
import PropTypes from 'prop-types';

import arrowRight from '../../images/common/arrow-right.svg';
import ellipseIcon from '../../images/common/ellipse.svg';
import tickIcon from '../../images/common/tick.svg';
import cautionIcon from '../../images/common/caution.svg';
import greenTick from '../../images/common/green-tick.svg';
import profileIcon from '../../images/common/profile.svg';
import stethoscope from '../../images/pharmacy/stethoscope.png';

const PrescriptionStatusCard = ({ orderRequest, onTap }) => {
  const {
    cardType: type,
    transactionId,
    openSection,
    consultationId,
  } = orderRequest;

  if (type === 'transcribing') {
    return (
      <div
        className="p-16px pb-12px mt-16px border border-txtsecondary-shades7 border-solid	rounded-12 cursor-pointer"
        onClick={() => {
          onTap(orderRequest);
        }}
      >
        <div className="flex flex-row justify-between	items-center pb-4px">
          <span className="text-title-s font-semibold">
            {orderRequest.title}
          </span>
          {openSection ? (
            <img className="float-right" src={arrowRight} alt="right" />
          ) : (
            ''
          )}
        </div>
        <div className="flex flex-row items-center pb-4px">
          <img
            src={ellipseIcon}
            alt="ellipse"
            height={11}
            width={12}
            className="icon-spin"
          />
          <span className="text-body-s text-secondary-shades6 font-regular pl-8px ">
            {orderRequest.statusMessage}
          </span>
        </div>
        <div className="text-body-s font-regular text-txtsecondary-shades8 pb-24px">
          {orderRequest.text}
        </div>
        <div className="text-chip text-txtsecondary">
          {orderRequest.prescribedTime}
        </div>
      </div>
    );
  } else if (type === 'transcribed') {
    return (
      <div
        className="p-16px pb-12px mt-16px border border-txtsecondary-shades7 border-solid	rounded-12 cursor-pointer"
        onClick={() => {
          onTap(orderRequest);
        }}
      >
        <div className="flex flex-row justify-between	items-center pb-4px">
          <div className="flex flex-row items-center">
            <img src={tickIcon} alt="tick" height={11} width={12} />
            <div className="text-body-s text-secondary-shades6 font-semibold pl-8px ">
              Transcribed
            </div>
          </div>
          <img className="float-right" src={arrowRight} alt="right" />
        </div>
        <div className="text-title-s font-semibold">{orderRequest.title}</div>
        <div className="flex flex-row items-center pb-2px mt-5px">
          <img src={stethoscope} alt="stethoscope" height={12} width={12} />
          <div className="text-body-s text-txtsecondary font-medium pl-4px ">
            {orderRequest.doctorName}
          </div>
        </div>
        <div className="flex flex-row items-center pb-24px">
          <img src={profileIcon} alt="profile" height={12} width={12} />
          <div className="text-body-s text-txtsecondary font-medium pl-4px ">
            {orderRequest.patientName}
          </div>
        </div>
        <div className="text-chip text-txtsecondary pb-8">
          {orderRequest.prescribedTime}
        </div>
        <div
          onClick={() => {
            onTap(orderRequest);
          }}
          className="bg-primary text-button-s font-semibold text-textbox text-center pt-10px pb-10px rounded-12 cursor-pointer"
        >
          {'View & Confirm'}
        </div>
      </div>
    );
  } else if (transactionId && transactionId > 0) {
    return (
      <div
        className="p-16px pb-12px mt-16px border border-txtsecondary-shades7 border-solid	rounded-12 cursor-pointer"
        onClick={() => {
          onTap(orderRequest);
        }}
      >
        <div className="flex flex-row justify-between	items-center pb-4px">
          <div className="flex flex-row items-center">
            {orderRequest.orderStatus === 'Order Cancelled' ? (
              <>
                <img src={cautionIcon} alt="tick" height={11} width={12} />
                <div className="text-body-s text-accent2-shades4 font-semibold pl-8px ">
                  {orderRequest.orderStatus}
                </div>
              </>
            ) : orderRequest.orderStatus === 'Order Delivered' ||
              orderRequest.orderStatus === 'delivered' ? (
              <>
                <img src={tickIcon} alt="tick" height={11} width={12} />
                <div className="text-body-s text-secondary-shades6 font-semibold pl-8px ">
                  {orderRequest.orderStatus}
                </div>
              </>
            ) : (
              <>
                <img src={greenTick} alt="tick" height={11} width={12} />
                <div className="text-body-s text-green-shades2 font-semibold pl-8px ">
                  {orderRequest.orderStatus}
                </div>
              </>
            )}
          </div>
          <img className="float-right" src={arrowRight} alt="right" />
        </div>
        <div className="text-title-s font-semibold">Medicines</div>
        <div className="flex flex-row items-center pb-2px mt-5px">
          <img src={stethoscope} alt="stethoscope" height={12} width={12} />
          <div className="text-body-s text-txtsecondary font-medium pl-4px ">
            {orderRequest.doctorName}
          </div>
        </div>
        <div className="flex flex-row items-center pb-24px">
          <img src={profileIcon} alt="profile" height={12} width={12} />
          <div className="text-body-s text-txtsecondary font-medium pl-4px ">
            {orderRequest.patientName}
          </div>
        </div>
        <div className="text-chip text-txtsecondary pb-8">
          {orderRequest.prescribedTime}
        </div>
        <div className="bg-primary text-button-s font-semibold text-textbox text-center pt-10px pb-10px rounded-12 cursor-pointer">
          {'View Detail'}
        </div>
      </div>
    );
  } else if (consultationId && consultationId > 0) {
    return (
      <div
        className="p-16px pb-12px mt-16px border border-txtsecondary-shades7 border-solid	rounded-12 cursor-pointer"
        onClick={() => {
          onTap(orderRequest);
        }}
      >
        <div className="flex flex-row justify-between	items-center pb-4px">
          <div className="flex flex-row items-center">
            <div className="text-body-s text-secondary-shades6 font-semibold">
              {orderRequest.orderStatus}
            </div>
          </div>
          <img className="float-right" src={arrowRight} alt="right" />
        </div>
        <div className="text-title-s font-semibold">Medicines</div>
        <div className="flex flex-row items-center pb-2px">
          <img src={stethoscope} alt="stethoscope" height={12} width={12} />
          <div className="text-body-s text-txtsecondary font-medium pl-4px ">
            {orderRequest.doctorName}
          </div>
        </div>
        <div className="flex flex-row items-center pb-24px">
          <img src={profileIcon} alt="profile" height={12} width={12} />
          <div className="text-body-s text-txtsecondary font-medium pl-4px ">
            {orderRequest.patientName}
          </div>
        </div>
        <div className="text-chip text-txtsecondary pb-8">
          {orderRequest.prescribedTime}
        </div>
        <div className="bg-primary text-button-s font-semibold text-textbox text-center pt-10px pb-10px rounded-12 cursor-pointer">
          {'View & Continue'}
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

PrescriptionStatusCard.propTypes = {
  onTap: PropTypes.func,
  orderRequest: PropTypes.object.isRequired,
};

export default PrescriptionStatusCard;
