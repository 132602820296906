import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../containers/common/Modal';
import { FlyInTransition } from '../../components/transitions/transitions';
import homeIcon from '../../images/pharmacy/home.svg';
import crossIcon from '../../images/common/cross.svg';
import { logEvent } from '../../utils/logEvent';
import Calendar from 'react-calendar';
import arrowRight from '../../images/reimbursement/arrow-right.svg';
import arrowLeft from '../../images/reimbursement/arrow-left.svg';
import { PrimaryButton } from '../common/Buttons';
import styled from 'styled-components';
import emergencyCaseIcon from '../../images/reimbursement/emergency-case.svg';
import ChoosePatients from './ChoosePatients';
import { emergencyReimbursement } from '../../services/reimbursement';
import { ButtonText, Text } from '../common/Text';
import moment from 'moment';

export class CalendarModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      selectedDate: '',
      emergencyreimbursment: '',
      totalemergencyreimbursment: '',
      showEmergency: false,
    };
    // console.log(props);
  }

  componentDidMount() {
    this.emergencyReimbursement();
  }

  emergencyReimbursement = () => [
    emergencyReimbursement(this.props.authToken)
      .then((res) => {
        // console.log(res);
        this.setState({
          emergencyreimbursment: res.emergencyReimbursements,
          totalemergencyreimbursment: res.totalEmergencyReimbursements,
          showEmergency: res.showEmergency,
        });
      })
      .catch((err) => {
        console.log(err);
      }),
  ];

  hideModal = () => {
    this.setState({ showModal: false });
    setTimeout(this.props.hideModal, 200);
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (
      (this.node && this.node.contains(e.target)) ||
      (this.node2 && this.node2.contains(e.target))
    ) {
      return;
    }
    this.hideModal();
  };

  onChange = (e) => {
    // console.log(e);
    this.setState({
      selectedDate: e,
    });
    this.props.selectedDate(e);
  };

  onProceed = () => {
    console.log('check here');
    this.props.onDateSelection();
  };

  render() {
    const {
      selectedDate,
      emergencyreimbursment,
      totalemergencyreimbursment,
      showEmergency,
    } = this.state;
    return (
      <Modal>
        <div className="modalContainer" onClick={this.handleOutsideClick}>
          <FlyInTransition
            in={this.state.showModal}
            mountOnEnter
            unmountOnExit
            appear
          >
            <div
              className="bg-textbox absolute bottom-0px w-full rounded-t-12 max-h-104 overflow-auto"
              ref={(node) => (this.node = node)}
            >
              <div className="p-20 border-b border-txtsecondary-shades7 border-solid flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <img src={homeIcon} alt="home" />
                  <span className="text-title-s ml-10px font-semibold text-txtlight">
                    Choose the date of treatment
                  </span>
                </div>
                <img src={crossIcon} alt="cross" onClick={this.hideModal} />
              </div>
              <div className="p-20">
                <div className="flex flex-row justify-center p-12px">
                  {/* {this.state.totalemergencyreimbursment -
                    this.state.emergencyreimbursment !=
                  0 ? ( */}
                  <Calendar
                    onChange={this.onChange}
                    value={this.state.selectedDate}
                    next2Label={false}
                    prev2Label={false}
                    className="reimbursent-calendar"
                    tileGroup={false}
                    calendarType="US"
                    nextLabel={
                      <img
                        src={arrowRight}
                        style={{
                          height: '24px',
                          width: '24px',
                        }}
                      />
                    }
                    prevLabel={
                      <img
                        src={arrowLeft}
                        style={{
                          height: '24px',
                          width: '24px',
                        }}
                      />
                    }
                  />
                  {/* ) : (
                    'You have exausted'
                  )} */}
                </div>
                {this.state.selectedDate ? (
                  <div>
                    {moment().diff(moment(this.state.selectedDate), 'hours') >
                      0 && showEmergency ? (
                      <div
                        className="rounded-12 w-full p-16px h-164px "
                        style={{ background: 'rgba(242, 85, 64, 0.05)' }}
                      >
                        <div className="flex flex-row">
                          <img src={emergencyCaseIcon} />
                          <div className="flex flex-row items-center">
                            <span className="text-title-m ml-10px font-semibold p-64px text-txtlight">
                              {emergencyreimbursment}/
                              {totalemergencyreimbursment}
                            </span>
                            <p className="text-title-m ml-5px p-5">
                              cases left
                            </p>
                          </div>
                        </div>
                        <p className="w-full p-12px">
                          Selected date has already past. This is going to go as
                          an emergency claim with the available emergency claim
                          limit.
                        </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {/* {this.state.totalemergencyreimbursment -
                      this.state.emergencyreimbursment !=
                    0 ? ( */}
                    {/* <div
                      className="rounded-12 w-full p-36px"
                      style={{
                        background:
                          'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 30.21%, #FFFFFF 95.31%)',
                      }}
                    >
                      <button
                        className="p-16px rounded-12 bg-primary w-full"
                        style={{ color: '#FFFFFF' }}
                        onClick={() => this.props.onDateSelection()}
                      >
                        Confirm
                      </button>
                    </div> */}
                    <div className="mt-40">
                      {/* {this.state.amount.length > 2 ? ( */}
                      <PrimaryButton
                        onTap={() => {
                          this.onProceed();
                          // this.props.onDateSelection();
                        }}
                      >
                        <ButtonText>Submit</ButtonText>
                      </PrimaryButton>
                      {/* // ) : ( // '' // )} //{' '} */}
                    </div>
                    {/* // ) : (
                    //   // 'You have exausted'
                    //   <div className="bg-custom01BFBD bg-opacity-10 rounded-12 p-16px text-body-l text-txtlight font-regular">
                    //     You have been exhausted
                    //   </div>
                    // )} */}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </FlyInTransition>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(CalendarModal);
